import React from "react";
import surveillance from "../../../assets/images/webp/surveillance.webp";
import { ListCheck } from "../../common/Icon";

const Surveillance = () => {
  return (
    <>
      <section className="   xl:py-24 overflow-x-hidden">
        <div className="container py-12">
          <div className=" flex-col-reverse flex lg:flex-row items-center justify-between  ">
            <div className=" w-full lg:w-1/2  m-auto">
              <div
                className="mt-8 lg:mt-0 "
                data-aos="fade-right"
                data-aos-delay="200"
              >
                <h3 className="text-black font-bold lg:leading-[55px] text-lg md:text-[32px] lg:text-xl font-Manrope">
                  24*7 Camera surveillance
                </h3>
                <p className="font-Poppins text-[#000000] font-normal leading-[160%] mb-5 mt-4 opacity-70 text-base lg:pe-20 ">
                  All security functions and processes are integrated within a
                  single framework, including cameras, entry/exit gates, license
                  plate recognition, tracking, notifications, and hardware,
                  firmware, and software updates. Our platform provides the
                  security assurance that shippers, brokers, and carriers need
                  for high value loads.
                </p>
                <ul className="font-Poppins">
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3  text-base flex items-center">
                    <span className=" me-[14px]">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      On Premise Video recording
                    </span>
                  </li>
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3  text-base flex items-center">
                    <span className=" me-[14px]">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">30 Days Video backup</span>
                  </li>
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3  text-base flex items-center">
                    <span className=" me-[14px]">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      Vehicle entry/exit Notifications
                    </span>
                  </li>
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3  text-base flex items-center">
                    <span className=" me-[14px]">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      LPR (License Plate Recognistion)
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className=" w-full lg:w-1/2 md:mt-10 lg:mt-0  ">
              <div
                className="lg:ms-10"
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <img
                  className="w-full max-w-[500px] lg:max-w-none m-auto"
                  src={surveillance}
                  alt="surveillance"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Surveillance;
