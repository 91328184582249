import React from "react";
import { LocationIcon, MessageIcon, PhoneIcon } from "../../common/Icon";
import { InlineWidget } from "react-calendly";
const Contact = () => {
  return (
    <>
      <div className="container lg:pt-20 sm:pt-15 pt-12">
        <div className="lg:flex">
          {/* <div className="flex  lg:max-w-[558px] w-full lg:w-[558px]  ">
            <form action="" className=" font-Poppins w-full pt-[80px] lg:pt-0">
              <label className="text-base w-full text-[#000000] block mb-[10px] ">
                Name
              </label>
              <input
                className="border-[1px]  border-[#0505053D]  rounded-[6px] mb-[14px] py-[11px] ps-3 w-full focus:outline-primary  "
                type="text"
                required
                placeholder="Enter Your Name*"
              />
              <label className="text-base w-full text-[#000000] block mb-[10px] mt-4">
                Phone Number
              </label>
              <input
                className="border-[1px] focus:outline-primary border-[#0505053D]  ] mb-[14px] rounded-[6px]  py-[11px] ps-3 w-full"
                type="number"
                required
                placeholder=" Enter Your Phone Number"
              />
              <label className="text-base w-full text-[#000000] block mb-[10px] mt-4">
                Email
              </label>
              <input
                className="border-[1px] focus:outline-primary border-[#0505053D] ] mb-[14px] rounded-[6px]  py-[11px] ps-3 w-full"
                type="email"
                required
                placeholder="Enter Your Email*"
              />
              <label className="text-base w-full text-[#000000] block mb-[10px] mt-4">
                Subject
              </label>
              <input
                className="border-[1px] focus:outline-primary border-[#0505053D] ] mb-[14px] rounded-[6px]  py-[11px] ps-3 w-full"
                type="text"
                required
                placeholder="Enter Subject*"
              />
              <label className="text-base w-full text-[#000000] block mb-[10px] mt-4">
                Message
              </label>
              <textarea
                className="border-[1px] focus:outline-primary border-[#0505053D] ] mb-[14px] rounded-[6px]  py-[11px] ps-3 w-full resize-none"
                type="text"
                required
                placeholder="Message*"
                rows={5}
              />
              <div>
                <button className="first_btn  px-[51px] font-Manrope  py-[14px]  text-base font-semibold  mt-[30px]">
                  Submit
                </button>
              </div>
            </form>

            <div class="calendly-inline-widget" data-url="https://calendly.com/park-nation?hide_landing_page_details=1&hide_gdpr_banner=1" style="min-width:320px;height:700px;"></div>
          </div> */}
          <div className="lg:ps-11 lg:pt-0	sm:pt-15 pt-12 max-w-full sm:max-w-[87%] w-full mx-auto  lg:w-[598px] ">
            <div className=" rounded-lg font-Poppins">
              <InlineWidget
                styles={{
                  minWidth: "320px",
                  height: "700px",
                  width: "100%",
                  maxWidth: "100%",
                  fontFamily: "'Poppins', sans-serif !important",
                  // height: "500px",
                  overflow: "hidden",
                  border: "1px solid #0505053D",
                  borderRadius: "8px",
                  boxShadow: " 0px 0px 3px rgba(0, 0, 0, 0.25)",
                }}
                url="https://calendly.com/park-nation/introduction-to-eaglepay-platform?background_color=ffffff&text_color=000000&primary_color=EB2022&hide_event_type_details=1&hide_gdpr_banner=1&branding=true"
                // url="https://calendly.com/muskansaini758?background_color=ffffff&text_color=000000&primary_color=EB2022&hide_event_type_details=1&hide_gdpr_banner=1&branding=true"
              />
            </div>
            <div className="flex pt-6">
              <a
                href="https://www.google.com/maps/place/350+Berlin+-+Cross+Keys+Rd,+Sicklerville,+NJ+08081,+USA/@39.7602021,-74.9741259,17z/data=!3m1!4b1!4m6!3m5!1s0x89c6d333b3c3ca8f:0x492d9ef775085d5a!8m2!3d39.760198!4d-74.971551!16s%2Fg%2F11c28hz5gn"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center"
              >
                <LocationIcon />
                <span className="ps-4 text-base font-normal text-black opacity-70 hover:text-primary transition ease-in-out delay-200 font-Poppins cursor-pointer">
                  350 Berlin Cross Keys Rd, Sicklerville, NJ 08081
                </span>
              </a>
            </div>
            <div className="flex pt-6">
              <a
                href="mailto:info@parknation.org"
                className="flex items-center"
              >
                <MessageIcon />
                <span className="ps-4 text-base font-normal  text-black opacity-70  hover:text-primary transition ease-in-out delay-200 font-Poppins cursor-pointer">
                  info@parknation.org
                </span>
              </a>
            </div>
            <div className="flex pt-6">
              <a href="tel:+(856) 210-2133" className="flex items-center">
                <PhoneIcon />
                <span className="ps-4 text-base font-normal text-black opacity-70  hover:text-primary transition ease-in-out delay-200 font-Poppins cursor-pointer f">
                  (856) 210-2133
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
