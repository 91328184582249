/* eslint-disable no-unused-vars */

import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  // eslint-disable-next-line no-unused-vars
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
import * as uuid from "uuid";
import AppConfig from "../constants/AppConfig";
import { delay, buffers, eventChannel, END } from "redux-saga";
//   import * as io from "socket.io-client";
import { types as sessionTypes } from "../reducers/sessionreducer";
import { types as siteTypes } from "../reducers/sitereducer";
import { types as entitlementTypes } from "../reducers/entitlementsreducer";
import { types as insertedDataTypes } from "../reducers/insertedDataReducer";
import { types as referenceDataTypes } from "../reducers/referenceDataReducer";
import { types as actionDataTypes } from "../reducers/actionReducer";
import { types as subscriptionsTypes } from "../reducers/subscriptionsReducer";
import { types as invoicesTypes } from "../reducers/invoicesReducer";
import { types as customerTypes } from "../reducers/customerReducer";
import { types as notificationTypes } from "../reducers/notificationreducer";
import { isNil, trim, cloneDeep, sortBy } from "lodash";
import { types as errorTypes } from "../reducers/errorDataReducer";
import { types as ccTypes } from "../reducers/ccreducer";
import { types as expenseTypes } from "../reducers/expensesReducer";
import { types as reportsTypes } from "../reducers/reportsReducer";
import { types as documentTypes } from "../reducers/documentreducer";

import { hasErrors } from "./commonSagas";
import { types as spaceTypeRateTypes } from "../reducers/spacetyperatesreducer";
import { types as emailInvoiceTypes } from "../reducers/emailinvoicereducer";
import { types as vehicleTypes } from "../reducers/vehiclereducer";
import { PNfetch } from "./../api/pnfetch";
//import { push } from 'react-router-redux';
//let socketRef;

const defaultSagaData = {
  data: [],
  message: "",
  hasLoaded: true,
  hasError: false,
  lastFetchSuccess: Date.now(),
  loading: false,
};

const defaultData = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

const headersObj = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};

const restApi = {
  insertUpdateNotifications(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spu_notifications",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  insertSiteData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_sites",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  insertSpaceTypeData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_space_types",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  insertCardData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      fetch(`https://dev.parknation.org/api/v1.0/users/${parms[1]}/cards`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          name: parms[2], // optional
          number: parms[3],
          exp_month: parms[4],
          exp_year: parms[5],
          cvc: parms[6],
          default: false, // optional default  false
          address_zip: parms[7], // optional
          // proc: "spi_documents",
          // input: parms,
        }),
      })
        // .then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  insertUpdateProfile(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {

    console.log("parms Profile", parms);

    if (trim(parms[9]) != "") {
      let URL = process.env.REACT_APP_API_URL + `/stripeApi/hashPassword`;
      // let URL = `http://localhost:8080/stripeApi/deleteCard`;
      // if (process.env.NODE_ENV == "production") {
      //   URL =  'http://localhost:3000' +  `/stripeApi/deleteCard`;
      // }

      fetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          hashp: parms[9],
        }),
      })
        .then((response) => {
          const resp = response.json();
          console.log("response *** Pwd", resp);
          parms[9] = resp.pwd;
          return (
            PNfetch(AppConfig.SQL_URL, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                authorization: "Bearer " + localStorage.getItem("accessToken"),
              },
              body: JSON.stringify({
                proc: "spi_user_profile",
                input: parms,
              }),
            })
              //.then(statusHelper)
              .then((response) => response.json())
              .catch((error) => error)
          );
        })
        .catch((error) => {
          return error;
        });

      // if (!response.ok) {
      //   const message = `An error has occured: ${response.status}`;
      //   throw new Error(message);
      // }
    } else {
      return (
        PNfetch(AppConfig.SQL_URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            proc: "spi_user_profile",
            input: parms,
          }),
        })
          //.then(statusHelper)
          .then((response) => response.json())
          .catch((error) => error)
      );
    }
  },
  insertUpdateLayout(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_user_layout_preferences",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  insertUpdateRateType(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_spacedatabytypeandsite",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  insertDocumentData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_documents",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  insertVehicleData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_vehicles",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  getInvoiceEmailBalanceData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_getEmailInvoice_log",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  getPNSignatureData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_getEmailInvoice_log",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  insertCustomerData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_customers",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  // insertVehicleData(parms) {
  //   // console.log(userData.user);
  //   // console.log(userData.password);

  //   //new Promise((resolve, reject) => {
  //   //return fetch("http://localhost:3003/loginsvc/", {
  //   // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
  //   return (
  //     PNfetch(AppConfig.SQL_URL, {
  //       method: "POST",
  //       headers: headersObj,
  //       body: JSON.stringify({
  //         proc: "spi_vehicles",
  //         input: parms,
  //       }),
  //     })
  //       //.then(statusHelper)
  //       .then((response) => response.json())
  //       .catch((error) => error)
  //   );
  // },

  insertAdminSiteData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_admin_sites",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  insertSubscriptionData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_subscription",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  deleteUserData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_customer",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  deleteSubscriptionData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_subscription",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteInvoice(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_invoice",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  async insertInvoiceData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {

    const PNInvoice = PNfetch(AppConfig.SQL_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        proc: "spi_invoice",
        input: parms,
      }),
    })
      //.then(statusHelper)
      .then((response) => response.json())
      .catch((error) => error);

    console.log("PNInvoice", PNInvoice);

    if (!isNil(PNInvoice) && !hasErrors(PNInvoice)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      const PNInvoiceRec =
        PNInvoice.data.length > 0 ? PNInvoice.data[0][0] : PNInvoice.data;

      let URL =
        process.env.REACT_APP_API_URL + `/stripeApi/createUpdateInvoice`;
      // let URL = `http://localhost:8080/stripeApi/createUpdateInvoice`;
      // if (process.env.NODE_ENV == "production") {
      //   URL = 'http://localhost:3000' +  `/stripeApi/createUpdateInvoice`;
      // }

      console.log("PNInvoiceRec", PNInvoiceRec);
      console.log("Id", PNInvoiceRec.id);
      console.log("parms", parms);
      console.log("session Id", parms[1]);
      const stripeParms = {
        invoice_id: PNInvoiceRec.id, //this.state.invoiceId,
        session_user_id: parms[1], // this.props.userId,
      };

      console.log("stripeParms", stripeParms);

      const stripeReturn = PNfetch(URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          ...stripeParms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error);

      return stripeReturn;
    } else {
      return PNInvoice;
    }
  },

  getUserData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_CustomerOnlyData",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  deleteReportData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_report",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteExpenseData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_expense",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteInvoiceData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_invoice",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteDocumentData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_documents",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteCardData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      fetch(
        `https://dev.parknation.org/api/v1.0/users/${parms[1]}/cards/${parms[0]}`,
        {
          // PNfetch(AppConfig.SQL_URL, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({}),
        }
      )
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteVehicleData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_vehicle",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteAdminSiteData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_adminsite",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  getData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_Reference_Data",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  getNotificationsDataForUser(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_notificationsdata",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  getSitesDataForUser(parms) {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify({
          proc: "sps_getsites",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  getSiteVacancyDataForUser(parms) {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          //authorization: 'Bearer ' + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify({
          proc: "sps_get_all_Siteavailable_Spaces",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  getSpecificDataForUser(parms) {
    // console.log(userData.user);
    console.log(
      "****accessToken in getDataForUser ****",
      localStorage.getItem("accessToken")
    );
    console.log(
      "****refreshToken in getSpecificDataForUser ****",
      localStorage.getItem("refreshToken")
    );

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_SpecificReference_DataForUser",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  getDataForUser(parms, customerCount) {
    // console.log(userData.user);
    console.log(
      "****accessToken in getDataForUser ****",
      localStorage.getItem("accessToken")
    );
    console.log(
      "****refreshToken in getDataForUser ****",
      localStorage.getItem("refreshToken")
    );

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc:
            customerCount <= 1
              ? "sps_get_Reference_Data" //sps_get_Reference_Data
              : "sps_get_Reference_Data", //sps_get_Reference_DataForUser
          input: customerCount <= 1 ? [parms[0], "Y"] : [parms[0], "Y"], //parms, We are getting Balances All the Time
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
};

function statusHelper(response) {
  // debugger;
  if (!response.ok) {
    // throw response;
    return response;
    // const error = new Error(response.statusText);
    // error.response = response;
    // throw error;
    //throw Error(response);
  }
  return response;
}

export function* getSitesDataForUser(userData) {
  try {
    //yield call(delay, 5000)
    console.log(userData.parms);

    let resultObj = yield call(restApi.getSitesDataForUser, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      console.log(resultObj.data);
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      // const dataEnriched = data.map((rec) => ({
      //   key: uuid.v4(),
      //   ...rec,
      // }));

      yield put({
        type: siteTypes.SITEDATA,
        siteData: {
          ...defaultSagaData,
          data: data,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: siteTypes.SITEDATA,
        siteData: {
          ...defaultSagaData,
          message: "",
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: siteTypes.SITEDATA,
      siteData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: siteTypes.SITEDATA,
        siteData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

function* deleteUser(userData) {
  try {
    let resultObj = yield call(restApi.deleteUserData, userData.parms);

    const getSessionData = (state) => state.sessionData.data;
    const sessionData = yield select(getSessionData);

    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      const dataEnriched = data.map((rec) => ({ key: uuid.v4(), ...rec }));

      const getReferenceData = (state) => state.referenceData.data;
      const referenceDataCurrent = yield select(getReferenceData);

      const currentUsers = referenceDataCurrent.users;
      const newUsers = currentUsers.filter(
        (user) => user.user_id.toString() != userData.parms[0].toString()
      );

      referenceDataCurrent.users = cloneDeep(newUsers);
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceDataCurrent),
          lastFetchSuccess: Date.now(),
        },
      });

      //Very IMP to override the customer Data
      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: cloneDeep(referenceDataCurrent.users),
          lastFetchSuccess: Date.now(),
        },
      });

      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* deleteSubscription(userData) {
  try {
    // yield put({
    //   type: subscriptionsTypes.SUBSCRIPTIONSDATA,
    //   subscriptionsData: {
    //     ...defaultData,
    //     lastFetchSuccess: Date.now(),
    //   },
    // });

    const subscription_id = userData.parms[0];
    const user_id_fk = userData.parms[2];
    const fromUser = userData.parms[3];

    let subscriptionsDataNew;

    let resultObj = yield call(restApi.deleteSubscriptionData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getSubscriptionsData = (state) => state.subscriptionsData.data;
      const subscriptionsDataCurrent = yield select(getSubscriptionsData);

      if (fromUser != "Y") {
        subscriptionsDataNew = subscriptionsDataCurrent.filter(
          (rec) => rec.subscription_id != subscription_id
        );
      } else {
        subscriptionsDataNew = subscriptionsDataCurrent.filter(
          (rec) =>
            rec.subscription_id != subscription_id &&
            rec.user_id_fk == user_id_fk
        );
      }

      // const data =
      //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      // const dataEnriched = data.map((rec) => ({
      //   key: uuid.v4(),
      //   searchText:
      //     rec.name +
      //     "~" +
      //     rec.extra_info +
      //     "~" +
      //     rec.subscription_title +
      //     "~" +
      //     rec.site_name +
      //     "~" +
      //     rec.subscription_type +
      //     "~" +
      //     rec.subscription_status,
      //   ...rec,
      // }));

      yield put({
        type: subscriptionsTypes.SUBSCRIPTIONSDATA,
        subscriptionsData: {
          ...defaultSagaData,
          data: subscriptionsDataNew, //dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      //No need to do this
      // const getSessionData = (state) => state.sessionData.data;
      // const sessionData = yield select(getSessionData);

      // //Refresh the Reference Data
      // const payload = {
      //   parms: [userData.user_id_fk ? userData.user_id_fk : userData.parms[1]],
      //   // parms: [userData.parms[1]], //User id
      // };
      // //  debugger;
      // const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* deleteCard(userData) {
  try {
    //This is called directly from the screen
    // debugger;
    const getSessionData = (state) => state.sessionData.data;
    const sessionData = yield select(getSessionData);

    let resultObj = yield call(restApi.deleteCardData, userData.parms);
    // debugger;
    //Refresh the Reference Data
    const payload = {
      parms: [sessionData.session_user_id],
    };
    const fetchTask = yield fork(getDataForUser, payload);
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}
function* deleteDocument(userData) {
  try {
    let resultObj = yield call(restApi.deleteDocumentData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_name +
          "~" +
          rec.file_type +
          "~" +
          rec.file_name +
          "~" +
          rec.document_type +
          "~" +
          rec.file_title,

        ...rec,
      }));

      referenceData.documents = cloneDeep(dataEnriched);
      yield put({
        type: documentTypes.DOCUMENTDATA,
        documentData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}
function* deleteVehicle(userData) {
  try {
    //yield call(delay, 5000)

    // const getInsertedData = (state) => state.insertedData.data;
    // const insertedData = yield select(getInsertedData);

    // console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.deleteVehicleData, userData.parms);

    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [userData.user_id_fk ? userData.user_id_fk : userData.parms[1]],
        // parms: [userData.parms[1]],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    }
    // //Refresh the Reference Data
    // const payload = {
    //   parms: [sessionData.session_user_id],
    // };
    // const fetchTask = yield fork(getDataForUser, payload);
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* deleteAdminSite(userData) {
  try {
    //yield call(delay, 5000)

    // const getInsertedData = (state) => state.insertedData.data;
    // const insertedData = yield select(getInsertedData);

    // console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    const getSessionData = (state) => state.sessionData.data;
    const sessionData = yield select(getSessionData);

    let resultObj = yield call(restApi.deleteAdminSiteData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* deleteReport(userData) {
  try {
    //yield call(delay, 5000)

    // const getInsertedData = (state) => state.insertedData.data;
    // const insertedData = yield select(getInsertedData);

    // console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.deleteReportData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        id: uuid.v4(),
        searchText:
          rec.rpt_name + "~" + rec.rpt_type + "~" + rec.rpt_email_users,

        ...rec,
      }));
      console.log("reportsEnriched Data**********", dataEnriched);

      yield put({
        type: reportsTypes.REPORTSDATA,
        reportsData: {
          ...defaultSagaData,
          data: dataEnriched,
          // expenseCategories,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}
function* deleteExpense(userData) {
  try {
    //yield call(delay, 5000)

    // const getInsertedData = (state) => state.insertedData.data;
    // const insertedData = yield select(getInsertedData);

    // console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    const expense_id = userData.parms[0];
    // const user_id_fk = userData.parms[2];

    let resultObj = yield call(restApi.deleteExpenseData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getExpensesData = (state) => state.expensesData.data;
      const expensesDataCurrent = yield select(getExpensesData);

      const expensesDataNew = expensesDataCurrent.filter(
        (rec) => rec.expense_id != expense_id
      );

      //Don't do this as we are filtering on the UI
      // yield put({
      //   type: expenseTypes.EXPENSESDATA,
      //   expensesData: {
      //     ...defaultSagaData,
      //     data: expensesDataNew,
      //     lastFetchSuccess: Date.now(),
      //   },
      // });

      // resultObj = JSON.parse(resultObj);
      //  debugger;
      // const data =
      //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      // const dataEnriched = data.map((rec) => ({
      //   key: uuid.v4(),
      //   searchText:
      //     rec.notes +
      //     "~" +
      //     rec.expense_title +
      //     "~" +
      //     rec.expense_message +
      //     "~" +
      //     rec.site_name,
      //   ...rec,
      // }));

      // yield put({
      //   type: expenseTypes.EXPENSESDATA,
      //   expensesData: {
      //     ...defaultSagaData,
      //     data: dataEnriched,
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* deleteInvoice(userData) {
  try {
    //yield call(delay, 5000)

    // const getInsertedData = (state) => state.insertedData.data;
    // const insertedData = yield select(getInsertedData);

    // console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    const invoice_id = userData.parms[0];
    const user_id_fk = userData.parms[2];
    const fromUser = userData.parms[3];

    let invoiceDataNew;
    let resultObj = yield call(restApi.deleteInvoiceData, userData.parms);

    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getInvoicesData = (state) => state.invoicesData.data;
      const invoicesDataCurrent = yield select(getInvoicesData);

      if (fromUser != "Y") {
        invoiceDataNew = invoicesDataCurrent.filter(
          (rec) => rec.invoice_id != invoice_id
        );
      } else {
        invoiceDataNew = invoicesDataCurrent.filter(
          (rec) => rec.invoice_id != invoice_id && rec.user_id_fk == user_id_fk
        );
      }

      // const data =
      //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      // const dataEnriched = data.map((rec) => ({
      //   key: uuid.v4(),
      //   searchText:
      //     rec.name +
      //     "~" +
      //     rec.invoice_type +
      //     "~" +
      //     rec.extra_info +
      //     "~" +
      //     rec.invoice_title +
      //     "~" +
      //     rec.invoice_message,
      //   ...rec,
      // }));

      yield put({
        type: invoicesTypes.INVOICESDATA,
        invoicesData: {
          ...defaultSagaData,
          data: invoiceDataNew, // dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      // const getSessionData = (state) => state.sessionData.data;
      // const sessionData = yield select(getSessionData);

      // //Refresh the Reference Data
      // const payload = {
      //   parms: [userData.user_id_fk ? userData.user_id_fk : userData.parms[1]],
      //   // parms: [userData.parms[1]],
      // };
      // //  debugger;
      // const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateInvoice(userData) {
  try {
    //yield call(delay, 5000)

    const getInsertedData = (state) => state.insertedData.data;
    const insertedData = yield select(getInsertedData);

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })
    // debugger;
    let resultObj = yield call(restApi.insertInvoiceData, userData.parms);
    // debugger;
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      const data =
        resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;
      const dataEnriched = data.map((rec) => ({ key: uuid.v4(), ...rec }));

      let insertedDataEnriched = cloneDeep(insertedData);
      insertedDataEnriched.invoice = dataEnriched[0];

      yield put({
        type: insertedDataTypes.INSERTEDDATA,
        insertedData: {
          ...defaultSagaData,
          data: insertedDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateSubscription(userData) {
  try {
    //yield call(delay, 5000)

    const getInsertedData = (state) => state.insertedData.data;
    const insertedData = yield select(getInsertedData);

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertSubscriptionData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      const data =
        resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;
      const dataEnriched = data.map((rec) => ({ key: uuid.v4(), ...rec }));

      // const insertedUpdatedSpace =
      //   resultObj.data.length > 1 ? resultObj.data[1][0] : {};

      let insertedDataEnriched = cloneDeep(insertedData);
      // insertedDataEnriched.site_space = insertedUpdatedSpace;
      insertedDataEnriched.subscription = dataEnriched[0];

      yield put({
        type: insertedDataTypes.INSERTEDDATA,
        insertedData: {
          ...defaultSagaData,
          data: insertedDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);
      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateCustomer(userData) {
  try {
    //yield call(delay, 5000)

    //Clear The error Message
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: cloneDeep([]),
        lastFetchSuccess: Date.now(),
      },
    });

    const getInsertedData = (state) => state.insertedData.data;
    const insertedData = yield select(getInsertedData);

    const getReferenceData = (state) => state.referenceData.data;
    const referenceDataCurrent = yield select(getReferenceData);

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertCustomerData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      const data =
        resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;
      const dataEnriched = data.map((rec) => ({ key: uuid.v4(), ...rec }));

      let insertedDataEnriched = cloneDeep(insertedData);
      insertedDataEnriched.customer = dataEnriched[0];
      let userRecord = dataEnriched[0];

      userRecord.key = uuid.v4();
      userRecord.searchText =
        userRecord.user_id +
        "~" +
        userRecord.email +
        "~" +
        userRecord.phone_number +
        "~" +
        userRecord.first_name +
        "~" +
        userRecord.last_name +
        "~" +
        userRecord.street_address +
        "~" +
        userRecord.city +
        "~" +
        userRecord.state +
        "~" +
        userRecord.company_name +
        "~" +
        userRecord.itemDescSearch +
        "~" +
        userRecord.licenseSearch +
        "~" +
        userRecord.vinSearch +
        "~" +
        userRecord.makeSearch +
        "~" +
        userRecord.modelSearch +
        "~" +
        userRecord.spaceNameSearch +
        "~" +
        userRecord.siteNameSubscriptionSearch +
        "~" +
        userRecord.siteNameInvoiceSearch;

      //Add or Update the current record in Users
      const currentUsers = referenceDataCurrent.users;
      const index = currentUsers.findIndex(
        (user) => user.user_id.toString() == userRecord.user_id.toString()
      );

      if (index > -1) {
        currentUsers.splice(index, 1, userRecord);
      } else {
        currentUsers.push(userRecord);
      }
      referenceDataCurrent.users = cloneDeep(currentUsers);
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceDataCurrent),
          lastFetchSuccess: Date.now(),
        },
      });

      //Very IMP to override the customer Data
      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: cloneDeep(referenceDataCurrent.users),
          lastFetchSuccess: Date.now(),
        },
      });

      if (userRecord.verified == 0) {
        //Send a Verification Email to the Customer
        let URL = process.env.REACT_APP_API_URL + `/email/sendemailverify`;
        // let URL = `http://localhost:8080/stripeApi/createUpdateInvoice`;
        // if (process.env.NODE_ENV == "production") {
        //   URL = 'http://localhost:3000' +  `/stripeApi/createUpdateInvoice`;
        // }

        const emailParms = {
          session_user_id: userData.parms[0], //session user Id
          email: userRecord.email, //this.state.invoiceId,
          user_id: userRecord.user_id, // this.props.userId,
        };

        console.log("emailParms", emailParms);
        const emailReturn = PNfetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            ...emailParms,
          }),
        })
          //.then(statusHelper)
          .then((response) => response.json())
          .catch((error) => error);

        console.log("emailReturn", emailReturn);
      }

      yield put({
        type: insertedDataTypes.INSERTEDDATA,
        insertedData: {
          ...defaultSagaData,
          data: insertedDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      yield put({
        type: errorTypes.ERRORDATA,
        errorData: {
          ...defaultSagaData,
          data: cloneDeep([resultObj.error]),
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: cloneDeep([e]),
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* clearInsertedData(userData) {
  try {
    yield put({
      type: insertedDataTypes.INSERTEDDATA,
      insertedData: {
        ...defaultSagaData,
        data: {
          customer: {},
          site: {},
          spaceType: {},
          vehicle: {},
        },
        lastFetchSuccess: Date.now(),
      },
    });
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateCard(userData) {
  try {
    //yield call(delay, 5000)

    //Clear The error Message
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: cloneDeep([]),
        lastFetchSuccess: Date.now(),
      },
    });
    const getInsertedData = (state) => state.insertedData.data;
    const insertedData = yield select(getInsertedData);

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertCardData, userData.parms);
    // debugger;
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      //  debugger;
      // resultObj = JSON.parse(resultObj);
      const data = resultObj.data ? resultObj.data : {}; //Data is coming in as object
      // const dataEnriched = data.map((rec) => ({ key: uuid.v4(), ...rec }));

      let insertedDataEnriched = cloneDeep(insertedData);
      insertedDataEnriched.invoice = data;

      yield put({
        type: insertedDataTypes.INSERTEDDATA,
        insertedData: {
          ...defaultSagaData,
          data: insertedDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });
      //  debugger;
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      yield put({
        type: errorTypes.ERRORDATA,
        errorData: {
          ...defaultSagaData,
          data: cloneDeep([resultObj.message]), //Data is in error
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateProfile(userData) {
  try {
    //yield call(delay, 5000)
    //This is being called directly on the Screen
    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertUpdateProfile, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      // const getReferenceData = (state) => state.referenceData.data;
      // const referenceData = yield select(getReferenceData);

      // referenceData.layouts = data.map((rec) => ({
      //   key: uuid.v4(),
      //   searchText: rec.preference_key,
      //   ...rec,
      // }));

      // yield put({
      //   type: referenceDataTypes.REFERENCEDATA,
      //   referenceData: {
      //     ...defaultSagaData,
      //     data: cloneDeep(referenceData),
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateLayout(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertUpdateLayout, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      //Don't need to do this as we are getting the reference data when we load the screens
      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);

      referenceData.layouts = data.map((rec) => ({
        key: uuid.v4(),
        searchText: rec.preference_key,
        ...rec,
      }));

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceData),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateRateType(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertUpdateRateType, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      const siteSpaceTypesData = sortBy(
        [].concat(...resultObj.data[0]).map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.site_name + "~" + rec.space_type_name + "~" + rec.space_names,
          ...rec,
        })),
        ["site_name", "space_type_name"]
      );
      const siteSpaceData = sortBy(
        [].concat(...resultObj.data[1]).map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.site_name + "~" + rec.space_type_name + "~" + rec.space_names,
          ...rec,
        })),
        ["site_name", "space_type_name", "space_name"]
      );

      yield put({
        type: spaceTypeRateTypes.SPACETYPERATESDATA,
        spaceTypeRatesData: {
          ...defaultSagaData,
          data: { siteSpaceTypesData, siteSpaceData },
          lastFetchSuccess: Date.now(),
        },
      });

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);
      //Refresh the Reference Data
      const payload = {
        parms: [sessionData.session_user_id],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateDocument(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertDocumentData, userData.parms);

    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_name +
          "~" +
          rec.file_type +
          "~" +
          rec.file_name +
          "~" +
          rec.document_type +
          "~" +
          rec.file_title,

        ...rec,
      }));

      referenceData.documents = cloneDeep(dataEnriched);
      yield put({
        type: documentTypes.DOCUMENTDATA,
        documentData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);

      // // const userParms = [userData.parms[2]]; //parms[2] is the current user id
      // const userParms = [
      //   userData.user_id_fk ? userData.user_id_fk : userData.parms[2],
      // ];
      // let resultObjUser = yield call(restApi.getUserData, userParms);
      // if (!isNil(resultObjUser) && !hasErrors(resultObjUser)) {
      //   const user = resultObjUser.data[0].map((rec) => ({
      //     key: uuid.v4(),
      //     searchText:
      //       rec.user_id +
      //       "~" +
      //       rec.email +
      //       "~" +
      //       rec.phone_number +
      //       "~" +
      //       rec.first_name +
      //       "~" +
      //       rec.last_name +
      //       "~" +
      //       rec.street_address +
      //       "~" +
      //       rec.city +
      //       "~" +
      //       rec.state +
      //       "~" +
      //       rec.company_name +
      //       "~" +
      //       rec.vehicleSearch +
      //       "~" +
      //       rec.spaceNameSearch,
      //     ...rec,
      //   }));

      //   let users = referenceData.users.filter(
      //     (user) => user.user_id.toString() != userParms[0].toString()
      //   );
      //   referenceData.users = cloneDeep([...user, ...users]);

      //   yield put({
      //     type: customerTypes.CUSTOMERDATA,
      //     customerData: {
      //       ...defaultSagaData,
      //       data: cloneDeep(referenceData.users),
      //       lastFetchSuccess: Date.now(),
      //     },
      //   });
      // }

      // yield put({
      //   type: referenceDataTypes.REFERENCEDATA,
      //   referenceData: {
      //     ...defaultSagaData,
      //     data: cloneDeep(referenceData),
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
    } else {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    }

    // const getSessionData = (state) => state.sessionData.data;
    // const sessionData = yield select(getSessionData);
    // //Refresh the Reference Data
    // const payload = {
    //   parms: [sessionData.session_user_id],
    // };
    // const fetchTask = yield fork(getDataForUser, payload);
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* getSignatureData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.getPNSignatureData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      //To Be done
      // resultObj = JSON.parse(resultObj);
      // const data =
      //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      // yield put({
      //   type: emailInvoiceTypes.EMAILINVOICEDATA,
      //   emailInvoiceData: {
      //     ...defaultSagaData,
      //     data: cloneDeep(data),
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
      // //Refresh the Reference Data
      // const payload = {
      //   parms: [data[0][0].user_id_fk],
      // };
      // const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}
function* getInvoiceEmailBalance(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(
      restApi.getInvoiceEmailBalanceData,
      userData.parms
    );
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      yield put({
        type: emailInvoiceTypes.EMAILINVOICEDATA,
        emailInvoiceData: {
          ...defaultSagaData,
          data: cloneDeep(data),
          lastFetchSuccess: Date.now(),
        },
      });

      //Refresh the Reference Data
      const payload = {
        parms: [data[0][0].user_id_fk],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateAffiliate(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);

    const getInsertedData = (state) => state.insertedData.data;
    const insertedData = yield select(getInsertedData);

    const getReferenceData = (state) => state.referenceData.data;
    const referenceData = yield select(getReferenceData);

    let resultObj = yield call(restApi.insertAffiliateData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      const data = resultObj.data ? resultObj.data[0][0] : {}; //Data is coming in as object
      let insertedDataEnriched = cloneDeep(insertedData);
      insertedDataEnriched.vehicle = data;

      console.log("Main Data For Vehicles", resultObj.data);
      console.log("Data For Vehicles", resultObj.data[1]);
      const affiliateData = resultObj.data ? resultObj.data[1] : {};

      // const userParms = [userData.parms[2]]; //parms[2] is the current user id
      const userParms = [
        userData.user_id_fk ? userData.user_id_fk : userData.parms[2],
      ];
      let resultObjUser = yield call(restApi.getUserData, userParms);
      if (!isNil(resultObjUser) && !hasErrors(resultObjUser)) {
        const user = resultObjUser.data[0].map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.user_id +
            "~" +
            rec.email +
            "~" +
            rec.phone_number +
            "~" +
            rec.first_name +
            "~" +
            rec.last_name +
            "~" +
            rec.street_address +
            "~" +
            rec.city +
            "~" +
            rec.state +
            "~" +
            rec.company_name +
            "~" +
            rec.vehicleSearch +
            "~" +
            rec.spaceNameSearch,
          ...rec,
        }));

        let users = referenceData.users.filter(
          (user) => user.user_id.toString() != userParms[0].toString()
        );
        referenceData.users = cloneDeep([...user, ...users]);

        yield put({
          type: customerTypes.CUSTOMERDATA,
          customerData: {
            ...defaultSagaData,
            data: cloneDeep(referenceData.users),
            lastFetchSuccess: Date.now(),
          },
        });

        yield put({
          type: vehicleTypes.VEHICLEDATA,
          vehicleData: {
            ...defaultSagaData,
            data: cloneDeep(referenceData.vehicles),
            lastFetchSuccess: Date.now(),
          },
        });
      }
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceData),
          lastFetchSuccess: Date.now(),
        },
      });

      yield put({
        type: insertedDataTypes.INSERTEDDATA,
        insertedData: {
          ...defaultSagaData,
          data: insertedDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      // const getSessionData = (state) => state.sessionData.data;
      // const sessionData = yield select(getSessionData);

      // //Refresh the Reference Data
      // const payload = {
      //   parms: [sessionData.session_user_id],
      // };
      // const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateVehicle(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);

    const getInsertedData = (state) => state.insertedData.data;
    const insertedData = yield select(getInsertedData);

    const getReferenceData = (state) => state.referenceData.data;
    const referenceData = yield select(getReferenceData);

    const getSessionData = (state) => state.sessionData.data;
    const sessionData = yield select(getSessionData);

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertVehicleData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      const data = resultObj.data ? resultObj.data[0][0] : {}; //Data is coming in as object
      let insertedDataEnriched = cloneDeep(insertedData);
      insertedDataEnriched.vehicle = data;

      console.log("Main Data For Vehicles", resultObj.data);
      // console.log("Data For Vehicles", resultObj.data[1]);
      // const VehicleData = resultObj.data ? resultObj.data[1] : {};
      // yield put({
      //   type: vehicleTypes.VEHICLEDATA,
      //   vehicleData: {
      //     ...defaultSagaData,
      //     data: cloneDeep(referenceData.vehicles),
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
      yield put({
        type: insertedDataTypes.INSERTEDDATA,
        insertedData: {
          ...defaultSagaData,
          data: insertedDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateAdminSite(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertAdminSiteData, userData.parms);

    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // const data =
      //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [userData.user_id_fk ? userData.user_id_fk : userData.parms[3]],
        // parms: [userData.parms[3]],
      };
      const fetchTask = yield fork(getDataForUser, payload);
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateNotifications(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })
    let resultObj = yield call(
      restApi.insertUpdateNotifications,
      userData.parms
    );
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      //  yield put({
      //   type: notificationTypes.NOTIFICATIONSDATA,
      //   notificationsData: {
      //     ...defaultSagaData,
      //     message: "",
      //     lastFetchSuccess: Date.now(),
      //   },
      // });

      //Refresh the Notifications Data
      const payload = {
        // parms: [userData.parms[0]], //Current User
        parms: [userData.user_id_fk ? userData.user_id_fk : userData.parms[0]],
      };
      const fetchTask = yield fork(getNotificationsDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateSiteType(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertSiteData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* clearError(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: cloneDeep([]),
        lastFetchSuccess: Date.now(),
      },
    });
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

function* insertUpdateSpaceType(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertSpaceTypeData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      const payload = {
        parms: [sessionData.session_user_id], //session_user_id Contains the logged in User, user_id contains the session User picked from the Header
      };
      const fetchTask = yield fork(getDataForUser, payload);
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

export function* getData(userData) {
  try {
    //yield call(delay, 5000)
    // debugger;
    console.log(userData.parms);

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.getData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      console.log(resultObj.data);

      let refData = {};
      refData.sites = resultObj.data[0].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.siteSpaces = resultObj.data[1].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.space_name +
          "~" +
          rec.space_type_name +
          "~" +
          rec.monthly_rate +
          "~" +
          rec.daily_rate +
          "~" +
          rec.weekly_rate +
          "~" +
          rec.weekend_rate +
          "~",

        ...rec,
      }));
      refData.spaceTypes = resultObj.data[2].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,

        ...rec,
      }));
      refData.roles = resultObj.data[3].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.abilities = resultObj.data[4].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionTypes = resultObj.data[5].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,
        ...rec,
      }));
      refData.subscriptionStatus = resultObj.data[6].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionRequestStatus = resultObj.data[7].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.refundStatus = resultObj.data[8].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.paymentStatus = resultObj.data[9].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      refData.invoiceStatus = resultObj.data[10].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.documentTypes = resultObj.data[11].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.userTypes = resultObj.data[12].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      refData.users = resultObj.data[13].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_id +
          "~" +
          rec.email +
          "~" +
          rec.phone_number +
          "~" +
          rec.first_name +
          "~" +
          rec.last_name +
          "~" +
          rec.street_address +
          "~" +
          rec.city +
          "~" +
          rec.state +
          "~" +
          rec.company_name +
          "~" +
          rec.vehicleSearch +
          "~" +
          rec.spaceNameSearch,
        ...rec,
      }));

      refData.vehicles = resultObj.data[14].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.vehicle_id +
          "~" +
          rec.user_id_fk +
          "~" +
          rec.license_num +
          "~" +
          rec.truck_num +
          "~" +
          rec.trailer_num +
          "~" +
          rec.model +
          "~" +
          rec.make +
          "~" +
          rec.color +
          "~" +
          rec.user_name +
          "~" +
          rec.mc,
        ...rec,
      }));

      refData.unitTypes = resultObj.data[15].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.cards = resultObj.data[16].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.card_brand +
          "~" +
          rec.last_4 +
          "~" +
          rec.exp_month +
          "~" +
          rec.exp_year +
          "~" +
          rec.cardholder_name,

        ...rec,
      }));

      refData.documents = resultObj.data[17].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_name +
          "~" +
          rec.file_type +
          "~" +
          rec.file_name +
          "~" +
          rec.document_type +
          "~" +
          rec.file_title,

        ...rec,
      }));

      refData.paymentMethods = resultObj.data[18].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.spaceUsuageDetails = resultObj.data[19].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.title + "~" + rec.space_name + "~" + rec.site_name,
        ...rec,
      }));

      refData.adminSites = resultObj.data[20].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.name +
          "~" +
          rec.user_role +
          "~" +
          rec.user_site_role,

        ...rec,
      }));

      refData.layouts = resultObj.data[21].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.preference_key,
        ...rec,
      }));

      refData.userSites = resultObj.data[25].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.PNSites = resultObj.data[26].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(refData),
          lastFetchSuccess: Date.now(),
        },
      });

      //Very IMP to override the customer Data
      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: cloneDeep(refData.users),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "",
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

export function* getNotificationsDataForUser(userData) {
  try {
    //yield call(delay, 5000)
    console.log(userData.parms);

    const getReferenceData = (state) => state.referenceData.data;
    const referenceDataCurrent = yield select(getReferenceData);

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(
      restApi.getNotificationsDataForUser,
      userData.parms
    );
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      console.log(resultObj.data);

      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),

        ...rec,
      }));

      yield put({
        type: notificationTypes.NOTIFICATIONSDATA,
        notificationsData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: notificationTypes.NOTIFICATIONSDATA,
        notificationsData: {
          ...defaultSagaData,
          message: "",
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: notificationTypes.NOTIFICATIONSDATA,
      notificationsData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: notificationTypes.NOTIFICATIONSDATA,
        notificationsData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

export function* getSpecificDataForUser(userData) {
  try {
    //yield call(delay, 5000)
    // debugger;
    console.log(userData.parms);

    const getReferenceData = (state) => state.referenceData.data;
    const refData = yield select(getReferenceData);

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    const dataEntity = userData.parms[1];
    let resultObj = yield call(restApi.getSpecificDataForUser, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      console.log(resultObj.data);

      if (dataEntity == "sites") {
        refData.sites = resultObj.data[0].map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.name +
            "~" +
            rec.description +
            "~" +
            rec.phone_number +
            "~" +
            rec.address_line_1 +
            "~" +
            rec.address_line_2
              ? rec.address_line_2
              : " " + "~" + rec.city + "~" + rec.state + "~",

          ...rec,
        }));

        yield put({
          type: referenceDataTypes.REFERENCEDATA,
          referenceData: {
            ...defaultSagaData,
            data: cloneDeep(refData),
            lastFetchSuccess: Date.now(),
          },
        });

        //Very IMP to override the customer, CC Data
        yield put({
          type: siteTypes.SITEDATA,
          siteData: {
            ...defaultSagaData,
            data: cloneDeep(refData.sites),
            lastFetchSuccess: Date.now(),
          },
        });
      } else if (dataEntity == "users") {
        const user = resultObj.data[0].map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.user_id +
            "~" +
            rec.email +
            "~" +
            rec.phone_number +
            "~" +
            rec.first_name +
            "~" +
            rec.last_name +
            "~" +
            rec.street_address +
            "~" +
            rec.city +
            "~" +
            rec.state +
            "~" +
            rec.company_name +
            "~" +
            rec.vehicleSearch +
            "~" +
            rec.spaceNameSearch,
          ...rec,
        }));

        const newUsers = refData.users;
        const index = newUsers.findIndex(
          (user) => user.user_id.toString() == userData.parms[0].toString()
        );

        if (index > -1) {
          newUsers.splice(index, 1, user[0]);
        } else {
          newUsers.push(user[0]);
        }
        refData.users = cloneDeep(newUsers);
        //  debugger;

        yield put({
          type: referenceDataTypes.REFERENCEDATA,
          referenceData: {
            ...defaultSagaData,
            data: cloneDeep(refData),
            lastFetchSuccess: Date.now(),
          },
        });

        //Very IMP to override the customer, CC Data
        yield put({
          type: customerTypes.CUSTOMERDATA,
          customerData: {
            ...defaultSagaData,
            data: cloneDeep(refData.users),
            lastFetchSuccess: Date.now(),
          },
        });
      } else if (dataEntity == "cards") {
        refData.cards = resultObj.data[0].map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.card_brand +
            "~" +
            rec.last_4 +
            "~" +
            rec.exp_month +
            "~" +
            rec.exp_year +
            "~" +
            rec.cardholder_name,

          ...rec,
        }));

        yield put({
          type: referenceDataTypes.REFERENCEDATA,
          referenceData: {
            ...defaultSagaData,
            data: cloneDeep(refData),
            lastFetchSuccess: Date.now(),
          },
        });

        //We don't need to do this as it's overriding Cards in the customer table
        // yield put({
        //   type: ccTypes.CCDATA,
        //   ccData: {
        //     ...defaultSagaData,
        //     data: cloneDeep(refData.cards),
        //     lastFetchSuccess: Date.now(),
        //   },
        // });
      } else if (dataEntity == "vehicles") {
        refData.vehicles = resultObj.data[0].map((rec) => ({
          key: uuid.v4(),
          searchText:
            rec.vehicle_id +
            "~" +
            rec.user_id_fk +
            "~" +
            rec.license_num +
            "~" +
            rec.truck_num +
            "~" +
            rec.trailer_num +
            "~" +
            rec.model +
            "~" +
            rec.make +
            "~" +
            rec.color +
            "~" +
            rec.user_name +
            "~" +
            rec.mc,

          ...rec,
        }));

        yield put({
          type: referenceDataTypes.REFERENCEDATA,
          referenceData: {
            ...defaultSagaData,
            data: cloneDeep(refData),
            lastFetchSuccess: Date.now(),
          },
        });

        //Very IMP to override the customer, CC Data
        yield put({
          type: vehicleTypes.VEHICLEDATA,
          vehicleData: {
            ...defaultSagaData,
            data: cloneDeep(refData.vehicles),
            lastFetchSuccess: Date.now(),
          },
        });
      } else if (dataEntity == "entitlements") {
        const entitlementsData = resultObj.data[0].map((rec) => ({
          key: uuid.v4(),
          searchText: rec.name + "~" + rec.description,

          ...rec,
        }));

        //Very IMP to override the customer, CC Data
        yield put({
          type: entitlementTypes.ENTITLEMENTSDATA,
          entitlementsData: {
            ...defaultSagaData,
            data: cloneDeep(entitlementsData),
            lastFetchSuccess: Date.now(),
          },
        });
      }
    }
  } catch (e) {
    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

export function* getDataForUser(userData) {
  try {
    //yield call(delay, 5000)
    // debugger;
    console.log(userData.parms);

    const getReferenceData = (state) => state.referenceData.data;
    const referenceDataCurrent = yield select(getReferenceData);

    const getSessionData = (state) => state.sessionData.data;
    const sessionData = yield select(getSessionData);

    let customerCount = 0;
    if (
      !isNil(referenceDataCurrent.users)
      // referenceDataCurrent.users.length <= 1
    ) {
      //We don't have the users populated
      customerCount = referenceDataCurrent.users.length;
    }

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(
      restApi.getDataForUser,
      userData.parms,
      customerCount
    );
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      console.log(resultObj.data);

      let refData = {};
      refData.sites = resultObj.data[0].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.siteSpaces = resultObj.data[1].map((rec) => ({
        key: uuid.v4(),
        searchText:
          // rec.site_name +
          // "~" +
          rec.space_name,
        // "~" +
        // rec.space_type_name +
        // "~" +
        // rec.monthly_rate +
        // "~" +
        // rec.daily_rate +
        // "~" +
        // rec.weekly_rate +
        // "~" +
        // rec.weekend_rate +
        // "~",
        ...rec,
      }));
      refData.spaceTypes = resultObj.data[2].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,

        ...rec,
      }));
      refData.roles = resultObj.data[3].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.abilities = resultObj.data[4].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionTypes = resultObj.data[5].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,
        ...rec,
      }));
      refData.subscriptionStatus = resultObj.data[6].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionRequestStatus = resultObj.data[7].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.refundStatus = resultObj.data[8].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.paymentStatus = resultObj.data[9].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      refData.invoiceStatus = resultObj.data[10].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.documentTypes = resultObj.data[11].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.userTypes = resultObj.data[12].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      //  debugger;
      //When we are doing Hard Refresh

      // const user = resultObj.data[13].map((rec) => ({
      //   key: uuid.v4(),
      //   searchText:
      //     rec.user_id +
      //     "~" +
      //     rec.email +
      //     "~" +
      //     rec.phone_number +
      //     "~" +
      //     rec.first_name +
      //     "~" +
      //     rec.last_name +
      //     "~" +
      //     rec.street_address +
      //     "~" +
      //     rec.city +
      //     "~" +
      //     rec.state +
      //     "~" +
      //     rec.company_name +
      //     "~" +
      //     rec.vehicleSearch +
      //     "~" +
      //     rec.spaceNameSearch,
      //   ...rec,
      // }));

      // const newUsers = referenceDataCurrent.users;
      // const index = newUsers.findIndex(
      //   (user) => user.user_id.toString() == userData.parms[0].toString()
      // );

      // if (index > -1) {
      //   newUsers.splice(index, 1, user[0]);
      // } else {
      //   newUsers.push(user[0]);
      // }
      // refData.users = cloneDeep(newUsers);

      const users = resultObj.data[13].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_id +
          "~" +
          rec.email +
          "~" +
          rec.phone_number +
          "~" +
          rec.first_name +
          "~" +
          rec.last_name +
          "~" +
          rec.street_address +
          "~" +
          rec.city +
          "~" +
          rec.state +
          "~" +
          rec.company_name +
          "~" +
          rec.vehicleSearch +
          "~" +
          rec.spaceNameSearch,
        ...rec,
      }));
      refData.users = cloneDeep(users);

      refData.vehicles = resultObj.data[14].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.vehicle_id +
          "~" +
          rec.user_id_fk +
          "~" +
          rec.license_num +
          "~" +
          rec.truck_num +
          "~" +
          rec.trailer_num +
          "~" +
          rec.model +
          "~" +
          rec.make +
          "~" +
          rec.color +
          "~" +
          rec.user_name +
          "~" +
          rec.mc,
        ...rec,
      }));

      refData.unitTypes = resultObj.data[15].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.cards = resultObj.data[16].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.card_brand +
          "~" +
          rec.last_4 +
          "~" +
          rec.exp_month +
          "~" +
          rec.exp_year +
          "~" +
          rec.cardholder_name,

        ...rec,
      }));

      refData.documents = resultObj.data[17].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_name +
          "~" +
          rec.file_type +
          "~" +
          rec.file_name +
          "~" +
          rec.document_type +
          "~" +
          rec.file_title,

        ...rec,
      }));

      refData.paymentMethods = resultObj.data[18].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.spaceUsuageDetails = resultObj.data[19].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.title + "~" + rec.space_name + "~" + rec.site_name,
        ...rec,
      }));

      refData.adminSites = resultObj.data[20].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.name +
          "~" +
          rec.user_role +
          "~" +
          rec.user_site_role,

        ...rec,
      }));

      refData.layouts = resultObj.data[21].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.preference_key,
        ...rec,
      }));

      //To populate Role
      // const userObj = resultObj.data[22][0];
      sessionData.userEntitlements =
        resultObj.data.length >= 24
          ? resultObj.data[24]
          : sessionData.userEntitlements;

      yield put({
        type: sessionTypes.SESSIONDATA,
        sessionData: {
          ...sessionData,
          lastFetchSuccess: Date.now(),
        },
      });

      refData.userSites = resultObj.data[25].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.PNSites = resultObj.data[26].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(refData),
          lastFetchSuccess: Date.now(),
        },
      });

      //Very IMP to override the customer, CC Data
      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: cloneDeep(refData.users),
          lastFetchSuccess: Date.now(),
        },
      });

      yield put({
        type: ccTypes.CCDATA,
        ccData: {
          ...defaultSagaData,
          data: cloneDeep(refData.cards),
          lastFetchSuccess: Date.now(),
        },
      });

      yield put({
        type: vehicleTypes.VEHICLEDATA,
        vehicleData: {
          ...defaultSagaData,
          data: cloneDeep(refData.vehicles),
          lastFetchSuccess: Date.now(),
        },
      });

      yield put({
        type: documentTypes.DOCUMENTDATA,
        documentData: {
          ...defaultSagaData,
          data: cloneDeep(refData.documents),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "",
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

export function* handleRequest(action) {
  console.log("authSaga request", action);
  console.log(action.payload);
  //yield put({ type: "ITEMS_IS_LOADING", isLoading: true });
  //yield call(updateStatus);

  try {
    switch (action.type) {
      case actionDataTypes.FETCH_NOTIFICATIONDATA_REQUEST: {
        const fetchTask = yield fork(
          getNotificationsDataForUser,
          action.payload
        );
        break;
      }

      case actionDataTypes.FETCH_SITEDATA_REQUEST: {
        const fetchTask = yield fork(getSitesDataForUser, action.payload);
        break;
      }

      case actionDataTypes.FETCH_SITEVACANCYDATA_REQUEST: {
        const fetchTask = yield fork(getSiteVacancyDataForUser, action.payload);
        break;
      }

      case actionDataTypes.REFRESH_REFDATA_REQUEST: {
        const fetchTask = yield fork(getDataForUser, action.payload);
        break;
      }

      case actionDataTypes.REFRESH_REFDATA_SPECIFIC_REQUEST: {
        const fetchTask = yield fork(getSpecificDataForUser, action.payload);
        break;
      }

      case actionDataTypes.CLEAR_ERROR_REQUEST: {
        const fetchTask = yield fork(clearError, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_SPACETYPE_REQUEST: {
        const fetchTask = yield fork(insertUpdateSpaceType, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_SITETYPE_REQUEST: {
        const fetchTask = yield fork(insertUpdateSiteType, action.payload);
        break;
      }

      case actionDataTypes.UPDATE_NOTIFICATIONS_REQUEST: {
        const fetchTask = yield fork(insertUpdateNotifications, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_VEHICLE_REQUEST: {
        const fetchTask = yield fork(insertUpdateVehicle, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_AFFLIATE_REQUEST: {
        const fetchTask = yield fork(insertUpdateAffiliate, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_ADMINSITE_REQUEST: {
        const fetchTask = yield fork(insertUpdateAdminSite, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_DOCUMENT_REQUEST: {
        const fetchTask = yield fork(insertUpdateDocument, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_SPACETYPERATE_REQUEST: {
        const fetchTask = yield fork(insertUpdateRateType, action.payload);
        break;
      }
      case actionDataTypes.CLEAR_INSERTEDDATA_REQUEST: {
        const fetchTask = yield fork(clearInsertedData, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_LAYOUT_REQUEST: {
        const fetchTask = yield fork(insertUpdateLayout, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_PROFILE_REQUEST: {
        const fetchTask = yield fork(insertUpdateProfile, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_CARD_REQUEST: {
        const fetchTask = yield fork(insertUpdateCard, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_CUSTOMER_REQUEST: {
        const fetchTask = yield fork(insertUpdateCustomer, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_SUBSCRIPTION_REQUEST: {
        const fetchTask = yield fork(insertUpdateSubscription, action.payload);
        break;
      }

      case actionDataTypes.DELETE_SUBSCRIPTION_REQUEST: {
        const fetchTask = yield fork(deleteSubscription, action.payload);
        break;
      }

      case actionDataTypes.DELETE_USER_REQUEST: {
        const fetchTask = yield fork(deleteUser, action.payload);
        break;
      }

      case actionDataTypes.INSERT_UPDATE_INVOICE_REQUEST: {
        const fetchTask = yield fork(insertUpdateInvoice, action.payload);
        break;
      }

      case actionDataTypes.DELETE_INVOICE_REQUEST: {
        const fetchTask = yield fork(deleteInvoice, action.payload);
        break;
      }
      case actionDataTypes.DELETE_EXPENSE_REQUEST: {
        const fetchTask = yield fork(deleteExpense, action.payload);
        break;
      }

      case actionDataTypes.DELETE_REPORT_REQUEST: {
        const fetchTask = yield fork(deleteReport, action.payload);
        break;
      }
      case actionDataTypes.DELETE_VEHICLE_REQUEST: {
        const fetchTask = yield fork(deleteVehicle, action.payload);
        break;
      }

      case actionDataTypes.DELETE_ADMINSITE_REQUEST: {
        const fetchTask = yield fork(deleteAdminSite, action.payload);
        break;
      }

      case actionDataTypes.DELETE_DOCUMENT_REQUEST: {
        const fetchTask = yield fork(deleteDocument, action.payload);
        break;
      }

      case actionDataTypes.DELETE_CARD_REQUEST: {
        const fetchTask = yield fork(deleteCard, action.payload);
        break;
      }

      case actionDataTypes.FETCH_INVBALDATA_REQUEST: {
        const fetchTask = yield fork(getInvoiceEmailBalance, action.payload);
        break;
      }

      case actionDataTypes.FETCH_SIGNATURE_REQUEST: {
        const fetchTask = yield fork(getSignatureData, action.payload);
        break;
      }
      default: {
        return null;
        // break;
      }
    }
  } catch (e) {
    yield put({ type: referenceDataTypes.LOGIN_FAILURE, error: e });
  }
}
