import React from "react";

const Level = ({ obj }) => {
  return (
    <>
      <div className="flex flex-col lg:flex-row max-w-[442px] text-center sm:text-start">
        <span className="mx-auto sm:mx-0"> {obj.securityIcons} </span>
        <div className="md:max-w-[330px] lg:ms-7 mt-2 md:mt-0">
          <h5 className="font-Manrope font-bold text-black text-[18px] lg:text-md lg:max-w-[198px] leading-[27.32px] lg:pe-[30px] mb-2">
            {obj.heading}
          </h5>
          <p className="font-Poppins font-normal text-base opacity-70 leading-[160%]">
            {obj.para}
          </p>
        </div>
      </div>
    </>
  );
};

export default Level;
