/* eslint-disable no-unused-vars */
import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import AppConfig from "../constants/AppConfig";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
//import io from 'socket.io-client';
//   import SockJsClient from 'react-stomp';
import * as uuid from "uuid";

import { delay, buffers, eventChannel, END } from "redux-saga";
import { isNil, trim, cloneDeep, sortBy } from "lodash";
//   import * as io from "socket.io-client";
import { types as customerTypes } from "../reducers/customerReducer";
import { types as errorTypes } from "../reducers/errorDataReducer";
// import * as utils from "../utils/common";
// import * as moment from 'moment';
//   import Singleton from '../socket';
import { hasErrors } from "./commonSagas";
import { getData as getReferenceData } from "./actionSaga";
import { PNfetch } from "./../api/pnfetch";
import { types as referenceDataTypes } from "../reducers/referenceDataReducer";
//import { push } from 'react-router-redux';
//let socketRef;

const defaultData = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

const defaultSagaData = {
  data: [],
  message: "",
  hasLoaded: true,
  hasError: false,
  loading: false,
  lastFetchSuccess: Date.now(),
};

const headersObj = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};

const restApi = {
  getCustomerData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);
    // debugger;
    console.log("Authorization", headersObj);
    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_customerOnlyData",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => ({ error }))
    );
  },
  getData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);
    // debugger;
    console.log("Authorization", headersObj);
    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_customerData",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => ({ error }))
    );
  },

  insertData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_customers",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
};

function statusHelper(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
    //throw Error(response);
  }
  return response;
}

function* getCustomerData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: customerTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.getCustomerData, userData.parms);
    console.log("resultObj", resultObj);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // if (!isNil(resultObj) &&  resultObj.messageType !="error") {
      // resultObj = JSON.parse(resultObj);
      //  // debugger;
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;
      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      let userRecord = dataEnriched[0];

      if (isNil(userRecord.longitude)) {
        let URL = process.env.REACT_APP_API_URL + `/geocodeLookup`;
        // let URL = `http://localhost:8080/stripeApi/deleteCard`;
        // if (process.env.NODE_ENV == "production") {
        //   URL =  'http://localhost:3000' +  `/stripeApi/deleteCard`;
        // }

        fetch(URL, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            addressLookup:
              userRecord.apt_suite_number +
              "," +
              userRecord.street_address +
              "," +
              userRecord.city +
              "," +
              userRecord.state,
          }),
        })
          .then(async (response) => {
            const resp = await response.json();
            console.log("response ***", resp);
            userRecord.longitude = resp.longitude;
            userRecord.latitude = resp.latitude;
            let parms = [];

            parms[0] = userRecord.user_id;
            parms[1] = userRecord.longitude;
            parms[2] = userRecord.latitude;

            return (
              PNfetch(AppConfig.SQL_URL, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  authorization:
                    "Bearer " + localStorage.getItem("accessToken"),
                },
                body: JSON.stringify({
                  proc: "spu_user_coordinates",
                  input: parms,
                }),
              })
                //.then(statusHelper)
                .then((response) => response.json())
                .catch((error) => error)
            );
          })
          .catch((error) => {
            return error;
          });
      }

      userRecord.key = uuid.v4();
      userRecord.searchText =
        userRecord.user_id +
        "~" +
        userRecord.email +
        "~" +
        userRecord.phone_number +
        "~" +
        userRecord.first_name +
        "~" +
        userRecord.last_name +
        "~" +
        userRecord.street_address +
        "~" +
        userRecord.city +
        "~" +
        userRecord.state +
        "~" +
        userRecord.company_name +
        "~" +
        userRecord.itemDescSearch +
        "~" +
        userRecord.licenseSearch +
        "~" +
        userRecord.vinSearch +
        "~" +
        userRecord.makeSearch +
        "~" +
        userRecord.modelSearch +
        "~" +
        userRecord.spaceNameSearch +
        "~" +
        userRecord.siteNameSubscriptionSearch +
        "~" +
        userRecord.siteNameInvoiceSearch;

      const getReferenceData = (state) => state.referenceData.data;
      const referenceDataCurrent = yield select(getReferenceData);
      //  // debugger;
      //Add or Update the current record in Users
      const currentUsers = referenceDataCurrent.users;
      const index = currentUsers.findIndex(
        (user) => user.user_id.toString() == userRecord.user_id.toString()
      );

      if (index > -1) {
        currentUsers.splice(index, 1, userRecord);
      } else {
        currentUsers.push(userRecord);
      }
      // debugger;
      referenceDataCurrent.users = cloneDeep(currentUsers);
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceDataCurrent),
          lastFetchSuccess: Date.now(),
        },
      });

      //Very IMP to override the customer Data
      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: cloneDeep(referenceDataCurrent.users),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: errorTypes.ERRORDATA,
        errorData: {
          ...defaultSagaData,
          data: cloneDeep([resultObj.error]),
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: customerTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* getData(userData) {
  try {
    //yield call(delay, 5000)

    yield put({
      type: customerTypes.CUSTOMERDATA,
      customerData: {
        ...defaultData,
        lastFetchSuccess: Date.now(),
      },
    });

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: customerTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.getData, userData.parms);
    console.log("resultObj", resultObj);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // if (!isNil(resultObj) &&  resultObj.messageType !="error") {
      // resultObj = JSON.parse(resultObj);

      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_id +
          "~" +
          rec.email +
          "~" +
          rec.phone_number +
          "~" +
          rec.first_name +
          "~" +
          rec.last_name +
          "~" +
          rec.street_address +
          "~" +
          rec.city +
          "~" +
          rec.state +
          "~" +
          rec.company_name +
          "~" +
          rec.vehicleSearch +
          "~" +
          rec.spaceNameSearch,
        // rec.itemDescSearch +
        // "~" +
        // rec.licenseSearch +
        // "~" +
        // rec.vinSearch +
        // "~" +
        // rec.makeSearch +
        // "~" +
        // rec.modelSearch +
        // "~" +
        // rec.spaceNameSearch +
        // "~" +
        // rec.siteNameSubscriptionSearch +
        // "~" +
        // rec.siteNameInvoiceSearch,
        ...rec,
      }));

      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      //Refreshing the Users in referenceData
      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);
      referenceData.users = cloneDeep(dataEnriched);

      console.log("***********Refreshing the Users");
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceData),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: errorTypes.ERRORDATA,
        errorData: {
          ...defaultSagaData,
          data: cloneDeep([resultObj.error]),
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: customerTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* insertData(userData) {
  try {
    //yield call(delay, 5000)
    yield put({
      type: errorTypes.ERRORDATA,
      customerData: {
        ...defaultSagaData,
        data: "",
        lastFetchSuccess: Date.now(),
      },
    });

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: customerTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertData, userData.parms);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({ key: uuid.v4(), ...rec }));

      const getSessionData = (state) => state.sessionData.data;
      const sessionData = yield select(getSessionData);

      //Refresh the Reference Data
      const payload = {
        parms: [sessionData.session_user_id],
      };
      // debugger;
      const fetchTask = yield fork(getReferenceData, payload);

      yield put({
        type: customerTypes.CUSTOMERDATA,
        customerData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: errorTypes.ERRORDATA,
        errorData: {
          ...defaultSagaData,
          data: resultObj.error,
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: errorTypes.ERRORDATA,
      errorData: {
        ...defaultSagaData,
        data: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: customerTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}

export function* handleRequest(action) {
  console.log("authSaga request", action);
  console.log(action.payload);
  //yield put({ type: "ITEMS_IS_LOADING", isLoading: true });
  //yield call(updateStatus);
  try {
    switch (action.type) {
      case customerTypes.FETCH_DATA_REQUEST: {
        const fetchTask = yield fork(getData, action.payload);
        break;
      }
      case customerTypes.FETCH_CUSTOMER_ONLY_DATA_REQUEST: {
        const fetchTask = yield fork(getCustomerData, action.payload);
        break;
      }
      case customerTypes.INSERT_UPDATE_DATA_REQUEST: {
        const fetchTask = yield fork(insertData, action.payload);
        break;
      }

      default: {
        return null;
        break;
      }
    }
  } catch (e) {
    yield put({ type: customerTypes.LOGIN_FAILURE, error: e });
  }
}
