// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "LPR/FETCH_REQUEST",
  LPRDATA: "LPR/LPRDATA",
  DELETE_REQUEST: "LPR/DELETE_REQUEST",
  INSERT_REQUEST: "LPR/INSERT_REQUEST",
  UPDATE_REQUEST: "LPR/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "LPR/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "LPR/MESSAGE",
  TOKEN: "LPR/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.LPRDATA:
      return { ...state, ...action.lprData };

    default:
      return state;
  }
};

export const actions = {
  getLPRData: (payload) => payload,
  deleteLPRRecord: (payload) => payload,
  insertUpdateVehicle: (payload) => payload,
};
