/**
 * Auth User Reducers
 */
import {
  LOGIN_USER,
  USER_ID,
  USER_NAME,
  IS_USER,
  IS_PARENT,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
} from "../actions/types";

/**
 * initial auth user
 */
const INIT_STATE = {
  user: JSON.parse(localStorage.getItem("user")) || [],
  roleId: localStorage.getItem("roleId")
    ? parseInt(localStorage.getItem("roleId"))
    : null,
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  // categorizedFeatures: JSON.parse(localStorage.getItem('categorizedFeatures')) || [],
  features: JSON.parse(localStorage.getItem("features")) || [],
  featureId: localStorage.getItem("featureId")
    ? parseInt(localStorage.getItem("featureId"))
    : null,
  menu: JSON.parse(localStorage.getItem("menu")) || [],
  loading: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case "LOADER":
      return { ...state, loading: action.payload };

    case "ACCESS_TOKEN":
      return { ...state, loading: false, accessToken: action.payload };

    case "REFRESH_TOKEN":
      return { ...state, loading: false, refreshToken: action.payload };

    case "USER":
      return { ...state, user: action.payload };

    case "ROLE_ID":
      return { ...state, loading: false, roleId: action.payload };

    case "FEATURES":
      return { ...state, features: action.payload };

    case "FEATURE_ID":
      return { ...state, featureId: action.payload };

    case "MENU":
      return { ...state, menu: action.payload };

    case LOGIN_USER_FAILURE:
      return { ...state, loading: false };

    case LOGOUT_USER:
      return { state: { loading: false } };

    case SIGNUP_USER:
      return { ...state, loading: true };

    case SIGNUP_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload };

    case SIGNUP_USER_FAILURE:
      return { ...state, loading: false };

    default:
      return { ...state };
  }
};
