import React from "react";
import { Link } from "react-router-dom";

const ContactUsBox = () => {
  return (
    <>
      <section className=" translate-y-28  ">
        <div className="container">
          <div className=" bg-white shadow-[0px_-6px_42px_0px_#10000012] rounded-[16px] relative overflow-y-hidden">
            <div className="h-6 w-[271px] rounded-[50%] opacity-50 blur-[49px] bg-primary absolute left-2 top-2 "></div>
            <div className="h-[73px] w-[271px] rounded-[50%] opacity-30 blur-[49px] bg-primary absolute bottom-0 right-0  "></div>
            <div
              className="max-w-[694px] mx-auto text-center py-10 px-2 sm:py-16  md:py-20 "
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h2 className="text-lg md:text-[32px] lg:text-xl font-bold text-black font-Manrope">
                Contact us for more inquiries
              </h2>
              <p className="text-base mt-4 mb-10 text-black font-Poppins">
                ParkNation brings the advantages of digital transformation to
                the transportation industry. Our platform helps truck parking
                enterprises integrate their operations within a single safe and
                reliable solution.
                <br />
                <br />
                By building an intuitive, cloud-based software platform, modern
                truck parking enterprises are able to run safer, smarter
                operations across all of their locations. Carriers, brokers, and
                truck drivers rely on our solution to make their operations
                safer, more easily compliant, and more profitable.
              </p>
              <div className="flex justify-center">
                <Link
                  to="/contact-us"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="px-[51px]  py-[14px] first_btn font-Manrope text-base font-semibold"
                >
                  Contact now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsBox;
