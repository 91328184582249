import React from "react";
import management from "../../../assets/images/webp/management.webp";
import mobilemen from "../../../assets/images/webp/portrait-middle-aged-promobilemen.webp";
const Management = () => {
  return (
    <>
      <section>
        <div className="container ">
          <h2 className=" text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold mt-10 sm:mt-16 xl:mt-[80px] pt-2 max-w-[873px]">
            Excel spreadsheets make it difficult to scale, collaborate, and
            organize documents, as there is no document management
          </h2>
          <div className="sm:flex items-center gap-[88px] mt-4">
            <p className="text-base mb-0  opacity-70 font-Poppins font-normal">
              Published on March: 24/2023
            </p>
            <p className="text-base mb-0  opacity-70 font-Poppins mt-3 sm:mt-0 font-normal">
              Read Time: 6 min
            </p>
          </div>
          <img
            className="max-w-[873px] w-full mt-5 sm:mt-[30px]"
            src={management}
            alt="management"
          />
          <h2 className="font-Poppins font-bold text-[18px] lg:text-md  pt-8 lg:pt-[73px]">
            TLDR - Here's what you need to know:
          </h2>
          <ul className="ms-5">
            <li className="text-base mb-0  opacity-70 font-Poppins mt-4  font-normal max-w-[720px] list-disc">
              Excel spreadsheets make it difficult to scale, collaborate, and
              organize documents, as there is no document management
            </li>
            <li className="text-base mb-0  opacity-70 font-Poppins mt-4  font-normal max-w-[720px]  list-disc">
              On-premise TMS systems require physical servers located at your
              office, which are often costly both to purchase and maintain. You
              also need to worry about security & privacy
            </li>
            <li className="text-base mb-0  opacity-70 font-Poppins mt-4  font-normal max-w-[720px]  list-disc">
              The Rose Rocket TMS is cloud-based, secure & private, helps
              streamline collaboration, and future-proof your business. Learn
              more about cloud-based
              <a
                href="#"
                className="underline ms-1 hover:text-primary duration-300 ease-in-out"
              >
                TMS software
              </a>
            </li>
          </ul>
          <p className="text-base mb-0  opacity-70 font-Poppins mt-[30px]  font-normal max-w-[720px] ">
            There are many options on the market that can run your business —
            like building out an extensive Excel spreadsheets or an on-premise
            TMS. There’s also
            <a
              href="#"
              className="underline mx-1  hover:text-primary duration-300 ease-in-out"
            >
              cloud-based
            </a>
            systems, which is what we, Rose Rocket, are.
          </p>
          <p className="text-base mb-0  font-Poppins  font-medium max-w-[720px] mt-7 sm:mt-10">
            Something needs to change, now. Things can’t keep running like they
            are. As you start researching transportation management softwares
            that can help you streamline and automate your business operations,
            you start to wonder, what’s the difference between all these
            softwares?
          </p>
          <p className="text-base mb-0  opacity-70 font-Poppins mt-4  font-normal max-w-[720px] ">
            In the below video, our Key Account Lead, Rob Doherty explains the
            pros and cons of using Excel spreadsheets, an on-premise TMS system,
            and Rose Rocket to run your business. Prior to joining Rose Rocket,
            he worked in trucking for many years and was the General Manager at
            a trucking company, so knows first hand how important it is to have
            the best tools for your business to help optimize efficiency and
            productivity.
          </p>
          <p className="text-base mb-0  font-Poppins  font-medium max-w-[720px] mt-7 sm:mt-10">
            Something needs to change, now. Things can’t keep running like they
            are. As you start researching transportation management softwares
            that can help you streamline and automate your business operations,
            you start to wonder, what’s the difference between all these
            softwares?
          </p>
          <p className="text-base mb-0  opacity-70 font-Poppins mt-4  font-normal max-w-[720px]  ">
            In the below video, our Key Account Lead, Rob Doherty explains the
            pros and cons of using Excel spreadsheets, an on-premise TMS system,
            and Rose Rocket to run your business. Prior to joining Rose Rocket,
            he worked in trucking for many years and was the General Manager at
            a trucking company, so knows first hand how important it is to have
            the best tools for your business to help optimize efficiency and
            productivity.
          </p>
          <div>
            <img
              className="max-w-[873px] w-full mt-5 sm:mt-10 "
              src={mobilemen}
              alt="mobilemen"
            />
          </div>
          <p className="text-base mb-0  font-Poppins  font-medium max-w-[720px] mt-8 lg:mt-10">
            Something needs to change, now. Things can’t keep running like they
            are. As you start researching transportation management softwares
            that can help you streamline and automate your business operations,
            you start to wonder, what’s the difference between all these
            softwares?
          </p>
          <p className="text-base mb-0  opacity-70 font-Poppins mt-4  font-normal max-w-[720px] ">
            In the below video, our Key Account Lead, Rob Doherty explains the
            pros and cons of using Excel spreadsheets, an on-premise TMS system,
            and Rose Rocket to run your business. Prior to joining Rose Rocket,
            he worked in trucking for many years and was the General Manager at
            a trucking company, so knows first hand how important it is to have
            the best tools for your business to help optimize efficiency and
            productivity.
          </p>
        </div>
      </section>
    </>
  );
};

export default Management;
