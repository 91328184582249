import React from "react";
import { whatWeOfferData } from "../../common/Helper";

const WhatWeOffer = () => {
  return (
    <>
      <section className="pb-12  md:py-28 lg:py-[120px] overflow-x-hidden">
        <div className="container">
          <h2 className="text-lg md:text-[32px] lg:text-xl text-black font-bold font-Manrope text-center mb-10 md:mb-20">
            What we offer in our parking lot (s){" "}
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-y-20 gap-x-6 ">
            {whatWeOfferData &&
              whatWeOfferData.map((obj, index) => {
                return (
                  <div
                    key={index}
                    data-aos="fade-dowm"
                    data-aos-delay={`${2 + index}00`}
                  >
                    <div className="max-w-[192px] text-center mx-auto transition-all ease duration-300 hover:-translate-y-3 cursor-pointer">
                      <span className="flex justify-center mb-3">
                        {obj.icon}
                      </span>
                      <p className="text-[18px] text-black lg:text-md font-bold font-Manrope leading-[135%]">
                        {obj.para}
                      </p>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeOffer;
