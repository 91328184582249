/* eslint-disable no-unused-vars */
import { trim, isNil } from "lodash";

export const filterResults = (searchText, data) => {
  let results = [];
  if (isNil(data)) {
    data = [];
  }

  const searchWords = trim(searchText).toLowerCase().split(" ");

  if (searchWords.length == 1) {
    results = data.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[0]) >= 0;
    });
  } else if (searchWords.length == 2) {
    const tmpResults = data.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[0]) >= 0;
    });

    results = tmpResults.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[1]) >= 0;
    });
  } else if (searchWords.length == 3) {
    const tmpResults = data.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[0]) >= 0;
    });

    const tmpResults1 = tmpResults.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[1]) >= 0;
    });
    results = tmpResults1.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[2]) >= 0;
    });
  } else if (searchWords.length == 4) {
    const tmpResults = data.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[0]) >= 0;
    });

    const tmpResults1 = tmpResults.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[1]) >= 0;
    });
    const tmpResults2 = tmpResults1.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[2]) >= 0;
    });
    results = tmpResults2.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[3]) >= 0;
    });
  } else {
    //if(searchWords.length == 5)
    const tmpResults = data.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[0]) >= 0;
    });

    const tmpResults1 = tmpResults.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[1]) >= 0;
    });
    const tmpResults2 = tmpResults1.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[2]) >= 0;
    });
    const tmpResults3 = tmpResults2.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[3]) >= 0;
    });
    results = tmpResults3.filter((rec) => {
      return (rec.searchText || "").toLowerCase().indexOf(searchWords[4]) >= 0;
    });
  }

  return results;
};

export const filterTotalResults = (searchText, data) => {
  debugger;
  const searchTerms = trim(searchText).toLowerCase().split(",");

  let results = searchTerms
    .map((term) => {
      if (trim(term) == "") return null;
      return filterResults(term, data);
    })
    .filter((x) => !!x);

  const finalResults = [].concat(...results);
  const uniqueIDs = [...new Set(finalResults.map((itm) => itm.key))];

  const finalUniqueResults = uniqueIDs.map((uniqueID) => {
    return finalResults.filter((itm) => itm.key == uniqueID)[0];
  });

  const finalUniqueResultsEnhanced = [].concat(...finalUniqueResults);
  console.log("finalResults", finalUniqueResultsEnhanced);
  return finalUniqueResultsEnhanced;
};
