import React from "react";
import TestimonailsCards from "./../../common/TestimonailsCards";
import { testimonailsData } from "../../common/Helper";

const Testimonials = () => {
  return (
    <>
      <section className="pb-12  md:pb-28 lg:pb-[160px] overflow-x-hidden ">
        <div className="container">
          <h2
            className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-center mb-14"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            Testimonials
          </h2>
          <div className="grid sm:grid-cols-2 gap-[27px] lg:grid-cols-3 ">
            {testimonailsData &&
              testimonailsData.map((obj, index) => (
                <TestimonailsCards
                  obj={obj}
                  key={index}
                  index={index}
                  data-aos="zoom-in"
                  data-aos-duration="200"
                  data-aos-delay={index.delay}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonials;
