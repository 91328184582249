// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "PAYMENTS/FETCH_REQUEST",
  PAYMENTSDATA: "PAYMENTS/PAYMENTSDATA",
  DELETE_REQUEST: "PAYMENTS/DELETE_REQUEST",
  INSERT_REQUEST: "PAYMENTS/INSERT_REQUEST",
  UPDATE_REQUEST: "PAYMENTS/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "PAYMENTS/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "PAYMENTS/MESSAGE",
  TOKEN: "PAYMENTS/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  switch (action.type) {
    case types.PAYMENTSDATA:
      return { ...state, ...action.paymentsData };

    default:
      return state;
  }
};

export const actions = {
  getPaymentsData: (payload) => payload,
};
