import React, { useEffect, useState, useRef, useLayoutEffect } from "react";
import BlueTruck2 from "../assets/images/webp/bluetruck2.webp";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../components/common/Icon";
import Logo from "../assets/images/webp/logo.webp";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { actions as paymentsActions } from "../reducers/paymentsreducer.js";
import { types as paymentsTypes } from "../reducers/paymentsreducer.js";
import AppConfig from "../constants/AppConfig";
import { PNfetch } from "../api/pnfetch";
import { useSetState } from "ahooks";
import { NotificationManager } from "react-notifications";
import { Button } from "antd";
import LoginReact from "../components/ForTruckDrivers/login/custlogin.js";

const hasDBErrors = (retObj) => {
  if (!isNil(retObj.errCode) && retObj.errCode != "0") {
    return true;
  } else {
    return false;
  }
};

const hasErrors = (retObj) => {
  //response.status >= 200 && response.status < 300

  // console.log('retObj', retObj);
  if (
    (!isNil(retObj.message) &&
      isNil(retObj.data) &&
      retObj.messageType == "error") ||
    (!isNil(retObj.message) &&
      // isNil(retObj.data) &&
      retObj.messageType == "error")
  ) {
    return true;
  } else {
    return false;
  }
};

const verifyUser = async (parms) => {
  debugger;
  console.log("REACT_APP_API_URL", process.env.REACT_APP_API_URL);
  let URL = process.env.REACT_APP_API_URL + `/reactlogin`;
  // let URL = `http://localhost:8080/reactlogin`;
  // if (process.env.NODE_ENV == "production") {
  //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
  // }
  return await fetch(URL, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // authorization: "Bearer " + localStorage.getItem("accessToken"),
    },
    body: JSON.stringify({
      ...parms,
    }),
    // redirect: "follow",
    // credentials: "include", // Don't forget to specify this if you need cookies
  })
    .then((response) => response.json())
    .catch((error) => error);
};

const Login = () => {
  const navigate = useNavigate();

  // const onUserLogin = async (e) => {
  //   debugger;

  //   const parms = {
  //     name: "kollive@gmail.com",
  //     password: "ParkNation!",
  //   };
  //   const resultObj = JSON.parse(await verifyUser(parms));
  //   console.log("resultObj", resultObj);
  //   if (resultObj.messageType == "success" || 1 == 1) {
  //     // //  console.log(response.data);
  //     // //Clear Customer Data
  //     //  props.resetLoginSessionData({
  //     //   type: sessionDataTypes.RESET_LOGIN_SESSION_DATA_REQUEST,
  //     //   payload: {
  //     //     parms: resultObj,
  //     //   },
  //     // });
  //     console.log("-----------params-----------------", parms);
  //     //Imp IMp as its taking time to write to the store
  //     localStorage.setItem("accessToken", resultObj.access_token);
  //     localStorage.setItem("refreshToken", resultObj.refresh_token);
  //     localStorage.setItem("session_user_id", resultObj.user.user_id);

  //     setTimeout(() => {
  //       window.location.href =
  //         "http://localhost:8000/customer?user_id=57&token=" +
  //         resultObj.refresh_token;

  //       // this.props.history.push(`/customer?user_id=${resultObj.user.user_id}`);
  //       NotificationManager.success(
  //         "User logged in successfully",
  //         "Login",
  //         1200
  //       );
  //     }, 350);
  //   } else {
  //     alert(resultObj.message);
  //     NotificationManager.error(resultObj.message, "Login", 1200);
  //   }
  // };

  const showHome = () => {
    navigate("/", { replace: true });
  };

  const [state, setState] = useSetState({
    searchText: "",
    animating: false,
    paymentListOrig: [],
    activeSections: [0],
    currentKey: undefined,
    renderVersion: 1,
    sites: [],
    siteSelected: "-1",
    visible: false,
    item: undefined,
    savedReason: undefined,
    amount: 0,
  });
  return (
    <>
      <section className="flex h-screen overflow-x-hidden bg-white">
        <div className="flex flex-col md:flex-row items-center justify-center w-full">
          <div className="w-full md:hidden flex justify-center my-6">
            <Link aria-label="logo" to="/">
              <img
                className=" max-w-[65px] m-auto sm:max-w-[75px]"
                src={Logo}
                alt="logo"
              />
            </Link>
          </div>
          <div className="w-[55%] hidden md:block">
            <img
              src={BlueTruck2}
              alt="BlueTruck"
              className="object-cover  w-full h-screen object-right"
            />
          </div>
          <div className="w-full max-w-[600px] mx-auto px-4 lg:px-0">
            <LoginReact
              showReserve={showHome}
              default_site_id={undefined} //state.site_id
            />
            {/* <h2 className="text-lg md:text-[32px] lg:leading-[55px] lg:text-xl font-bold font-Manrope text-black text-center md:text-start">
              Let’s sign you in back
            </h2>
            <form action="" className="mt-9 font-Poppins">
              <label className="text-base w-full text-black block mb-[2px] ">
                Full name
              </label>
              <input
                className="border-[1px] h-[40px] border-[#0505053D] rounded-[6px] bg-white  py-[11px] ps-2 w-full focus:outline-primary"
                type="text"
                required
                placeholder="Jhon deo"
              />
              <label className="text-base w-full text-black block mb-[2px] mt-[19px]">
                Create password
              </label>
              <input
                className="border-[1px] h-[40px] border-[#0505053D] rounded-[6px] bg-white  py-[11px] ps-2 w-full focus:outline-primary"
                type="password"
                required
                placeholder="*************"
              />              
              <button
                type="button"
                onClick={(e) => onUserLogin()}
                className="first_btn h-[49px] w-full py-[14px] text-base font-semibold font-Manrope text-white mt-[34px]"
              >
                Login
              </button>              
            </form>
            <div className="flex my-[33px] justify-between items-center">
              <span className="h-[1px] bg-black w-[139px]"></span>
              <span className="inline-block mx-[27px] font-Poppins text-black text-md opacity-50">
                or
              </span>
              <span className="h-[1px] bg-black w-[139px]"></span>
            </div>
            <div className="flex items-center justify-center gap-4 ">
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:-translate-y-1 duration-300 ease-in-out "
                href="https://www.google.com"
              >
                <GoogleIcon />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:-translate-y-1 duration-300 ease-in-out  "
                href="https://www.apple.com"
              >
                <AppleIcon />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                className="hover:-translate-y-1 duration-300 ease-in-out  "
                href="https://www.facebook.com"
              >
                <FacebookIcon />
              </a>
            </div>
            <p className="text-center mt-[33px] font-Poppins text-black text-base">
              Don't you have an account ?{" "}
              <Link
                to="/sign-up"
                className="text-primary font-semibold hover:opacity-60"
              >
                {" "}
                Sign Up
              </Link>
            </p>
            <p>
              <Button className="bg-[#1677ff]" type="primary">
                Primary Button
              </Button>
            </p> */}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  // console.log('State', state);
  // const paymentsData = getPaymentsDataSelector(state);
  // const user = getUserDataSelector(state);
  // const refData = getReferenceDataSelector(state);
  return {
    // data: paymentsData.data,
    // paymentsR: state.paymentsData,
    // user,
    // refData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // ...bindActionCreators(
    //   {
    //     ...paymentsActions,
    //   },
    //   dispatch,
    // ),
    defaultRequest: () => {},
    // getInvoice: (userId, token) => {
    //   dispatch(InvoiceA(userId, token));
    // },
    getPaymentsData: (payload) => {
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch getPaymentsData");
      console.log("dispatch getPaymentsData payload", payload);
      dispatch(paymentsActions.getPaymentsData(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
// export default Login;
