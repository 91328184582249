// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "VEHICLE/FETCH_REQUEST",
  VEHICLEDATA: "VEHICLE/VEHICLEDATA",
  DELETE_REQUEST: "VEHICLE/DELETE_REQUEST",
  INSERT_REQUEST: "VEHICLE/INSERT_REQUEST",
  UPDATE_REQUEST: "VEHICLE/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "VEHICLE/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "VEHICLE/MESSAGE",
  TOKEN: "VEHICLE/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.VEHICLEDATA:
      return { ...state, ...action.vehicleData };

    default:
      return state;
  }
};

export const actions = {
  getVehiclesData: (payload) => payload,
};
