import React from "react";
import IntregrationPay from "../../../assets/images/webp/intregration-pay.webp";
import MeetMiddleCricle from "../../../assets/images/webp/meet-opportunity-middle-circle.webp";
const IntegrationsPayment = () => {
  return (
    <>
      <section className="mb-12 md:mb-20 lg:mb-28 xl:mb-[150px]">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-4 xl:gap-[62px] items-center">
            <div>
              <div className="max-w-[553px]">
                <div className="lg:translate-x-[-40px] relative overflow-hidden">
                  <img
                    className="w-full  box"
                    src={IntregrationPay}
                    alt="IntregrationPay"
                  />
                  <img
                    className="absolute top-1/2 left-1/2 -translate-x-1/2  -translate-y-1/2 w-[100px]  sm:w-[218px]"
                    src={MeetMiddleCricle}
                    alt="MeetMiddleCricle"
                  />
                </div>
              </div>
            </div>
            <div className="">
              <div className="max-w-[553px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Integrations in payment with Eagle payment{" "}
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70">
                  ParkNation is tailored to make truck parking enterprises
                  safer, more efficient, and better.
                </p>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70">
                  Our robust features help operators gain the most value from
                  their operations, with less complexity and a higher level of
                  convenience.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrationsPayment;
