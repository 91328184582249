/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import AppConfig from "../../../constants/AppConfig";
import { types as actionTypes } from "../../../reducers/actionReducer";
import { actions as actionActions } from "../../../reducers/actionReducer";
// import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { PNfetch } from "../../../api/pnfetch";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
import { NotificationManager } from "react-notifications";
import dayjs from "dayjs";
import {
  rangePresets,
  formatPhoneNumber,
  printCustName,
  printSiteName,
} from "../../../util/common";
// import {
//   exportToExcelReport,
//   exportToPlainExcelReport,
// } from "../../../util/export";
import {
  getEntitlementsDataSelector,
  getSessionDataSelector,
  getReferenceLayoutDataSelector,
  getErrorDataSelector,
  getReferenceDataSelector,
  getMobileDeviceSelector,
  getCustomersDataSelector,
} from "../../../reselect/sagaReselect";
// import { GlobalStoreContainer } from "../../store";
import { filterTotalResults } from "../../../util/search.js";

import {
  Table,
  Switch,
  Radio,
  Form,
  Space,
  Input,
  InputNumber,
  Layout,
  Popconfirm,
  Typography,
  Select,
  Row,
  Col,
  Button,
  Menu,
  Popover,
  Dropdown,
  message,
  Modal,
  Checkbox,
  Empty,
  notification,
  Drawer,
  Descriptions,
} from "antd";
// import Highlighter from "react-highlight-words";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
import { formatMoney, unformat } from "accounting-js";
import { FormInstance } from "antd/lib/form";
import ReactDragListView from "react-drag-listview";
import * as uuid from "uuid";
import {
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  DownloadOutlined,
  SettingOutlined,
  UserOutlined,
} from "@ant-design/icons";
// intl messages
// import IntlMessages from "Util/IntlMessages";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { types as entitlementTypes } from "../../../reducers/entitlementsreducer";
import { actions as entitlementActions } from "../../../reducers/entitlementsreducer";
import { orderBy, trim, isNil, debounce, cloneDeep, padStart } from "lodash";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ClipLoader, GridLoader } from "react-spinners";
// import { css } from "@emotion/react";
// Can be a string as well. Need to ensure each key-value pair ends with ; // border-color: red;
// const override = css`
//   display: block;
//   margin: 0 auto;
// `;

const { Header, Sider, Content } = Layout;
const Option = Select.Option;
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 12,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
// comment: ""
// created_at: "2021-08-05T08:51:18.263000"
// created_by: 1
// daily_rate: 20
// description: "Can accommodate 4 Motor bikes... test description  test description test description test description"
// id: 14
// monthly_rate: 200
// monthly_reservable: true
// name: "p2"
// site_id_fk: 5
// space_type_id_fk: 2
// status: true
// updated_at: "2021-08-23T21:54:58.470000"
// updated_by: 1
// weekend_rate: 20
// weekly_rate: 20

// space_type_name
// space_name
// monthly_rate
// daily_rate
// weekly_rate
// weekend_rate
// const EditableCell = ({
//   editing,
//   dataIndex,
//   title,
//   inputType,
//   record,
//   index,
//   children,
//   ...restProps
// }) => {
//   const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
//   return (
//     <td {...restProps}>
//       {editing ? (
//         <Form.Item
//           name={dataIndex}
//           style={{
//             margin: 0,
//           }}
//           rules={[
//             {
//               required: true,
//               message: `Please Input ${title}!`,
//             },
//           ]}
//         >
//           {inputNode}
//         </Form.Item>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };

// ,id as site_id
// ,name
// ,description
// ,STATUS as status
// ,city
// ,STATE state
// ,zipcode
// ,comment
// ,contact_info
// ,address_line_1
// ,address_line_2
// ,country
// ,tax_rate
// ,deleted_at
class CloverConnect extends Component {
  grid = 8;
  userInput = null;

  iframeRef = React.createRef();
  getItemStyle = (isDragging, draggableStyle, item) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: "10px 10px 13px",
    margin: `0 0 ${this.grid}px 0`,
    borderRadius: "4px",
    height: "36px",
    lineHeight: "14px",
    opacity: item.selected && !item.canAggregate ? 1 : 0.5,
    // change background colour if dragging
    background: isDragging
      ? "#d1570d"
      : item.type == "groupBy"
      ? "#00a22a"
      : "#1477c8",
    color: "#fff",
    // styles we need to apply on draggables
    ...draggableStyle,
  });

  getListStyleSelected = (isDraggingOver) => ({
    background: isDraggingOver ? "lightblue" : "rgba(0,0,0,0.04",
    padding: this.grid,
    width: 250,
    minHeight: 265,
    paddingBottom: 1,
  });

  id2List = {
    // droppable: "groupBySelected",
    droppable2: "selectedColumns",
  };

  getColumnList = (id) => this.state[this.id2List[id]];

  reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  onDragStart = (result) => {
    const groupByColumnIds = [
      ...new Set(this.state.groupByItems.map((item) => item.id)),
    ];
    let isDropDisabled = true;
    if (groupByColumnIds.includes(result.draggableId)) {
      isDropDisabled = false;
    }
    this.setState({ isDropDisabled });
  };

  onDragColumnEnd = (result) => {
    // debugger;
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const groupBySelected = this.reorder(
        this.getColumnList(source.droppableId),
        source.index,
        destination.index
      );

      if (source.droppableId == "droppable2") {
        // On the right side
        this.setState({ selectedColumns: groupBySelected }, () => {
          let origReportColumns = this.columns;

          const chekedColumns = this.state.selectedColumns.filter(
            (column) => column.selected
          );
          const chekedColumnsNonAggregate = chekedColumns.filter(
            (column) => !column.canAggregate
          );
          const chekedColumnsAggregate = chekedColumns.filter(
            (column) => column.canAggregate
          );

          const unChekedColumns = this.state.selectedColumns.filter(
            (column) => !column.selected
          );
          const selectedColumns = [
            ...chekedColumnsNonAggregate,
            ...unChekedColumns,
            ...chekedColumnsAggregate,
          ];
          const visibleColumns = [
            ...chekedColumnsNonAggregate,
            ...chekedColumnsAggregate,
          ];

          const finalReportColumns = visibleColumns
            .map((column) => {
              if (column.selected) {
                const origColumn = origReportColumns.filter(
                  (origColumn) => origColumn.dataIndex == column.id
                );
                return origColumn;
              } else {
                return null;
              }
            })
            .filter((x) => !!x);

          this.setState(
            {
              selectedColumns,
              columnsToShow: [].concat(...finalReportColumns),
              renderVersion: this.state.renderVersion + 1,
            },
            () => {
              //Save this in DB
              const colSkeletonKeys = selectedColumns.map((col) => {
                return {
                  key: col.id,
                  selected: col.selected ? "Y" : "N",
                };
              });

              const escapedColumns = JSON.stringify(cloneDeep(colSkeletonKeys));
              console.log("escapedColumns", escapedColumns);
              this.props.updateUserLayoutPreferences({
                type: actionTypes.INSERT_UPDATE_LAYOUT_REQUEST,
                payload: {
                  // parms: [record.card_id, this.props.userId],
                  parms: [
                    this.props.userId,
                    this.state.layoutId,
                    this.props.userId,
                    "balances",
                    escapedColumns,
                  ],
                },
              });
            }
          );
        });
      }
    }
  };

  onCheckChange = (record, e) => {
    const checked = e.target.checked;

    let { selectedColumns } = this.state;
    const index = selectedColumns.findIndex((item) => item.id === record.id);
    if (index > -1) {
      let item = selectedColumns[index];
      selectedColumns.splice(index, 1, {
        ...item,
        selected: checked,
      });
    }

    const chekedColumns = selectedColumns.filter((column) => column.selected);
    const chekedColumnsNonAggregate = chekedColumns.filter(
      (column) => !column.canAggregate
    );
    const chekedColumnsAggregate = chekedColumns.filter(
      (column) => column.canAggregate
    );

    const unChekedColumns = selectedColumns.filter(
      (column) => !column.selected
    );

    selectedColumns = [
      ...chekedColumnsNonAggregate,
      ...unChekedColumns,
      ...chekedColumnsAggregate,
    ];

    const visibleColumns = [
      ...chekedColumnsNonAggregate,
      ...chekedColumnsAggregate,
    ];

    let origReportColumns = this.columns;
    const finalReportColumns = visibleColumns
      .map((column) => {
        if (column.selected) {
          const origColumn = origReportColumns.filter(
            (origColumn) => origColumn.dataIndex == column.id
          );
          return origColumn;
        } else {
          return null;
        }
      })
      .filter((x) => !!x);

    this.setState(
      {
        selectedColumns: cloneDeep(selectedColumns),
        columnsToShow: [].concat(...finalReportColumns),
        renderVersion: this.state.renderVersion + 1,
      },
      () => {
        //Save this in DB
        const colSkeletonKeys = selectedColumns.map((col) => {
          return {
            key: col.id,
            selected: col.selected ? "Y" : "N",
          };
        });

        const escapedColumns = JSON.stringify(cloneDeep(colSkeletonKeys));
        console.log("escapedColumns", escapedColumns);
        this.props.updateUserLayoutPreferences({
          type: actionTypes.INSERT_UPDATE_LAYOUT_REQUEST,
          payload: {
            // parms: [record.card_id, this.props.userId],
            parms: [
              this.props.userId,
              this.state.layoutId,
              this.props.userId,
              "balances",
              escapedColumns,
            ],
          },
        });
      }
    );
  };

  resetColumns = () => {
    // debugger;
    const origReportColumns = this.columns;
    const selectedColumns = origReportColumns.map((column) => ({
      id: column.dataIndex,
      content: column.title,
      type: undefined,
      selected: true,
      canAggregate: column.canAggregate,
    }));

    this.setState(
      {
        selectedColumns,
        columnsToShow: origReportColumns,
        data: this.state.dataOrig,
        searchText: "",
      },
      () => {
        //Save this in DB
        const colSkeletonKeys = selectedColumns.map((col) => {
          return {
            key: col.id,
            selected: col.selected ? "Y" : "N",
          };
        });

        const escapedColumns = JSON.stringify(cloneDeep(colSkeletonKeys));
        console.log("escapedColumns", escapedColumns);
        this.props.updateUserLayoutPreferences({
          type: actionTypes.INSERT_UPDATE_LAYOUT_REQUEST,
          payload: {
            // parms: [record.card_id, this.props.userId],
            parms: [
              this.props.userId,
              this.state.layoutId,
              this.props.userId,
              "sites",
              escapedColumns,
            ],
          },
        });
      }
    );
  };

  renderGridColumnSetting = () => {
    const selectedColumns = this.state.selectedColumns;
    const origReportColumnsBase = this.columns;

    const origReportColumns = origReportColumnsBase.map((column) => ({
      id: column.dataIndex,
      content: column.title,
      type: undefined,
      selected: true,
      canAggregate: column.canAggregate,
    }));

    return (
      <div
        style={{
          width: 350,
          textAlign: "center",
          padding: "2px 10px 16px 10px",
          height: 600, //"auto",
          overflowY: "scroll",
        }}
      >
        <DragDropContext
          // onDragStart={this.onDragStart}
          onDragEnd={this.onDragColumnEnd}
        >
          <Row>
            <Col span="24">
              <div>
                <Row gutter={8} style={{ marginBottom: "12px" }}>
                  <Col span="10" style={{ textAlign: "left" }}>
                    <span style={{ fontWeight: 700 }}> Selected Columns</span>
                  </Col>
                  <Col span="14">
                    <span style={{ whiteSpace: "nowrap" }}>
                      <Button
                        size={"medium"}
                        type={"dashed"}
                        onClick={(e) => this.resetColumns()}
                        style={{ width: 90, marginRight: "8px" }}
                      >
                        Reset
                      </Button>
                      <Button
                        size={"medium"}
                        type={"dashed"}
                        onClick={(e) => this.handleVisibleChange(false)}
                        style={{ width: 90 }}
                      >
                        Close
                      </Button>
                    </span>
                  </Col>
                </Row>
                <Row gutter={8}>
                  <Col
                    span="24"
                    style={{ textAlign: "center", paddingLeft: "45px" }}
                  >
                    <Droppable droppableId="droppable2" type="groupBy">
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          style={this.getListStyleSelected(
                            snapshot.isDraggingOver
                          )}
                        >
                          {this.state.selectedColumns.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                              type={item.type}
                              isDragDisabled={
                                !item.selected || item.canAggregate
                              }
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={this.getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style,
                                    item
                                  )}
                                >
                                  {
                                    <span>
                                      <span
                                        style={{
                                          float: "right",
                                          marginTop: "-2px",
                                        }}
                                      >
                                        <Checkbox
                                          checked={item.selected}
                                          disabled={item.canAggregate}
                                          onChange={(e) =>
                                            this.onCheckChange(item, e)
                                          }
                                        />
                                      </span>

                                      <span
                                        style={{
                                          float: "left",
                                          margin: "0px 10px 0px 10px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-bars"
                                          style={{ fontSize: "18px" }}
                                        ></i>
                                      </span>
                                      <span
                                        style={{
                                          float: "left",
                                          marginTop: "2px",
                                        }}
                                      >
                                        {item.content}
                                        {/* {item.canAggregate ? (
                                          <span
                                            style={{
                                              fontSize: "18px",
                                              marginLeft: "8px",
                                              float: "right,",
                                            }}
                                          >
                                            &Sigma;
                                          </span>
                                        ) : null} */}
                                      </span>
                                    </span>
                                  }
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </DragDropContext>
      </div>
    );
  };

  onScreenCheckChange = (record, e, mode) => {
    const checked = e.target.checked;
    let screen_access = "N";
    if (mode == "V") {
      if (checked) {
        screen_access = "V";
      } else {
        screen_access = "N";
      }
    } else {
      //Edit
      if (checked) {
        screen_access = "E";
      } else {
        screen_access = "N";
      }
    }

    const newData = [...this.state.data];
    const index = newData.findIndex((item) => record.key === item.key);
    let updatedRecord = null;
    if (index > -1) {
      const record = newData[index];
      updatedRecord = { ...record, screen_access };
      newData.splice(index, 1, updatedRecord);
      this.setState({
        data: newData,
        // dataOrig: newData,
        currentGridFilteredData: newData,
      });
    }
  };

  paymentColumns = [
    {
      title: "Type",
      dataIndex: "type",
      filterable: true,
      editable: true,
      inputType: "select",
      // fixed: "left",
      width: "100px",
      render: (text, row) => {
        return <span> {row.type} </span>;
      },
      sorter: (a, b) => (a.type || "").localeCompare(b.type || ""),
    },
    {
      title: "Gross",
      dataIndex: "amount",
      filterable: true,
      editable: true,
      inputType: "select",
      // fixed: "left",
      width: "100px",
      render: (text, row) => {
        return <span> {this.formatNumber(row.amount)} </span>;
      },
      sorter: (a, b) => (a.amount || 0) - (b.amount || 0),
    },
    {
      title: "Fee",
      dataIndex: "fee",
      filterable: true,
      editable: true,
      inputType: "select",
      // fixed: "left",
      width: "100px",
      render: (text, row) => {
        return <span> {this.formatNumber(row.fee)} </span>;
      },
      sorter: (a, b) => (a.fee || 0) - (b.fee || 0),
      // sorter: (a, b) => (a.fee || "").localeCompare(b.fee || ""),
    },
    {
      title: "Total",
      dataIndex: "total",
      filterable: true,
      editable: true,
      inputType: "select",
      // fixed: "left",
      width: "100px",
      render: (text, row) => {
        return <span> {this.formatNumber(row.total)} </span>;
      },
      sorter: (a, b) => (a.total || 0) - (b.total || 0),
    },
    //routing_number: bankAccount.routing_number,
    // account_holder_name: bankAccount.account_holder_name,
    {
      title: "Description",
      dataIndex: "description",
      filterable: true,
      editable: true,
      inputType: "text",
      width: "auto",
      // width: 100,
      render: (text, row) => {
        return (
          <span>
            <span>{row.description}</span>{" "}
          </span>
        );
      },
      sorter: (a, b) => (a.bankName || "").localeCompare(b.bankName || ""),
    },

    {
      title: "Date",
      dataIndex: "created_at",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      width: 210,
      render: (text, row) => {
        return <span> {row.created_at ? row.created_at : ""} </span>;
      },
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
    },
  ];

  columns = [
    {
      title: "Brand",
      dataIndex: "brand",
      filterable: true,
      editable: true,
      inputType: "select",
      // fixed: "left",
      width: 250,
      render: (text, row) => {
        return <span> {row.information.brand} </span>;
      },
      sorter: (a, b) =>
        (a.information.brand || "").localeCompare(b.information.brand || ""),
    },

    //routing_number: bankAccount.routing_number,
    // account_holder_name: bankAccount.account_holder_name,
    {
      title: "Model",
      dataIndex: "model",
      filterable: true,
      editable: true,
      inputType: "text",
      width: 250,
      // width: 100,
      render: (text, row) => {
        return (
          <span>
            <span>{row.information.model}</span>{" "}
            <span style={{ fontStyle: "italic", opacity: 0.5 }}>
              <br />
              {`${row.information.year} ${row.information.vin}`}
            </span>
          </span>
        );
      },
      // sorter: (a, b) => (a.bankName || "").localeCompare(b.bankName || ""),
    },
    {
      title: "Miles",
      dataIndex: "distance",
      filterable: true,
      editable: true,
      inputType: "text",
      width: 250,
      render: (text, row) => {
        return (
          <span> {Number(row.odometer.distance / 1.609).toFixed(0)} </span>
        );
      },
      // sorter: (a, b) =>
      //   (a.odometer.distance || "").localeCompare(
      //     b.odometer.distance|| ""
      //   ),
    },
    {
      title: "Range",
      dataIndex: "arrived_at",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      width: 210,
      render: (text, row) => {
        return (
          <span> {Number(row.chargeState.range / 1.609).toFixed(0)} </span>
        );
      },
      // sorter: (a, b) => new Date(a.arrived_at) - new Date(b.arrived_at),
    },
    {
      title: "Action",
      dataIndex: "operation",
      width: 200,
      align: "center",
      canAggregate: true,
      // width: 100,
      render: (_, record) => {
        return (
          <span
            style={{ whiteSpace: "nowrap" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Button
              type="dashed"
              // shape="round"
              // loading={
              //   // this.state.loadingSubscription &&
              //   record.subscriptionId == this.state.currentSubscriptionId &&
              //   !isNil(this.state.currentSubscriptionId)
              // }
              // onClick={() => this.edit(record)}
              icon={<EditOutlined />}
              size="medium"
              style={{
                marginRight: "8px",
                cursor: "pointer",
              }}
            >
              Smart Schedule
            </Button>
            <Button
              type="dashed"
              // shape="round"
              // loading={
              //   // this.state.loadingSubscription &&
              //   record.subscriptionId == this.state.currentSubscriptionId &&
              //   !isNil(this.state.currentSubscriptionId)
              // }
              // onClick={() => this.edit(record)}
              icon={<EditOutlined />}
              size="medium"
              style={{
                marginRight: "8px",
                cursor: "pointer",
              }}
            >
              Charge
            </Button>
          </span>
        );
      },
    },
  ];

  onResetSearchAll = () => {
    this.setState({
      data: this.state.dataOrig,
      searchText: "",
      // renderVersion: this.state.renderVersion + 1,
    });
  };

  debouncedGridSearch = debounce(() => this.onSearch(), 50);

  onSearch = () => {
    let { searchText } = this.state;
    if (isNil(searchText)) {
      searchText = "";
    }

    if (searchText.trim() === "") {
      this.setState({ searchText: "" }, () => {
        // this.onResetSearchAll();
      });
    } else {
      const results = filterTotalResults(searchText, this.state.dataOrig);
      this.setState({
        data: results,
        currentGridFilteredData: results,
        // renderVersion: this.state.renderVersion + 1,
        searchText,
      });
    }
  };
  onInputChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.debouncedGridSearch();
    });
  };

  state = {
    validationError: "",
    useDefaultMethod: undefined,
    discAck: true,
    expiry: "",
    customers: this.props.referenceData.users || [],
    customer: undefined,
    routing_number: undefined,
    account_number: undefined,
    paymentType: "C",
    windowSize: {
      width: window.innerWidth,
      height: window.innerHeight,
    },
    cardToken: undefined,
    enodeVehicles: [],
    invoice_user_id: this.props.user_id_fk,
    site_id: this.props.site_id,
    evModalVisible: false,
    layoutId: "-1",
    paymentTransactions: [],
    payOutsdata: [],
    showDrawer: false,
    balance: undefined,
    selectedSite: 5,
    id: undefined,
    description: undefined,
    name: undefined,
    newSpaceTypeModalVisible: false,
    currentPage: 1,
    currentGridFilteredData: [],
    bordered: false,
    loading: false,
    filteredInfo: null,
    sortedInfo: null,
    //pagination,
    size: "default",
    //expandable,
    title: undefined,
    //showHeader,
    //footer,
    rowSelection: {},
    scroll: undefined,
    xScroll: "fixed",
    yScroll: true,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    searchText: "",
    searchedColumn: "",
    renderVersion: 1,
    editingKey: "",
    data: [],
    dataOrig: [],
    columnsToShow: this.columns,
    visible: false,
    selectedColumns: [],
  };

  formRef = React.createRef();
  formatNumber = (val) => {
    return formatMoney(val, {
      symbol: "$",
      precision: 2,
      thousand: ",",
      format: {
        pos: "%s %v",
        neg: "%s (%v)",
        zero: "%s --",
      },
    });
  };

  formatPerc = (val) => {
    return `${formatMoney(val, {
      symbol: "",
      precision: 2,
      thousand: ",",
      format: {
        pos: "%s %v",
        neg: "%s (%v)",
        zero: "%s --",
      },
    })} %`;
  };

  cloverMessage = (event) => {
    console.log("event", event);
    var token = JSON.parse(event.data);
    console.log("token", token.message);
    const record = this.formRef.current.getFieldsValue(true);
    console.log("record", record);
    this.formRef.current.setFieldsValue({
      ...record,
      mytoken: token.message,
    });
  };

  constructor(props) {
    super(props);
    window.addEventListener("message", (e) => {
      debugger;
      console.log("event", e);
      //       3X= Amex
      // 4X= Visa
      // 5X= Mastercard
      // 6X= Discover
      try {
        var data = JSON.parse(e?.data);
        const myToken = data.message;
        console.log("token", data.message);
        console.log("validationError", data.validationError);
        // const record = this.formRef.current.getFieldsValue(true);
        // console.log("record", record);
        this.setState(
          {
            cardToken: myToken,
            expiry: data.expiry,
            validationError: data.validationError,
          },
          () => {
            // this.formRef.current.setFieldsValue({
            //   ...record,
            //   mytoken: myToken,
            // });
          }
        );
      } catch (e) {
        console.log("event Error", e);
      }
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      console.log("data nextProps", nextProps.data);
      // if (!isNil(nextProps.errorData) && nextProps.errorData.length > 0) {
      //   // debugger;
      //   this.openNotificationWithIcon("error", "Error", nextProps.errorData[0]);
      //   if (nextProps.errorData[0] == "Invalid Token") {
      //     let store = GlobalStoreContainer.store;
      //     store.dispatch({ type: "LOGOUT_USER" });
      //   } else {
      //     //Clear Error
      //     this.props.clearError({
      //       type: actionTypes.CLEAR_ERROR_REQUEST,
      //       payload: {
      //         parms: [],
      //       },
      //     });
      //   }
      // }

      // let data = nextProps.data;

      this.setState(
        {
          // data: data,
          // dataOrig: data,
          // currentGridFilteredData: data,
        },
        () => {}
      );
    }
  }

  getSiteBalancesAPI = async (parms) => {
    // debugger;
    let URL = process.env.REACT_APP_API_URL + `/balance/getSiteBalance`;

    const response = await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        user_id: this.props.userId,
        site_id: parms.site_id,
        stripe_connect_account_id: parms.stripe_connect_account_id,
        is_affiliate: parms.is_affiliate,
      }),
    });

    return await response.json();
  };

  getPayoutDetailsAPI = async (parms) => {
    // debugger;
    let URL = process.env.REACT_APP_API_URL + `/balance/getPayoutTransactions`;

    const response = await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        // user_id: this.props.userId,
        // site_id: parms.site_id,
        stripe_connect_account_id: parms.stripe_connect_account_id,
        is_affiliate: parms.is_affiliate,
        payoutId: parms.payoutId,
      }),
    });

    return await response.json();
  };
  componentDidUpdate(prevProps, prevState) {}
  componentDidMount() {}

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  };
  exportItems = [
    {
      label: "Export To Excel",
      key: "1",
    },
    {
      label: "Export To CSV",
      key: "2",
    },
  ];

  handleMenuClick = ({ key }) => {
    // debugger;
    if (key.toString() == 1) {
      exportToPlainExcelReport(
        this.state.columnsToShow,
        "excel",
        this.state.currentGridFilteredData,
        "EVehicles",
        ["operation", "action", "communicate"]
      );
    } else {
      exportToPlainExcelReport(
        this.state.columnsToShow,
        "csv",
        this.state.currentGridFilteredData,
        "EVehicles",
        ["operation", "action", "communicate"]
      );
    }
    // message.info("Click on menu item.");
    // console.log("click", e);
  };

  menu = (
    <Menu onClick={this.handleMenuClick}>
      <Menu.Item key="1" icon={<UserOutlined />}>
        Export To Excel
      </Menu.Item>
      <Menu.Item key="2" icon={<UserOutlined />}>
        Export To CSV
      </Menu.Item>
      {/* <Menu.Item key="3" icon={<UserOutlined />}>
        Export To PDF
      </Menu.Item> */}
    </Menu>
  );

  printSiteName = (item) => {
    //
    const custStr =
      (item.name ? item.name : " ") +
      "| " +
      (item.description ? item.description : " ") +
      " | " +
      (item.city ? item.city : "No City") +
      " | " +
      (item.state ? item.state : "No State") +
      " | " +
      (item.zipcode ? item.zipcode : "No Zip");
    return custStr;
  };

  getSiteDetails = () => {
    //
    //This is coming from the database
    if (isNil(this.state.siteId) || this.state.siteId == -1) {
      return {
        id: "",
        name: "",
        description: "",
        status: "",
        city: "",
        state: "",
        zipcode: "",
        comment: "",
        contact_info: "",
        address_line_1: "",
        address_line_2: " ",
        country: "",
        tax_rate: "",
      };
    } else {
      return this.state.sites.filter(
        (rec) => rec.site_id == this.state.siteId
      )[0];
    }
  };

  setSpaceTypeVisible = (visible) => {
    this.setState({ newSpaceTypeModalVisible: visible });
  };

  openNotificationWithIcon = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  onFinish = (values) => {
    console.log(values);
  };
  printCustName = (item) => {
    //
    const custStr =
      (item.last_name ? item.last_name : " ") +
      ", " +
      (item.first_name ? item.first_name : " ") +
      " | " +
      (item.email ? item.email : "No Email") +
      " | " +
      (item.phone_number
        ? formatPhoneNumber(item.phone_number)
        : "No Phone #") +
      " | " +
      (item.company_name ? item.company_name : "No Company Name");
    return custStr;
  };

  // handleUser = (val) => {
  //   // debugger;
  //   let name, site_id;
  //   const foundRecords = (this.props.referenceData.users || []).filter(
  //     (rec) => rec.user_id == val
  //   );
  //   if (foundRecords.length > 0) {
  //     name = foundRecords[0].last_name + ", " + foundRecords[0].first_name;
  //     site_id = foundRecords[0].default_site_id;
  //   }

  //   this.setState(
  //     {
  //       invoice_user_id: val,
  //       customer: foundRecords[0],
  //       name,
  //       site_id_fk: site_id,
  //       enodeVehicles: [],
  //     },
  //     async () => {
  //       if (foundRecords[0].hasEV && foundRecords[0].hasEV == "Y") {
  //         MySwal.fire({
  //           title: "EVehicles",
  //           html: "Loading the EVehicles, Please be patient.",
  //           allowEscapeKey: false,
  //           allowOutsideClick: false,
  //           onOpen: () => {
  //             MySwal.showLoading();
  //           },
  //           onClose: () => {},
  //         }).then((result) => {
  //           if (result.dismiss === Swal.DismissReason.timer) {
  //             console.log("Closed by Timer");
  //           }
  //         });
  //         const retObj = await this.getEVVehicles();
  //         MySwal.close();
  //         if (!isNil(retObj) && !this.hasErrors(retObj)) {
  //           console.log("retObj", retObj);
  //           console.log("enodeVehicles", retObj.enodeVehicles);
  //           this.setState({
  //             enodeVehicles: retObj.enodeVehicles,
  //           });
  //         } else {
  //           NotificationManager.error(retObj.message, "EV URL", 1200);
  //         }
  //       } else {
  //         const retObj = await this.getEVURL();
  //         if (!isNil(retObj) && !this.hasErrors(retObj)) {
  //           console.log("retObj", retObj);
  //           this.setState({
  //             evModalVisible: true,
  //             EVURL: retObj.enodeLink.linkUrl,
  //           });
  //         } else {
  //           NotificationManager.error(retObj.message, "EV URL", 1200);
  //         }
  //       }
  //     }
  //   );
  // };

  getUserDetails = (userId) => {
    // debugger;

    return new Promise((resolve, reject) => {
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_checkUser_id",
          input: [userId],
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });
  };

  handleUser = (val) => {
    // const record = this.formRef.current.getFieldsValue(true);
    MySwal.fire({
      title: "User Details",
      html: "Retrieving the User Details, Please be patient.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        MySwal.showLoading();
      },
      onClose: () => {},
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Closed by Timer");
      }
    });

    this.getUserDetails(val)
      .then((resultObj) => {
        MySwal.close();
        if (!this.hasErrors(resultObj)) {
          // const data =
          //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

          const userObj = resultObj.data[0][0];
          this.setState({
            total_balance_amount: -1 * userObj.total_balance_amount,
          });
        } else {
          //Display Error
          this.openNotificationWithIcon(
            "error",
            "Error",
            resultObj.message ? resultObj.message : "An error has occured!"
          );
        }
      })
      .catch((error) => {
        MySwal.close();
        console.log("Error", error);
      });

    let invoiceReferenceId = `${dayjs().format("YYYYMMDD")}-${val}`;
    let name = "";
    let site_id = this.state.site_id;
    let tax = true;
    let tax_rate = 0;
    const foundRecords = (this.props.referenceData.users || []).filter(
      (rec) => rec.user_id == val
    );
    if (foundRecords.length > 0) {
      name = foundRecords[0].last_name + ", " + foundRecords[0].first_name;

      invoiceReferenceId =
        invoiceReferenceId +
        "-" +
        padStart(foundRecords[0].newInvoiceId, 6, "0");

      if (isNil(site_id) || site_id == -1) {
        site_id = foundRecords[0].default_site_id;
      }
      // const siteRec = this.props.referenceData.sites.filter(
      //   (itm) => itm.site_id == site_id
      // )[0];
      // tax_rate = siteRec.tax_rate;
    } else {
      invoiceReferenceId = invoiceReferenceId + "-" + padStart("1", 6, "0");
    }

    this.setState(
      {
        invoice_user_id: val,
        invoiceReferenceId,
        customer: foundRecords[0],
        name,
        site_id,
        // isNil(site_id) || site_id == -1
        //   ? foundRecords.length > 0
        //     ? foundRecords[0].default_site_id
        //     : undefined
        //   : site_id,
        card_id: undefined,
        cardOption: undefined,
      },
      () => {}
    );
  };

  handleSave = async () => {
    MySwal.fire({
      title: "EVehicles",
      html: "Saving the EVehicles, Please be patient.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        MySwal.showLoading();
      },
      onClose: () => {},
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Closed by Timer");
      }
    });

    let itemStr = "";
    this.state.data.map((itm) => {
      itemStr += itm.screen_id + "~" + itm.screen_access + "^";
    });

    const resultObj = await this.saveEntitlements(itemStr);
    // console.log("resultObj", resultObj);
    // MySwal.close();
    // window.open("data:application/pdf," + escape(resultObj));
    // return;
    // const data = resultObj.data;
    if (!this.hasDBErrors(resultObj)) {
      // if (resultObj.errCode == "0") {
      MySwal.close();
      this.openNotificationWithIcon("success", "Success", "EVehicles Saved.");
    } else {
      MySwal.close();
      //Display Error
      this.openNotificationWithIcon(
        "error",
        "Error",
        resultObj.message ? resultObj.message : "An error has occured!"
      );
    }
  };

  hasDBErrors = (retObj) => {
    if (!isNil(retObj.errCode) && retObj.errCode != "0") {
      return true;
    } else {
      return false;
    }
  };

  hasErrors = (retObj) => {
    //response.status >= 200 && response.status < 300
    // debugger;
    console.log("retObj", retObj);
    if (isNil(retObj)) {
      return true;
    } else if (
      (!isNil(retObj.data) &&
        !isNil(retObj.data.status) &&
        retObj.data.status != "succeeded" &&
        retObj.data.status != "processing") ||
      (!isNil(retObj.message) && retObj.messageType == "error")
    ) {
      return true;
    } else {
      return false;
    }
  };
  handleCancel = () => {
    this.setState({ data: this.state.dataOrig });
  };
  handleSite = (val) => {
    // const record = this.formRef.current.getFieldsValue(true);
    const site_id = val;
    this.setState({ selectedSite: val, site_id }, () => {
      // this.getSiteBalances();
    });
  };

  iframeCounter = 0;
  onLoad = async () => {
    // debugger;
    const url = this.iframeRef.current.src; //contentWindow.location.href;
    console.log("url", url);
    if (
      url &&
      url.toString().indexOf("parknation.io") > 0 &&
      this.iframeCounter == 1
    ) {
      //Update the User Flag
    } else {
      this.iframeCounter += 1;
    }
  };

  handlePaymentType = (e) => {
    const option = e.target.value;

    this.setState(
      {
        paymentType: option,
      },
      () => {}
    );
    console.log("radio checked", e.target.value);
  };
  updateRoutingNumber = (e) => {
    this.setState({
      routing_number: e.target.value.replace(/[^0-9]/g, ""),
    });
    // });
  };

  updateAccountNumber = (e) => {
    this.setState({
      account_number: e.target.value.replace(/[^0-9]/g, ""),
    });
    // });
  };

  saveNewPayment = async (e) => {
    e.preventDefault();
    e.persist();

    if (this.state.paymentType == "C") {
      if (
        isNil(this.state.cardToken) ||
        trim(this.state.cardToken) == "" ||
        trim(this.state.expiry) == ""
      ) {
        this.openNotificationWithIcon(
          "error",
          "Card Error",
          "Please enter correct card information"
        );
        return false;
      }
    } else {
      if (
        isNil(this.state.routing_number) ||
        trim(this.state.routing_number) == ""
      ) {
        this.openNotificationWithIcon(
          "error",
          "Card Error",
          "Please enter the Bank routing Number"
        );
        return false;
      }

      if (
        isNil(this.state.account_number) ||
        trim(this.state.account_number) == ""
      ) {
        this.openNotificationWithIcon(
          "error",
          "Card Error",
          "Please enter the Bank Account Number"
        );
        return false;
      }
    }

    let name = "";
    const foundRecords = (this.props.referenceData.users || []).filter(
      (rec) => rec.user_id == this.state.invoice_user_id
    );
    if (foundRecords.length > 0) {
      name = foundRecords[0].last_name + ", " + foundRecords[0].first_name;
    }

    MySwal.fire({
      title: "Payments",
      html: "Saving the payment Method, Please be patient.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      onOpen: () => {
        MySwal.showLoading();
      },
      onClose: () => {},
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        console.log("Closed by Timer");
      }
    });

    let URL = process.env.REACT_APP_API_URL + `/clover/updateSI`;
    const response = await PNfetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        // amount: this.state.amountToPay,
        session_user_id: this.props.userId
          ? this.props.userId
          : this.state.invoice_user_id,
        user_id_fk: this.state.invoice_user_id,
        site_id: this.state.site_id,
        paymentType: this.state.paymentType,
        expiry: this.state.expiry,
        cardToken: this.state.cardToken,
        routing_number: this.state.routing_number,
        account_number: this.state.account_number,
        cust_name: name,
        userAckForFutureCharge: this.state.discAck ? "Y" : "N",
        useDefaultMethod: this.state.useDefaultMethod ? 1 : 0,
        // cust_name: this.state.customer?.customer_name
        //   ? this.state.customer.customer_name
        //   : this.state.customer.last_name +
        //     ", " +
        //     this.state.customer.first_name,
      }),
    });

    const retObj = await response.json();
    //  // debugger;
    MySwal.close();
    if (!this.hasErrors(retObj)) {
      this.openNotificationWithIcon(
        "success",
        "Setup Success",
        "Payment Method Captured successfully."
      );

      //Refresh the Reference Data Cards
      // const refreshObjects = ['cards','vehicles','users']
      this.props.refreshReferenceData({
        type: actionTypes.REFRESH_REFDATA_REQUEST,
        payload: {
          parms: [this.props.userId],
        },
        user_id_fk: this.props.userId,
      });

      // this.props.refreshReferenceSpecificData({
      //   type: actionTypes.REFRESH_REFDATA_SPECIFIC_REQUEST,
      //   payload: {
      //     parms: [this.props.userId, "cards"],
      //   },
      //   user_id_fk: this.props.userId,
      // });

      console.log("retObj", retObj);
      if (this.props.fromFooterAdd) {
        setTimeout(() => {
          const { history } = this.props;
          history.push({
            pathname: "/ccard",
          });
        }, 200);
      } else {
        this.props.closeSetupModal(true, retObj);
      }
    } else {
      MySwal.close();
      //Display Error
      this.openNotificationWithIcon(
        "error",
        "Error",
        retObj.message ? retObj.message : "An error has occured!"
      );
    }
  };

  onCheckDiscChange = (e) => {
    const checked = e.target.checked;
    this.setState({ discAck: checked });
  };

  onChecDefaultChange = (e) => {
    const checked = e.target.checked;
    this.setState({ useDefaultMethod: checked });
  };

  render() {
    // const uri = ".error{color:%20red;}input{width:500px;}";

    const uri = `
     body{
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      line-height: 1.5;
      color: rgb(26, 32, 44);
     }
     label {margin-left: 5px}
     br {margin: 0;}
    .error{color:red;}
     input, select {
      padding: 8px;
      background-color:white;
      border-radius: 8px;
      font-size:16px;
      margin-bottom: 15px;
      margin-top: 5px;
      width: 500px;
      max-width: 90%;
      border: 1px solid lightgray;
      transition: background 0.15s ease, border 0.15s ease, box-shadow 0.15s ease, color 0.15s ease;
      box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%);
    }   
    select {
      width: 245px;
      max-width: 43%;
    } 
    select + select { margin-left: 4% !important;}
   `;
    const encoded = encodeURI(uri);

    //&invalidexpiryevent=true
    const iframeURL =
      process.env.REACT_APP_CC_URL +
      `?tokenizewheninactive=true&inactivityto=500&cardinputmaxlength=19&autofocus=true&fullmobilekeyboard=true&enhancedresponse=true&cardnumbernumericonly=true&
      unique=true&useexpiryfield=false&formatinput=true&useexpiry=true&usecvv=true&invalidcreditcardevent=true&invalidcvvevent=true
      &css=${encoded}`;

    console.log("iframeURL", iframeURL);

    return (
      <div className="ecom-Invoices-wrapper">
        <div className={"pn-modal-body"}>
          {this.props.showUserDropdown == "Y" || this.props.fromFooterAdd ? (
            <>
              {this.props.fromFooterAdd && (
                <Row align={"middle"} gutter={24}>
                  <Col lg={6}>
                    <Typography.Title
                      level={4}
                      style={{
                        marginBottom: 16,
                        color: "inherit",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Add Payment Method
                    </Typography.Title>
                  </Col>
                </Row>
              )}
              <Typography.Title
                level={5}
                style={{ fontSize: 14, marginLeft: 10 }}
              >
                Customer
              </Typography.Title>
              <div
                style={{
                  marginBottom: "12px",
                }}
              >
                <Select
                  key={this.state.renderVersion}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Customer"
                  // width={"90%"}
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={780}
                  disabled
                  // disabled={this.props.fromFooterAdd ? false : true}
                  // disabled={this.props.sessionData.role == 4}
                  // ref={(input) => {
                  //   this.userInput = input;
                  // }}
                  // disabled={
                  //   this.props.history.location.record.mode == "E" ||
                  //   this.props.history.location.record
                  //     .fromCustomerScreen == "Y"
                  // }
                  onChange={this.handleUser}
                  value={this.state.invoice_user_id}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={(e) => {
                  //   if (e.length > 0)
                  //     this.setState({ newCustomerOption: e });
                  // }}
                  filterOption={(input, option) => {
                    return (
                      (option.searchText || "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {(this.props.referenceData.users || []) // this.props.parentState.customers || this.state.customers
                    .map((item) => (
                      <Select.Option
                        key={uuid.v4()}
                        value={item.user_id}
                        searchText={item.searchText}
                      >
                        <span>{this.printCustName(item)}</span>
                      </Select.Option>
                    ))}
                </Select>
              </div>
              <Typography.Title
                level={5}
                style={{ fontSize: 14, marginLeft: 10 }}
              >
                Site
              </Typography.Title>
              <div
                style={{
                  marginBottom: "12px",
                }}
              >
                <Select
                  key={this.state.renderVersion}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Select a Site"
                  optionFilterProp="children"
                  disabled
                  dropdownMatchSelectWidth={780}
                  // disabled
                  // disabled={this.props.sessionData.role == 4}
                  // ref={(input) => {
                  //   this.userInput = input;
                  // }}
                  // disabled={
                  //   this.props.history.location.record.mode == "E" ||
                  //   this.props.history.location.record
                  //     .fromCustomerScreen == "Y"
                  // }
                  onChange={this.handleSite}
                  value={this.state.site_id}
                  // onFocus={onFocus}
                  // onBlur={onBlur}
                  // onSearch={(e) => {
                  //   if (e.length > 0)
                  //     this.setState({ newCustomerOption: e });
                  // }}
                  filterOption={(input, option) => {
                    return (
                      (option.searchText || "")
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {(this.props.sites || []).map((item) => (
                    <Select.Option
                      key={uuid.v4()}
                      value={item.site_id}
                      searchText={item.name}
                    >
                      <span>{printSiteName(item)}</span>
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </>
          ) : null}
          <div
            style={
              {
                //marginBottom: "12px",
              }
            }
          >
            {/* <Typography.Title
              level={5}
              style={{ fontSize: 14, marginLeft: 10 }}
            >
              Payment Method Information
            </Typography.Title> */}
            <Row>
              <Col span="24">
                <Descriptions
                  // title="Payment Method"
                  bordered
                  layout={
                    this.state.windowSize.width < 576
                      ? "vertical"
                      : "horizontal"
                  }
                  column={1}
                  className={"table-layout-fixed"}
                  labelStyle={{ width: "180px" }}
                >
                  <Descriptions.Item
                    label={
                      <span>
                        <span
                          style={{
                            position: "relative",
                            top: "-3px",
                            left: "-9px",
                            color: "#e20000",
                          }}
                        >
                          *
                        </span>
                        <span
                          style={{
                            position: "relative",
                            left: "-7px",
                          }}
                        >
                          Payment Type
                        </span>
                      </span>
                    }
                  >
                    <Radio.Group
                      onChange={this.handlePaymentType}
                      value={this.state.paymentType}
                      buttonStyle="solid"
                      style={{ backgroundColor: "whitesmoke" }}
                      optionType="button"
                    >
                      <Space direction="horizontal">
                        <Radio value={"C"}>Credit Card</Radio>
                        <Radio value={"B"}>E-Check / ACH</Radio>
                      </Space>
                    </Radio.Group>
                  </Descriptions.Item>
                </Descriptions>
              </Col>
            </Row>
            <Row>
              <Col span="24">
                {this.state.paymentType == "C" ? (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ margin: "15px 0 0" }}
                    //                 // style={{
                    //                 //   width: "350px",
                    //                 //   position: "relative",
                    //                 //   top: "5px",
                    //                 //   left: "15%",
                    //                 //   height: "27px",
                    //                 // }}
                  >
                    <Form
                      id="dataEntryDiv"
                      {...layout}
                      ref={this.formRef}
                      component={false}
                      name="control-ref"
                      onFinish={this.onFinish}
                      initialValues={{
                        mytoken: "",
                        //                     // id: this.state.id,
                        //                     // name: this.state.name,
                        //                     // description: this.state.description,
                      }}
                    >
                      {/* https://fts-uat.cardconnect.com/itoke/outer-page.html?useexpiry=true&invalidcreditcardevent=true.
                       */}
                      {/* &placeholder=4242 4242 4242 4242&placeholdercvv=____& */}
                      <iframe
                        ref={this.iframeRef}
                        onLoad={this.onLoad}
                        // src={`https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html?
                        src={iframeURL}
                        width={800}
                        className={"clover-card"}
                        //                     // style={{
                        //                     //   width: "auto",
                        //                     //   textAlign: "center",
                        //                     //   maxHeight: "200px !important",
                        //                     // }}
                      ></iframe>
                      {/* <Form.Item
                          name="mytoken"
                          label="My Token"
                          // hidden={true}
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item> */}
                    </Form>
                  </div>
                ) : (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    style={{ margin: "15px 0 0" }}
                    //                 // style={{
                    //                 //   width: "350px",
                    //                 //   position: "relative",
                    //                 //   top: "5px",
                    //                 //   left: "15%",
                    //                 //   height: "27px",
                    //                 // }}
                  >
                    <Descriptions
                      // title="Line Items"
                      bordered
                      layout={
                        this.state.windowSize.width < 576
                          ? "vertical"
                          : "horizontal"
                      }
                      column={1}
                      className={"table-layout-fixed"}
                      labelStyle={{ width: "180px" }}
                    >
                      <Descriptions.Item
                        label={
                          <span>
                            <span
                              style={{
                                position: "relative",
                                top: "-3px",
                                left: "-9px",
                                color: "#e20000",
                              }}
                            >
                              *
                            </span>
                            <span
                              style={{
                                position: "relative",
                                left: "-7px",
                              }}
                            >
                              Routing Number
                            </span>
                          </span>
                        }
                      >
                        <Input
                          style={{ width: 180 }}
                          // stringMode
                          // formatter={(value) =>
                          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
                          // }
                          // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          //                       // precision="2"
                          //                       // min={0}
                          //                       // max={9999}
                          maxLength={9}
                          value={this.state.routing_number}
                          onChange={(e) => this.updateRoutingNumber(e)}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item
                        label={
                          <span>
                            <span
                              style={{
                                position: "relative",
                                top: "-3px",
                                left: "-9px",
                                color: "#e20000",
                              }}
                            >
                              *
                            </span>
                            <span
                              style={{
                                position: "relative",
                                left: "-7px",
                              }}
                            >
                              Account Number
                            </span>
                          </span>
                        }
                      >
                        <Input
                          style={{ width: 180 }}
                          // stringMode
                          // formatter={(value) =>
                          //   `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
                          // }
                          // parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                          //                       // precision="2"
                          //                       // min={0}
                          //                       // max={9999}
                          maxLength={17}
                          value={this.state.account_number}
                          onChange={(e) => this.updateAccountNumber(e)}
                        />
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                )}
              </Col>
            </Row>
            {/* <form
              onSubmit={this.handleSubmit}
              ref={(c) => (this.paymentFormRef = c)}
              // key={this.state.renderVersion}
            >
              <PaymentElement options={this.state.SEOptions} />
            </form> */}
          </div>
        </div>
        <div className={"pn-modal-footer"}>
          {this.state.validationError != "" && (
            <Row style={{ marginBottom: "8px" }}>
              <Col>
                <>
                  {/* <Typography.Text
                    // level={6}
                    style={{
                      marginRight: "8px",
                      color: "#1677ff",
                      // fontSize: "11px",
                      // fontWeight: 700,
                    }}
                  >
                    Error:
                  </Typography.Text> */}

                  <Typography.Text
                    // level={6}
                    style={{
                      // marginBottom: "16px",
                      color: "#e20000",
                      // fontSize: "11px",
                      // fontWeight: 700,
                    }}
                  >
                    {this.state.validationError}
                  </Typography.Text>
                </>
              </Col>
            </Row>
          )}
          <Row style={{ marginBottom: "8px" }}>
            <Col>
              <>
                <Checkbox
                  checked={this.state.useDefaultMethod}
                  // disabled={item.canAggregate}
                  onChange={(e) => this.onChecDefaultChange(e)}
                  style={{ marginRight: "8px" }}
                />

                <Typography.Text
                  // level={6}
                  style={{
                    // marginBottom: "16px",
                    color: "#1677ff",
                    // fontSize: "11px",
                    // fontWeight: 700,
                  }}
                >
                  Make it Default Payment Method
                </Typography.Text>
              </>
            </Col>
          </Row>
          <Row style={{ marginBottom: "12px" }}>
            <Col>
              <div
                className={"d-flex align-items-start"}
                style={{ textAlign: "left" }}
              >
                {this.props.showCheckDisclaimer == "Y" && (
                  <Checkbox
                    checked={this.state.discAck}
                    // disabled={item.canAggregate}
                    onChange={(e) => this.onCheckDiscChange(e)}
                    style={{ marginRight: "8px" }}
                  />
                )}
                <Typography.Text
                  // level={6}
                  style={{
                    // marginBottom: "16px",
                    color: "inherit",
                    // fontSize: "11px",
                    // fontWeight: 700,
                  }}
                >
                  By providing your card information, you allow PARK NATION LLC
                  to charge your card for future payments in accordance with
                  their terms.
                </Typography.Text>
              </div>
            </Col>
          </Row>
          <span className="text-right" onClick={(e) => e.stopPropagation()}>
            <Button
              size="medium"
              type="primary"
              danger
              htmlType="submit"
              style={{ marginRight: "8px" }}
              disabled={
                this.state.paymentType == "C" &&
                (isNil(this.state.cardToken) ||
                  trim(this.state.cardToken) == "" ||
                  trim(this.state.expiry) == "")
              }
              onClick={(e) =>
                setTimeout(() => {
                  this.saveNewPayment(e);
                }, 700)
              }
            >
              Save
            </Button>
            <Button
              size="medium"
              type="dashed"
              // style={{ width: "100px" }}
              onClick={(e) => {
                if (this.props.fromFooterAdd) {
                  setTimeout(() => {
                    const { history } = this.props;
                    history.push({
                      pathname: "/ccard",
                    });
                  }, 200);
                } else {
                  this.props.closeSetupModal(false, null);
                }
              }}
            >
              Cancel
            </Button>
          </span>
        </div>
      </div>

      // <div className="ecom-dashboard-wrapper">
      //   <Layout>
      //     <Header>
      //       <Row align={"middle"} gutter={24}>
      //         <Col lg={6}>
      //           <Typography.Title
      //             level={4}
      //             style={{
      //               marginBottom: 0,
      //               color: "inherit",
      //               fontSize: 18,
      //               fontWeight: 700,
      //             }}
      //           >
      //             Clover Connect
      //           </Typography.Title>
      //         </Col>

      //         <Col className="ml-auto">
      //           <Dropdown
      //             menu={{
      //               items: this?.exportItems,
      //               onClick: this?.handleMenuClick,
      //             }}
      //           >
      //             <Button
      //               type="text"
      //               style={{
      //                 border: "none",
      //                 padding: 0,
      //                 lineHeight: "unset",
      //                 color: "white",
      //               }}
      //               icon={<DownloadOutlined />}
      //             ></Button>
      //           </Dropdown>
      //         </Col>
      //       </Row>
      //     </Header>
      //     <Content>
      //       <>

      //         <Row style={{ marginTop: "16px" }}>
      //           <Col span="12"></Col>
      //           <Col span="12">
      //             <span
      //               className="text-right"
      //               onClick={(e) => e.stopPropagation()}
      //             >
      //               <Button
      //                 size="medium"
      //                 type="dashed"
      //                 htmlType="submit"
      //                 style={{ marginRight: "8px" }}
      //                 // onClick={(e) => this.addInvoice()}
      //               >
      //                 Save
      //               </Button>

      //               <Button
      //                 size="medium"
      //                 type="dashed"
      //                 // style={{ width: "100px" }}
      //                 // onClick={(e) => this.showInvoices()}
      //               >
      //                 Cancel
      //               </Button>
      //             </span>
      //           </Col>
      //         </Row>
      //       </>
      //     </Content>
      //   </Layout>
      // </div>
    );
  }
}

// map state to props
const mapStateToProps = (state, ownProps) => {
  console.log("state", state);

  const fromFooterAdd =
    ownProps?.location && ownProps?.location.pathname.indexOf("mode=add") >= 0
      ? true
      : false;

  // const entitlementData = getEntitlementsDataSelector(state);
  const sessionData = getSessionDataSelector(state);
  const layoutData = getReferenceLayoutDataSelector(state);
  const errorData = getErrorDataSelector(state);
  const referenceData = getReferenceDataSelector(state);
  const isMobile = getMobileDeviceSelector(state);
  const customersData = getCustomersDataSelector(state);
  const operatorUsers = customersData.filter(
    (rec) => rec && rec.role.toString() == "3"
  );

  const userId = ownProps.invoice_user_id;

  // const userId =
  //   state.authUser && state.authUser.user
  //     ? state.authUser.user.user_id
  //     : ownProps.invoice_user_id;

  const sites = (referenceData.userSites || []).filter(
    (rec) => rec.user_id_fk == userId
  );

  //    const userId = authUser.user ? authUser.user.id : null;
  return {
    // data: entitlementData,
    //Coming for Pay invoices
    userId,

    // userId: state.authUser.user.user_id,
    sessionData,
    layoutData,
    errorData,
    referenceData,
    sites,
    // loading:
    //   operatorUsers.length > 0 && state.entitlementsData
    //     ? state.entitlementsData.loading
    //     : false,
    isMobile,
    customersData,
    operatorUsers,
    fromFooterAdd,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ...entitlementActions,
      ...actionActions,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(CloverConnect);
