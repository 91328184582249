// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SITES/FETCH_REQUEST",
  SITEDATA: "SITES/SITEDATA",
  DELETE_REQUEST: "SITES/DELETE_REQUEST",
  DELETE_SITEPIC_REQUEST: "SITES/DELETE_SITEPIC_REQUEST",
  INSERT_REQUEST: "SITES/INSERT_REQUEST",
  UPDATE_REQUEST: "SITES/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SITES/INSERT_UPDATE_DATA_REQUEST",
  INSERT_UPDATE_SITEPIC_REQUEST: "SITES/INSERT_UPDATE_SITEPIC_REQUEST",
  MESSAGE: "SITES/MESSAGE",
  TOKEN: "SITES/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  sitePicsData: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.SITEDATA:
      return { ...state, ...action.siteData };

    default:
      return state;
  }
};

export const actions = {
  getSiteData: (payload) => payload,
  insertUpdateSite: (payload) => payload,
  insertUpdateSitePic: (payload) => payload,
  deleteSite: (payload) => payload,
  deleteSitePic: (payload) => payload,
};
