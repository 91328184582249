import React from "react";
import CommonHero from "../../components/common/CommonHero";
import Contact from "../../components/ForTruckDrivers/contact-us/Contact";

const ContactUs = () => {
  const commonHero = {
    heading: "Get in touch",
    herobgImg: "bg-contact_us",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <Contact />
    </>
  );
};

export default ContactUs;
