import React from "react";
import Integrate from "../../../assets/images/webp/integrate-everything.webp";
import { Link } from "react-router-dom";
const IntegrateEverything = () => {
  return (
    <>
      <section className="my-12 my:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center justify-between">
            <div data-aos="fade-right" data-aos-duration="1500">
              <div className="max-w-[418px]">
                <img className="w-full" src={Integrate} alt="Integrate" />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Integrate Everything{" "}
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  We've built a better, faster, easier to use solution. All
                  functions and processes are integrated: reservations,
                  security, cameras, billing, notifications, reporting, and
                  more. ParkNation eliminates the need for you to connect
                  essential applications to other systems: everything is managed
                  within a single platform, for increased operational efficiency
                  and reduced costs.
                </p>
                <Link
                   to="/account-sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px]  inline-block sm:p-[14px_26px] font-semibold first_btn whitespace-nowrap font-Manrope text-base">
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default IntegrateEverything;
