/* eslint-disable no-unused-vars */
import { createSelector } from "reselect";

const notificationsDataSelector = (state) => state.notificationsData.data;
const notificationsDataLastUpdatedSelector = (state) =>
  state.notificationsData.lastFetchSuccess;

export const getNotificationsDataSelector = createSelector(
  notificationsDataSelector,
  notificationsDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const spacesDataSelector = (state) => state.dashBoard.data;
const spacesDataLastUpdatedSelector = (state) =>
  state.dashBoard.lastFetchSuccess;

export const getSpacesDataSelector = createSelector(
  spacesDataSelector,
  spacesDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const ccDataSelector = (state) => state.ccData.data;
const ccDataLastUpdatedSelector = (state) => state.ccData.lastFetchSuccess;

export const getCCDataSelector = createSelector(
  ccDataSelector,
  ccDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const affiliateDataSelector = (state) => state.affiliateData.data;
const affiliateDataLastUpdatedSelector = (state) =>
  state.affiliateData.lastFetchSuccess;

export const getAffiliateDataSelector = createSelector(
  affiliateDataSelector,
  affiliateDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const requestsDataSelector = (state) => state.requestsData.data;
const requestsDataLastUpdatedSelector = (state) =>
  state.requestsData.lastFetchSuccess;

export const getRequestsDataSelector = createSelector(
  requestsDataSelector,
  requestsDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const metricsDataSelector = (state) => state.metricsData.data;
const metricsDataLastUpdatedSelector = (state) =>
  state.metricsData.lastFetchSuccess;

export const getMetricsDataSelector = createSelector(
  metricsDataSelector,
  metricsDataLastUpdatedSelector,
  (data, updated) => data //Object.values(data)
);

const vehicleDataSelector = (state) => state.vehicleData.data;
const vehicleDataLastUpdatedSelector = (state) =>
  state.vehicleData.lastFetchSuccess;

export const getVehicleDataSelector = createSelector(
  vehicleDataSelector,
  vehicleDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const expensesDataSelector = (state) => state.expensesData.data;
const expensesDataLastUpdatedSelector = (state) =>
  state.expensesData.lastFetchSuccess;

export const getExpensesDataSelector = createSelector(
  expensesDataSelector,
  expensesDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const expensesCatgDataSelector = (state) =>
  state.expensesData.expenseCategories;
const expensesCatgDataLastUpdatedSelector = (state) =>
  state.expensesData.lastFetchSuccess;

export const getExpensesCatgDataSelector = createSelector(
  expensesCatgDataSelector,
  expensesCatgDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const reportsDataSelector = (state) => state.reportsData.data;
const reportsDataLastUpdatedSelector = (state) =>
  state.reportsData.lastFetchSuccess;

export const getReportsDataSelector = createSelector(
  reportsDataSelector,
  reportsDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const reportDataSelector = (state) => state.reportData.data;
const reportDataLastUpdatedSelector = (state) =>
  state.reportData.lastFetchSuccess;

export const getReportDataSelector = createSelector(
  reportDataSelector,
  reportDataLastUpdatedSelector,
  (data, updated) => data
);

const vacancyDataSelector = (state) => state.vacancyData.data;
const vacancyDataLastUpdatedSelector = (state) =>
  state.vacancyData.lastFetchSuccess;

export const getVacancyDataSelector = createSelector(
  vacancyDataSelector,
  vacancyDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const reservationsDataSelector = (state) => state.reservationsData.data;
const reservationsDataLastUpdatedSelector = (state) =>
  state.reservationsData.lastFetchSuccess;

export const getReservationsDataSelector = createSelector(
  reservationsDataSelector,
  reservationsDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const spaceAvailaleDataSelector = (state) => state.spaceAvailableData.data;
const spaceAvailaleDataLastUpdatedSelector = (state) =>
  state.spaceAvailableData.lastFetchSuccess;

export const getSpaceAvailableDataSelector = createSelector(
  spaceAvailaleDataSelector,
  spaceAvailaleDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const lprDataSelector = (state) => state.lprData.data;
const lprDataLastUpdatedSelector = (state) => state.lprData.lastFetchSuccess;

export const getLPRDataSelector = createSelector(
  lprDataSelector,
  lprDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const customersDataSelector = (state) => state.customers.data || [];
const customersDataLastUpdatedSelector = (state) =>
  state.customers.lastFetchSuccess;

export const getCustomersDataSelector = createSelector(
  customersDataSelector,
  customersDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const subscriptionsDataSelector = (state) => state.subscriptionsData.data || [];
const subscriptionsDataLastUpdatedSelector = (state) =>
  state.subscriptionsData.lastFetchSuccess;

export const getSubscriptionsDataSelector = createSelector(
  subscriptionsDataSelector,
  subscriptionsDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);

const referenceDataSelector = (state) => state.referenceData.data || [];
const referenceDataLastUpdatedSelector = (state) =>
  state.referenceData.lastFetchSuccess;

export const getReferenceDataSelector = createSelector(
  referenceDataSelector,
  referenceDataLastUpdatedSelector,
  (data, updated) => data
);

const referenceLayoutDataSelector = (state) =>
  state.referenceData.data.layouts || [];
const referenceLayoutDataLastUpdatedSelector = (state) =>
  state.referenceData.lastFetchSuccess;

export const getReferenceLayoutDataSelector = createSelector(
  referenceLayoutDataSelector,
  referenceLayoutDataLastUpdatedSelector,
  (data, updated) => data
);

const vehiclesDataSelector = (state) => state.vehiclesData.data || [];
const vehiclesDataLastUpdatedSelector = (state) =>
  state.vehiclesData.lastFetchSuccess;

export const getVehiclesDataSelector = createSelector(
  vehiclesDataSelector,
  vehiclesDataLastUpdatedSelector,
  (data, updated) => data
);

const insertedDataSelector = (state) => state.insertedData.data || [];
const insertedDataLastUpdatedSelector = (state) =>
  state.insertedData.lastFetchSuccess;

export const getInsertedDataSelector = createSelector(
  insertedDataSelector,
  insertedDataLastUpdatedSelector,
  (data, updated) => data
);

const invoicesDataSelector = (state) => state.invoicesData.data || [];
const invoicesDataLastUpdatedSelector = (state) =>
  state.invoicesData.lastFetchSuccess;

export const getInvoicesDataSelector = createSelector(
  invoicesDataSelector,
  invoicesDataLastUpdatedSelector,
  (data, updated) => data
);

const paymentsDataSelector = (state) => state.payments.data || [];
const paymentsDataLastUpdatedSelector = (state) =>
  state.payments.lastFetchSuccess;

export const getPaymentsDataSelector = createSelector(
  paymentsDataSelector,
  paymentsDataLastUpdatedSelector,
  (data, updated) => data
);

const errorDataSelector = (state) => state.errorData.data || [];
const errorDataLastUpdatedSelector = (state) =>
  state.errorData.lastFetchSuccess;

export const getErrorDataSelector = createSelector(
  errorDataSelector,
  errorDataLastUpdatedSelector,
  (data, updated) => data
);

const siteDataSelector = (state) => state.siteData.data || [];
const siteDataLastUpdatedSelector = (state) => state.siteData.lastFetchSuccess;

export const getSiteDataSelector = createSelector(
  siteDataSelector,
  siteDataLastUpdatedSelector,
  (data, updated) => data
);

const documentDataSelector = (state) => state.documentData.data || [];
const documentDataLastUpdatedSelector = (state) =>
  state.documentData.lastFetchSuccess;

export const getDocumentDataSelector = createSelector(
  documentDataSelector,
  documentDataLastUpdatedSelector,
  (data, updated) => data
);

const siteDocumentDataSelector = (state) => state.siteDocumentData.data || [];
const siteDocumentDataLastUpdatedSelector = (state) =>
  state.siteDocumentData.lastFetchSuccess;

export const getSiteDocumentDataSelector = createSelector(
  siteDocumentDataSelector,
  siteDocumentDataLastUpdatedSelector,
  (data, updated) => data
);

const sitePicDataSelector = (state) => state.siteData.sitePicsData || [];
const sitePicDataLastUpdatedSelector = (state) =>
  state.siteData.lastFetchSuccess;

export const getSitePicsDataSelector = createSelector(
  sitePicDataSelector,
  sitePicDataLastUpdatedSelector,
  (data, updated) => data
);

const statementDataSelector = (state) => state.statementData.data || [];
const statementDataLastUpdatedSelector = (state) =>
  state.statementData.lastFetchSuccess;

export const getStatementDataSelector = createSelector(
  statementDataSelector,
  statementDataLastUpdatedSelector,
  (data, updated) => data
);

const custStatementDataSelector = (state) => state.custStatementData.data || [];
const custStatementDataLastUpdatedSelector = (state) =>
  state.custStatementData.lastFetchSuccess;

export const getCustStatementDataSelector = createSelector(
  custStatementDataSelector,
  custStatementDataLastUpdatedSelector,
  (data, updated) => data
);

const spaceTypeDataSelector = (state) => state.spaceTypesData.data || [];
const spaceTypeDataLastUpdatedSelector = (state) =>
  state.spaceTypesData.lastFetchSuccess;

export const getSpaceTypeDataSelector = createSelector(
  spaceTypeDataSelector,
  spaceTypeDataLastUpdatedSelector,
  (data, updated) => data
);

const entitlementsDataSelector = (state) => state.entitlementsData.data || [];
const entitlementsDataLastUpdatedSelector = (state) =>
  state.entitlementsData.lastFetchSuccess;

export const getEntitlementsDataSelector = createSelector(
  entitlementsDataSelector,
  entitlementsDataLastUpdatedSelector,
  (data, updated) => data
);

const spaceTypeRateDataSelector = (state) =>
  state.spaceTypeRatesData.data.siteSpaceTypesData || [];
const spaceTypeRateDataLastUpdatedSelector = (state) =>
  state.spaceTypeRatesData.lastFetchSuccess;

export const getSpaceTypeRateDataSelector = createSelector(
  spaceTypeRateDataSelector,
  spaceTypeRateDataLastUpdatedSelector,
  (data, updated) => data
);

const sessionDataSelector = (state) => state.sessionData.data || {};
const sessionDataLastUpdatedSelector = (state) =>
  state.sessionData.lastFetchSuccess;

export const getSessionDataSelector = createSelector(
  sessionDataSelector,
  sessionDataLastUpdatedSelector,
  (data, updated) => data
);

const emailDataSelector = (state) => state.emailInvoiceData.data || [];
const emailDataLastUpdatedSelector = (state) =>
  state.emailInvoiceData.lastFetchSuccess;
export const getEmailInvoiceDataSelector = createSelector(
  emailDataSelector,
  emailDataLastUpdatedSelector,
  (data, updated) => data
);

export const getMobileDeviceSelector = createSelector(
  (state) => {
    return state?.mobileDevice?.isMobile || false;
  },
  (data, updated) => data
);

const aiDataSelector = (state) => state.agingInvoicesData.data || [];
const aiDataLastUpdatedSelector = (state) =>
  state.agingInvoicesData.lastFetchSuccess;

export const getAIDataSelector = createSelector(
  aiDataSelector,
  aiDataLastUpdatedSelector,
  (data, updated) => Object.values(data)
);
