import React from "react";
import Partnership from "../../../assets/images/webp/business-partnership 1.webp";
import { Link } from "react-router-dom";
const WhiteLabelPartnership = () => {
  return (
    <>
      <section className="mb-12 md:mb-20 lg:mb-28 xl:mb-[150px] overflow-x-hidden">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center">
            <div data-aos="fade-right" data-aos-duration="1500">
              <div className="max-w-[520px]">
                <img className="w-full" src={Partnership} alt="" />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  White Label Partnership{" "}
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  Launch your business with the kind of push that generates
                  immediate results. Boost your marketplace visibility, connect
                  with customers, scale on demand, save on costs versus trying
                  to build a solution in-house, and leverage our technology and
                  service expertise. It's the fastest, easiest route to build a
                  successful truck parking business.
                </p>
                <Link
                  to="/account-sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px]  inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base"
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhiteLabelPartnership;
