// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SESSION/FETCH_REQUEST",
  SET_SESSION_DATA_REQUEST: "SESSION/SET_SESSION_DATA_REQUEST",
  SET_LOGIN_SESSION_DATA_REQUEST: "SESSION/SET_LOGIN_SESSION_DATA_REQUEST",
  RESET_LOGIN_SESSION_DATA_REQUEST: "SESSION/RESET_LOGIN_SESSION_DATA_REQUEST",
  SESSIONDATA: "SESSION/SESSIONDATA",
  DELETE_REQUEST: "SESSION/DELETE_REQUEST",
  INSERT_REQUEST: "SESSION/INSERT_REQUEST",
  UPDATE_REQUEST: "SESSION/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SESSION/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "SESSION/MESSAGE",
  TOKEN: "SESSION/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: {
    site_id: -1,
    user_id: -1,
    session_user_id: -1,
    sessionId: "",
  },
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.SESSIONDATA:
      return { ...state, ...action.sessionData };

    default:
      return state;
  }
};

export const actions = {
  setSessionData: (payload) => payload,
  setLoginSessionData: (payload) => payload,
  resetLoginSessionData: (payload) => payload,
  insertUpdateSite: (payload) => payload,
  deleteSite: (payload) => payload,
};
