import { isNil } from "lodash";
export const hasErrors = (retObj) => {
  //response.status >= 200 && response.status < 300
  // debugger;
  console.log("retObj", retObj);
  // if (!isNil(retObj.message) && isNil(retObj.data)) {
  if (
    (!isNil(retObj.errCode) && retObj.errCode != "0") ||
    (!isNil(retObj.messageType) && retObj.messageType == "error")
  ) {
    return true;
  } else {
    return false;
  }
  // if (retObj.hasOwnProperty("error")) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const defaultData = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};
