import React from "react";
import ConnectAccount from "../../../assets/images/webp/connect-account.webp";
import { Link } from "react-router-dom";

const ConnectAnyAccount = () => {
  return (
    <>
      <section className="my-12 md:my-20 lg:my-28 xl:mt-[100px] xl:mb-[160px]  ">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[54px] items-center">
            <div className="order-2 lg:order-none">
              <div className="max-w-[538px]">
                <h2 className="text-lg md:text-[32px] font-bold font-Manrope text-black mb-4">
                  Connect any account
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  Pull, categorize, and reconcile your transactions in seconds
                  by connecting your bank account or credit card to DoorLoop
                  through Plaid. Fintech, short for financial technology, is a
                  rapidly growing industry that is changing the way we manage
                  and access our money.
                </p>
                <Link
                  to="/get-started"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px]  inline-block sm:p-[14px_26px] first_btn font-semibold  whitespace-nowrap font-Manrope text-base"
                >
                  Get Started{" "}
                </Link>
              </div>
            </div>
            <div>
              <div className="max-w-[600px]">
                <img
                  className="w-full"
                  src={ConnectAccount}
                  alt="ConnectAccount"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ConnectAnyAccount;
