export const types = {
  FETCH_DATA_REQUEST: "INSERTEDDATA/FETCH_REQUEST",
  INSERTEDDATA: "INSERTEDDATA/INSERTEDDATA",
};

// const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };

const initialState = {
  data: {
    customer: {},
    site: {},
    spaceType: {},
    vehicle: {},
  },
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.INSERTEDDATA:
      return { ...state, ...action.insertedData };

    default:
      return state;
  }
};

export const actions = {
  getInsertedData: (payload) => payload,
};
