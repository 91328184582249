import React from "react";
import LicenceDash from "../../../assets/images/webp/licence-prof.webp";
import { CheckIcon } from "../../common/Icon";

const LicensePlateRecognition = () => {
  return (
    <>
      <section className="bg-[#a8a8a814] py-12 xl:py-24 overflow-x-hidden">
        <div className="container">
          <div className="lg:flex items-center  ">
            <div className=" w-full lg:w-1/2">
              <div data-aos="fade-right" data-aos-delay="200">
                <img
                  className="w-full sm:max-w-[500px] lg:max-w-[548px] lg:max-w-auto  m-auto lg:mr-auto"
                  src={LicenceDash}
                  alt="parking-locaiton"
                />
              </div>
            </div>
            <div className=" w-full lg:w-1/2 lg:max-w-[550px] ml-auto">
              <div
                className="mt-8 lg:mt-0 lg:ps-10 "
                data-aos="fade-left"
                data-aos-delay="200"
              >
                <h3 className="  text-black capitalize font-bold lg:leading-[55px] text-lg md:text-[32px] lg:text-xl font-Manrope mb-4">
                  license Plate recognition
                </h3>
                <p className="font-Poppins  font-normal leading-[160%] mb-0 opacity-70 text-base">
                  ParkNation helps manage a robust security environment for your
                  truck lot.
                </p>
                <div className="flex justify-between mt-5 lg:mt-10 gap-4 sm:gap-5 xl:gap-0">
                  <div className="xl:max-w-[238px]">
                    <CheckIcon />
                    <p className="font-semibold  text-md leading-[160%] font-Manrope mb-0 mt-4 xl:mt-5">
                      Video Clip
                    </p>
                    <p className="font-normal font-Poppins   opacity-70 text-base leading-[160%] mb-0">
                      Every Entry and Exit of the vehicle has a 10 second video
                      clip
                    </p>
                  </div>
                  <div className="xl:max-w-[238px]">
                    <CheckIcon />
                    <p className="font-semibold  text-md leading-[160%] font-Manrope mb-0 mt-4 xl:mt-5">
                      License Plate Search
                    </p>
                    <p className="font-normal font-Poppins  opacity-70 text-base leading-[160%] mb-0 xl:pe-5">
                      Customers will be able to see all the entry / Exit events
                      related to the vehicle license plate.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LicensePlateRecognition;
