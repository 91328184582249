import React from "react";
import Location from "../../components/ForTruckDrivers/allsitepage/Location";
import SiteCards from "../../components/ForTruckDrivers/allsitepage/SiteCards";
import FindLocation from "../../components/common/FindLocation";
import SiteFeatures from "../../components/ForTruckDrivers/allsitepage/SiteFeatures";

const AllSites = () => {
  return (
    <>
      <FindLocation />
      {/* <Location />
      <SiteCards />
      <SiteFeatures /> */}
    </>
  );
};

export default AllSites;
