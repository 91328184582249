export default () => {
	return {
		text: `Are you sure you want to proceed further`,
		customClass: "styled-modal",
		allowOutsideClick: false,
		buttonsStyling: false,
		showCancelButton: true,
		reverseButtons: true,
		cancelButtonText: "No",
		confirmButtonText: "Yes",
		confirmButtonClass: "btn MuiButton-root MuiButton-containedPrimary",
		cancelButtonClass: "btn MuiButton-root MuiButton-containedPrimary mr-2",
		closeOnClickOutside: false,
		heightAuto: false,
	};
}
