import React from "react";
import { Link } from "react-router-dom";

const BlogCommonCard = ({ obj }) => {
  return (
 
    <div
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
    >
      <Link
        to="/blog-detail"
        className="flex flex-col  bg-[#F2F3F4] w-full rounded-[10px] cursor-pointer overflow-hidden group transition-all duration-500 hover:scale-[1.04]"
      >
        <span className=" overflow-hidden">
          <img
            className="w-full  transition-all duration-500  group-hover:scale-110"
            src={obj.image}
            alt="blog1"
          />
        </span>
        <div className="my-4  max-w-[364px] px-4">
          <p className="text-base font-normal font-Poppins text-[#000000]   mb-0 opacity-70">
            {obj.para1}
          </p>
          <h3 className="text-[18px] lg:text-md  font-medium mt-[3px]  font-Poppins ">
            {obj.heading}
          </h3>
          <p className="text-base font-normal font-Poppins text-[#000000]  mb-0 opacity-70 mt-[14px] pb-4 max-w-[326px]">
            {obj.para2}
          </p>
          <Link
            to="/blog-detail"
            className="text-base font-normal inline-block font-Poppins text-primary"
          >
            Read More...
          </Link>
        </div>
      </Link>
     </div>
  );
};

export default BlogCommonCard;
