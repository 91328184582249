import React, { useEffect, useState } from "react";

const Backtotop = () => {
  const [scrollPosition, setScrollPosition] = useState(0);

  // SCROLL TO TOP FUNCTION WHEN CLICK ON THIS PAGE SCROLL TOP

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // TO FIND SCROLL Y POSITION
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  // THIS USEFFECT GIVE US POSITION OF SCROLL IN EVERY PIXELS WE SCROLL
  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {scrollPosition > 200 ? (
        <div
          className="fixed bottom-6 right-6 backtotop  cursor-pointer  z-50"
          onClick={() => scrollToTop()}
        >
          <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="24" cy="24" r="24" fill="#EB2022" />
            <path
              d="M23.7943 15.5916C23.8705 15.5152 23.961 15.4546 24.0607 15.4132C24.1603 15.3719 24.2672 15.3506 24.3751 15.3506C24.483 15.3506 24.5898 15.3719 24.6895 15.4132C24.7891 15.4546 24.8796 15.5152 24.9558 15.5916L34.7996 25.4353C34.9536 25.5894 35.0402 25.7983 35.0402 26.0161C35.0402 26.234 34.9536 26.4429 34.7996 26.5969C34.6456 26.7509 34.4366 26.8375 34.2188 26.8375C34.001 26.8375 33.7921 26.7509 33.638 26.5969L24.3751 17.3323L15.1121 26.5969C14.9581 26.7509 14.7491 26.8375 14.5313 26.8375C14.3135 26.8375 14.1046 26.7509 13.9505 26.5969C13.7965 26.4429 13.71 26.234 13.71 26.0161C13.71 25.7983 13.7965 25.5894 13.9505 25.4353L23.7943 15.5916Z"
              fill="white"
            />
            <path
              d="M23.7943 22.1541C23.8705 22.0777 23.961 22.0171 24.0607 21.9757C24.1603 21.9344 24.2672 21.9131 24.3751 21.9131C24.483 21.9131 24.5898 21.9344 24.6895 21.9757C24.7891 22.0171 24.8796 22.0777 24.9558 22.1541L34.7996 31.9978C34.9536 32.1519 35.0402 32.3608 35.0402 32.5786C35.0402 32.7965 34.9536 33.0054 34.7996 33.1594C34.6456 33.3134 34.4366 33.4 34.2188 33.4C34.001 33.4 33.7921 33.3134 33.638 33.1594L24.3751 23.8948L15.1121 33.1594C14.9581 33.3134 14.7491 33.4 14.5313 33.4C14.3135 33.4 14.1046 33.3134 13.9505 33.1594C13.7965 33.0054 13.71 32.7965 13.71 32.5786C13.71 32.3608 13.7965 32.1519 13.9505 31.9978L23.7943 22.1541Z"
              fill="white"
            />
          </svg>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default Backtotop;
