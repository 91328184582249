/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/**
 * Signin Firebase
 */

import React, { Component } from "react";
import { connect, useDispatch } from "react-redux";
import { GlobalStoreContainer } from "../../../store";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Form, FormGroup } from "reactstrap";
// import LinearProgress from "@material-ui/core/LinearProgress";
import QueueAnim from "rc-queue-anim";
// import Footer from "Components/Footer/Footer";
import axios from "axios";
import classnames from "classnames";
import Title from "antd/lib/typography/Title";
// import ReserveInvoice from "../../invoice/reserveInvoice";
import { filterTotalResults } from "../../../util/search.js";
import { useNetwork } from "ahooks";
// import { useAuth0 } from "@auth0/auth0-react";
import MicrosoftLogin from "react-microsoft-login";
// import { withRouter } from "react-router";

import {
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
  PhoneOutlined,
  MailOutlined,
  GoogleOutlined,
  FacebookOutlined,
  SkypeOutlined,
} from "@ant-design/icons";
import { Avatar, List, Carousel } from "antd";
import NewCustomerForm from "./NewCustomerForm";
import { types as actionTypes } from "../../../reducers/actionReducer";
import { actions as actionActions } from "../../../reducers/actionReducer";
import Swal from "sweetalert2";
// import StepWizard from "react-step-wizard";
import * as uuid from "uuid";
import {
  getErrorDataSelector,
  getMobileDeviceSelector,
  getReferenceDataSelector,
  getVacancyDataSelector,
} from "../../../reselect/sagaReselect";
import {
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  DownloadOutlined,
  DollarOutlined,
  SettingOutlined,
  UserOutlined,
  SolutionOutlined,
  SmileOutlined,
  CloseOutlined,
  PlusOutlined,
  VideoCameraOutlined,
  PlayCircleOutlined,
  CalendarOutlined,
  ArrowRightOutlined,
  ArrowLeftOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
import { PNfetch } from "../../../api/pnfetch";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { Loader } from "@googlemaps/js-api-loader";
import { types as vacancyTypes } from "../../../reducers/vacancyDataReducer";
import { actions as vacancyActions } from "../../../reducers/vacancyDataReducer";
// import dayjs from "dayjs-with-plugins";
import dayjs from "dayjs";
// import { withAuth0, auth0 } from "@auth0/auth0-react";

export const withRouter = (Component) => {
  debugger;
  const Wrapper = (props) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const loader = new Loader({
  apiKey: "AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM",
  version: "weekly",
  libraries: ["places"],
});

const defaultSagaData = {
  data: [],
  message: "",
  hasLoaded: true,
  hasError: false,
  lastFetchSuccess: Date.now(),
  loading: false,
};

const mapOptions = {
  center: {
    lat: 39.760198,
    lng: -74.971551,
  },
  zoom: 10,
};

import {
  orderBy,
  trim,
  shuffle,
  isNil,
  debounce,
  cloneDeep,
  padStart,
  isNaN,
} from "lodash";
import {
  Table,
  Switch,
  Radio,
  Space,
  Input,
  InputNumber,
  Layout,
  Popconfirm,
  Typography,
  Select,
  Row,
  Col,
  Menu,
  Popover,
  Dropdown,
  message,
  Modal,
  Checkbox,
  Empty,
  notification,
  Card,
  Divider,
  Tooltip,
  Image,
  DatePicker,
  Rate,
  Descriptions,
  Button as AButton,
  Steps,
} from "antd";

import {
  LoginSocialGoogle,
  // LoginSocialAmazon,
  LoginSocialFacebook,
  // LoginSocialGithub,
  // LoginSocialInstagram,
  // LoginSocialLinkedin,
  LoginSocialMicrosoft,
  // LoginSocialPinterest,
  // LoginSocialTwitter,
  // LoginSocialApple,
  // IResolveParams,
} from "reactjs-social-login";

import {
  FacebookLoginButton,
  GoogleLoginButton,
  // GithubLoginButton,
  // AmazonLoginButton,
  // InstagramLoginButton,
  // LinkedInLoginButton,
  // MicrosoftLoginButton,
  // TwitterLoginButton,
  // AppleLoginButton,
} from "react-social-login-buttons";

// import { ReactComponent as PinterestLogo } from './assets/pinterest.svg';

const REDIRECT_URI = window.location.href;

// const REDIRECT_URI =
//   'https://plenty-planets-beam-42-118-51-2.loca.lt/account/login';
// const REDIRECT_URI = 'http://localhost:3000/account/login'

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const { Header, Content, Sider } = Layout;
import { LockOutlined } from "@ant-design/icons";
// app config
import AppConfig from "../../../constants/AppConfig.js";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import EmailCheck from "./emailCheck";
// icons
import EmailIcon from "@material-ui/icons/Email";
import LockIcon from "@material-ui/icons/Lock";
//To get notifications
import { NotificationManager } from "react-notifications";
// api
// import API from "Api";
import { bindActionCreators } from "redux";
// import { actions as referenceDataActions } from "../../../reducers/referenceDataReducer";
// import { types as referenceDataTypes } from "../../../reducers/referenceDataReducer";
import { actions as sessionDataActions } from "../../../reducers/sessionreducer";
import { types as sessionDataTypes } from "../../../reducers/sessionreducer";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 14,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 12,
  },
};

const sites = [
  {
    site_id: 5,
    name: "SicklerVille",
  },
];
const roles = [
  {
    id: 4,
    name: "User",
  },
];

const steps = [
  {
    title: "Email",
    // content: "First-content",
    icon: <MailOutlined />,
  },
  {
    title: "Register",
    // content: "First-content",
    icon: <SolutionOutlined />,
  },
  // {
  //   title: "Login",
  //   // content: "First-content",
  //   icon: <UserOutlined />,
  // },
  {
    title: "Reserve & Pay",
    // content: "Second-content",
    icon: <DollarOutlined />,
  },
  // {
  //   title: "Pay",
  //   // content: "Second-content",
  //   icon: <SolutionOutlined />,
  // },
  {
    title: "Done",
    // content: "Last-content",
    icon: <SmileOutlined />,
  },
];

const contentStyle = {
  // height: "240px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  maxWidth: "300px",
  maxHeight: "300px",
};
// redux action
import { signinUser, setFeatures } from "../../../actions";
import { Form as AForm } from "antd";

import bgImage from "../../../assets/img/neature.jpg";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";

const data = Array.from({
  length: 23,
}).map((_, i) => ({
  href: "https://ant.design",
  title: `ant design part ${i}`,
  avatar: "https://joeschmoe.io/api/v1/random",
  description:
    "Ant Design, a design language for background applications, is refined by Ant UED Team.",
  content:
    "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
}));
const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

export function NetworkState(props) {
  const dispatch = useDispatch();
  const networkState = useNetwork();
  dispatch({
    type: actionTypes.NETWORKDATA,
    networkData: {
      ...defaultSagaData,
      lastFetchSuccess: Date.now(),
      data: networkState,
    },
  });
  return null;
}
class Login extends Component {
  mapRef = null;
  googleRef = null;

  constructor(props) {
    super(props);
    const customerEmail = localStorage.getItem("customerEmail") || null;
    //Very Important as We are using the token set in PN reservation
    localStorage.clear();
    this.state = {
      firstTimeLoaded: false,
      reserveEmail: "",
      reserveSite: undefined,
      reserveInvoiceId: "-1",
      phoneVerified: false,
      otpModalVisible: false,
      OTPNumber: undefined,
      prevClicked: undefined,
      nextClicked: undefined,
      reserveEmailExists: undefined,
      currentStep: 0,
      stepItems: undefined,
      currentSite: undefined,
      showSiteDetailsModal: false,
      currentInvoiceRecord: undefined,
      showInvoiceModal: false,
      searchText: "",
      sites: [],
      sitesOrig: [],
      vacancies: [],
      showMobilePicker: false,
      startDt: dayjs().format("YYYY-MM-DD"),
      endDt: dayjs().add(7, "days").format("YYYY-MM-DD"),
      loading: false,
      email: customerEmail || "",
      password: "",
      errors: {},
      userId: null,
      verifiedStatus: true,
      accountPending: null,
      passwordModalVisible: false,
      registerModalVisible: false,
      siteModalVisible: false,
    };
  }
  formCustomerRef = React.createRef();
  formRegisterRef = React.createRef();
  formLoginRef = React.createRef();

  reserveRef = React.createRef();

  componentDidMount() {
    debugger;
    this.setState({ firstTimeLoaded: true });
  }

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  checkSocialId = async (parms) => {
    //  debugger;

    let URL = process.env.REACT_APP_API_URL + `/checkSocialId`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
    })
      .then((response) => response.json())
      .catch((error) => error);
  };
  verifyEmail = async (parms) => {
    //  debugger;

    let URL = process.env.REACT_APP_API_URL + `/checkEmail`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
    })
      .then((response) => response.json())
      .catch((error) => error);
  };

  verifyUser = async (parms) => {
    //debugger;
    console.log("process.env", process.env);
    let URL = process.env.REACT_APP_API_URL + `/reactlogin`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
      // redirect: "follow",
      // credentials: "include", // Don't forget to specify this if you need cookies
    })
      .then((response) => response.json())
      .catch((error) => error);
  };

  onUserLogin = async (e) => {
    e.preventDefault();

    if (this.isValid()) {
      // this.props.signinUser(null);
      debugger;
      // const { user, isAuthenticated, isLoading } = this.props.auth0;
      // console.log(user);
      const parms = {
        name: this.state.email,
        password: this.state.password,
      };
      console.log("-----------params", parms);
      const resultObj = JSON.parse(await this.verifyUser(parms));
      console.log("Sign In resultObj", resultObj);
      //  debugger;
      if (resultObj.messageType == "success") {
        //  console.log(response.data);
        //Clear Customer Data
        this.props.resetLoginSessionData({
          type: sessionDataTypes.RESET_LOGIN_SESSION_DATA_REQUEST,
          payload: {
            parms: resultObj,
          },
        });
        console.log("-----------params-----------------", parms);
        //Imp IMp as its taking time to write to the store
        localStorage.setItem("accessToken", resultObj.access_token);
        localStorage.setItem("refreshToken", resultObj.refresh_token);
        localStorage.setItem("session_user_id", resultObj.user.user_id);
        localStorage.setItem("session_orig_user_id", resultObj.user.user_id);

        //Storage Of token is done in Here
        // to store user id and name
        this.props.signinUser(resultObj, 1);
        // to get user feature information
        // alert("resultObj.user.user_id", resultObj.user.user_id);
        this.props.setFeatures(
          resultObj.user.user_id,
          resultObj.user.role,
          resultObj.userEntitlements
        );

        console.log("In setLoginSessionData");
        //Load the session data and Reference Data
        this.props.setLoginSessionData({
          type: sessionDataTypes.SET_LOGIN_SESSION_DATA_REQUEST,
          payload: {
            parms: resultObj,
          },
        });

        // this.props.refreshReferenceData({
        //   type: actionTypes.REFRESH_REFDATA_REQUEST,
        //   payload: {
        //     parms: [resultObj.user.user_id], //Y will get balances, N all the users, as default
        //   },
        //   user_id_fk: resultObj.user.user_id,
        // });

        setTimeout(() => {
          console.log("IN SESSION LOGIN%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
          this.props.showReserve(resultObj.user);

          NotificationManager.success(
            "User logged in successfully",
            "Login",
            1200
          );
        }, 350);
      } else {
        NotificationManager.error(resultObj.message, "Login", 1200);
      }
      // }
    }
  };

  //=== key up function to remove errors
  onKeyUp = (e) => {
    const { errors } = this.state;
    errors[e.target.name] = "";
    this.setState({ [e.target.name]: e.target.value, errors });
  };

  //=== function to validate user mailand password
  isValid = () => {
    let isValid = true;
    const { errors } = this.state;
    if (
      !this.state.email ||
      !/^[+\w-]+@([\w-]+\.)+[\w-]{2,4}$/g.test(this.state.email)
    ) {
      isValid = false;
      errors.email = "Please enter a valid email.";
    }
    if (!this.state.password || this.state.password.length === 0) {
      isValid = false;
      errors.password = "Please enter password.";
    } else if (
      this.state.password.length < 4 ||
      this.state.password.length > 15
    ) {
      isValid = false;
      errors.password = "Password must be between 4 and 15 characters.";
    }
    this.setState({ errors });
    return isValid;
  };

  // resendVerificationEmail = () => {
  //   API.post(`/users/${this.state.userId}/resend-verification-email`, null, {
  //     handlerEnabled: true,
  //   });
  // };

  setSiteVisible = (visible) => {
    this.setState({ siteModalVisible: visible });
  };
  setRegisterVisible = (visible) => {
    this.setState({ registerModalVisible: visible });
  };
  showPasswordModal = () => {
    this.setState({ passwordModalVisible: true });
  };

  hideModal = () => {
    this.setState({
      passwordModalVisible: false,
    });
  };

  openNotificationWithIcon = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  sendResetEmailToUser = (email) => {
    //  debugger;

    return new Promise((resolve, reject) => {
      let URL = process.env.REACT_APP_API_URL + `/email/sendpwdresetlink`;

      fetch(URL, {
        method: "POST",
        // responseType: "arraybuffer",
        // responseEncoding: "binary",
        // responseType: "stream",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", //"application/json"
          // authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          email: email,
          // htmlSent: this.state.html,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        // .then((response) => resolve(response.json()))
        .catch((error) => reject(error));
    });
  };

  // createSessionForUser = (email) => {
  //   //  debugger;

  //   return new Promise((resolve, reject) => {
  //     let URL = process.env.REACT_APP_API_URL + `/email/sendpwdresetlink`;

  //     fetch(URL, {
  //       method: "POST",
  //       // responseType: "arraybuffer",
  //       // responseEncoding: "binary",
  //       // responseType: "stream",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json", //"application/json"
  //         // authorization: "Bearer " + localStorage.getItem("accessToken"),
  //       },
  //       body: JSON.stringify({
  //         email: email,
  //         // htmlSent: this.state.html,
  //       }),
  //     })
  //       .then((response) => resolve(response ? response.json() : null))
  //       // .then((response) => resolve(response.json()))
  //       .catch((error) => reject(error));
  //   });
  // };

  getSitesAndVacancies = () => {
    //  debugger;

    return new Promise((resolve, reject) => {
      let URL = process.env.REACT_APP_API_URL + `/email/getSitesAndVacancies`;

      fetch(URL, {
        method: "POST",
        // responseType: "arraybuffer",
        // responseEncoding: "binary",
        // responseType: "stream",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json", //"application/json"
          // authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          start_dt: this.state.startDt,
          end_dt: this.state.endDt,
          // email: email,
          // htmlSent: this.state.html,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        // .then((response) => resolve(response.json()))
        .catch((error) => reject(error));
    });
  };

  hasErrors = (retObj) => {
    //response.status >= 200 && response.status < 300
    //  debugger;
    console.log("retObj", retObj);
    if (
      (!isNil(retObj.message) &&
        isNil(retObj.data) &&
        retObj.messageType == "error") ||
      (!isNil(retObj.message) &&
        // isNil(retObj.data) &&
        retObj.messageType == "error")
    ) {
      return true;
    } else {
      return false;
    }
  };

  resetPassword = async () => {
    const record = this.formCustomerRef.current.getFieldsValue(true);

    if (isNil(record.email) || trim(record.email) == "") {
      this.openNotificationWithIcon("error", "Error", "Please Enter email");
      return false;
    }

    this.setState({ loading: true }, async () => {
      const resultObj = await this.sendResetEmailToUser(record.email);

      if (!this.hasErrors(resultObj)) {
        // if (!this.hasDBErrors(resultObj)) {
        if (resultObj.messageType == "success") {
          // if (resultObj.errCode == "0") {
          // MySwal.close();
          this.formCustomerRef.current.resetFields();
          this.setState({ passwordModalVisible: false }, () => {
            this.openNotificationWithIcon(
              "success",
              "Success",
              resultObj.message
            );
          });
        } else {
          // MySwal.close();
          //Display Error
          this.openNotificationWithIcon(
            "error",
            "Error",
            resultObj.message ? resultObj.message : "An error has occured!"
          );
        }
      } else {
        // MySwal.close();
        //Display Error
        this.openNotificationWithIcon(
          "error",
          "Error",
          resultObj.message ? resultObj.message : "An error has occured!"
        );
      }
    });

    // MySwal.fire({
    //   title: "Email",
    //   html: "Sending Email. Please be patient.",
    //   allowEscapeKey: false,
    //   allowOutsideClick: false,
    //   onOpen: () => {
    //     MySwal.showLoading();
    //   },
    //   onClose: () => {},
    // }).then((result) => {
    //   if (result.dismiss === Swal.DismissReason.timer) {
    //     console.log("Closed by Timer");
    //   }
    // });
  };

  registerUser = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const resultObj = await this.getSitesAndVacancies();
    debugger;
    if (!this.hasErrors(resultObj)) {
      // if (!this.hasDBErrors(resultObj)) {
      if (resultObj.messageType == "success") {
        //Add Markers to the site
        this.setState(
          {
            registerModalVisible: true,
            sites: resultObj.sites.map((itm) => ({
              ...itm,
              key: uuid.v4(),
            })),
            sitesOrig: resultObj.sites.map((itm) => ({
              ...itm,
              key: uuid.v4(),
            })),
            // vacancies: resultObj.vacancies,
          },
          () => {}
        );
      } else {
        // MySwal.close();
        //Display Error
        this.openNotificationWithIcon(
          "error",
          "Error",
          resultObj.message ? resultObj.message : "An error has occured!"
        );
      }
    } else {
      // MySwal.close();
      //Display Error
      this.openNotificationWithIcon(
        "error",
        "Error",
        resultObj.message ? resultObj.message : "An error has occured!"
      );
    }
    // this.setState({ registerModalVisible: true });
  };

  showSites = async (e) => {
    debugger;
    e.preventDefault();
    e.stopPropagation();
    //Get the Sites and Vacancies
    const resultObj = await this.getSitesAndVacancies();
    debugger;
    if (!this.hasErrors(resultObj)) {
      // if (!this.hasDBErrors(resultObj)) {
      if (resultObj.messageType == "success") {
        //Add Markers to the site

        this.setState(
          {
            siteModalVisible: true,
            sites: resultObj.sites.map((itm) => ({
              ...itm,
              imagesURL: JSON.parse(itm.imagesURL)
                ? JSON.parse(itm.imagesURL).split("@@@@@@")
                : [],
            })),
            sitesOrig: resultObj.sites.map((itm) => ({
              ...itm,
              imagesURL: JSON.parse(itm.imagesURL)
                ? JSON.parse(itm.imagesURL).split("@@@@@@")
                : [],
            })),
            vacancies: resultObj.vacancies,
          },
          () => {
            // loader
            //   .load()
            //   .then((google) => {
            //     // this.googleRef = google;
            //     this.mapRef = new google.maps.Map(
            //       document.getElementById("map"),
            //       mapOptions
            //     );
            //   })
            //   .catch((e) => {
            //     // do something
            //   });
          }
        );
      } else {
        // MySwal.close();
        //Display Error
        this.openNotificationWithIcon(
          "error",
          "Error",
          resultObj.message ? resultObj.message : "An error has occured!"
        );
      }
    } else {
      // MySwal.close();
      //Display Error
      this.openNotificationWithIcon(
        "error",
        "Error",
        resultObj.message ? resultObj.message : "An error has occured!"
      );
    }
  };

  checkUserEmailAndPhone = async (parms) => {
    //  debugger;

    let URL = process.env.REACT_APP_API_URL + `/reactloginregistration`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
    })
      .then((response) => response.json())
      .catch((error) => error);
  };

  saveCustomerInDB = (parms, token) => {
    //  debugger;

    return new Promise((resolve, reject) => {
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          proc: "spi_customers",
          input: parms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });

    // return new Promise((resolve, reject) => {
    //   fetch(`https://dev.parknation.org/api/v1.0/payments`, {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       authorization: "Bearer " + localStorage.getItem("accessToken"),
    //     },
    //     body: JSON.stringify({
    //       ...parms,
    //     }),
    //   })
    //     .then((response) => resolve(response ? response.json() : null))
    //     .catch((error) => reject(error));
    // });
  };

  hasDBErrors = (retObj) => {
    if (!isNil(retObj.errCode) && retObj.errCode != "0") {
      return true;
    } else {
      return false;
    }
  };

  saveCustomer = async (key) => {
    // @UserId int,
    // @siteid int,
    // @name varchar(255),
    // @description varchar(max),
    // @status bit,
    // @city varchar(100),
    // @state varchar(100),
    // @zipcode varchar(20),
    // @comment varchar(max),
    // @contact_info varchar(max),
    // @address_line_1 varchar(max),
    // @address_line_2 varchar(max),
    // @country varchar(100),
    // @tax_rate numeric(6,4)

    // email:  '',
    // phone_number: '',
    // first_name: '',
    // last_name: '',
    // street_address :'',
    // city: '',
    // state: '',
    // zipcode: '',
    // country:'',
    // company_name: '',

    try {
      let token = "",
        htoken = "";
      const record = this.formRegisterRef.current.getFieldsValue(true);
      console.log("record", record);
      const row = await this.formRegisterRef.current.validateFields();
      //  debugger;

      const resultObj = JSON.parse(
        await this.checkUserEmailAndPhone({
          email: record.email,
          phone: record.phone_number.slice(record.country_code.length), //record.phone_number,
          password: trim(record.password),
        })
      );

      if (resultObj.messageType == "error") {
        this.openNotificationWithIcon("error", "Message", resultObj.message);
        return;
      } else {
        token = resultObj.refresh_token;
        htoken = resultObj.htoken;
        localStorage.setItem("refreshToken", resultObj.refresh_token);
        localStorage.setItem("accessToken", resultObj.accessToken);
      }

      // const newSpaceTypeOption
      this.setState(
        {
          registerModalVisible: false,
        },
        () => {
          // this.props.insertUpdateCustomer({
          //   type: actionTypes.INSERT_UPDATE_CUSTOMER_REQUEST,
          //   payload: {
          //     parms: [
          //       this.props.userId,
          //       -1,
          //       record.email,
          //       record.phone_number,
          //       record.first_name,
          //       record.last_name,
          //       record.street_address,
          //       record.apt_suite_number ? record.apt_suite_number : "",
          //       record.city,
          //       record.state,
          //       record.zipcode,
          //       record.country,
          //       1,
          //       record.company_name ? record.company_name : "",
          //       record.role,
          //       record.groups,
          //       record.default_site_id,
          //       record.notes ? record.notes : "",
          //     ],
          //   },
          // });

          //

          MySwal.fire({
            title: "Registration",
            html: "Registering the User, Please be patient.",
            allowEscapeKey: false,
            allowOutsideClick: false,
            onOpen: () => {
              MySwal.showLoading();
            },
            onClose: () => {},
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log("Closed by Timer");
            }
          });
          const parms = [
            this.props.userId,
            -1,
            record.email,
            record.phone_number.slice(record.country_code.length),
            record.first_name,
            record.last_name,
            record.street_address,
            record.apt_suite_number ? record.apt_suite_number : "",
            record.city,
            record.state,
            record.zipcode,
            record.country,
            1,
            record.company_name ? record.company_name : "",
            record.role,
            record.groups,
            this.props.default_site_id,
            //record.default_site_id,
            record.notes ? record.notes : "",
            record.mapCenter?.lat,
            record.mapCenter?.lng,
            record.user_name,
            htoken, //record.password,
            record.country_code,
          ];
          //  debugger;
          this.saveCustomerInDB(parms, token)
            .then(async (resultObjCustomer) => {
              //  debugger;

              if (!this.hasDBErrors(resultObjCustomer)) {
                MySwal.close();
                console.log("resultObjCustomer", resultObjCustomer);
                const data = resultObjCustomer.data;
                //  debugger;
                console.log("User Created", data);
                this.openNotificationWithIcon(
                  "success",
                  "Success",
                  "User registered Succesfully, Please login with the registered email"
                );
                //Ask the use to Relogin
                //  debugger;
                // const resultObj = await this.sendResetEmailToUser(record.email);
                // if (resultObj.messageType == "success") {
                //   // if (resultObj.errCode == "0") {
                //   // MySwal.close();

                //   this.openNotificationWithIcon(
                //     "success",
                //     "Success",
                //     resultObj.message
                //   );
                // } else {
                //   // MySwal.close();
                //   //Display Error
                //   this.openNotificationWithIcon(
                //     "error",
                //     "Error",
                //     resultObj.message
                //       ? resultObj.message
                //       : "An error has occured!"
                //   );
                // }
              } else {
                //Display Error
                this.openNotificationWithIcon(
                  "error",
                  "Error",
                  resultObj.message
                    ? resultObj.message
                    : "An error has occured!"
                );
              }
              MySwal.close();
            })
            .catch((error) => {
              MySwal.close();
              console.log("Error", error);
            });
        }
      );

      // this.setSpaceTypeVisible(false);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  disabledDateRange = (current) => {
    // const today = dayjs().add(1, "month");
    const today = dayjs();
    const endDate = dayjs().add(12, "month");

    return !dayjs(current).isBetween(today, endDate, "day", "[]");
  };

  updatedateRange = (dates) => {
    this.setState({ startDt: dates[0], endDt: dates[1] }, () => {
      this.getSitesAndVacancies();
      // this.getActiveVacancies();
    });
  };

  getActiveVacancies = () => {
    this.props.getVacancyData({
      type: vacancyTypes.FETCH_DATA_REQUEST,
      payload: {
        parms: [this.state.startDt, this.state.endDt],
      },
    });
  };

  onResetSearchAll = () => {
    this.setState({
      sites: this.state.sitesOrig,
      searchText: "",
      // renderVersion: this.state.renderVersion + 1,
    });
  };

  onInputChange = (e) => {
    this.setState({ searchText: e.target.value }, () => {
      this.debouncedGridSearch();
    });
  };

  debouncedGridSearch = debounce(() => this.onSearch(), 50);

  onSearch = () => {
    let { searchText } = this.state;
    if (isNil(searchText)) {
      searchText = "";
    }

    if (searchText.trim() === "") {
      this.setState({ searchText: "" }, () => {
        this.onResetSearchAll();
      });
    } else {
      const results = filterTotalResults(searchText, this.state.sitesOrig);
      this.setState({
        sites: results,
        // currentGridFilteredData: results,
        // renderVersion: this.state.renderVersion + 1,
        searchText,
      });
    }
  };

  addReserve = (site) => {
    //See if the user is registered

    const { data } = this.state;
    const { history } = this.props;
    //  debugger;

    const stepItems = steps.map((item) => ({
      ...item,
      key: item.title,
      title: item.title,
    }));

    console.log("stepItems", stepItems);
    this.setState({
      showInvoiceModal: true,
      // currentInvoiceRecord: record,
      stepItems,
      currentStep: 0,
      reserveSite: site,
    });
  };
  displayMarkers = () => {
    return this.state.sites.map((site, index) => {
      // debugger;
      return (
        <Marker
          key={index}
          id={site.site_id}
          position={{
            lat: site.latitude,
            lng: site.longitude,
          }}
          onClick={(site) => {
            const siteObj = this.state.sites.filter(
              (rec) => rec.site_id == site.id
            )[0];
            this.setState({ showSiteDetailsModal: true, currentSite: siteObj });
            // alert("You clicked me! " + siteObj.address_line_1);
            // console.log("site! " + JSON.parse(site));
            // console.log("You clicked me! " + site.address_line_1);
          }}
        />
      );
    });
  };
  // addMarker({lat: 40.6782, lng: -73.9442}); // Brooklyn Coordinates
  // addMarker({lat: 40.7831, lng: -73.9712}); // Manhattan Coordinates
  getSiteVacanciesCount = (site) => {};

  showSiteDetails = (site) => {
    this.setState({ showSiteDetailsModal: true, currentSite: site });
  };

  verifyPhone = async () => {
    //  debugger;
    const record = this.formRegisterRef.current.getFieldsValue(true);
    const phone_number = trim(
      (record.phone_number || "").replace(/[^\d]/g, "")
    );
    if (!phone_number || phone_number.length != 10) {
      NotificationManager.error(
        "Please Enter Valid Phone Number",
        "Phone Number",
        1100
      );
      return false;
    }

    let URL = process.env.REACT_APP_API_URL + `/twilio/otp`;
    // const optionToken = await AsyncStorage.getItem('accessToken');
    console.log("URL", URL);

    const response = await PNfetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        phone: "+1" + phone_number,
      }),
    });

    const retObj = await response.json();
    console.log("response", retObj);
    // console.log('response', response.json());

    if (retObj.messageType == "error") {
      NotificationManager.error(retObj.message, "Phone Number", 1100);
    } else {
      //Show the textbox to verify
      this.setState({ otpModalVisible: true, OTPNumber: undefined });
      // setOTPVerified(true);
    }
  };
  verifyOTP = async () => {
    //  debugger;
    if (this.state.OTPNumber < 6) {
      NotificationManager.error(
        "Please enter 6 digit otp number",
        "Phone Number",
        1100
      );
      return false;
    }
    const record = this.formRegisterRef.current.getFieldsValue(true);
    const phone_number = trim(
      (record.phone_number || "").replace(/[^\d]/g, "")
    );
    let URL = process.env.REACT_APP_API_URL + `/twilio/otpVerify`;
    // const optionToken = await AsyncStorage.getItem('accessToken');
    console.log("URL", URL);
    const response = await PNfetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        phone: "+1" + phone_number,
        otp: this.state.OTPNumber,
      }),
    });

    const retObj = await response.json();
    console.log("response", retObj);
    // console.log('response', response.json());

    if (retObj.messageType == "error") {
      NotificationManager.error(retObj.message, "OTP Verification", 1100);
    } else {
      //Show the textbox to verify

      localStorage.setItem("refreshToken", retObj.refresh_token);
      localStorage.setItem("accessToken", retObj.access_token);

      if (retObj.verification_check.status == "approved") {
        this.setState(
          {
            otpModalVisible: false,
            phoneVerified: true,
            refreshToken: retObj.refresh_token,
            accessToken: retObj.access_token,
          },
          () => {
            NotificationManager.success(
              "Phone Number is Verified",
              "OTP Verification",
              1100
            );
            this.next();
            //  props.navigation.navigate("RegisterUser", { phone: state.phoneNumber });
          }
        );
      } else {
        NotificationManager.error(
          "Invalid OTP number, Please try again",
          "OTP Verification",
          1100
        );
      }
      // setOTPVerified(true);
    }
  };
  updateOTPNumber = (e) => {
    const otpNum = e.target.value.replace(/[^0-9]/g, "");
    this.setState({ OTPNumber: otpNum });
  };

  setOTPVisible = (visibile) => {
    this.setState({ otpModalVisible: visibile });
  };

  //  const [current, setCurrent] = useState(0);
  next = async () => {
    if (this.state.currentStep <= 0) {
      // && isNil(this.state.reserveEmailExists)
      const record = this.formCustomerRef.current.getFieldsValue(true);

      if (isNil(record.email) || trim(record.email) == "") {
        this.openNotificationWithIcon("error", "Error", "Please Enter email");
        return false;
      }
      //Verify the email Exists
      const parms = {
        email: record.email,
      };
      const resultObj = JSON.parse(await this.verifyEmail(parms));
      console.log("Sign In resultObj", resultObj);
      //  debugger;
      if (resultObj.messageType == "success") {
        if (resultObj.emailExists == "Y") {
          this.setState(
            {
              currentStep: this.state.currentStep + 1,
              reserveEmail: record.email,
              reserveEmailExists: resultObj.emailExists,
              prevClicked: false,
              nextClicked: true,
              userDetailsObj: resultObj.userDetailsObj,
              phoneVerified: false,
            },
            () => {}
          );
        } else {
          this.setState(
            {
              currentStep: this.state.currentStep + 1,
              reserveEmail: record.email,
              reserveEmailExists: resultObj.emailExists,
              prevClicked: false,
              nextClicked: true,
              phoneVerified: false,
              userDetailsObj: undefined,
            },
            () => {
              //Get the reference Data
            }
          );
        }
      } else {
        NotificationManager.error(resultObj.message, "Login", 1200);
      }
    } else if (this.state.currentStep == 1) {
      // let token = "";
      const record = this.formRegisterRef.current.getFieldsValue(true);
      console.log("record", record);
      const row = await this.formRegisterRef.current.validateFields();
      let token = "";
      if (!this.state.phoneVerified) {
        token = await this.verifyPhone();
      } else {
        MySwal.fire({
          title: "Registration",
          html: "Registering the User, Please be patient.",
          allowEscapeKey: false,
          allowOutsideClick: false,
          onOpen: () => {
            MySwal.showLoading();
          },
          onClose: () => {},
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log("Closed by Timer");
          }
        });
        const parms = [
          this.props.userId,
          record.user_id, // - 1,
          record.email,
          record.phone_number,
          record.first_name,
          record.last_name,
          record.street_address,
          record.apt_suite_number ? record.apt_suite_number : "",
          record.city,
          record.state,
          record.zipcode,
          record.country,
          1,
          record.company_name ? record.company_name : "",
          record.role,
          record.groups,
          record.default_site_id,
          record.notes ? record.notes : "",
          record.mapCenter?.lat,
          record.mapCenter?.lng,
          record.user_name,
          record.password,
        ];
        //  debugger;
        this.saveCustomerInDB(parms, this.state.refreshToken)
          .then(async (resultObjCustomer) => {
            // debugger;

            if (!this.hasDBErrors(resultObjCustomer)) {
              MySwal.close();
              console.log("resultObjCustomer", resultObjCustomer);
              const data = resultObjCustomer.data;
              //  debugger;
              console.log("User Created", data);
              const userObj = resultObjCustomer.data[0][0];
              console.log("userObj", userObj);
              this.openNotificationWithIcon(
                "success",
                "Success",
                "User registered Succesfully"
              );

              const resultObj = {
                user: userObj,
                access_token: this.state.accessToken,
                refresh_token: this.state.refreshToken,
              };

              console.log("In setLoginSessionData");
              //Load the session data and Reference Data
              this.props.setLoginSessionData({
                type: sessionDataTypes.SET_LOGIN_SESSION_DATA_REQUEST,
                payload: {
                  parms: resultObj,
                },
              });

              this.setState({
                userDetailsObj: userObj,
                currentStep: this.state.currentStep + 1,
                prevClicked: false,
                nextClicked: true,
              });
            } else {
              //Display Error
              this.openNotificationWithIcon(
                "error",
                "Error",
                resultObjCustomer.message
                  ? resultObjCustomer.message
                  : "An error has occured!"
              );
            }
            MySwal.close();
          })
          .catch((error) => {
            MySwal.close();
            console.log("Error", error);
          });
      }
    }
    // else if (this.state.currentStep == 2) {
    //   // this.props.signinUser(null);
    //   const record = this.formLoginRef.current.getFieldsValue(true);
    //   if (isNil(record.email) || trim(record.email) == "") {
    //     this.openNotificationWithIcon("error", "Error", "Please Enter email");
    //     return false;
    //   }

    //   if (isNil(record.pwd) || trim(record.pwd) == "") {
    //     this.openNotificationWithIcon(
    //       "error",
    //       "Error",
    //       "Please Enter password"
    //     );
    //     return false;
    //   }
    //   const parms = {
    //     name: record.email,
    //     password: record.pwd,
    //   };
    //   const resultObj = JSON.parse(await this.verifyUser(parms));
    //   console.log("Sign In resultObj", resultObj);
    //   // debugger;
    //   if (resultObj.messageType == "success") {
    //     this.setState({
    //       currentStep: this.state.currentStep + 1,
    //       prevClicked: false,
    //       nextClicked: true,
    //     });
    //   } else {
    //     NotificationManager.error(resultObj.message, "Login", 1200);
    //   }
    // } else {
    else if (this.state.currentStep == 2) {
      // debugger;
      //Take the payments
      const retObj = await this.reserveRef.current.addInvoice();
      // debugger;
      if (retObj.messageType == "success") {
        this.setState({
          currentStep: this.state.currentStep + 1,
          prevClicked: false,
          nextClicked: true,
          //no need to do this as we are not letting them edit invoice /reservation
          // reserveInvoiceId: retObj.invoice_id,
        });
      }
    } else {
      this.setState({
        currentStep: this.state.currentStep + 1,
        prevClicked: false,
        nextClicked: true,
      });
    }
  };
  prev = () => {
    this.setState({
      currentStep: this.state.currentStep - 1,
      prevClicked: true,
      nextClicked: false,
    });
  };

  refreshReserveData = () => {};
  onLoginStart = () => {};
  authHandler = (err, data) => {
    console.log(err, data);
  };

  render() {
    //Passwords must be between 4 and 15 characters.
    const { email, password, errors, verifiedStatus, accountPending } =
      this.state;
    const { loading } = this.props;
    return (
      <QueueAnim type="bottom" duration={2000}>
        {!this.state.firstTimeLoaded && <NetworkState />}
        <div
          className="rct-session-wrapper"
          // style={{
          //   backgroundImage: AppConfig.enableSessionGradientBackgroundImage
          //     ? `${AppConfig.gradientBg}, url("${AppConfig.sessionBgImage}")`
          //     : AppConfig.enableSidebarBackgroundImage
          //     ? AppConfig.sessionBgImage
          //     : "none",
          // }}
        >
          {/* {loading &&
                  <LinearProgress />
               } */}
          <div className="auth-page-root">
            <div className="auth-page-body" style={{ background: "#dadfe6" }}>
              <Row>
                <Col xs={24} md={10}>
                  <div
                    className="auth-left d-flex flex-column p-4"
                    style={{
                      // position: "relative",
                      // background: `#666 url(${bgImage}) no-repeat center`,
                      // backgroundSize: "cover",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      className="d-flex flex-column relative"
                      style={{
                        zIndex: 2,
                        // minHeight: 280,
                        // display: "flex",
                        // alignItems: "center",
                      }}
                    >
                      <div
                        // className="mx-auto"
                        style={{
                          margin: "10px auto",
                        }}
                      >
                        <div className="site-brand">
                          <Link to="/">
                            <img
                              src={AppConfig.appLogo}
                              alt="ParkNation"
                              className="img-fluid"
                            />
                          </Link>
                        </div>
                      </div>
                      {/* <Typography.Title level={3} style={{ color: "#FFF" }}>
                        Sign In
                      </Typography.Title>
                      <Typography.Paragraph style={{ color: "#FFF" }}>
                        By Signing Up, you can avail full features of our
                        services.
                      </Typography.Paragraph>
                      <Typography.Paragraph style={{ color: "#FFF" }}>
                        Contact us at&nbsp;
                        <a
                          href="mailto:info@parknation.org"
                          className="color-white"
                        >
                          info@parknation.org
                        </a>
                      </Typography.Paragraph> */}
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={14}>
                  <div className="d-flex flex-column p-4">
                    <p className="text-danger mb-1">
                      {accountPending && accountPending}
                    </p>
                    <Form onSubmit={(e) => this.onUserLogin(e)}>
                      <FormGroup className="input-affix-wrapper">
                        <Input
                          // type="mail"
                          value={email}
                          name="email"
                          // id="user-mail"
                          className="input-sm"
                          placeholder="Email address"
                          onChange={this.onKeyUp}
                          prefix={<EmailIcon color="disabled" />}
                          // prefix={<EmailOutlined />}
                        />
                        {/* <span className="input-prefix">
                          <EmailIcon color="disabled" />
                        </span> */}
                        <p className="text-danger text-left">{errors.email}</p>
                      </FormGroup>
                      <FormGroup className="input-affix-wrapper">
                        <Input.Password
                          value={password}
                          // type="Password"
                          name="password"
                          // id="pwd"
                          className="input-sm"
                          placeholder="Password"
                          onChange={this.onKeyUp}
                          prefix={<LockIcon color="disabled" />}
                          // addonBefore={<LockOutlined />}
                        />
                        {/* <span className="input-prefix">
                          <LockIcon color="disabled" />
                        </span> */}
                        <p className="text-danger text-left">
                          {errors.password}
                        </p>
                      </FormGroup>
                      <FormGroup className="input-affix-wrapper">
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size={this.props.isMobile ? "" : "large"}
                          style={{
                            backgroundColor: "#04294d",
                          }}
                        >
                          Login
                        </Button>
                        <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size={this.props.isMobile ? "" : "large"}
                          style={{
                            backgroundColor: "#04294d",
                            marginLeft: this.props.isMobile ? "12px" : "20px",
                          }}
                          onClick={(e) => this.registerUser(e)}
                        >
                          Register
                        </Button>
                        {/* <Button
                          type="submit"
                          color="primary"
                          variant="contained"
                          size={this.props.isMobile ? "" : "large"}
                          style={{
                            marginLeft: this.props.isMobile ? "12px" : "20px",
                          }}
                          onClick={(e) => this.showSites(e)}
                        >
                          Site(s)
                        </Button> */}
                      </FormGroup>
                      <p>
                        <Divider style={{ fontSize: "13px" }}>
                          or sign in with
                        </Divider>
                      </p>

                      <div style={{ whiteSpace: "nowrap" }}>
                        <Row gutter={16}>
                          <Col>
                            <LoginSocialGoogle
                              isOnlyGetToken
                              discoveryDocs="claims_supported"
                              access_type="offline"
                              scope="openid profile email"
                              client_id={
                                "289004629313-708s3uhauf9lhqlpusd8hs3g44m24rth.apps.googleusercontent.com"
                              }
                              onLoginStart={this.onLoginStart}
                              onResolve={({ provider, data }) => {
                                debugger;
                                axios
                                  .get(
                                    `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.access_token}`,
                                    {
                                      headers: {
                                        Authorization: `Bearer ${data.access_token}`,
                                        Accept: "application/json",
                                      },
                                    }
                                  )
                                  .then(async (res) => {
                                    debugger;
                                    console.log(res.data);

                                    //Check if the email exists
                                    const record = res.data;
                                    const parms = {
                                      ...record,
                                      provider: "Google",
                                    };
                                    const resultObj = JSON.parse(
                                      await this.checkSocialId(parms)
                                    );
                                    console.log("Sign In resultObj", resultObj);
                                    debugger;
                                    if (resultObj.messageType == "success") {
                                      if (resultObj.emailExists == "Y") {
                                        this.props.resetLoginSessionData({
                                          type: sessionDataTypes.RESET_LOGIN_SESSION_DATA_REQUEST,
                                          payload: {
                                            parms: resultObj,
                                          },
                                        });
                                        console.log(
                                          "-----------params-----------------",
                                          parms
                                        );
                                        //Imp IMp as its taking time to write to the store
                                        localStorage.setItem(
                                          "accessToken",
                                          resultObj.access_token
                                        );
                                        localStorage.setItem(
                                          "refreshToken",
                                          resultObj.refresh_token
                                        );
                                        localStorage.setItem(
                                          "session_user_id",
                                          resultObj.user.user_id
                                        );

                                        localStorage.setItem(
                                          "session_orig_user_id",
                                          resultObj.user.user_id
                                        );
                                        //Storage Of token is done in Here
                                        // to store user id and name
                                        this.props.signinUser(resultObj, 1);
                                        // to get user feature information
                                        // alert("resultObj.user.user_id", resultObj.user.user_id);
                                        this.props.setFeatures(
                                          resultObj.user.user_id,
                                          resultObj.user.role,
                                          resultObj.userEntitlements
                                        );

                                        console.log("In setLoginSessionData");
                                        //Load the session data and Reference Data
                                        this.props.setLoginSessionData({
                                          type: sessionDataTypes.SET_LOGIN_SESSION_DATA_REQUEST,
                                          payload: {
                                            parms: resultObj,
                                          },
                                        });

                                        setTimeout(() => {
                                          console.log(
                                            "IN SESSION LOGIN%%%%%%%%%%%%%%%%%%%%%%%%%%%%",
                                            resultObj.user
                                          );
                                          this.props.showReserve(
                                            resultObj.user
                                          );

                                          NotificationManager.success(
                                            "User logged in successfully",
                                            "Login",
                                            1200
                                          );
                                        }, 350);
                                      } else {
                                        NotificationManager.error(
                                          resultObj.message,
                                          "Login",
                                          1200
                                        );
                                      }
                                    } else {
                                      NotificationManager.error(
                                        resultObj.message,
                                        "Login",
                                        1200
                                      );
                                    }
                                    // setProfile(res.data);
                                  })
                                  .catch((err) => console.log(err));
                                // setProvider(provider);
                                // setProfile(data);
                              }}
                              onReject={(err) => {
                                console.log(err);
                              }}
                            >
                              <AButton
                                type="dashed"
                                size="middle"
                                style={{ backgroundColor: "white" }}
                                icon={
                                  <GoogleOutlined style={{ fontSize: 16 }} />
                                }
                              >
                                Google
                              </AButton>
                              {/* <GoogleLoginButton /> */}
                            </LoginSocialGoogle>
                          </Col>
                          <Col>
                            <LoginSocialMicrosoft
                              isOnlyGetToken={false}
                              client_id={"034ce501-f5b0-451a-8906-fa1e2425ccfe"}
                              onLoginStart={this.onLoginStart}
                              //  redirect_uri={"https://portal.parknation.org/login"}
                              redirect_uri={window.location.href}
                              // scope="profile email" offline_access%20user.read%20mail.read
                              scope="offline_access user.read mail.read"
                              onResolve={({ provider, data }) => {
                                // setProvider(provider);
                                // setProfile(data);
                                console.log("data", data);
                                axios
                                  .get(`https://graph.microsoft.com/v1.0/me`, {
                                    headers: {
                                      Authorization: `Bearer ${data.access_token}`,
                                      Accept: "application/json",
                                    },
                                  })
                                  .then(async (res) => {
                                    debugger;
                                    console.log(res.data);

                                    //Check if the email exists
                                    const record = res.data;
                                    const parms = {
                                      // ...record,
                                      email: record.userPrincipalName,
                                      id: record.id,
                                      family_name: record.surname,
                                      given_name: record.givenName,
                                      locale: "en",
                                      picture: "",
                                      provider: "Microsoft",
                                    };
                                    const resultObj = JSON.parse(
                                      await this.checkSocialId(parms)
                                    );
                                    console.log("Sign In resultObj", resultObj);
                                    debugger;
                                    if (resultObj.messageType == "success") {
                                      if (resultObj.emailExists == "Y") {
                                        this.props.resetLoginSessionData({
                                          type: sessionDataTypes.RESET_LOGIN_SESSION_DATA_REQUEST,
                                          payload: {
                                            parms: resultObj,
                                          },
                                        });
                                        console.log(
                                          "-----------params-----------------",
                                          parms
                                        );
                                        //Imp IMp as its taking time to write to the store
                                        localStorage.setItem(
                                          "accessToken",
                                          resultObj.access_token
                                        );
                                        localStorage.setItem(
                                          "refreshToken",
                                          resultObj.refresh_token
                                        );
                                        localStorage.setItem(
                                          "session_user_id",
                                          resultObj.user.user_id
                                        );

                                        localStorage.setItem(
                                          "session_orig_user_id",
                                          resultObj.user.user_id
                                        );

                                        //Storage Of token is done in Here
                                        // to store user id and name
                                        this.props.signinUser(resultObj, 1);
                                        // to get user feature information
                                        // alert("resultObj.user.user_id", resultObj.user.user_id);
                                        this.props.setFeatures(
                                          resultObj.user.user_id,
                                          resultObj.user.role,
                                          resultObj.userEntitlements
                                        );

                                        console.log("In setLoginSessionData");

                                        // this.props.refreshReferenceData({
                                        //   type: actionTypes.REFRESH_REFDATA_REQUEST,
                                        //   payload: {
                                        //     parms: [resultObj.user.user_id], //Y will get balances, N all the users, as default
                                        //   },
                                        //   user_id_fk: resultObj.user.user_id,
                                        // });

                                        //Load the session data and Reference Data
                                        this.props.setLoginSessionData({
                                          type: sessionDataTypes.SET_LOGIN_SESSION_DATA_REQUEST,
                                          payload: {
                                            parms: resultObj,
                                          },
                                        });

                                        setTimeout(() => {
                                          console.log(
                                            "IN SESSION LOGIN%%%%%%%%%%%%%%%%%%%%%%%%%%%%"
                                          );
                                          this.props.showReserve(
                                            resultObj.user
                                          );

                                          NotificationManager.success(
                                            "User logged in successfully",
                                            "Login",
                                            1200
                                          );
                                        }, 350);
                                      } else {
                                        NotificationManager.error(
                                          resultObj.message,
                                          "Login",
                                          1200
                                        );
                                      }
                                    } else {
                                      NotificationManager.error(
                                        resultObj.message,
                                        "Login",
                                        1200
                                      );
                                    }
                                    // setProfile(res.data);
                                  })
                                  .catch((err) => console.log(err));
                                // setProvider(provider);
                                // setProfile(data);
                              }}
                              onReject={(err) => {
                                console.log(err);
                              }}
                            >
                              <AButton
                                type="dashed"
                                style={{ backgroundColor: "white" }}
                                size="middle"
                                icon={
                                  <SkypeOutlined style={{ fontSize: 16 }} />
                                }
                              >
                                Skype
                              </AButton>
                            </LoginSocialMicrosoft>
                            {/* <Col span="8">
                             <LoginSocialFacebook
                              appId={"1659162621191944"}
                              onLoginStart={this.onLoginStart}
                              // scope="public_profile, email, user_birthday"
                              redirect_uri="https://dev.parknation.org/login"
                              fieldsProfile={
                                "id,first_name,last_name,middle_name,name,name_format,picture,short_name,gender,email"
                              }
                              onResolve={({ provider, data }) => {
                                // setProvider(provider);
                                // setProfile(data);
                                console.log("data", data);
                                // axios
                                //   .get(
                                //     `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${data.access_token}`,
                                //     {
                                //       headers: {
                                //         Authorization: `Bearer ${data.access_token}`,
                                //         Accept: "application/json",
                                //       },
                                //     }
                                //   )
                                //   .then((res) => {
                                //     debugger;
                                //     console.log(res.data);
                                //     // setProfile(res.data);
                                //   })
                                //   .catch((err) => console.log(err));
                              }}
                              onReject={(err) => {
                                console.log(err);
                              }}
                            >
                              <AButton
                                type="dashed"
                                size="middle"
                                icon={
                                  <FacebookOutlined style={{ fontSize: 16 }} />
                                }
                              >
                                Facebook
                              </AButton>
                            </LoginSocialFacebook>  
                          </Col> */}
                          </Col>
                        </Row>
                      </div>
                    </Form>
                    <Typography
                      //className={"mt-auto"}
                      style={{
                        fontWeight: 600,
                        fontSize: 14,
                        marginTop: "12px",
                      }}
                    >
                      <Link onClick={(e) => this.showPasswordModal()}>
                        Forgot password?
                      </Link>
                    </Typography>
                  </div>
                </Col>
              </Row>
              {/* <Row>
                <Col span="12">
                  <LoginSocialGoogle
                    isOnlyGetToken
                    discoveryDocs="claims_supported"
                    access_type="offline"
                    scope="openid profile email"
                    client_id={
                      "289004629313-708s3uhauf9lhqlpusd8hs3g44m24rth.apps.googleusercontent.com"
                    }
                    onLoginStart={this.onLoginStart}
                    onResolve={({ provider, data }) => {
                      debugger;
                      axios
                        .get(
                          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${data.access_token}`,
                          {
                            headers: {
                              Authorization: `Bearer ${data.access_token}`,
                              Accept: "application/json",
                            },
                          }
                        )
                        .then((res) => {
                          debugger;
                          console.log(res.data);
                          // setProfile(res.data);
                        })
                        .catch((err) => console.log(err));
                      // setProvider(provider);
                      // setProfile(data);
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <GoogleLoginButton />
                  </LoginSocialGoogle>
                </Col> 
                  <Col span="12">
                  <LoginSocialFacebook
                    appId={"1659162621191944"}
                    onLoginStart={this.onLoginStart}
                    // scope="public_profile, email, user_birthday"
                    redirect_uri="https://dev.parknation.org/login"
                    fieldsProfile={
                      "id,first_name,last_name,middle_name,name,name_format,picture,short_name,gender,email"
                    }
                    onResolve={({ provider, data }) => {
                      // setProvider(provider);
                      // setProfile(data);
                      console.log("data", data);
                      // axios
                      //   .get(
                      //     `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${data.access_token}`,
                      //     {
                      //       headers: {
                      //         Authorization: `Bearer ${data.access_token}`,
                      //         Accept: "application/json",
                      //       },
                      //     }
                      //   )
                      //   .then((res) => {
                      //     debugger;
                      //     console.log(res.data);
                      //     // setProfile(res.data);
                      //   })
                      //   .catch((err) => console.log(err));
                    }}
                    onReject={(err) => {
                      console.log(err);
                    }}
                  >
                    <FacebookLoginButton />
                  </LoginSocialFacebook>
                </Col>
              </Row> */}
            </div>
            {/*<div className="signIn-div">
            <AppBar position="static" className="">
              <Toolbar>
                <div className="session-logo">
                  <Link to="/">
                    <img
                      src={AppConfig.appLogo}
                      alt="session-logo"
                      className="img-fluid"
                    />
                  </Link>
                </div>
              </Toolbar>
            </AppBar>
            <div className="session-inner-wrapper">
              <div className="row-eq-height">
                <div className="session text-center">
                  <div className="session-header text-center">
                    <div className="header-text">
                      <h1 className="m-0">
                        <b>Login</b>
                      </h1>
                      <p className="text-danger">
                        {accountPending && accountPending}
                      </p>
                    </div>
                  </div>
                  <Form onSubmit={(e) => this.onUserLogin(e)}>
                    <div className="session-body row">
                      <FormGroup className="has-wrapper col-12">
                        <Input
                          type="mail"
                          value={email}
                          name="email"
                          id="user-mail"
                          className="has-input input-sm"
                          placeholder="Email address"
                          onChange={this.onKeyUp}
                        />
                        <span className="has-icon">
                          <EmailIcon color="disabled" />
                        </span>
                        <p className="text-danger text-left">{errors.email}</p>
                      </FormGroup>
                      <FormGroup className="has-wrapper col-12">
                        <Input
                          value={password}
                          type="Password"
                          name="password"
                          id="pwd"
                          className="has-input input-sm"
                          placeholder="Password"
                          onChange={this.onKeyUp}
                        />
                        <span className="has-icon">
                          <LockIcon color="disabled" />
                        </span>
                        <p className="text-danger text-left">
                          {errors.password}
                        </p>
                      </FormGroup>
                    </div>
                    <div className="session-footer">
                      <div className="mb-20 text-right">
                        <small>
                          {!verifiedStatus && (
                            <div
                              className="display-inline pull-left text-danger pointer"
                              onClick={this.resendVerificationEmail}
                            >
                              Resend verification email
                            </div>
                          )}
                           <Link to="/lost-password">Lost password?</Link>
                        </small>
                      </div>
                      <div>
                        <Button
                          type="submit"
                          color="primary"
                          className="btn-block text-white w-100"
                          variant="contained"
                          size="large"
                        >
                          Login
                        </Button>
                      </div>
                       <h4 className="mt-20 mb-0 text-center link">
                        <Link to="/signup">Create an account</Link>
                      </h4>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>*/}
            {/* <Footer
              style={{
                display: "block",
                textAlign: "center",
                background: "rgba(255, 255, 255, 0.8)",
              }}
            /> */}
          </div>
          <Modal
            title="Forgot Password -- Please enter email to reset password"
            open={this.state.passwordModalVisible}
            centered
            footer={null}
            closable={false}
            maskClosable={false}
            style={{ overflow: "hidden" }}
            className="parentModal"
            // onOk={this.hideModal}
            // onCancel={this.hideModal}
            // okText="OK"
            // cancelText="Cancel"
          >
            <div style={{ overflow: "hidden" }}>
              <AForm
                id="dataEntryDiv"
                name="form-customer"
                preserve={false}
                {...layout}
                ref={this.formCustomerRef}
                // name="control-ref"
                // size={"small"}
                onFinish={this.onFinish}
                initialValues={{
                  email: "",
                }}
                scrollToFirstError
                style={{ padding: "10px" }}
              >
                <AForm.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input style={{ maxWidth: "500px" }} />
                </AForm.Item>
                <Row justify="end">
                  <Col>
                    <Button
                      loading={this.state.loading}
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: "8px" }}
                      onClick={(e) => this.resetPassword()}
                    >
                      Send
                    </Button>
                    <Button
                      htmlType="button"
                      style={{ marginRight: "20px" }}
                      onClick={this.hideModal}
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </AForm>
            </div>
          </Modal>
          <Modal
            title="Create Registration"
            centered
            open={this.state.registerModalVisible}
            onOk={() => this.setRegisterVisible(false)}
            onCancel={(e) => this.setRegisterVisible(false)}
            width={1000}
            footer={null}
            closable={false}
            maskClosable={false}
            style={{ zIndex: 990 }}
            destroyOnClose={true}
            className="parentModal"
          >
            <NewCustomerForm
              id="dataEntryDiv"
              {...layout}
              name="control-ref"
              ref={this.formRegisterRef}
              onCancel={(e) => this.setRegisterVisible(false)}
              onSave={(e) => this.saveCustomer()}
              initialValues={{
                email: undefined,
                phone_number: undefined,
                first_name: undefined,
                last_name: undefined,
                street_address: undefined,
                city: undefined,
                state: undefined,
                zipcode: undefined,
                country: "USA",
                company_name: undefined,
                user_id: -1,
                role: 4,
                default_site_id: this.props.default_site_id, //undefined, //Sicklerville
                groups: "1",
                apt_suite_number: undefined,
                user_name: "",
                password: "",
                country_code: "1",
              }}
              phoneVerification={true}
              showDefaultSite={false}
              showStatus={false}
              showGroup={false}
              showMap={false}
              showAddress={true}
              sites={this.state.sites}
              roles={roles}
              disableEmailandPhone={this.state.reserveEmailExists == "Y"}
            />
          </Modal>
          <Modal
            title="ParkNation Site(s)"
            centered
            open={this.state.siteModalVisible}
            onOk={() => this.setSiteVisible(false)}
            onCancel={(e) => this.setSiteVisible(false)}
            width={"100vw"}
            footer={null}
            closable={true}
            maskClosable={false}
            style={{ zIndex: 950 }}
            destroyOnClose={true}
            className="parentModal"
          >
            <Layout>
              <Header>
                <Row align={"middle"} gutter={24}>
                  <Col lg={6}>
                    <Typography.Title
                      level={4}
                      style={{
                        marginBottom: 0,
                        color: "inherit",
                        fontSize: 18,
                        fontWeight: 700,
                      }}
                    >
                      Reservations
                    </Typography.Title>
                  </Col>
                  <Col
                    className={classnames(
                      "global-picker-header",
                      this.state.showMobilePicker ? "active" : ""
                    )}
                  >
                    <div className={"d-flex align-items-center"}>
                      <RangePicker
                        value={[
                          dayjs(this.state.startDt, dateFormat),
                          dayjs(this.state.endDt, dateFormat),
                        ]}
                        disabledDate={this.disabledDateRange}
                        format={dateFormat}
                        onChange={this.updatedateRange}
                        style={{ width: 260 }}
                      />
                      <span className="d-lg-none ml-auto">
                        <AButton
                          type="text"
                          style={{
                            border: "none",
                            padding: 0,
                            lineHeight: "unset",
                            color: "white",
                          }}
                          icon={<CloseOutlined style={{ fontSize: 16 }} />}
                          onClick={() =>
                            this.setState((state) => ({
                              ...state,
                              showMobilePicker: false,
                            }))
                          }
                        />
                      </span>
                    </div>
                  </Col>
                  <Col
                    className={classnames(
                      "ml-auto global-search-header",
                      this.state.showMobileSearch ? "active" : ""
                    )}
                  >
                    <div
                      onClick={(e) => e.stopPropagation()}
                      className={"d-flex align-items-center"}
                    >
                      <span onClick={(e) => e.preventDefault()}>
                        <Input
                          // allowClear
                          autoFocus={true}
                          style={{
                            height: "32px",
                            width: 260,
                            borderRadius: "16px",
                            boxShadow: "0 1px 6px 0 rgba(32,33,36,.28)",
                          }}
                          placeholder="Search Site(s)"
                          value={this.state.searchText}
                          onChange={this.onInputChange}
                          onPressEnter={(e) => e.preventDefault()}
                          prefix={
                            <SearchOutlined
                              style={{ color: "#9aa0a6", fontSize: 18 }}
                            />
                          }
                        />
                      </span>
                      <span style={{ marginLeft: 12 }}>
                        <AButton onClick={() => this.onResetSearchAll()}>
                          Reset
                        </AButton>
                      </span>
                      <span className="d-lg-none ml-auto">
                        <AButton
                          type="text"
                          style={{
                            border: "none",
                            padding: 0,
                            lineHeight: "unset",
                            color: "white",
                          }}
                          icon={<CloseOutlined style={{ fontSize: 16 }} />}
                          onClick={() =>
                            this.setState((state) => ({
                              ...state,
                              showMobileSearch: false,
                            }))
                          }
                        />
                      </span>
                    </div>
                  </Col>
                  <Col className="d-lg-none ml-auto">
                    <span className={"d-lg-none"}>
                      <AButton
                        type="text"
                        style={{
                          border: "none",
                          padding: 0,
                          lineHeight: "unset",
                          color: "white",
                        }}
                        icon={<CalendarOutlined />}
                        onClick={() =>
                          this.setState((state) => ({
                            ...state,
                            showMobilePicker: true,
                          }))
                        }
                      />
                      <AButton
                        type="text"
                        style={{
                          border: "none",
                          padding: 0,
                          lineHeight: "unset",
                          color: "white",
                        }}
                        icon={<SearchOutlined />}
                        onClick={() =>
                          this.setState((state) => ({
                            ...state,
                            showMobileSearch: true,
                          }))
                        }
                      />
                    </span>
                  </Col>
                </Row>
              </Header>
              <Content>
                <Row>
                  {/*Details Container*/}
                  <Col xs={24} xl={12}>
                    <div className={"sites-details"}>
                      <List
                        itemLayout="vertical"
                        size="large"
                        // pagination={{
                        //   onChange: (page) => {
                        //     console.log(page);
                        //   },
                        //   pageSize: 3,
                        // }}
                        dataSource={this.state.sites}
                        footer={
                          <div>
                            Copyright © 2022 Parknation. All Rights Reserved.
                          </div>
                        }
                        renderItem={(item) => (
                          <List.Item
                            style={{
                              border: "1px solid lightgrey",
                              padding: "20px",
                            }}
                            key={item.title}
                            actions={[
                              <IconText
                                icon={StarOutlined}
                                text={`Vacancies: ${
                                  this.state.vacancies.filter(
                                    (rec) => rec.site_id == item.site_id
                                  ).length
                                }`}
                                key="list-vertical-star-o"
                              />,
                              <Rate
                                key="list-rate"
                                allowHalf
                                disabled
                                defaultValue={4.5}
                              ></Rate>,
                              // <IconText
                              //   icon={LikeOutlined}
                              //   text="156"
                              //   key="list-vertical-like-o"
                              // />,
                              <IconText
                                icon={MessageOutlined}
                                text="2"
                                key="list-vertical-message"
                              />,
                              <i
                                className="fas fa-camera"
                                key="list-vertical-camera"
                              ></i>,
                              <i
                                className="fas fa-user-shield"
                                key="list-vertical-lightbulb"
                              ></i>,
                              <i
                                className="fas fa-sun"
                                key="list-vertical-lightbulb"
                              ></i>,
                              <i
                                className="fas fa-restroom"
                                key="list-vertical-camera"
                              ></i>,
                            ]}
                            extra={
                              <div style={{ textAlign: "-webkit-center" }}>
                                <Carousel
                                  // autoplay
                                  // effect="fade"
                                  arrows
                                  nextArrow={<RiArrowRightSLine />}
                                  prevArrow={<RiArrowLeftSLine />}
                                  style={{ width: "300px", maxHeight: "300px" }}
                                >
                                  {item.imagesURL &&
                                    // &&
                                    //   trim(item.imagesURL) != "" &&
                                    //   JSON.parse(item.imagesURL)
                                    //     .filter((x) => !!x)
                                    item.imagesURL.map((img, idx) => (
                                      <div key={idx}>
                                        <Image style={contentStyle} src={img} />
                                      </div>
                                    ))}
                                </Carousel>
                              </div>
                            }
                          >
                            <List.Item.Meta
                              avatar={<Avatar src={item.logo_url} />}
                              title={
                                <div
                                  className={
                                    "d-flex align-items-center flex-wrap"
                                  }
                                >
                                  <a
                                    href={item.web_site_url}
                                    style={{ marginRight: 12 }}
                                  >
                                    {item.logo_company_name}
                                  </a>
                                  <div className={"list-meta-button-group"}>
                                    <Button
                                      variant="contained"
                                      // style={{
                                      //   border: "none",
                                      //   padding: 0,
                                      //   lineHeight: "unset",
                                      // }}
                                      onClick={(e) =>
                                        this.showSiteDetails(item)
                                      }
                                      // icon={<PrinterOutlined />}
                                      style={{ marginRight: 8 }}
                                    >
                                      Details
                                    </Button>
                                    <Button
                                      variant="contained"
                                      // style={{
                                      //   border: "none",
                                      //   padding: 0,
                                      //   lineHeight: "unset",
                                      // }}
                                      onClick={(e) => this.addReserve(item)}
                                      // icon={<PrinterOutlined />}
                                    >
                                      Reserve
                                    </Button>
                                  </div>
                                </div>
                              }
                              description={`${item.address_line_1}, ${item.city},  ${item.state} - ${item.zipcode}`}
                            />
                            {item.description}
                          </List.Item>
                        )}
                      />
                    </div>
                  </Col>
                  {/*Map Container*/}
                  <Col xs={24} xl={12}>
                    <div className={"map-container"}>
                      <Map
                        google={this.props.google}
                        zoom={5}
                        style={{ width: "100%", height: "100%" }}
                        initialCenter={{ lat: 39.8282, lng: -98.5795 }} //TO Center USA
                      >
                        {this.displayMarkers()}
                        {/* <Marker position={{ lat: 48.0, lng: -122.0 }} /> */}
                      </Map>
                    </div>
                  </Col>
                </Row>
              </Content>
            </Layout>
          </Modal>
          {/* <Modal
            title={
              <Row
                gutter={20}
                align={"middle"}
                justify={"space-between"}
                style={{ marginRight: 0 }}
              >
                <Col>
                  <Title level={5} className={"mb-0"}>
                    Reservation Details
                  </Title>
                </Col>
                <Col></Col>
              </Row>
            }
            centered
            open={this.state.showInvoiceModal}
            onOk={() => this.setState({ showInvoiceModal: false })}
            onCancel={() => this.setState({ showInvoiceModal: false })}
            width={1000}
            footer={null}
            // closable={false}
            maskClosable={false}
            // className="childModal pn-modal-root"
            destroyOnClose={true}
            // height={600}
            style={{ zIndex: 970 }}
            className="parentModal"
          >
            <div style={{ marginTop: "2x", padding: "20px" }}>
              <>
                <Steps
                  current={this.state.currentStep}
                  // items={this.state.stepItems}
                >
                  {(this.state.stepItems || []).map((item) => {
                    return (
                      <Steps.Step
                        key={item.title}
                        title={item.title}
                        icon={item.icon}
                      ></Steps.Step>
                    );
                  })}
                </Steps>
                <div className="steps-content">
                  {this.showStepContent()}
                  
                </div>
                <div className="steps-action">
                  {this.state.currentStep > 0 && this.state.currentStep < 3 && (
                    // (this.state.currentStep == 0 &&
                    //   !isNil(this.state.reserveEmailExists))) && (
                    <AButton
                      style={{
                        margin: "0 8px",
                      }}
                      onClick={() => this.prev()}
                    >
                      Previous
                    </AButton>
                  )}
                  {this.state.currentStep < steps.length - 1 && (
                    <AButton type="primary" onClick={() => this.next()}>
                      Next
                    </AButton>
                  )}
                  {this.state.currentStep === steps.length - 1 && (
                    <AButton type="primary" onClick={() => this.doneReserve()}>
                      Done
                    </AButton>
                  )}
                </div>
              </>
            </div>
          </Modal> */}
          <Modal
            title={
              <Row
                gutter={20}
                align={"middle"}
                justify={"space-between"}
                style={{ marginRight: 0 }}
              >
                <Col span="24">
                  <>
                    <Title level={4} className={"mb-0"}>
                      {`${this.state.currentSite?.logo_company_name}`}
                    </Title>
                    <div>
                      {`${this.state.currentSite?.address_line_1} ${this.state.currentSite?.city} ${this.state.currentSite?.state} - ${this.state.currentSite?.zipcode}`}
                    </div>
                    <div>
                      <>
                        <PhoneOutlined />
                        {` ${this.formatPhoneNumber(
                          this.state.currentSite?.site_phone_number
                        )}
                        `}
                        <br />
                        <MailOutlined />{" "}
                        {` ${this.state.currentSite?.site_email}`}
                      </>
                    </div>
                  </>
                </Col>
                {/* <Col></Col> */}
              </Row>
            }
            centered
            open={this.state.showSiteDetailsModal}
            onOk={() => this.setState({ showSiteDetailsModal: false })}
            onCancel={() => this.setState({ showSiteDetailsModal: false })}
            width={1000}
            footer={null}
            // closable={false}
            maskClosable={false}
            className="childModal pn-modal-root"
            destroyOnClose={true}
            // height={600}
            style={{ zIndex: 999 }}
          >
            <div style={{ marginTop: "2x", padding: "20px" }}>
              <Row>
                <Col span="24" style={{ textAlign: "center" }}>
                  <Carousel
                    // autoplay
                    // effect="fade"
                    arrows
                    nextArrow={<RiArrowRightSLine />}
                    prevArrow={<RiArrowLeftSLine />}
                    style={{ maxHeight: "300px" }}
                  >
                    {this.state.currentSite?.imagesURL &&
                      // &&
                      //   trim(item.imagesURL) != "" &&
                      //   JSON.parse(item.imagesURL)
                      //     .filter((x) => !!x)
                      (this.state.currentSite?.imagesURL || []).map(
                        (img, idx) => (
                          <div key={idx}>
                            <Image style={contentStyle} src={img} />
                          </div>
                        )
                      )}
                  </Carousel>
                </Col>
              </Row>
              <Row style={{ paddingTop: "50px" }}>
                <Col lg={8}>
                  <Descriptions
                    title="Details"
                    bordered
                    Layout={"horizontal"}
                    column={1}
                    className={"table-layout-fixed"}
                    labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item label="Opening Hours">
                      <span>24 Hours</span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Parking fees (net)">
                      <div>12 Hours: 20$</div>
                      <div>24 Hours: 20$</div>
                      <div>Weekend: 20$</div>
                      <div>Week: 20$</div>
                      <div>Monthly: 20$</div>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col lg={8}>
                  {" "}
                  <Descriptions
                    title="Description"
                    bordered
                    Layout={"horizontal"}
                    column={1}
                    // className={"table-layout-fixed"}
                    // labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item>
                      <span>
                        The secure truck park Truck Parking Arena 31 with 130
                        spaces is located north of Toulouse in the logistics
                        area Eurocentre.A wide range of services are offered,
                        such as: B. a bar-restaurant open seven days a week from
                        6 a.m. to 10.30 p.m., a break room with TV, sanitary
                        facilities with showers and toilets, Wi-Fi, a
                        self-service laundry, bicycles and a truck service
                        station.
                      </span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col lg={8}>
                  {" "}
                  <Descriptions
                    title="Amenities"
                    bordered
                    Layout={"horizontal"}
                    column={1}
                    className={"table-layout-fixed"}
                    labelStyle={{ width: "150px" }}
                  >
                    <Descriptions.Item label="Security">
                      <span></span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Comfort">
                      <span></span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Infrastructure">
                      <span></span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Authentication">
                      <span></span>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </Row>
              <Row
                align={"middle"}
                justify={"end"}
                style={{ marginTop: "8px" }}
              >
                <Button
                  onClick={(e) => this.addReserve(this.state.currentSite)}
                  style={{
                    backgroundColor: "#464d69",
                    color: "white",
                    paddingInline: "16px",
                  }}
                >
                  Reserve
                </Button>
              </Row>
            </div>
          </Modal>
          <Modal
            title="Enter OTP"
            centered
            open={this.state.otpModalVisible}
            onOk={() => this.setOTPVisible(false)}
            onCancel={() => this.setOTPVisible(false)}
            width={400}
            height={400}
            footer={null}
            closable={false}
            maskClosable={false}
            style={{ zIndex: 1100 }}
            destroyOnClose={true}
            className="parentModal"
          >
            <div>
              <Row style={{ marginTop: "8px" }}>
                <Col span="6">
                  <span>OTP</span>
                </Col>
                <Col span="18">
                  <Input
                    style={{ width: 160 }}
                    value={this.state.OTPNumber}
                    maxLength={6}
                    onChange={(e) => this.updateOTPNumber(e)}
                  ></Input>
                </Col>
              </Row>
              <Row style={{ marginTop: "8px" }}>
                <Col span="6"></Col>
                <Col span="18" className="text-right">
                  <span>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ marginRight: "8px" }}
                      onClick={(e) => this.verifyOTP()}
                    >
                      Verify
                    </Button>
                    <Button
                      htmlType="button"
                      style={{ marginRight: "8px" }}
                      onClick={(e) => this.setOTPVisible(false)}
                    >
                      Cancel
                    </Button>
                  </span>
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </QueueAnim>
    );
  }
}

// map state to props
const mapStateToProps = (state, ownProps) => {
  const isMobile = getMobileDeviceSelector(state);
  // const vacancyData = getVacancyDataSelector(state);
  const { user, loading } = state.authUser;
  return { user, isMobile, loading };
};
const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ...sessionDataActions,
      ...actionActions,
      ...vacancyActions,
      signinUser,
      setFeatures,
    },
    dispatch
  ),
});

// export default withAuth0(
//   connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(
//     GoogleApiWrapper({
//       apiKey: "AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM",
//     })(Login)
//   )
// );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));

// (
//   GoogleApiWrapper({
//     apiKey: "AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM",
//   })(Login)
// );
