import React from "react";
import excelImg from "../../../assets/images/webp/excel_img.webp";
import { DarkDot } from "../../common/Icon";
const Spreadsheets = () => {
  return (
    <>
      <section>
        <div className="container">
          <h2 className="text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold mt-10 sm:mt-16 xl:mt-[130px] pt-2">
            Excel spreadsheets make it difficult to scale, collaborate, and
            organize documents, as there is no document management
          </h2>
          <p className="text-base mb-0 text-[#000000] opacity-70 font-Poppins font-normal mt-4">
            Published on March: 24/2023
          </p>
          <div className="flex flex-col lg:flex-row justify-between mt-[30px] lg:pb-5 xl:pb-10">
            <div className="lg:w-[47%]">
              <img
                className="w-full  lg:max-w-full mx-auto sm:mx-0"
                src={excelImg}
                alt="excel"
              />
            </div>
            <div className="lg:w-[47%]  mt-8  lg:mt-0">
              <h2 className="font-Poppins font-bold text-[18px] lg:text-md text-black">
                TLDR - Here's what you need to know:
              </h2>
              <p className="text-base mb-0 opacity-70 font-Poppins mt-3 font-normal max-w-[720px] flex ms-2">
                <span className="mt-[9px] me-[7px]">
                  <DarkDot />
                </span>
                Excel spreadsheets make it difficult to scale, collaborate, and
                organize documents, as there is no document management
              </p>
              <p className="text-base mb-0 opacity-70 font-Poppins mt-3  font-normal max-w-[720px] flex ms-2">
                <span className="mt-[9px] me-[7px]">
                  <DarkDot />
                </span>
                On-premise TMS systems require physical servers located at your
                office, which are often costly both to purchase and maintain.
                You also need to worry about security & privacy
              </p>
              <p className="text-base mb-0 opacity-70 font-Poppins mt-3  font-normal max-w-[720px] flex ms-2">
                <span className="mt-[9px] me-[7px]">
                  <DarkDot />
                </span>
                <span>
                  The Rose Rocket TMS is cloud-based, secure & private, helps
                  streamline collaboration, and future-proof your business.
                  Learn more about cloud-based
                  <a
                    href=""
                    className="underline ms-1  hover:text-primary duration-300 ease-in-out "
                  >
                    TMS software
                  </a>
                  .
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Spreadsheets;
