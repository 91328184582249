// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SUBSCRIPTION/FETCH_REQUEST",
  SUBSCRIPTIONDATA: "SUBSCRIPTION/SUBSCRIPTIONDATA",
  DELETE_REQUEST: "SUBSCRIPTION/DELETE_REQUEST",
  INSERT_REQUEST: "SUBSCRIPTION/INSERT_REQUEST",
  UPDATE_REQUEST: "SUBSCRIPTION/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SUBSCRIPTION/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "SUBSCRIPTION/MESSAGE",
  TOKEN: "SUBSCRIPTION/TOKEN",
};

export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SUBSCRIPTIONDATA:
      return { ...state, ...action.subscriptionData };

    default:
      return state;
  }
};

export const actions = {
  getSubscriptionData: (payload) => payload,  
};
