import React from "react";
import { CustomerBenifits } from "../../common/Helper";

const BenifitsOfCustomer = () => {
  return (
    <>
      <div className="bg-[#F8F8F8] py-[50px] md:pt-[100px] md:pb-[119px]">
        <div className="container">
          <h2 className="font-Manrope font-bold text-[#020202] text-[24px] sm:text-[32px] text-center">
            Benefits of as a our customer
          </h2>
          <div className="flex flex-row flex-wrap -ml-[12px] -mr-[12px] justify-center">
            {CustomerBenifits.map((object, index) => {
              return (
                <div
                  className="w-2/4 md:w-1/4 pl-3 pr-3 xl:mt-[80px] md:mt-[70px] mt-[30px]"
                  key={index}
                >
                  <div className="flex flex-col justify-center items-center hover:-translate-y-3 duration-300">
                    <span className="inline-block mb-4 ">
                      {object.customerIcons}
                    </span>
                    <p className="font-Manrope font-bold text-[#020202] text-center text-[18px] lg:text-md md:px-4">
                      {object.heading}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default BenifitsOfCustomer;
