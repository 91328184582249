export const types = {
  FETCH_DATA_REQUEST: "REFERENCEDATA/FETCH_REQUEST",
  REFERENCEDATA: "REFERENCEDATA/DATA",
};

// const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };

const initialState = {
  data: {
    sites: [],
    siteSpaces: [],
    spaceTypes: [],
    roles: [],
    abilities: [],
    subscriptionTypes: [],
    subscriptionStatus: [],
    subscriptionRequestStatus: [],
    refundStatus: [],
    paymentStatus: [],
    invoiceStatus: [],
    documentTypes: [],
    userTypes: [],
    users: [],
    vehicles: [],
    unitTypes: [],
    cards: [],
    documents: [],
    paymentMethods: [],
    spaceUsuageDetails: [],
    adminSites: [],
    layouts: [],
  },
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.REFERENCEDATA:
      return { ...state, ...action.referenceData };

    default:
      return state;
  }
};

export const actions = {
  getReferenceData: (payload) => payload,
};
