// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "CUSTOMER/FETCH_REQUEST",
  FETCH_CUSTOMER_ONLY_DATA_REQUEST: "CUSTOMER/FETCH_CUSTOMER_ONLY_DATA_REQUEST",
  CUSTOMERDATA: "CUSTOMER/CUSTOMERDATA",
  DELETE_REQUEST: "CUSTOMER/DELETE_REQUEST",
  INSERT_REQUEST: "CUSTOMER/INSERT_REQUEST",
  UPDATE_REQUEST: "CUSTOMER/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "CUSTOMER/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "CUSTOMER/MESSAGE",
  TOKEN: "CUSTOMER/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
  message: "",
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  switch (action.type) {
    case types.CUSTOMERDATA:
      return { ...state, ...action.customerData };
    case types.MESSAGE:
      return { ...state, ...action.message };

    default:
      return state;
  }
};

export const actions = {
  getCustomerData: (payload) => payload,
  getCustomerOnlyData: (payload) => payload,
  insertUpdateCustomer: (payload) => payload,
};
