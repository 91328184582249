// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SPACETYPERATES/FETCH_REQUEST",
  SPACETYPERATESDATA: "SPACETYPERATES/SPACETYPERATESDATA",
  DELETE_REQUEST: "SPACETYPERATES/DELETE_REQUEST",
  INSERT_REQUEST: "SPACETYPERATES/INSERT_REQUEST",
  UPDATE_REQUEST: "SPACETYPERATES/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SPACETYPERATES/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "SPACETYPERATES/MESSAGE",
  TOKEN: "SPACETYPERATES/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.SPACETYPERATESDATA:
      return { ...state, ...action.spaceTypeRatesData };

    default:
      return state;
  }
};

export const actions = {
  getSpaceTypeRatesData: (payload) => payload,
  insertUpdateSpaceType: (payload) => payload,
  deleteSpaceType: (payload) => payload,
};
