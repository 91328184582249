import React from "react";
import Management from "../../components/ForTruckDrivers/blog-detail/Management";
import OtherBlogPost from "../../components/ForTruckDrivers/blog-detail/OtherBlogPost";

const BlogDetails = () => {
  return (
    <>
      <Management />
      <OtherBlogPost />
    </>
  );
};

export default BlogDetails;
