// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "REPORT/FETCH_REQUEST",
  REPORTDATA: "REPORT/REPORTDATA",
  DELETE_REQUEST: "REPORT/DELETE_REQUEST",
  INSERT_REQUEST: "REPORT/INSERT_REQUEST",
  UPDATE_REQUEST: "REPORT/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "REPORT/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "REPORT/MESSAGE",
  TOKEN: "REPORT/TOKEN",
  SET_ACTIVEREPORT_REQUEST: "REPORT/SET_ACTIVEREPORT_REQUEST",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: { rptType: "inv" },
  expenseCategories: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  switch (action.type) {
    case types.REPORTDATA:
      return { ...state, ...action.reportData };

    default:
      return state;
  }
};

export const actions = {
  getReportData: (payload) => payload,
  setActiveReportData: (payload) => payload,
};
