import React from "react";
import CommonHero from "../../components/common/CommonHero";
import Spreadsheets from "../../components/ForTruckDrivers/blog/Spreadsheets";
import BlogCards from "../../components/ForTruckDrivers/blog/BlogCards";
const Blog = () => {
  const commonHero = {
    heading: "Blogs",
    herobgImg: "bg-blog",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <Spreadsheets />
      <BlogCards />
    </>
  );
};

export default Blog;
