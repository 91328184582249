import { createStore, applyMiddleware, compose } from "redux";
import Thunk from "redux-thunk";
import reducers from "../reducers";
import createSagaMiddleware from "redux-saga";
import rootSaga from "../sagas/index";
import { types as referenceDataTypes } from "../reducers/referenceDataReducer";
import { isNil } from "lodash";

export class GlobalStoreContainer {
  static store;
}

export function configureStore(initialState) {
  debugger;
  window.process = { ...window.process };
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(sagaMiddleware, Thunk))
  );

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  GlobalStoreContainer.store = store;
  window["store"] = GlobalStoreContainer.store;
  window["getState"] = window["store"].getState;

  let session_user_id = localStorage.getItem("session_user_id");
  let session_id = "";
  // let role = 4;
  if (isNil(session_user_id)) {
    session_user_id = window.getState().authUser.user.id;
    // role = window.getState().authUser.user.role;
  }
  //  // debugger;
  console.log("Window getState", window.getState());
  if (session_id === "" && window.getState() && window.getState().sessionData) {
    session_id = window.getState().sessionData?.data.sessionId;
  }

  if (session_id === "" || isNil(session_id)) {
    session_id = localStorage.getItem("session_id");
  }
  // then run the saga
  sagaMiddleware.run(rootSaga);

  // if (!isNil(session_user_id)) {
  //   store.dispatch({
  //     type: referenceDataTypes.FETCH_DATA_REQUEST,
  //     payload: {
  //       parms: [session_user_id, "Y", session_id],
  //     },
  //   });
  // }
  return store;
}
