import React from "react";
import { Avatar } from "antd";
import {
  CustomerServiceOutlined,
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  DownloadOutlined,
  SettingOutlined,
  UserOutlined,
  UploadOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  CheckCircleOutlined,
  PieChartOutlined,
  FieldTimeOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  MailOutlined,
  MessageOutlined,
  SendOutlined,
  WechatOutlined,
  PlusOutlined,
  VideoCameraOutlined,
  CloseOutlined,
  RetweetOutlined,
  CalendarOutlined,
  PhoneOutlined,
  EyeOutlined,
  PaperClipOutlined,
  AntDesignOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
const TestimonailsCards = ({ obj, index }) => {
  return (
    <>
      <div data-aos="fade-up" data-aos-delay={`${2 + index}00`}>
        <div
          className="py-9 px-7 border border-[rgba(2,2,2,0.1)] rounded-[9px] testimonials-card hover:border-transparent  transition-all ease-in-out duration-500 w-full cursor-pointer"
          style={{ height: "100%" }}
        >
          <div className="flex items-center mb-3">
            <span>
              <Avatar
                className="mr-[26px]"
                size={80}
                icon={<AntDesignOutlined />}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                // src={this.state.currentUser?.user_profile_url}
              />
              {/* <img
                className="mr-[26px]  "
                src={obj.profileImg}
                alt="testimonials1"
              /> */}
            </span>
            <div>
              <h3 className="text-[18px] md:text-md lg:text-lg font-medium font-Manrope  leading-[33px]">
                {obj.heading}{" "}
              </h3>
              <p className="font-base fontno font-Poppins  opacity-50 leading-[160%] ">
                {obj.designation}
              </p>
            </div>
          </div>
          <div>
            <p className="font-base fontno font-Poppins  opacity-70 leading-[160%] ">
              {obj.description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonailsCards;
