// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "REQUESTS/FETCH_REQUEST",
  REQUESTSDATA: "REQUESTS/REQUESTSDATA",
  DELETE_REQUEST: "REQUESTS/DELETE_REQUEST",
  INSERT_REQUEST: "REQUESTS/INSERT_REQUEST",
  UPDATE_REQUEST: "REQUESTS/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "REQUESTS/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "REQUESTS/MESSAGE",
  TOKEN: "REQUESTS/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.REQUESTSDATA:
      return { ...state, ...action.requestsData };

    default:
      return state;
  }
};

export const actions = {
  getRequestsData: (payload) => payload,
  insertUpdateRequest: (payload) => payload,
  deleteRequest: (payload) => payload,
};
