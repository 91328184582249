import React from "react";
import PropTypes from "prop-types";
import { Button, Col, Input, InputNumber, Row, Modal, Card } from "antd";

const defaultModalProps = {
  title: "Enter OTP",
  centered: true,
  visible: false,
  width: 400,
  height: 400,
  footer: null,
  closable: false,
  maskClosable: false,
  style: { zIndex: 999 },
  loading: false,
};

const OtpVerifyModal = ({ onVerify, loading, ...rest }) => {
  const props = { ...defaultModalProps, ...rest };
  const inputRef = React.useRef();

  const handleVerifyOtp = () => {
    debugger;
    if (onVerify) {
      onVerify(inputRef.current.input.value);
    }
  };

  return (
    <Modal {...props} className="parentModal">
      <Row style={{ marginTop: "8px" }}>
        <Col span="6">
          <span>OTP</span>
        </Col>
        <Col span="18">
          <Input
            type="number"
            style={{ width: 160 }}
            maxLength={6}
            ref={inputRef}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "8px" }}>
        <Col span="6"></Col>
        <Col span="18" className="text-right">
          <span>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "8px" }}
              onClick={handleVerifyOtp}
              loading={loading}
            >
              Verify
            </Button>
            <Button
              htmlType="button"
              style={{ marginRight: "8px" }}
              onClick={props.onCancel}
            >
              Cancel
            </Button>
          </span>
        </Col>
      </Row>
    </Modal>
  );
};
OtpVerifyModal.propTypes = {
  title: PropTypes.string,
  onVerify: PropTypes.func,
  visible: PropTypes.bool,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
};
export default React.memo(OtpVerifyModal);
