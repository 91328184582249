import React from "react";
import Hero from "../../components/ForTruckDrivers/homepage/Hero";
import FindLocation from "./../../components/common/FindLocation";
import AboutUs from "../../components/ForTruckDrivers/homepage/AboutUs";
import ParkingSpace from "../../components/ForTruckDrivers/homepage/ParkingSpace";
import WhatWeOffer from "../../components/ForTruckDrivers/homepage/WhatWeOffer";
import CollaborateInnovate from "../../components/ForTruckDrivers/homepage/CollaborateInnovate";
import LicensePlateRecognition from "../../components/ForTruckDrivers/homepage/LicensePlateRecognition";
import UseParkingForBetterSecurity from "../../components/ForTruckDrivers/homepage/UseParkingForBetterSecurity";
import Testimonials from "../../components/ForTruckDrivers/homepage/Testimonials";
import Faq from "./../../components/ForTruckDrivers/homepage/Faq";
import Process from "./Process";
import HowItWork from "./HowItWork";
import Surveillance from "../../components/ForTruckDrivers/homepage/Surveillance";
import Facility from "../../components/ForTruckDrivers/homepage/Facility";

const Homepage = () => {
  return (
    <>
      <Hero />
      <FindLocation />
      {/* <AboutUs /> */}
      <HowItWork />
      {/* <Process /> */}
      {/* <ParkingSpace /> */}
      <WhatWeOffer />
      <CollaborateInnovate />
      <Surveillance />
      <LicensePlateRecognition />
      {/* <Facility /> */}
      <UseParkingForBetterSecurity />
      <Testimonials />
      <Faq />
    </>
  );
};

export default Homepage;
