import React from "react";
import Search from "../../components/ForTruckDrivers/search/Search";
import FindLocation from "../../components/common/FindLocation";

const SearchPage = () => {
  return (
    <>
      <FindLocation />
      {/* <Search /> */}
    </>
  );
};

export default SearchPage;
