import React from "react";
import professional from "../../../assets/images/webp/professional-middle.webp";
import smallProfessional from "../../../assets/images/webp/small-professional-middle.webp";

const CreatingNewopportunity = () => {
  return (
    <>
      <section className="mt-12 sm:my-12 lg:mt-[140px] xl:mb-24 ">
        <div className="container flex flex-col-reverse lg:flex-row gap-4 md:gap-6 text-center md:text-start items-center">
          <div className="w-full lg:w-[50%] xl:w-1/2 mt-10 lg:mt-0">
            <h2 className=" text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold xl:me-5">
              Creating New Opportunity for Truck Parking Enterprises
            </h2>
            <p className="text-base mt-4 mb-5 text-[#000000] opacity-70 font-Poppins">
              ParkNation are a team of industry experts who are focused on
              delivering valuable solutions for the trucking industry combining
              efficient, easy to use facilities with cutting edge technology in
              groundbreaking new ways.
            </p>
            <p className="text-base mt-4  text-[#000000] opacity-70 font-Poppins">
              With our highly professional team and staff, we are dedicated to
              promoting safe trucking operations through our high capacity
              parking solution with wide drive aisles and oversized parking
              spaces for ease of use.
            </p>
          </div>
          <div className="w-full lg:w-[45%] xl:w-1/2 relative sm:ms-0 sm:ps-10 xl:ps-0">
            <div className="mx-auto max-w-[220px] sm:max-w-[400px] lg:max-w-[381px]">
              <img
                className="shadow-[-21px_-21px_4px_0px_#EB2022,0px_4px_4px_0px_#00000040] rounded-lg md:rounded-2xl"
                src={professional}
                alt="aboutBig"
              />
              <img
                className="absolute  right-[7.5px] sm:right-14 lg:-right-10 xl:-right-2 max-w-[170px] 4xl:max-w-[200px] sm:max-w-[250px] md:max-w-[298px] -bottom-[19px] shadow-[0px_4px_4px_0px_#00000040] rounded-lg md:rounded-2xl"
                src={smallProfessional}
                alt="aboutsmall"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CreatingNewopportunity;
