// import defaultData from "./commonreducer";

export const types = {
  CLEAR_ERROR_REQUEST: "ACTION/CLEAR_ERROR_REQUEST",
  CLEAR_INSERTEDDATA_REQUEST: "ACTION/CLEAR_INSERTEDDATA_REQUEST",
  FETCH_SITEDATA_REQUEST: "ACTION/FETCH_SITEDATA_REQUEST",
  FETCH_SITEVACANCYDATA_REQUEST: "ACTION/FETCH_SITEVACANCYDATA_REQUEST",
  FETCH_SIGNATURE_REQUEST: "ACTION/FETCH_SIGNATURE_REQUEST",
  FETCH_INVBALDATA_REQUEST: "ACTION/FETCH_INVBALDATA_REQUEST",
  FETCH_NOTIFICATIONDATA_REQUEST: "ACTION/FETCH_NOTIFICATIONDATA_REQUEST",
  REFRESH_REFDATA_REQUEST: "ACTION/REFRESH_REFDATA_REQUEST",
  REFRESH_REFDATA_SPECIFIC_REQUEST: "ACTION/REFRESH_REFDATA_SPECIFIC_REQUEST",
  DELETE_SUBSCRIPTION_REQUEST: "ACTION/DELETE_SUBSCRIPTION_REQUEST",
  DELETE_EXPENSE_REQUEST: "ACTION/DELETE_EXPENSE_REQUEST",
  DELETE_REPORT_REQUEST: "ACTION/DELETE_REPORT_REQUEST",
  DELETE_INVOICE_REQUEST: "ACTION/DELETE_INVOICE_REQUEST",
  DELETE_VEHICLE_REQUEST: "ACTION/DELETE_VEHICLE_REQUEST",
  DELETE_DOCUMENT_REQUEST: "ACTION/DELETE_DOCUMENT_REQUEST",
  DELETE_CARD_REQUEST: "ACTION/DELETE_CARD_REQUEST",
  DELETE_USER_REQUEST: "ACTION/DELETE_USER_REQUEST",
  INSERT_UPDATE_SPACETYPE_REQUEST: "ACTION/INSERT_UPDATE_SPACETYPE_REQUEST",
  INSERT_UPDATE_SITETYPE_REQUEST: "ACTION/INSERT_UPDATE_SITETYPE_REQUEST",
  INSERT_UPDATE_VEHICLE_REQUEST: "ACTION/INSERT_UPDATE_VEHICLE_REQUEST",
  INSERT_UPDATE_AFFLIATE_REQUEST: "ACTION/INSERT_UPDATE_AFFLIATE_REQUEST",
  INSERT_UPDATE_DOCUMENT_REQUEST: "ACTION/INSERT_UPDATE_DOCUMENT_REQUEST",
  DELETE_ADMINSITE_REQUEST: "ACTION/DELETE_ADMINSITE_REQUEST",
  INSERT_UPDATE_CARD_REQUEST: "ACTION/INSERT_UPDATE_CARD_REQUEST",
  INSERT_UPDATE_CUSTOMER_REQUEST: "ACTION/INSERT_UPDATE_CUSTOMER_REQUEST",
  INSERT_UPDATE_INVOICE_REQUEST: "ACTION/INSERT_UPDATE_INVOICE_REQUEST",
  INSERT_UPDATE_ADMINSITE_REQUEST: "ACTION/INSERT_UPDATE_ADMINSITE_REQUEST",
  INSERT_UPDATE_PROFILE_REQUEST: "ACTION/INSERT_UPDATE_PROFILE_REQUEST",
  INSERT_UPDATE_SPACETYPERATE_REQUEST:
    "ACTION/INSERT_UPDATE_SPACETYPERATE_REQUEST",
  INSERT_UPDATE_LAYOUT_REQUEST: "ACTION/INSERT_UPDATE_LAYOUT_REQUEST",
  INSERT_UPDATE_SUBSCRIPTION_REQUEST:
    "ACTION/INSERT_UPDATE_SUBSCRIPTION_REQUEST",
  UPDATE_NOTIFICATIONS_REQUEST: "ACTION/UPDATE_NOTIFICATIONS_REQUEST",
  NETWORKDATA: "ACTION/NETWORKDATA",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.NETWORKDATA:
      return { ...state, ...action.networkData };

    default:
      return state;
  }
};

export const actions = {
  // getDashBoardData: (payload) => payload,
  insertUpdateSpaceType: (payload) => payload,
  insertUpdateSiteType: (payload) => payload,
  insertUpdateVehicle: (payload) => payload,
  insertUpdateAffiliate: (payload) => payload,
  insertUpdateAdminSite: (payload) => payload,
  insertUpdateCustomer: (payload) => payload,
  insertUpdateSubscription: (payload) => payload,
  insertUpdateDocument: (payload) => payload,
  insertUpdateCard: (payload) => payload,
  deleteSubscription: (payload) => payload,
  insertUpdateInvoice: (payload) => payload,
  insertUpdateSpaceRateType: (payload) => payload,
  deleteInvoice: (payload) => payload,
  deleteVehicle: (payload) => payload,
  deleteReport: (payload) => payload,
  deleteAffiliate: (payload) => payload,
  deleteUser: (payload) => payload,
  clearError: (payload) => payload,
  deleteDocument: (payload) => payload,
  deleteCard: (payload) => payload,
  refreshReferenceData: (payload) => payload,
  refreshReferenceSpecificData: (payload) => payload,
  deleteAdminSite: (payload) => payload,
  updateUserLayoutPreferences: (payload) => payload,
  getInvoiceDataForPay: (payload) => payload,
  insertUpdateProfile: (payload) => payload,
  getPNSignature: (payload) => payload,
  getNotificationsData: (payload) => payload,
  updateNotifications: (payload) => payload,
  clearInsertedData: (payload) => payload,
  getSitesData: (payload) => payload,
};
