// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "AI/FETCH_REQUEST",
  AIDATA: "AI/AIDATA",
  DELETE_REQUEST: "AI/DELETE_REQUEST",
  INSERT_REQUEST: "AI/INSERT_REQUEST",
  UPDATE_REQUEST: "AI/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "AI/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "AI/MESSAGE",
  TOKEN: "AI/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.AIDATA:
      return { ...state, ...action.agingInvoicesData };

    default:
      return state;
  }
};

export const actions = {
  getAIData: (payload) => payload,
};
