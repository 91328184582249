/**
 * App Reducers
 */
export const types = {
  CLEAR_ALL_REDUCERS_DATA: "CLEAR_ALL_REDUCERS_DATA",
};

import { combineReducers } from "redux";
import settings from "./settings";
import authUser from "./AuthUserReducer";
import swalOptions from "./SwalReducer";
import swalInputOptions from "./SwalInputReducer";
import dashBoard from "./dashboardReducer";
import customers from "./customerReducer";
import subscriptionsData from "./subscriptionsReducer";
import subscription from "./subscriptionReducer";
import referenceData from "./referenceDataReducer";
import insertedData from "./insertedDataReducer";
import dbActions from "./actionReducer";
import invoicesData from "./invoicesReducer";
import statementData from "./statementreducer";
import custStatementData from "./custstatementreducer";
import payments from "./paymentsreducer";
import vehiclesData from "./vehicleDataReducer";
import errorData from "./errorDataReducer";
import siteData from "./sitereducer";
import spaceTypesData from "./spacetypesreducer";
import sessionData from "./sessionreducer";
import spaceTypeRatesData from "./spacetyperatesreducer";
import emailInvoiceData from "./emailinvoicereducer";
import notificationsData from "./notificationreducer";
import ccData from "./ccreducer";
import vehicleData from "./vehiclereducer";
import affiliateData from "./affiliatereducer";
import requestsData from "./requestsreducer";
import metricsData from "./metricsreducer";
import mobileDevice from "./mobileReducer";
import entitlementsData from "./entitlementsreducer";
import lprData from "./lprreducer";
import agingInvoicesData from "./aginginvoicereducer";
import vacancyData from "./vacancyDataReducer";
import spaceAvailableData from "./spaceavailabledatareducer";
import reservationsData from "./reservationsreducer";
import expensesData from "./expensesReducer";
import networkData from "./actionReducer";
import reportsData from "./reportsReducer";
import reportData from "./reportReducer";
import documentData from "./documentreducer";
import siteDocumentData from "./sitedocumentreducer";

const reducers = combineReducers({
  settings,
  authUser,
  swalOptions,
  swalInputOptions,
  dashBoard,
  customers,
  subscriptionsData,
  subscription,
  referenceData,
  insertedData,
  vehiclesData,
  dbActions,
  invoicesData,
  errorData,
  siteData,
  spaceTypesData,
  payments,
  statementData,
  custStatementData,
  sessionData,
  spaceTypeRatesData,
  emailInvoiceData,
  notificationsData,
  ccData,
  vehicleData,
  lprData,
  affiliateData,
  requestsData,
  metricsData,
  entitlementsData,
  mobileDevice,
  agingInvoicesData,
  vacancyData,
  spaceAvailableData,
  reservationsData,
  expensesData,
  networkData,
  reportsData,
  reportData,
  documentData,
  siteDocumentData,
});

const rootReducer = (state, action) => {
  // debugger;
  switch (action.type) {
    case types.CLEAR_ALL_REDUCERS_DATA:
      console.log("CLEAR_ALL_REDUCERS_DATA");
      // return reducers(state, action);
      return (state = undefined);
    default:
      return reducers(state, action);
  }
};
export default rootReducer;
