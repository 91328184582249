import React from "react";
import CommonHero from "../../components/common/CommonHero";
import SeamlessPayment from "../../components/ForSiteOwner/fintech/SeamlessPayment";
import EveryFeature from "../../components/ForSiteOwner/fintech/EveryFeature";
import ConnectAnyAccount from "../../components/ForSiteOwner/fintech/ConnectAnyAccount";
import EffortlesslyTrack from "../../components/ForSiteOwner/fintech/EffortlesslyTrack";
import ExploreMoreBenefits from "../../components/ForSiteOwner/fintech/ExploreMoreBenefits";
import IntegrationsPayment from "../../components/ForSiteOwner/fintech/IntegrationsPayment";
import Faq from "../../components/ForSiteOwner/fintech/Faq";

const Fintech = () => {
  const commonHero = {
    heading: "Fintech",
    herobgImg: "bg-fintech_img",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <SeamlessPayment />
      <EveryFeature />
      <ConnectAnyAccount />
      <EffortlesslyTrack />
      <ExploreMoreBenefits />
      <IntegrationsPayment />
      <Faq />
    </>
  );
};

export default Fintech;
