// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "VACANCY/FETCH_REQUEST",
  VACANCYDATA: "VACANCY/VACANCYDATA",
  DELETE_REQUEST: "VACANCY/DELETE_REQUEST",
  INSERT_REQUEST: "VACANCY/INSERT_REQUEST",
  UPDATE_REQUEST: "VACANCY/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "VACANCY/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "VACANCY/MESSAGE",
  TOKEN: "VACANCY/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.VACANCYDATA:
      return { ...state, ...action.vacancyData };

    default:
      return state;
  }
};

export const actions = {
  getVacancyData: (payload) => payload,
};
