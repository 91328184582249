/* eslint-disable no-unused-vars */

import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import AppConfig from "../constants/AppConfig";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
//import io from 'socket.io-client';
//   import SockJsClient from 'react-stomp';
import * as uuid from "uuid";

import { delay, buffers, eventChannel, END } from "redux-saga";
import * as _ from "lodash";
//   import * as io from "socket.io-client";
import { types as spaceTypeTypes } from "../reducers/spacetypesreducer";
// import * as utils from "../utils/common";
// import * as moment from 'moment';
//   import Singleton from '../socket';
import { isNil, trim, sortBy } from "lodash";
import { hasErrors } from "./commonSagas";
import { PNfetch } from "./../api/pnfetch";
import { types as referenceDataTypes } from "../reducers/referenceDataReducer";
import { cloneDeep } from "lodash";
//import { push } from 'react-router-redux';
//let socketRef;

const defaultData = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

const defaultSagaData = {
  data: [],
  message: "",
  loading: false,
  hasLoaded: true,
  hasError: false,
  lastFetchSuccess: Date.now(),
};

const headersObj = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};
const restApi = {
  getData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_spaceTypesData",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  insertData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_space_types",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  deleteData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spd_space_types",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
};

function statusHelper(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
    //throw Error(response);
  }
  return response;
}

function* getData(userData) {
  try {
    //yield call(delay, 5000)
    yield put({
      type: spaceTypeTypes.SPACETYPESDATA,
      spaceTypesData: {
        ...defaultData,
        lastFetchSuccess: Date.now(),
      },
    });
    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: spaceTypeTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.getData, userData.parms);
    if (!isNil(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      // const dataEnriched = sortBy(
      //   data.map((rec) => ({
      //     key: uuid.v4(),
      //     searchText: rec.name + "~" + rec.description,
      //     ...rec,
      //   })),
      //   ["name"]
      // );

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,
        ...rec,
      }));

      yield put({
        type: spaceTypeTypes.SPACETYPESDATA,
        spaceTypesData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      //Refreshing the spaceTypes in referenceData
      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);
      referenceData.spaceTypes = dataEnriched;

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceData),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: spaceTypeTypes.MESSAGE,
        message: "",
      });
    }
  } catch (e) {
    yield put({ type: spaceTypeTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: spaceTypeTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* insertData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: spaceTypeTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertData, userData.parms);
    if (!isNil(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,
        ...rec,
      }));

      // const dataEnriched = sortBy(
      //   data.map((rec) => ({
      //     key: uuid.v4(),
      //     searchText: rec.name + "~" + rec.description,
      //     ...rec,
      //   })),
      //   ["name"]
      // );

      yield put({
        type: spaceTypeTypes.SPACETYPESDATA,
        spaceTypesData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      //Refreshing the spaceTypes in referenceData
      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);
      referenceData.spaceTypes = dataEnriched;

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceData),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: spaceTypeTypes.MESSAGE,
        message: "",
      });
    }
  } catch (e) {
    yield put({ type: spaceTypeTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: spaceTypeTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* deleteData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: spaceTypeTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.deleteData, userData.parms);
    if (!isNil(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,
        ...rec,
      }));

      // const dataEnriched = sortBy(
      //   data.map((rec) => ({
      //     key: uuid.v4(),
      //     searchText: rec.name + "~" + rec.description,
      //     ...rec,
      //   })),
      //   ["name"]
      // );

      yield put({
        type: spaceTypeTypes.SPACETYPESDATA,
        spaceTypesData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });

      //Refreshing the spaceTypes in referenceData
      const getReferenceData = (state) => state.referenceData.data;
      const referenceData = yield select(getReferenceData);
      referenceData.spaceTypes = dataEnriched;

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: cloneDeep(referenceData),
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: spaceTypeTypes.MESSAGE,
        message: "",
      });
    }
  } catch (e) {
    yield put({ type: spaceTypeTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: spaceTypeTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

export function* handleRequest(action) {
  console.log("authSaga request", action);
  console.log(action.payload);
  //yield put({ type: "ITEMS_IS_LOADING", isLoading: true });
  //yield call(updateStatus);
  try {
    switch (action.type) {
      case spaceTypeTypes.FETCH_DATA_REQUEST: {
        const fetchTask = yield fork(getData, action.payload);
        break;
      }
      case spaceTypeTypes.INSERT_UPDATE_DATA_REQUEST: {
        const fetchTask = yield fork(insertData, action.payload);
        break;
      }
      case spaceTypeTypes.DELETE_REQUEST: {
        const fetchTask = yield fork(deleteData, action.payload);
        break;
      }
      default: {
        return null;
        break;
      }
    }
  } catch (e) {
    yield put({ type: spaceTypeTypes.LOGIN_FAILURE, error: e });
  }
}
