export const formInitialValues = {
  email: "",
  phone_number: "",
  first_name: "",
  last_name: "",
  street_address: "",
  city: "",
  state: "",
  zipcode: "",
  country: "USA",
  company_name: "",
  user_id: -1,
  role: 4,
  default_site_id: undefined, //Sicklerville
  groups: "1",
  apt_suite_number: "",
  notes: "",
  user_name: "",
  password: "",
  password_confirm: "",
  phoneVerified: false,
};

export const mapContainerStyle = {
  marginTop: "12px",
  height: "200px",
};

export const PNGroups = [
  {
    id: "1",
    name: "Active",
  },
  {
    id: "2",
    name: "Dormant",
  },
];
