import React from "react";
import Versatile from "../../../assets/images/webp/truck-waiting-loading-near-warehouse 1.webp";
import { Link } from "react-router-dom";
const VersatileServices = () => {
  return (
    <>
      <section className="py-12 md:py-20 lg:py-28 xl:py-[100px] bg-[rgba(168,168,168,0.08)] overflow-x-hidden ">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center">
            <div
              className="order-2 lg:order-none"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Versatile Service
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  ParkNation is a versatile solution that can be used for the
                  full range of truck parking applications. Provide fleet
                  parking, service for individual trucks/drivers, RV parking,
                  dealerships, short or long term storage, and equipment
                  parking. Customization features allow you to adjust platform
                  performance to match your business goals and objectives.
                </p>
                <Link
                  to="/account-sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px] inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base"
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[520px]">
                <img className="w-full" src={Versatile} alt="Versatile" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VersatileServices;
