import React from "react";
import { Tab } from "@headlessui/react";
import FaqDisclosure from "../../common/FaqDisclosure";
import {
  customerOuestionsFintechPage,
  partnersOuestionsFintechPage,
  faqTabsFintechPage,
  techSupportOuestionsFintechPage,
} from "../../common/Helper";
import FaqTabs from "../../common/FaqTabs";
import { useRef } from "react";

const Faq = () => {
  const buttonRefs = useRef([]);
  const openedRef = useRef(null);
  return (
    <>
      <section className="container mx-auto xl:mb-16 text-black">
        <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-center mb-16 text-black">
          Frequently Asked Questions
        </h2>
        <Tab.Group as="div" className="xl:flex items-start justify-between ">
          <Tab.List
            as="div"
            className="flex xl:flex-col xl:max-w-[364px] w-full xl:w-[364px] overflow-scroll no-scrollbar  "
          >
            {faqTabsFintechPage &&
              faqTabsFintechPage.map((obj, index) => (
                <FaqTabs obj={obj} key={index} />
              ))}
          </Tab.List>
          <Tab.Panels className="mt-6 xl:mt-0 xl:max-w-[680px] w-full xl:w-[680px] ">
            <Tab.Panel>
              {customerOuestionsFintechPage &&
                customerOuestionsFintechPage.map((obj, index) => (
                  <FaqDisclosure
                    obj={obj}
                    key={index}
                    index={index}
                    buttonRefs={buttonRefs}
                    openedRef={openedRef}
                  />
                ))}
            </Tab.Panel>
            <Tab.Panel>
              {partnersOuestionsFintechPage &&
                partnersOuestionsFintechPage.map((obj, index) => (
                  <FaqDisclosure
                    obj={obj}
                    key={index}
                    index={index}
                    buttonRefs={buttonRefs}
                    openedRef={openedRef}
                  />
                ))}
            </Tab.Panel>
            <Tab.Panel>
              {techSupportOuestionsFintechPage &&
                techSupportOuestionsFintechPage.map((obj, index) => (
                  <FaqDisclosure
                    obj={obj}
                    key={index}
                    index={index}
                    buttonRefs={buttonRefs}
                    openedRef={openedRef}
                  />
                ))}
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </section>
    </>
  );
};

export default Faq;
