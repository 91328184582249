import React, { useState } from "react";
import { Arrows } from "../../components/common/Icon";
import { HowItWorkData } from "./../../components/common/Helper";
import { Link } from "react-router-dom";

const HowItWork = () => {
  const [indexValue, setIndexValue] = useState(0);
  const [value, setValue] = useState(HowItWorkData[indexValue]);
  const clickHandler = (index, item) => {
    setIndexValue(index);
    setValue(item);
  };
  return (
    <>
      <section className="bg-[#F8F8F8]">
        <div
          className="container py-12 md:py-14 lg:py-[100px] "
          data-aos="zoom-in"
          data-aos-delay="200"
        >
          <h2 className=" text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold text-center">
            How it works
          </h2>
          <div className="bg-[#ffffff] rounded-[20px] p-4 sm:p-5 lg:px-7 lg:py-6 lg:mt-[60px] mt-6 sm:mt-8 ">
            <h4 className="text-black text-md lg:text-lg font-bold font-Manrope">
              Seacrh For a space
            </h4>
            <div className="relative ">
              <div className="flex justify-between  after:absolute after:bottom-0 after:left-0 after:bg-[#BEC2CA] after:h-[1px] after:w-full !transition-all duration-300  mt-[30px] overflow-x-scroll no-scrollbar ">
                {HowItWorkData &&
                  HowItWorkData.map((item, index) => {
                    return (
                      <div
                        className={`flex items-center justify-between cursor-pointer relative  pb-3  ${
                          item.heading === value.heading
                            ? "  after:absolute after:bottom-0  after:z-20 after:left-0 after:bg-primary after:h-[1px]   after:w-full !transition-all duration-300   "
                            : ""
                        } ${index <= 3 ? "me-4" : ""}`}
                        key={index}
                        onClick={() => clickHandler(index, item)}
                      >
                        <p
                          className={`font-Poppins font-semibold text-base  whitespace-nowrap  ${
                            item.heading === value.heading
                              ? " text-primary  "
                              : "text-black "
                          }${index <= 3 ? "me-[40px]" : ""}`}
                        >
                          {item.heading}
                        </p>
                        <span>{index <= 3 ? <Arrows /> : ""}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="text-center sm:text-start sm:flex mt-9 ">
              <span className="px-2 xs:px-4 lg:px-[46px] icon_size_48 inline-block">
                {value.icon}
              </span>
              <p className="max-w-[823px] mt-4 sm:mt-0 font-Poppins text-xs xs:text-base ">
                {value.para}
              </p>
            </div>
            <div className="  flex sm:justify-end mt-4">
              <Link
                to="/contact-us"
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                }}
                className="first_btn inline-block  py-[9px] px-[18px] sm:py-[14px] sm:px-[26px]  font-Manrope text-base font-semibold lg:mt-[7px] mx-auto sm:mx-0"
              >
                Join Now
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowItWork;
