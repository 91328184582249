// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SITEDOCUMENT/FETCH_REQUEST",
  SITEDOCUMENTDATA: "SITEDOCUMENT/SITEDOCUMENTDATA",
  DELETE_REQUEST: "SITEDOCUMENT/DELETE_REQUEST",
  // INSERT_UPDATE_DOCUMENT_REQUEST: "SITEDOCUMENT/INSERT_UPDATE_DOCUMENT_REQUEST",
  UPDATE_REQUEST: "SITEDOCUMENT/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SITEDOCUMENT/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "SITEDOCUMENT/MESSAGE",
  TOKEN: "SITEDOCUMENT/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.SITEDOCUMENTDATA:
      return { ...state, ...action.siteDocumentData };

    default:
      return state;
  }
};

export const actions = {
  getSiteDocumentsData: (payload) => payload,
  insertUpdateSiteDocument: (payload) => payload,
  deleteSiteDocument: (payload) => payload,
};
