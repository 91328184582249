import React from "react";
import { blogcards } from "../../common/Helper";
import BlogCommonCard from "../../common/BlogCommonCard";

const OtherBlogPost = () => {
  return (
    <>
      <section>
        <div className="container">
          <h2 className="block text-lg md:text-[32px] lg:text-xl text-black font-Manrope font-bold mt-7 sm:mt-9 md:mt-10 xl:mt-[140px] text-center">
            Other blog posts
          </h2>
          <div className="flex justify-center flex-wrap sm:grid sm:grid-cols-2 gap-6 lg:grid-cols-3 mt-[40px]">
            {blogcards &&
              blogcards.map((obj, index) => (
                <div className=" w-full" key={index}>
                  <BlogCommonCard obj={obj} />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OtherBlogPost;
