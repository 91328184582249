import React from "react";
import { Link } from "react-router-dom";

const UseParkingForBetterSecurity = () => {
  return (
    <>
      <section className="bg-UseParkingForBetterSecurity bg-no-repeat bg-cover min-h-[344px] md:min-h-[688px] flex items-center mb-12 sm:mb-20 md:mb-28 lg:mb-[120px] overflow-x-hidden">
        <div className="container pb-16 md:pb-0">
          <div data-aos="fade-right" data-aos-delay="200">
            <h2 className="text-[32px] md:text-xl lg:text-2xl text-black font-bold font-Manrope leading-[130%] max-w-[586.09px] ">
              Use our <span className="text-primary">parking</span> for <br />
              better security
            </h2>
            <Link
              to="/contact-us"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
              className="p-[8px_16px] inline-block sm:p-[14px_26px] first_btn font-semibold font-Manrope whitespace-nowrap font-base mt-[20px]"
            >
              Contact Now{" "}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default UseParkingForBetterSecurity;
