/* eslint-disable no-unused-vars */

import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  // eslint-disable-next-line no-unused-vars
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
//import io from 'socket.io-client';
//   import SockJsClient from 'react-stomp';
import * as uuid from "uuid";

import { delay, buffers, eventChannel, END } from "redux-saga";
import * as _ from "lodash";
//   import * as io from "socket.io-client";
import { types as referenceDataTypes } from "../reducers/referenceDataReducer";
import { types as vehicleDataTypes } from "../reducers/vehicleDataReducer";
// import * as utils from "../utils/common";
// import * as moment from 'moment';
//   import Singleton from '../socket';
import { isNil, trim, sortBy } from "lodash";
import { titleCase } from "../util/common";
import { types as sessionTypes } from "../reducers/sessionreducer";
//import { push } from 'react-router-redux';
//let socketRef;
import AppConfig from "../constants/AppConfig";
import { PNfetch } from "./../api/pnfetch";

const defaultSagaData = {
  data: [],
  message: "",
  loading: false,
  hasLoaded: true,
  hasError: false,
  lastFetchSuccess: Date.now(),
};

const headersObj = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};

// const api = {
//   URL:
//     process.env.NODE_ENV == "production"
//       ? `https://dev.parknation.org/services/sql/execSP`
//       : `http://localhost:8080/sql/execSP`,
// };

const restApi = {
  getData(parms) {
    //
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_Reference_Data",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  getAllMakes(parms) {
    //
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    //https://vpic.nhtsa.dot.gov/api/vehicles/getallmakes?format=json
    return (
      fetch("/nhtsaApi?format=json", {
        method: "GET",
        // headers: {
        //   Accept: "application/json",
        //   "Content-Type": "application/json",
        // },
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
};

function statusHelper(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
    //throw Error(response);
  }
  return response;
}

function* getAllMakes(userData) {
  try {
    //yield call(delay, 5000)
    //  debugger;
    let resultObj = yield call(restApi.getAllMakes, userData.parms);
    //  debugger;
    console.log("resultObj", resultObj);
    if (!isNil(resultObj)) {
      // resultObj = JSON.parse(resultObj);
      //  debugger;
      // const data =
      //   resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;
      const data = resultObj.Results;
      const dataEnriched = sortBy(
        data.map((rec) => ({
          key: uuid.v4(),
          ...rec,
          Make_Name: titleCase(rec.Make_Name),
        })),
        ["Make_Name"]
      );

      let vehilceDataEnriched = {};
      vehilceDataEnriched.allMakes = dataEnriched;

      yield put({
        type: vehicleDataTypes.VEHICLESDATA,
        vehiclesData: {
          ...defaultSagaData,
          data: vehilceDataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      // yield put({
      //   type: dashboardTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    // yield put({ type: dashboardTypes.MESSAGE, message: e });
  } finally {
    // if (yield cancelled())
    //   yield put({
    //     type: dashboardTypes.MESSAGE,
    //     message: "Task Cancelled",
    //   });
  }
}
function* getData(userData) {
  try {
    //yield call(delay, 5000)
    //
    //  debugger;
    console.log(userData.parms);
    let site_id = -1,
      user_id = -1,
      session_user_id = userData.parms[0];

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })
    const sessionId = userData.parms[2];
    const parms = [session_user_id, "Y"];
    let resultObj = yield call(restApi.getData, parms);
    if (!isNil(resultObj)) {
      //
      // resultObj = JSON.parse(resultObj);

      console.log(resultObj.data);

      let refData = {};
      refData.sites = resultObj.data[0].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.siteSpaces = resultObj.data[1].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.space_name +
          "~" +
          rec.space_type_name +
          "~" +
          rec.monthly_rate +
          "~" +
          rec.daily_rate +
          "~" +
          rec.weekly_rate +
          "~" +
          rec.weekend_rate +
          "~",

        ...rec,
      }));

      refData.spaceTypes = resultObj.data[2].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,

        ...rec,
      }));
      refData.roles = resultObj.data[3].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.abilities = resultObj.data[4].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionTypes = resultObj.data[5].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionStatus = resultObj.data[6].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionRequestStatus = resultObj.data[7].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.refundStatus = resultObj.data[8].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.paymentStatus = resultObj.data[9].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      refData.invoiceStatus = resultObj.data[10].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.documentTypes = resultObj.data[11].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.userTypes = resultObj.data[12].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      refData.users = resultObj.data[13].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_id +
          "~" +
          rec.email +
          "~" +
          rec.phone_number +
          "~" +
          rec.first_name +
          "~" +
          rec.last_name +
          "~" +
          rec.street_address +
          "~" +
          rec.city +
          "~" +
          rec.state +
          "~" +
          rec.company_name +
          "~" +
          rec.vehicleSearch +
          "~" +
          rec.spaceNameSearch,

        ...rec,
      }));

      refData.vehicles = resultObj.data[14].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.vehicle_id +
          "~" +
          rec.user_id_fk +
          "~" +
          rec.license_num +
          "~" +
          rec.truck_num +
          "~" +
          rec.trailer_num +
          "~" +
          rec.model +
          "~" +
          rec.make +
          "~" +
          rec.color +
          "~" +
          rec.user_name +
          "~" +
          rec.mc,
        ...rec,
      }));

      refData.unitTypes = resultObj.data[15].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.cards = resultObj.data[16].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.card_brand +
          "~" +
          rec.last_4 +
          "~" +
          rec.exp_month +
          "~" +
          rec.exp_year +
          "~" +
          rec.cardholder_name,

        ...rec,
      }));

      refData.documents = resultObj.data[17].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_name +
          "~" +
          rec.file_type +
          "~" +
          rec.file_name +
          "~" +
          rec.document_type +
          "~" +
          rec.file_title,

        ...rec,
      }));

      refData.paymentMethods = resultObj.data[18].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.spaceUsuageDetails = resultObj.data[19].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.title + "~" + rec.space_name + "~" + rec.site_name,
        ...rec,
      }));

      refData.adminSites = resultObj.data[20].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.name +
          "~" +
          rec.user_role +
          "~" +
          rec.user_site_role,

        ...rec,
      }));

      refData.layouts = resultObj.data[21].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.preference_key,
        ...rec,
      }));

      refData.userSites = resultObj.data[25].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      //To populate Role
      const userObj = resultObj.data[22][0];
      yield put({
        type: sessionTypes.SESSIONDATA,
        sessionData: {
          ...defaultSagaData,
          data: {
            site_id: userObj.role == 4 ? userObj.default_site_id : -1,
            user_id: user_id, //session_user_id,
            session_user_id: session_user_id,
            role: userObj.role,
            default_site_id: userObj.default_site_id,
            userEntitlements:
              resultObj.data.length >= 24 ? resultObj.data[24] : [],
            sessionId,
          },
          lastFetchSuccess: Date.now(),
        },
      });

      refData.affilates = resultObj.data[23].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.name +
          "~" +
          rec.user_role +
          "~" +
          rec.user_site_role,

        ...rec,
      }));

      refData.PNSites = resultObj.data[26].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: refData,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "",
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

export function* handleRequest(action) {
  console.log("authSaga request", action);
  console.log(action.payload);
  //yield put({ type: "ITEMS_IS_LOADING", isLoading: true });
  //yield call(updateStatus);
  try {
    switch (action.type) {
      case referenceDataTypes.FETCH_DATA_REQUEST: {
        const fetchTask = yield fork(getData, action.payload);
        // const fetchAllMakes = yield fork(getAllMakes, action.payload);
        break;
      }

      default: {
        return null;
        // break;
      }
    }
  } catch (e) {
    yield put({ type: referenceDataTypes.LOGIN_FAILURE, error: e });
  }
}
