import React from "react";
import Customer from "./Customer";

const Howitworkpage = () => {
  return (
    <>
      <Customer />
    </>
  );
};

export default Howitworkpage;
