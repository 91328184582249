/* eslint-disable no-unused-vars */
/**
 * Auth Actions
 * Auth Action With Google, Facebook, Twitter and Github
 */
// import firebase from "firebase/app";
// import "firebase/auth";
// import API from "Api";
import { NotificationManager } from "react-notifications";
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  USER_ID,
  USER_NAME,
  LOGIN_USER_FAILURE,
  LOGOUT_USER,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  SIGNUP_USER_FAILURE,
  IS_USER,
  IS_PARENT,
} from "./types.js";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import {
  MenuOutlined,
  DollarOutlined,
  WechatOutlined,
  SendOutlined,
} from "@ant-design/icons";
// import { Icon } from "@iconify/react";
/**
 * Redux Action To Sigin User With Firebase
 */
export const signinUser = (authInfo, type, role) => (dispatch) => {
  // if (!authInfo && type !== 3){
  //   dispatch({ type: "LOADER", payload: true });
  // } else
  if (type === 1) {
    // localStorage.setItem("accessToken", authInfo.access_token);
    // localStorage.setItem("refreshToken", authInfo.refresh_token);
    // localStorage.setItem("session_user_id", authInfo.user.user_id);
    localStorage.setItem("roleId", authInfo.user.role);
    localStorage.setItem("userId", authInfo.user.user_id);
    // const name = authInfo.user.first_name + (authInfo.user.last_name || '');
    // authInfo.user.fullName = name;
    localStorage.setItem("user", JSON.stringify(authInfo.user));
    dispatch({ type: "USER", payload: authInfo.user });
    dispatch({ type: "ROLE_ID", payload: authInfo.user.role });
    dispatch({ type: "ACCESS_TOKEN", payload: authInfo.access_token });
    dispatch({ type: "REFRESH_TOKEN", payload: authInfo.refresh_token });
  } else if (type === 2) {
    dispatch({ type: LOGIN_USER_FAILURE });
  }
};

export const updateUser = (data) => (dispatch) => {
  // const name = data.first_name + (data.last_name || '');
  // data.fullName = name;
  localStorage.setItem("user", JSON.stringify(data));
  dispatch({ type: "USER", payload: data });
};

/**
 * Redux Action To get feature id of current page
 */
export const getFeatureId = (featureName) => (dispatch) => {
  const features = JSON.parse(localStorage.getItem("features")) || [];
  const featureId =
    features[features.findIndex((x) => x.name.toLowerCase() === featureName)]
      .id;
  localStorage.setItem("featureId", featureId);
  dispatch({ type: "FEATURE_ID", payload: featureId });
};

/**
 * Redux Action To Signout User From  Firebase
 */

// export const logoutUserFromFirebase = () => (dispatch) => {
//   //  // debugger;
//   // NotificationManager.success("User logged out succesfully");
//   // dispatch({ type: LOGOUT_USER });
//   // localStorage.clear();
//   // let URL = `http://localhost:8080/logout`;
//   // if (process.env.NODE_ENV == "production") {
//   //   URL = 'http://localhost:3000' +  `/logout`;
//   // }
//   let URL = process.env.REACT_APP_API_URL + `/logout`;
//   const parms = {
//     token: localStorage.getItem("accessToken"),
//   };
//   const responseObj = fetch(URL, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//       authorization: "Bearer " + localStorage.getItem("accessToken"),
//     },
//     body: JSON.stringify({
//       ...parms,
//     }),
//   })
//     .then((response) => {
//       NotificationManager.success(
//         "User logged out succesfully",
//         "Logout",
//         1200
//       );
//       // dispatch({ type: "REFRESH_TOKEN", payload: null });
//       dispatch({ type: LOGOUT_USER });
//       localStorage.clear();
//     })
//     .catch((error) => {
//       NotificationManager.error(error.message, "Error", 1200);
//     });

//   // API.post(`auth/users/${localStorage.getItem("userId")}/logout`)
//   //   .then(() => {
//   //     dispatch({ type: LOGOUT_USER });
//   //     localStorage.clear();
//   //     NotificationManager.success("User logged out succesfully");
//   //   })
//   //   .catch((error) => {
//   //     NotificationManager.error(error.message);
//   //   });
// };

export const setFeatures = (userId, roleId, userEntitlements) => (dispatch) => {
  const menu = [];
  const path = "/";

  console.log("setFeatures roleId", roleId);
  console.log("setFeatures userEntitlements", userEntitlements);

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "quick charge" &&
        rec.screen_access != "N"
    ).length > 0
  ) {
    menu.push({
      name: "Quick Charge",
      icon: "fas fa-dollar-sign",
      path: path + "quickcharge",
      isChildMenu: false,
    });
  }

  if (roleId == 4 || 1 == 1) {
    const childMenu = [];

    childMenu.push({
      name: "Chat",
      icon: "fas fa-comment-alt",
      path: path + "mainchat",
      isChildMenu: false,
    });

    childMenu.push({
      name: "Mail",
      icon: "fas fa-envelope",
      path: path + "mainmail",
      isChildMenu: false,
    });
    // childMenu.push({
    //   name: "Notes",
    //   icon: "fas fa-sticky-note",
    //   path: path + "notes",
    //   isChildMenu: false,
    // });

    menu.push({
      name: "Communication",
      icon: "fas fa-comments",
      isChildMenu: true,
      childMenu,
    });
  }

  //User Menu
  if (roleId == 4) {
    menu.push({
      name: roleId == 4 ? "My Info" : "Customers",
      icon: "fas fa-users",
      path: path + "customer",
      isChildMenu: false,
    });
  } else {
    const childMenu = [];

    childMenu.push({
      name: "Add Customer",
      icon: "fas fa-user-plus",
      path: path + "addcustomer",
      isChildMenu: false,
    });

    childMenu.push({
      name: "Directory",
      icon: "fas fa-user-friends",
      path: path + "customer",
      isChildMenu: false,
    });

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "subscriptions" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Subscriptions",
        icon: "fas fa-clock",
        path: path + "subscriptions",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "invoices" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Invoices",
        icon: "fas fa-file-invoice-dollar",
        path: path + "invoicesnew",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "transactions" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Transactions",
        icon: "fas fa-exchange-alt",
        path: path + "transactions?type=tran",
        // path: path + "transactions",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "documents" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Documents",
        icon: "fas fa-file-signature",
        path: path + "documents",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "statements" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Statements",
        icon: "fas fa-file-pdf",
        path: path + "custstatements",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "payment methods" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Payment Methods",
        icon: "fas fa-credit-card",
        path: path + "ccard",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "vehicles" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Vehicles",
        icon: "fas fa-truck",
        path: path + "vehicles",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "wait list" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 4
    ) {
      childMenu.push({
        name: "Wait List",
        icon: "fas fa-wrench",
        path: path + "requests",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "permissions" &&
          rec.screen_access != "N"
      ).length > 0
    ) {
      childMenu.push({
        name: "Permissions",
        icon: "fas fa-user-shield",
        path: path + "entitlements",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "e-vehicles" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 1
    ) {
      childMenu.push({
        name: "E-Vehicles",
        icon: "fas fa-charging-station",
        path: path + "e-vehicles",
        isChildMenu: false,
      });
    }

    menu.push({
      name: "Customers",
      icon: "fas fa-users",
      isChildMenu: true,
      childMenu,
    });
  }

  //Billing Menu
  const billingMenu = [];
  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "quick charge" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    billingMenu.push({
      name: "Quick Charge",
      icon: "fas fa-dollar-sign",
      path: path + "quickcharge",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "payment methods" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    billingMenu.push({
      name: "Payment Methods",
      icon: "fas fa-credit-card",
      path: path + "ccard",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "subscriptions" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    billingMenu.push({
      name: "Subscriptions",
      icon: "fas fa-clock",
      path: path + "subscriptions",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "invoices" && rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    billingMenu.push({
      name: "Invoices",
      icon: "fas fa-file-invoice-dollar",
      path: path + "invoicesnew",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "transactions" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    billingMenu.push({
      name: "Transactions",
      icon: "fas fa-exchange-alt",
      path: path + "transactions?type=tran",
      // path: path + "transactions",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "aging invoices report" &&
        rec.screen_access != "N"
    ).length > 0
  ) {
    billingMenu.push({
      name: "Aging Invoices",
      icon: "fas fa-file-invoice-dollar",
      path: path + "ai",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "transactions ledger" &&
        rec.screen_access != "N"
    ).length > 0
  ) {
    billingMenu.push({
      name: "Transaction Ledger",
      icon: "far fa-file-pdf",
      path: path + "statements",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "expenses" && rec.screen_access != "N"
    ).length > 0 ||
    roleId == 1
  ) {
    billingMenu.push({
      name: "Site Expenses",
      icon: "fas  fa-money-check-alt",
      path: path + "expenses",
      isChildMenu: false,
    });
  }
  menu.push({
    name: "Billing",
    icon: "fas fa-dollar-sign",
    isChildMenu: true,
    childMenu: billingMenu,
  });

  //Accounting Menu
  const accountingMenu = [];
  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "accounting" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 1
  ) {
    accountingMenu.push({
      name: "Quick Books",
      icon: "fas fa-file-invoice-dollar",
      path: path + "intuit/quickbooks",
      isChildMenu: false,
    });
  }

  menu.push({
    name: "Accounting",
    icon: "fas fa-file-invoice-dollar",
    isChildMenu: true,
    childMenu: accountingMenu,
  });

  //Space Menu
  const spaceMenu = [];
  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "spaces" && rec.screen_access != "N"
    ).length > 0
  ) {
    spaceMenu.push({
      name: "Spaces",
      icon: "fas fa-parking",
      path: path + "spaces",
      isChildMenu: false,
    });
    spaceMenu.push({
      name: "Space Cards",
      icon: "fas fa-parking",
      path: path + "spacecards",
      isChildMenu: false,
    });
    spaceMenu.push({
      name: "Space Layout",
      icon: "fas fa-parking",
      path: path + "spacelayout",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "space types" &&
        rec.screen_access != "N"
    ).length > 0
  ) {
    spaceMenu.push({
      name: "Space Types",
      icon: "fas fa-truck",
      path: path + "space_types",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "rate by space type" &&
        rec.screen_access != "N"
    ).length > 0
  ) {
    spaceMenu.push({
      name: "Rate By Space Type",
      icon: "fas fa-file-invoice-dollar",
      path: path + "space_type_rate",
      isChildMenu: false,
    });
  }

  if (spaceMenu.length > 0) {
    menu.push({
      name: "Spaces",
      icon: "fas fa-truck",
      isChildMenu: true,
      childMenu: spaceMenu,
    });
  }

  //Documents Menu
  const documentMenu = [];
  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "documents" && rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    documentMenu.push({
      name: "Customer Documents",
      icon: "fas fa-file-signature",
      path: path + "documents",
      isChildMenu: false,
    });
  }

  documentMenu.push({
    name: "Site Documents",
    icon: "fas fa-file-contract",
    path: path + "sitedocuments",
    isChildMenu: false,
  });

  menu.push({
    name: "Documents",
    icon: "fas fa-file-pdf",
    isChildMenu: true,
    childMenu: documentMenu,
  });

  //Security Menu
  const securityMenu = [];
  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "cameras" && rec.screen_access != "N"
    ).length > 0 ||
    roleId == 1
  ) {
    securityMenu.push({
      name: "Cameras",
      icon: "fas fa-camera",
      path: path + "cameras",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "lpr search" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 1
  ) {
    securityMenu.push({
      name: "LPR Search",
      icon: "fas fa-camera-retro",
      path: path + "lpr",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "kisi dashboard" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 1
  ) {
    securityMenu.push({
      name: "Kisi Dashboard",
      icon: "fas fa-lock",
      path: path + "kisi",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "permissions" &&
        rec.screen_access != "N"
    ).length > 0
  ) {
    securityMenu.push({
      name: "Permissions",
      icon: "fas fa-user-shield",
      path: path + "entitlements",
      isChildMenu: false,
    });
  }

  menu.push({
    name: "Security",
    icon: "fas fa-eye",
    isChildMenu: true,
    childMenu: securityMenu,
  });

  //Reservations Menu
  const reserveMenu = [];
  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "reservations" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    reserveMenu.push({
      name: "Reservations",
      icon: "fas fa-calendar-alt",
      path: path + "reserve",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "rent my space" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 1
  ) {
    reserveMenu.push({
      name: "Rent My Space",
      icon: "fas fa-calendar-check",
      path: path + "rentmyspace",
      isChildMenu: false,
    });
  }

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "wait list" && rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    reserveMenu.push({
      name: "Wait List",
      icon: "fas fa-wrench",
      path: path + "requests",
      isChildMenu: false,
    });
  }

  menu.push({
    name: "Reservations",
    icon: "fas fa-calendar-alt",
    isChildMenu: true,
    childMenu: reserveMenu,
  });

  if (
    userEntitlements.filter(
      (rec) =>
        rec.screen_name.toLowerCase() == "statements" &&
        rec.screen_access != "N"
    ).length > 0 ||
    roleId == 4
  ) {
    menu.push({
      name: "Reports",
      icon: "fas fa-tachometer-alt",
      isChildMenu: true,
      childMenu: [
        {
          name: "System Reports",
          icon: "fas fa-file-pdf",
          path: path + "cannedreports",
          // path: path + "transactions",
          isChildMenu: false,
        },
        {
          name: "Statements",
          icon: "fas fa-file-pdf",
          path: path + "custstatements",
          // path: path + "transactions",
          isChildMenu: false,
        },
        {
          name: "Transaction Ledger",
          icon: "far fa-file-pdf",
          path: path + "statements",
          // path: path + "transactions",
          isChildMenu: false,
        },

        {
          name: "Invoice Report(s)",
          icon: "fas fa-chart-bar",
          // path: path + "reports",
          path: path + "reports?type=inv",
          isChildMenu: false,
        },
      ],
    });
  }

  if (roleId == 1 || roleId == 3) {
    menu.push({
      name: "Admin Reports",
      icon: "fas fa-chart-pie",
      isChildMenu: true,
      childMenu: [
        {
          name: "Reports Dashboard",
          icon: "fas fa-chart-line",
          path: path + "reportsDB",
          isChildMenu: false,
        },
        {
          name: "Metrics",
          icon: "fas fa-chart-pie",
          path: path + "metrics",
          isChildMenu: false,
        },
        {
          name: "Balances",
          icon: "fas fa-money-bill",
          path: path + "balances",
          isChildMenu: false,
        },
      ],
    });

    if (roleId == 1 || 1 == 1) {
      //Show for both Super User and Site Admins
      menu.push({
        name: "Admin Dashboard",
        icon: "fas fa-tachometer-alt",
        isChildMenu: true,
        childMenu: [
          {
            name: "PN Monitor",
            icon: "fas fa-eye",
            path: path + "pnmonitor",
            isChildMenu: false,
          },
          {
            name: "Permissions",
            icon: "fas fa-user-shield",
            path: path + "entitlements",
            isChildMenu: false,
          },
          {
            name: "Kisi Dashboard",
            icon: "fas fa-lock",
            path: path + "kisi",
            isChildMenu: false,
          },
        ],
      });
    } else {
      menu.push({
        name: "Admin Dashboard",
        icon: "fas fa-tachometer-alt",
        isChildMenu: true,
        childMenu: [
          {
            name: "Permissions",
            icon: "fas fa-user-shield",
            path: path + "entitlements",
            isChildMenu: false,
          },
          {
            name: "Kisi Dashboard",
            icon: "fas fa-lock",
            path: path + "kisi",
            isChildMenu: false,
          },
        ],
      });
    }
  }

  if (roleId != 4) {
    const siteMenu = [];

    if (roleId == 1) {
      siteMenu.push({
        name: "Sites",
        icon: "fas fa-map-marker-alt",
        path: path + "sites",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "expenses" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 1
    ) {
      siteMenu.push({
        name: "Site Expenses",
        icon: "fas  fa-money-check-alt",
        path: path + "expenses",
        isChildMenu: false,
      });
    }

    if (
      userEntitlements.filter(
        (rec) =>
          rec.screen_name.toLowerCase() == "wait list" &&
          rec.screen_access != "N"
      ).length > 0 ||
      roleId == 1
    ) {
      siteMenu.push({
        name: "Wait List",
        icon: "fas fa-wrench",
        path: path + "requests",
        isChildMenu: false,
      });
    }

    menu.push({
      name: "Site Dashboard",
      icon: "fas fa-cogs",
      isChildMenu: true,
      childMenu: siteMenu,
    });
  }

  // }
  // localStorage.setItem("features", JSON.stringify(features));
  localStorage.setItem("menu", JSON.stringify(menu));
  // dispatch({ type: "FEATURES", payload: features });
  dispatch({ type: "MENU", payload: menu });
};
