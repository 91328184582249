import React, { useRef } from "react";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LeftArrowRed, RightArrowRed } from "../../common/Icon";
import { factSilderData } from "../../common/Helper";

const Factslider = () => {
  const slider = useRef(null);
  var settings = {
    dots: false,
    infinite: true,
    autoplaySpeed: 9000,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    fade: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {},
      },
      {
        breakpoint: 992,
        settings: {},
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <section>
        <div className="container relative pt-[70px] md:pt-[70px] md:pb-[10px] lg:pt-[100px] xl:pt-[140px] xl:pb-[80px]">
          <Slider ref={slider} {...settings}>
            {factSilderData &&
              factSilderData.map((obj, index) => {
                return (
                  <div key={index}>
                    <div className="flex-col lg:flex-row flex items-center gap-[38px] sm:gap-[46px] ">
                      <div className="rounded-2xl sm:w-[536px]">
                        <img
                          className="sm:w-full  rounded-2xl "
                          src={obj.sliderImg}
                          alt="facts_slider_img1"
                        />
                      </div>
                      <div className="w-full md:w-[558px]">
                        <p className="font-Poppins font-normal text-[18px]  xl:text-lg text-[#000000] leading-[158%] text-center lg:text-left">
                          {obj.description}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
          <div className="flex align-middle mt-6 lg:mt-12 gap-8 right-[45%] 2xs:right-[40%] sm:right-[45%] lg:right-[41%] xl:right-[43.50%] absolute lg:bottom-[6%] xl:bottom-[15%] ">
            <button
              className="flex w-6 h-6"
              onClick={() => slider.current.slickNext()}
            >
              <LeftArrowRed />
            </button>
            <button
              className="flex w-6 h-6"
              onClick={() => slider.current.slickPrev()}
            >
              <RightArrowRed />
            </button>
          </div>
        </div>
      </section>
    </>
  );
};

export default Factslider;
