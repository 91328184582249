import React from "react";
import CommonHero from "../../components/common/CommonHero";
import Factslider from "../../components/ForTruckDrivers/facts/Factslider";

const Fact = () => {
  const commonHero = {
    heading: "Facts",
    herobgImg: "bg-features_and_about_us_hero_img",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <Factslider />
    </>
  );
};

export default Fact;
