// check device width
const mobileDeviceSize = 768;

const onResizeWindow = 'window_resize';

const initialState = {
    width: window.innerWidth,
    height: window.innerHeight,
    isMobile: false
}

export default ( state = initialState, action ) => {
    if( action.type === onResizeWindow ){
        return {
            width: action.payload.width,
            height: action.payload.height,
            isMobile: action.payload.width <= mobileDeviceSize
        }
    }
    return {
        ...state,
        isMobile: state.width <= mobileDeviceSize
    };
}
