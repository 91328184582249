import React from "react";
import cameraSurveillance from "../../../assets/images/webp/camera-surveillance.webp";
import { ListCheck } from "./../../common/Icon";
const CameraSurveillance = () => {
  return (
    <>
      <section className="my-12 md:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[41px] items-center">
            <div data-aos="fade-right" data-aos-duration="1500">
              <div className="max-w-[580px]">
                <img
                  className="w-full"
                  src={cameraSurveillance}
                  alt="cameraSurveillance"
                />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[540px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  24*7 Camera surveillance{" "}
                </h2>
                <p className="text-base font-Poppins font-normal xl:pe-12 text-[#000] leading-[160%] opacity-70 mb-12">
                  Nibh mauris dignissim sit imperdiet adipiscing feugiat in
                  lectus. Pellentesque tortor nunc orci volutpat at adipiscing
                  donec pellentesque. Fermentum tellus iaculis quisque et turpis
                  purus.
                </p>
                <ul className="font-Poppins">
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3 text-base flex items-center">
                    <span className="me-2.5">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      Adipiscing placerat nulla cursus platea feugiat arcu.
                      Tristique.
                    </span>
                  </li>
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3 text-base flex items-center">
                    <span className="me-2.5">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      Curabitur orci eget turpis ut bibendum eget facilisis
                      pulvinar.
                    </span>
                  </li>
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3 text-base flex items-center">
                    <span className="me-2.5">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      Purus nisi vulputate etiam mauris orci faucibus ligula ut.
                      Sed.
                    </span>
                  </li>
                  <li className=" text-[#000000] font-normal leading-[160%] mb-3 text-base flex items-center">
                    <span className="me-2.5">
                      <ListCheck />
                    </span>
                    <span className="opacity-70">
                      Blandit mauris vestibulum in tincidunt nisi sagittis nisi
                      eget. Ac
                    </span>
                    .
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CameraSurveillance;
