/* eslint-disable no-unused-vars */
import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
import AppConfig from "../constants/AppConfig";
//import io from 'socket.io-client';
//   import SockJsClient from 'react-stomp';
import * as uuid from "uuid";

import { delay, buffers, eventChannel, END } from "redux-saga";
// import * as _ from "lodash-es";
//   import * as io from "socket.io-client";
import { types as reportTypes } from "../reducers/reportReducer";
// import * as utils from "../utils/common";
// import * as moment from 'moment';
//   import Singleton from '../socket';
import { isNil, trim } from "lodash";
import { getData as getReferenceData } from "./actionSaga";
import { hasErrors } from "./commonSagas";
import { PNfetch } from "../api/pnfetch";
//import { push } from 'react-router-redux';
//let socketRef;

const defaultData = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

const defaultSagaData = {
  data: [],
  message: "",
  loading: false,
  hasLoaded: true,
  hasError: false,
  lastFetchSuccess: Date.now(),
};

const headersObj = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};
const restApi = {
  getData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_ReportsData",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },

  insertData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_customers",
          input: parms,
          // "input":  {
          //   "AdminId"  : 1
          // },
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
};

function statusHelper(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
    //throw Error(response);
  }
  return response;
}

function* getData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: reportTypes.LOGIN_REQUEST, isLoading: false })

    yield put({
      type: reportTypes.REPORTDATA,
      reportData: {
        ...defaultData,
        lastFetchSuccess: Date.now(),
      },
    });

    let resultObj = yield call(restApi.getData, userData.parms);
    // if (!isNil(resultObj)) {
    if (!isNil(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      // const data =
      //   resultObj.data.length == 1 ? resultObj.data[0] : resultObj.data;

      console.log("reports Data**********", resultObj.data);
      const dataEnriched = resultObj.data[0].map((rec) => ({
        key: uuid.v4(),
        id: uuid.v4(),
        searchText:
          rec.rpt_name + "~" + rec.rpt_type + "~" + rec.rpt_email_users,

        ...rec,
      }));
      console.log("reportsEnriched Data**********", dataEnriched);

      // const expenseCategories = resultObj.data[1].map((rec) => ({
      //   key: uuid.v4(),
      //   id: uuid.v4(),
      //   ...rec,
      // }));

      yield put({
        type: reportTypes.REPORTDATA,
        reportData: {
          ...defaultSagaData,
          data: dataEnriched,
          // expenseCategories,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: reportTypes.MESSAGE,
        message: "",
      });
    }
  } catch (e) {
    yield put({ type: reportTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: reportTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* insertData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData.parms);
    // console.log(userData.payload.password);
    //yield put({ type: reportTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.insertData, userData.parms);
    if (!resultObj) {
      // resultObj = JSON.parse(resultObj);
      const data =
        resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;

      const dataEnriched = data.map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.company_name +
          "~" +
          rec.email +
          "~" +
          rec.phone_number +
          "~" +
          rec.expense_type +
          "~" +
          rec.extra_info +
          "~" +
          rec.expense_title +
          "~" +
          rec.expense_message,
        ...rec,
      }));

      yield put({
        type: reportTypes.REPORTDATA,
        reportData: {
          ...defaultSagaData,
          data: dataEnriched,
          lastFetchSuccess: Date.now(),
        },
      });
    } else {
      yield put({
        type: reportTypes.MESSAGE,
        message: "",
      });
    }
  } catch (e) {
    yield put({ type: reportTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: reportTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* updateData(userData) {
  try {
    //yield call(delay, 5000)

    console.log(userData);
    // console.log(userData.payload.password);
    //yield put({ type: reportTypes.LOGIN_REQUEST, isLoading: false })

    yield put({
      type: reportTypes.REPORTDATA,
      reportData: {
        ...defaultSagaData,
        data: userData,
        lastFetchSuccess: Date.now(),
      },
    });
  } catch (e) {
    yield put({ type: reportTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: reportTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

export function* handleRequest(action) {
  console.log("authSaga request", action);
  console.log(action.payload);
  //yield put({ type: "ITEMS_IS_LOADING", isLoading: true });
  //yield call(updateStatus);
  try {
    switch (action.type) {
      case reportTypes.FETCH_DATA_REQUEST: {
        const fetchTask = yield fork(getData, action.payload);
        break;
      }
      case reportTypes.SET_ACTIVEREPORT_REQUEST: {
        const fetchTask = yield fork(updateData, action.payload);
        break;
      }

      default: {
        return null;
        // break;
      }
    }
  } catch (e) {
    yield put({ type: reportTypes.LOGIN_FAILURE, error: e });
  }
}
