// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "RESERVATIONS/FETCH_REQUEST",
  RESERVATIONSDATA: "RESERVATIONS/RESERVATIONSDATA",
  DELETE_REQUEST: "RESERVATIONS/DELETE_REQUEST",
  INSERT_REQUEST: "RESERVATIONS/INSERT_REQUEST",
  UPDATE_REQUEST: "RESERVATIONS/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "RESERVATIONS/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "RESERVATIONS/MESSAGE",
  TOKEN: "RESERVATIONS/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.RESERVATIONSDATA:
      return { ...state, ...action.reservationsData };

    default:
      return state;
  }
};

export const actions = {
  getReservationsData: (payload) => payload,
  insertUpdateRequest: (payload) => payload,
  deleteReservation: (payload) => payload,
};
