import React from "react";
import CommonHero from "../../components/common/CommonHero";
import MeetOpportunity from "../../components/ForSiteOwner/features/MeetOpportunity";
import WhiteLabelPartnership from "../../components/ForSiteOwner/features/WhiteLabelPartnership";
import VersatileServices from "../../components/ForSiteOwner/features/VersatileServices";
import IntegrateEverything from "../../components/ForSiteOwner/features/IntegrateEverything";
import RealTimeManagement from "../../components/ForSiteOwner/features/RealTimeManagement";
import FullyCustomizable from "../../components/ForSiteOwner/features/FullyCustomizable";
import DataAnalytics from "../../components/ForSiteOwner/features/DataAnalytics";
import BetterAndSimpler from "../../components/ForSiteOwner/features/BetterAndSimpler";
import EvChargingFacility from "../../components/ForSiteOwner/features/EvChargingFacility";
import CameraSurveillance from "../../components/ForSiteOwner/features/CameraSurveillance";
import LicensePlateRecognition from "../../components/ForSiteOwner/features/LicensePlateRecognition";

const Features = () => {
  const commonHero = {
    heading: "Features",
    herobgImg: "bg-features_and_about_us_hero_img",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <MeetOpportunity />
      <WhiteLabelPartnership />
      <VersatileServices />
      <IntegrateEverything />
      <RealTimeManagement />
      <BetterAndSimpler />
      <FullyCustomizable />
      <DataAnalytics />
      <EvChargingFacility />
      <CameraSurveillance />
      <LicensePlateRecognition />
    </>
  );
};

export default Features;
