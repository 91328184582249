import React from "react";
import Watching from "../../../assets/images/webp/truck_waiting_loading_near_warehouse.webp";
import { Link } from "react-router-dom";
const ParkNationLooking = () => {
  return (
    <>
      <section className="lg:py-[50px]">
        <div className="container py-12">
          <div className="lg:flex items-center justify-between">
            <div className=" w-full lg:w-[50%] xl:w-[40%]">
              <img
                className="w-full lg:h-[423px] max-w-[500px] lg:max-w-auto m-auto"
                src={Watching}
                alt="truck-waiting"
              />
            </div>
            <div className=" w-full lg:w-[60%] xl:w-[70%] lg:max-w-[554px] m-auto">
              <div className="mt-8 lg:mt-0 lg:ps-10 xl:ps-0">
                <h3 className="text-black font-bold lg:leading-[55px] text-lg md:text-[32px] lg:text-xl font-Manrope mb-4">
                  ParkNation is Looking <br className="hidden xl:flex" /> Ahead
                </h3>
                <p className="font-Poppins text-[#000000] font-normal leading-[25.6px] mb-0 opacity-70 text-base">
                  Our platform is just the beginning. We are also helping build
                  new truck parking enterprises from the ground up, with
                  comprehensive support for business planning, construction, and
                  operations management.
                </p>
                <p className="font-Poppins text-[#000000] font-normal leading-[25.6px] mb-0 opacity-70 text-base">
                  The transport industry is expanding rapidly. At ParkNation, we
                  are inspired to be the leading business partner for
                  enterprises in the truck parking segment. Our experience,
                  technology expertise, and operations leadership have
                  positioned us as a preferred business partner.
                </p>
                <Link
                  to="/sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="first_btn px-[26px] py-[14px]  inline-block mt-6 lg:mt-11 text-base font-Manrope font-semibold"
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ParkNationLooking;
