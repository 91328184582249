export default () => {
	return {
		text: `Are you sure you want to proceed further`,
		className: "styled-modal",
		input: "text",
		inputClass: "has-input input-sm form-control",
		inputPlaceholder: "Reason to decline",
		customClass: "styled-modal",
		allowOutsideClick: false,
		buttonsStyling: false,
		showCancelButton: true,
		reverseButtons: true,
		cancelButtonText: "No",
		confirmButtonText: "Yes",
		confirmButtonClass: "btn MuiButton-root MuiButton-containedPrimary",
		cancelButtonClass: "btn MuiButton-root MuiButton-containedPrimary mr-2",
		closeOnClickOutside: false,
		heightAuto: false,
		inputValidator: (value) => {
			if (!value) return 'Your text here'
			else return null
		}
	};
}
