// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "NOTIFICATIONS/FETCH_REQUEST",
  NOTIFICATIONSDATA: "NOTIFICATIONS/NOTIFICATIONSDATA",
  DELETE_REQUEST: "NOTIFICATIONS/DELETE_REQUEST",
  INSERT_REQUEST: "NOTIFICATIONS/INSERT_REQUEST",
  UPDATE_REQUEST: "NOTIFICATIONS/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "NOTIFICATIONS/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "NOTIFICATIONS/MESSAGE",
  TOKEN: "NOTIFICATIONS/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.NOTIFICATIONSDATA:
      return { ...state, ...action.notificationsData };

    default:
      return state;
  }
};

export const actions = {
  getNotificationsData: (payload) => payload,
};
