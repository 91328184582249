import React from "react";

const EveryFeature = () => {
  return (
    <>
      <section className="text-center">
        <div className="container">
          <div className="max-w-[800px] mx-auto">
            <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black">
              Every feature you ever wanted, and more.
            </h2>
            <p className="font-Poppins text-base text-[#000000] opacity-70 leading-[160%] ">
              Fintech, short for financial technology, is a rapidly growing
              industry that is changing the way we manage and access our money.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default EveryFeature;
