import React from "react";
import BetterSimpler from "../../../assets/images/webp/better-simpler.webp";
import { Link } from "react-router-dom";
const BetterAndSimpler = () => {
  return (
    <>
      <section className="my-12 md:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center">
            <div data-aos="fade-right" data-aos-duration="1500">
              <div className="max-w-[520px]">
                <img
                  className="w-full"
                  src={BetterSimpler}
                  alt="BetterSimpler"
                />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Better and Simpler Security
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-0">
                  ParkNation helps manage a robust security environment for your
                  truck lot.
                </p>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  All security functions and processes are integrated within a
                  single framework, including cameras, entry/exit gates, license
                  plate recognition, tracking, notifications, and hardware,
                  firmware, and software updates. Our platform provides the
                  security assurance that shippers, brokers, and carriers need
                  for high value loads.
                </p>
                <Link
                  to="/account-sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px]  inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base"
                >
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BetterAndSimpler;
