export const types = {
  FETCH_DATA_REQUEST: "ERRORDATA/FETCH_REQUEST",
  ERRORDATA: "ERRORDATA/DATA",
};

// const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };

const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.ERRORDATA:
      return { ...state, ...action.errorData };

    default:
      return state;
  }
};

export const actions = {
  getErrorData: (payload) => payload,
};
