// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SUBSCRIPTIONS/FETCH_REQUEST",
  FETCH_RPTDATA_REQUEST: "SUBSCRIPTIONS/FETCH_RPTDATA_REQUEST",
  SUBSCRIPTIONSDATA: "SUBSCRIPTIONS/SUBSCRIPTIONSDATA",
  DELETE_REQUEST: "SUBSCRIPTIONS/DELETE_REQUEST",
  INSERT_REQUEST: "SUBSCRIPTIONS/INSERT_REQUEST",
  UPDATE_REQUEST: "SUBSCRIPTIONS/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SUBSCRIPTIONS/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "SUBSCRIPTIONS/MESSAGE",
  TOKEN: "SUBSCRIPTIONS/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  switch (action.type) {
    case types.SUBSCRIPTIONSDATA:
      return { ...state, ...action.subscriptionsData };

    default:
      return state;
  }
};

export const actions = {
  getSubscriptionsData: (payload) => payload,
  getRptSubscriptionsData: (payload) => payload,
  insertUpdateCustomer: (payload) => payload,
};
