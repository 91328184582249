/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
var ReactDOMServer = require("react-dom/server");
import NewCustomerForm from "./NewCustomerForm.js";
// import { GlobalStoreContainer } from "../../../store";
import MaskedInput from "antd-mask-input";
import AppConfig from "../../../constants/AppConfig";

// import { PDFViewer } from "@react-pdf/renderer";
import { connect } from "react-redux";
import { Badge } from "reactstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import {
//   Elements,
//   ElementsConsumer,
//   CardElement,
//   PaymentElement,
//   useElements,
//   useStripe,
// } from "@stripe/react-stripe-js";
const MySwal = withReactContent(Swal);
// import { Email, Item, Image, Span, A, renderEmail, Box } from 'react-html-email'
// import SignaturePad from "react-signature-pad-wrapper";
// import SignaturePad from "react-signature-canvas";
// import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// import dayjs from "dayjs-with-plugins";
import dayjs from "dayjs";
// import stylesSig from "./../../../src/assets/signpad.css";
// import Card from "react-credit-cards";
import { NotificationManager } from "react-notifications";
// import Payment from "payment";
// import "react-credit-cards/es/styles-compiled.css";
import { PNfetch } from "../../../api/pnfetch";
import { types as dashboardTypes } from "../../../reducers/dashboardReducer";
import { actions as dashboardActions } from "../../../reducers/dashboardReducer";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
  formatFormData,
  formatZip,
} from "../../../util/card-util";
import {
  getInvoicesDataSelector,
  getReferenceDataSelector,
  getInsertedDataSelector,
  getSessionDataSelector,
  getErrorDataSelector,
  getMobileDeviceSelector,
} from "../../../reselect/sagaReselect";
// import {
//   Page,
//   Document,
//   Image,
//   StyleSheet,
//   Text,
//   View,
//   pdfjs,
//   Font,
//   PDFDownloadLink,
//   pdf,
// } from "@react-pdf/renderer";
import { PageHeader } from "@ant-design/pro-layout";

import {
  Button,
  Card as ACard,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  FloatButton,
  Form as AForm,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Statistic,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";

// import {
//   sortableContainer,
//   sortableElement,
//   sortableHandle,
// } from "react-sortable-hoc";
import { MenuOutlined, DollarOutlined } from "@ant-design/icons";
import { arrayMoveImmutable } from "array-move";
// import { PaymentInputsContainer } from "react-payment-inputs";

//import Highlighter from "react-highlight-words";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
import { formatMoney, unformat } from "accounting-js";
import { FormInstance } from "antd/lib/form";
import ReactDragListView from "react-drag-listview";
import * as uuid from "uuid";
import {
  InfoCircleOutlined,
  DownOutlined,
  SearchOutlined,
  FilterOutlined,
  EditOutlined,
  DeleteOutlined,
  SaveOutlined,
  DownloadOutlined,
  SettingOutlined,
  PrinterOutlined,
  UserOutlined,
} from "@ant-design/icons";
// intl messages
//import IntlMessages from "Util/IntlMessages";
import classnames from "classnames";
import { bindActionCreators } from "redux";
import { types as invoiceTypes } from "../../../reducers/invoicesReducer";
import { actions as invoiceActions } from "../../../reducers/invoicesReducer";
import { types as actionTypes } from "../../../reducers/actionReducer";
import { actions as actionActions } from "../../../reducers/actionReducer";
import {
  orderBy,
  trim,
  isNil,
  capitalize,
  cloneDeep,
  padStart,
  round,
} from "lodash";
import { titleCase } from "../../../util/common";
// import StripeSetupPay from "../quickcharge/stripeSetupPay";

// import PaymentFormSQ from "Components/PaymentFormSQ";
// import printExpense from "./printExpense";

const { TabPane } = Tabs;

const { Title, Link } = Typography;
const AText = Typography.Text;
// const MemoizedInvoice = React.memo(printExpense);

const { Header, Sider, Content } = Layout;
const Option = Select.Option;
// const DragHandle = sortableHandle(() => (
//   <MenuOutlined style={{ cursor: "grab", color: "#999" }} />
// ));
// const SortableItem = sortableElement((props) => <tr {...props} />);
// const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const PNGroups = [
  {
    id: "1",
    name: "Active",
  },
  {
    id: "2",
    name: "Dormant",
  },
];

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const layoutCol = {
  labelCol: {
    span: 5,
  },
  wrapperCol: {
    span: 19,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const fileToBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

// const css = `
// @media only screen and (max-device-width: 480px) {
//   font-size: 20px !important;
// }`.trim()

// const sourceURL = 'https://raw.githubusercontent.com/chromakode/react-html-email/master/examples/kitchenSink.js'

// const emailContent = renderEmail(
//   <Email title="Test Email" headCSS={css}>
//     <Item>
//       <Span fontSize={15}>Hello, world!</Span>
//     </Item>
//     <Item>
//       <Box cellSpacing={20} width="100%" style={{ borderTop: '3px solid black' }}>
//         <Item>
//           <Span color="gray" lineHeight={20}>Generated by <A href="https://github.com/chromakode/react-html-email">react-html-email</A></Span>
//           <Image data-mc-bar="bar" data-mc-baz="baz" alt="react" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/57/React.js_logo.svg/100px-React.js_logo.svg.png" width={100} height={100} />
//           <A download={sourceURL} href={sourceURL}>Source code</A>
//         </Item>
//       </Box>
//     </Item>
//   </Email>
// );
const borderColor = "#546476";

const discountOptions = [
  { label: "%", value: "P" },
  { label: "$", value: "V" },
];

const serviceOptions = [
  { label: "%", value: "P" },
  { label: "$", value: "V" },
];
// comment: ""
// created_at: "2021-08-05T08:51:18.263000"
// created_by: 1
// daily_rate: 20
// description: "Can accommodate 4 Motor bikes... test description  test description test description test description"
// id: 14
// monthly_rate: 200
// monthly_reservable: true
// name: "p2"
// site_id_fk: 5
// space_type_id_fk: 2
// status: true
// updated_at: "2021-08-23T21:54:58.470000"
// updated_by: 1
// weekend_rate: 20
// weekly_rate: 20

// space_type_name
// space_name
// monthly_rate
// daily_rate
// weekly_rate
// weekend_rate
// const EditableCell = ({
//   editing,
//   dataIndex,
//   title,
//   inputType,
//   record,
//   index,
//   children,
//   ...restProps
// }) => {
//   const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
//   return (
//     <td {...restProps}>
//       {editing ? (
//         <Form.Item
//           name={dataIndex}
//           style={{
//             margin: 0,
//           }}
//           rules={[
//             {
//               required: true,
//               message: `Please Input ${title}!`,
//             },
//           ]}
//         >
//           {inputNode}
//         </Form.Item>
//       ) : (
//         children
//       )}
//     </td>
//   );
// };pack

const repeatRecurrency = ["Month"]; //"Day", "Week", "Month", "Year"

const refundReasons = [
  // "Returned goods",
  // "Accidental charge",
  // "Canceled order",
  // "Fradulent charge",
  // "Other",
  "Duplicate",
  "Fraudulent",
  "Requested_by_customer",
  // "Other",
];

const remindersData = [
  { desc: "7 day before due date", value: "b7" },
  { desc: "on due date", value: "dd" },
  { desc: "1 day after due date", value: "a1" },
  { desc: "3 day after due date", value: "a3" },
];

const formatTaxPerc = (val) => {
  return `${formatMoney(val, {
    symbol: "",
    precision: 3,
    thousand: ",",
    format: {
      pos: "%s %v",
      neg: "%s (%v)",
      zero: "%s --",
    },
  })} %`;
};

class EmailCheck extends Component {
  formCustomerRef = React.createRef();
  handleUser = (val) => {
    let expenseReferenceId = `${dayjs().format("YYYYMMDD")}-${val}`;
    let site_id = this.state.site_id;
    const foundRecords = (this.props.referenceData.users || []).filter(
      (rec) => rec.user_id == val
    );
    if (foundRecords.length > 0) {
      expenseReferenceId =
        expenseReferenceId +
        "-" +
        padStart(foundRecords[0].newInvoiceId, 6, "0");
    } else {
      expenseReferenceId = expenseReferenceId + "-" + padStart("1", 6, "0");
    }

    this.setState(
      {
        expense_user_id: val,
        expenseReferenceId,
        customer: foundRecords[0],
        name: foundRecords[0].customer_name,
        site_id:
          isNil(site_id) || site_id == -1
            ? foundRecords.length > 0
              ? foundRecords[0].default_site_id
              : undefined
            : site_id,
        card_id: undefined,
        cardOption: 8,
      },
      () => {
        if (!isNil(this.state.site_id)) {
          // For new customer
          const foundRec = this.state.sites.filter(
            (itm) => itm.site_id == this.state.site_id
          )[0];

          this.setState({
            tax_rate: foundRec.tax_rate,
            tax: true,
            site_name: foundRec.name,
          });
        }
      }
    );
  };

  handleVehicle = (val) => {
    this.setState({
      vehicle_id: val,
    });
  };

  paymentColumns = [
    {
      title: "Items",
      dataIndex: "expense_title",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      width: "auto",
      minwidth: "auto",
      maxwidth: "auto",
      // minwidth: "auto",
      // maxwidth: "auto",
      render: (text, row) => {
        return (
          <span>
            <span>{titleCase(row.expense_title)}</span> <br />{" "}
            {/* <span style={{ opacity: 0.5 }}>{row.discountDesc}</span> */}
          </span>
        );
      },
      sorter: (a, b) =>
        (a.expense_title || "").localeCompare(b.expense_title || ""),
    },
    {
      title: "Date",
      dataIndex: "created_at",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      align: "right",
      width: "110px",
      minwidth: "110px",
      maxwidth: "110px",
      render: (text, row) => {
        return <span> {row.created_at ? row.created_at : ""} </span>;
      },
      sorter: (a, b) => (a.created_at || "").localeCompare(b.created_at || ""),
    },
    {
      title: "Type",
      dataIndex: "payment_type",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      align: "right",
      width: "200px",
      minwidth: "200px",
      maxwidth: "200px",
      render: (text, row) => {
        return (
          <span>
            {" "}
            {row.payment_type.toLowerCase() == "charge"
              ? row.payment_type
              : `${row.payment_type} - ${row.refund_reason}`}{" "}
          </span>
        );
      },
      sorter: (a, b) => (a.created_at || "").localeCompare(b.created_at || ""),
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      align: "right",
      width: "200px",
      minwidth: "200px",
      maxwidth: "200px",
      render: (text, row) => {
        return (
          <span>
            {" "}
            {row.total_amount ? this.formatNumber(row.total_amount) : ""}{" "}
          </span>
        );
      },
      sorter: (a, b) =>
        Number(a.total_amount || 0.0) - Number(b.total_amount || 0.0),
    },
  ];

  columns = [
    {
      title: "Sort",
      dataIndex: "sort",
      width: 50,
      className: "drag-visible",
      render: () => <DragHandle />,
    },
    {
      title: "Item",
      dataIndex: "item",
      width: 200,
      filterable: true,
      editable: true,
      inputType: "text",
      // fixed: "left",
      // width: 150,
      render: (text, row) => {
        return (
          <div>
            {" "}
            {row.item ? (
              <span>
                <span>{titleCase(row.item)}</span> <br />{" "}
                <span style={{ fontStyle: "italic", opacity: 0.5 }}>
                  {!isNil(row.note) && trim(row.note) != "-1" ? row.note : ""}
                </span>
              </span>
            ) : (
              ""
            )}
          </div>
        );
      },
      sorter: (a, b) => (a.item || "").localeCompare(b.item || ""),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      filterable: true,
      editable: true,
      inputType: "text",
      width: 100,
      // fixed: "left",
      // width: 100,
      render: (text, row) => {
        return <span> {row.quantity ? row.quantity : ""} </span>;
      },
      sorter: (a, b) => (a.quantity || "").localeCompare(b.quantity || ""),
    },
    {
      title: "Price",
      dataIndex: "price",
      filterable: true,
      editable: true,
      inputType: "text",
      width: 100,
      render: (text, row) => {
        return <span> {row.price ? this.formatNumber(row.price) : ""} </span>;
      },
      sorter: (a, b) => (a.price || "").localeCompare(b.price || ""),
    },
    {
      title: "Tax",
      dataIndex: "tax_rate",
      filterable: true,
      editable: true,
      inputType: "text",
      width: 100,
      render: (text, row) => {
        return (
          <span> {row.tax_rate > 0 ? formatTaxPerc(row.tax_rate) : ""} </span>
        );
      },
      sorter: (a, b) => (a.tax_rate || "").localeCompare(b.tax_rate || ""),
    },
    {
      title: "Total",
      dataIndex: "total",
      filterable: true,
      editable: true,
      inputType: "text",
      width: 100,
      render: (text, row) => {
        return <span> {row.total ? this.formatNumber(row.total) : 0} </span>;
      },
      sorter: (a, b) => Number(a.total || 0.0) - Number(b.total || 0.0),
    },

    {
      title: "",
      dataIndex: "operation",
      width: 80,
      render: (_, record) => {
        // const editable = this.isEditing(record);
        const editable = false;
        return editable ? (
          <span style={{ whiteSpace: "nowrap" }}>
            <span
              style={{ width: "200px" }}
              onClick={() => this.save(record.key)}
            >
              <SaveOutlined
                style={{
                  fontSize: "18px",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
              />
              <a href="javascript:;" style={{ marginRight: "16px" }}>
                Save
              </a>
            </span>
            <span style={{ width: "200px" }}>
              <Popconfirm title="Sure to cancel?" onConfirm={this.cancel}>
                <DeleteOutlined
                  style={{
                    fontSize: "18px",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                />

                <a>Cancel</a>
              </Popconfirm>
            </span>
          </span>
        ) : (
          <span>
            {this.props.sessionData.role != 4 &&
              !this.state.hasDeposit &&
              !this.props.readOnly && (
                <span onClick={() => this.edit(record)}>
                  <EditOutlined
                    style={{
                      fontSize: "18px",
                      marginRight: "10px",
                      marginLeft: "4px",
                      cursor: "pointer",
                    }}
                  />
                  {/* <Typography.Link
                disabled={this.state.editingKey != ""}
                style={{ marginRight: "16px" }}
              >
                Edit
              </Typography.Link> */}
                </span>
              )}
            {this.props.sessionData.role != 4 &&
              !this.state.hasDeposit &&
              !this.props.readOnly && (
                <span>
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => {
                      this.handleDeleteItem(record);
                    }}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: "18px",
                        marginRight: "10px",
                        cursor: "pointer",
                      }}
                    />
                    {/* <Typography.Link
                // disabled={this.state.editingKey != ""}
                // onClick={() => this.handleDelete(record)}
                >
                  Delete
                </Typography.Link> */}
                  </Popconfirm>
                </span>
              )}
          </span>
        );
      },
    },
  ];

  units = [
    "Per Space",
    "Day",
    "Mile",
    "Minute",
    "Hour",
    "Per Item",
    "Square Foot",
    "Foot",
    "Gallon",
    "Inch",
    "Ounce",
  ];

  //   expenseDiscount: undefined,
  // invoiceDiscountType: "P", //Percent / Amount
  // expenseSubTotal: undefined,
  // expenseSalesTax: undefined,
  // expenseServiceCharge: undefined,
  // invoiceServiceChargeType: "P", //Percent / Amount
  // expenseTotal: undefined,

  //  discountItemsData: discountItemsDataEnhanced,
  //   serviceItemsData: serviceItemsDataEnhanced,
  //   expenseSubTotal,
  //   expenseServiceCharge: serviceTotal,
  //   expenseDiscount: discountTotal,
  //   expenseSalesTax: serviceItemsTaxDataTotal,
  //   expenseTotal,

  handleVehicleFileDropped = async (e) => {
    // debugger;

    //  file_name: record.file_name,
    //     file_title: record.file_title,
    //     file_type: record.file_type,
    //     file_size: record.file_size,

    //  this.setState({ selectedFile: event.target.files[0] });
    const file = event.target.files;
    if (file) {
      await fileToBase64(file[0], (err, result) => {
        //  // debugger;
        if (result) {
          const pos = result.indexOf(",");
          const readType = result.substring(0, pos);
          const base64Data = result.substring(pos + 1);
          console.log("name", file[0].name);
          console.log("size", file[0].size);
          console.log("type", file[0].type);
          console.log("readType", readType);
          console.log("base64Data", base64Data);

          const record = this.formVehicleRef.current.getFieldsValue(true);

          this.setState(
            {
              originalFile: file[0],
              file: result, //file[0],
              fileType: readType,
              fileContent: base64Data,
              document_name: file[0].name,
            },
            () => {
              this.formVehicleRef.current.setFieldsValue({
                ...record,
                file_name: file[0].name,
                file_size: file[0].size,
                file_type: readType,
              });
            }
          );
        }
      });
    }
  };

  setNewPaymentModalVisible = (visible) => {
    this.setState({ newPaymentModalVisible: visible });
  };

  closeNewPaymentModalVisible = (saved, setupObject) => {
    // alert("in closeNewPaymentModalVisible");
    console.log(
      "closeNewPaymentModalVisible setupObject ***********",
      setupObject
    );
    // debugger;
    if (!saved) {
      this.setState({ newPaymentModalVisible: false, cardOption: 8 });
    } else {
      this.setState({
        newPaymentModalVisible: false,
        cardOption: 1,
        card_id: setupObject.card_id,
      });
    }
  };
  state = {
    quickChargeDesc: "Payment on Account",
    newSpaceOption: false,
    newSpaceModalVisible: false,
    originalFile: undefined,
    file: undefined,
    fileType: undefined,
    fileContent: undefined,
    document_name: undefined,
    newVehicleOption: false,
    newVehicleModalVisible: false,
    shareInvoiceMode: "email",
    hasReminders: "Y",
    reminderData: ["b7", "dd", "a1", "a3"],
    customerBalance: 0,
    refundAmountForCard: 0,
    refundPaymentRows: [],
    refundReason: undefined,
    refundReasonEntered: undefined,
    refundCardId: undefined,
    invoicePaidAmountForCard: 0,
    mode: "pay",
    amountRemaining: 0,
    amountToPay: 0,
    paymentModalVisible: false,
    refundModalVisible: false,
    newCustomerModalVisible: false,
    newItemModalVisible: false,
    newCustomerOption: false,
    newItemOption: false,
    items: [],
    //  record:this.props.expenseRecord,
    column: [],
    currentPage: 1,
    currentGridFilteredData: [],
    bordered: false,
    loading: false,
    filteredInfo: null,
    sortedInfo: null,
    //pagination,
    size: "default",
    //expandable,
    title: undefined,
    //showHeader,
    //footer,
    // rowSelection: {},
    scroll: undefined,
    xScroll: "fixed",
    yScroll: true,
    hasData: true,
    tableLayout: undefined,
    top: "none",
    bottom: "bottomRight",
    searchText: "",
    searchedColumn: "",
    renderVersion: 1,
    editingKey: "",
    data: [],
    columnsToShow: this.columns,
    visible: false,
    customers: [],
    customer: undefined,
    status: undefined,
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (!isNil(nextProps.errorData) && nextProps.errorData.length > 0) {
        // debugger;
        this.openNotificationWithIcon("error", "Error", nextProps.errorData[0]);
        if (nextProps.errorData[0] == "Invalid Token") {
          let store = GlobalStoreContainer.store;
          store.dispatch({ type: "LOGOUT_USER" });
        } else {
          //Clear Error
          this.props.clearError({
            type: actionTypes.CLEAR_ERROR_REQUEST,
            payload: {
              parms: [],
            },
          });
        }
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    // debugger;
    // window.addEventListener("resize", this.resizeCanvas);
    this.setState({}, () => {});

    // this.props.getInvoiceData({
    //   type: invoiceTypes.FETCH_DATA_REQUEST,
    //   payload: {
    //     parms: [1],
    //   },
    // });
  }

  render() {
    return (
      <div className="ecom-Invoices-wrapper">
        <AForm
          id="dataEntryDiv"
          name="form-customer"
          preserve={false}
          {...layout}
          ref={this.formCustomerRef}
          // name="control-ref"
          // size={"small"}
          //   onFinish={this.onFinish}
          initialValues={{
            email: "",
          }}
          scrollToFirstError
        >
          <AForm.Item
            name="email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input style={{ maxWidth: "500px" }} />
          </AForm.Item>
          {/* <Row justify="end">
            <Col>
              <Button
                loading={this.state.loading}
                type="primary"
                htmlType="submit"
                style={{ marginRight: "8px" }}
                onClick={(e) => this.resetPassword()}
              >
                Send
              </Button>
              <Button
                htmlType="button"
                style={{ marginRight: "20px" }}
                onClick={this.hideModal}
              >
                Cancel
              </Button>
            </Col>
          </Row> */}
        </AForm>
      </div>
    );
  }
}

// map state to props
const mapStateToProps = (state, ownProps) => {
  console.log("state", state);
  console.log("ownProps", ownProps);
  // const expenseData = getInvoicesDataSelector(state);
  //   const referenceData = getReferenceDataSelector(state);
  //   const insertedData = getInsertedDataSelector(state);
  //   const sessionData = getSessionDataSelector(state);
  //   const isMobile = getMobileDeviceSelector(state);

  //   const readOnly =
  //     (sessionData.userEntitlements || []).filter(
  //       (rec) =>
  //         rec.screen_name.toLowerCase() == "invoices" && rec.screen_access == "V"
  //     ).length > 0
  //       ? true
  //       : false;
  //    const userId = authUser.user ? authUser.user.id : null;
  return {
    //     referenceData: referenceData,
    //     // data: expenseData,
    //     userId: state.authUser.user.user_id,
    //     insertedData,
    //     sessionData,
    //     expenseRecord: ownProps.record,
    //     isMobile,
    //     readOnly,
    // ownProps && ownProps.record
    //   ? ownProps.record
    //   : this.props.history.location.record,
  };
};

const mapDispatchToProps = (dispatch) => ({
  ...bindActionCreators(
    {
      ...actionActions,
    },
    dispatch
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(EmailCheck);
