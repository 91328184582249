import React from "react";
import CreatingNewopportunity from "../../components/ForTruckDrivers/about-us/CreatingNewopportunity";
import CommonHero from "../../components/common/CommonHero";
import OurTeam from "../../components/ForTruckDrivers/about-us/OurTeam";
import ParkNationLooking from "../../components/ForTruckDrivers/about-us/ParkNationLooking";
import WeDo from "../../components/ForTruckDrivers/about-us/WeDo";

const AboutUs = () => {
  const commonHero = {
    heading: "About Us",
    herobgImg: "bg-features_and_about_us_hero_img",
  };
  return (
    <>
      <CommonHero commonHero={commonHero} />
      <CreatingNewopportunity />
      <ParkNationLooking />
      {/* <OurTeam /> */}
      <WeDo />
    </>
  );
};

export default AboutUs;
