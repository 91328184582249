export const types = {
  FETCH_DATA_REQUEST: "REFERENCEDATA/FETCH_REQUEST",
  REFERENCEDATA: "REFERENCEDATA/DATA",
  VEHICLESDATA: "REFERENCEDATA/VEHICLESDATA",
};

// const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };

const initialState = {
  data: {},
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.VEHICLESDATA:
      return { ...state, ...action.vehiclesData };

    default:
      return state;
  }
};

export const actions = {
  getReferenceData: (payload) => payload,
};
