import React from "react";
import Seamless from "../../../assets/images/webp/seamless.webp";

const SeamlessPayment = () => {
  return (
    <>
      {" "}
      <section className="my-12 md:my-20 lg:my-28 xl:mb-[150px] xl:mt-[120px]">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center">
            <div>
              <div className="max-w-[520px]">
                <img className="w-full" src={Seamless} alt="Seamless" />
              </div>
            </div>
            <div>
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Seamless payment buy Eagle pay{" "}
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 ">
                  Fintech, short for financial technology, is a rapidly growing
                  industry that is changing the way we manage and access our
                  money. By leveraging the power of technology, fintech
                  companies are creating innovative solutions that make
                  financial transactions faster, more convenient, and more
                  secure than ever before.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SeamlessPayment;
