import React from "react";
import dataAnalytics from "../../../assets/images/webp/data-analytics.webp";
import { Link } from "react-router-dom";
const DataAnalytics = () => {
  return (
    <>
      <section className="my-12 md:my-20 lg:my-28 xl:my-[150px] overflow-x-hidden">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[56px] items-center">
            <div data-aos="fade-right" data-aos-duration="1500">
              <div className="max-w-[525px]">
                <img
                  className="w-full"
                  src={dataAnalytics}
                  alt="dataAnalytics"
                />
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Data Analytics{" "}
                </h2>

                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  Engage the benefits of digital transformation and gain value
                  from your data that creates a competitive advantage. Smart
                  metrics and deep analytics capabilities allow you to
                  understand how your enterprise is performing in real-time.
                  Track, analyze, and predict reservations, availability,
                  invoices, payments, and subscriptions. Uncover valuable
                  insights and make better, more confident business decisions.
                </p>
                <Link
                   to="/account-sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px]  inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base">
                  Sign Up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DataAnalytics;
