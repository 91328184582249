import React from "react";
import Effortlessly from "../../../assets/images/webp/effortlessly.webp";
import { Link } from "react-router-dom";
const EffortlesslyTrack = () => {
  return (
    <>
      <section className="mb-12 md:mb-20 lg:mb-28 xl:mb-[150px]">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[62px] items-center">
            <div>
              <div className="max-w-[520px]">
                <img className="w-full" src={Effortlessly} alt="Effortlessly" />
              </div>
            </div>
            <div>
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px]   font-bold font-Manrope text-black mb-4">
                  Effortlessly track your finances with seamless invoicing{" "}
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  Look no further than our seamless invoicing solution. With our
                  user-friendly platform, you can easily create and send
                  invoices, track payments, and manage your finances all in one
                  place. Say goodbye to the hassle of manual invoicing and hello
                  to a streamlined financial management process.
                </p>
                <Link
                  to="/get-started"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px] inline-block sm:p-[14px_26px]  font-semibold first_btn whitespace-nowrap font-Manrope text-base"
                >
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EffortlesslyTrack;
