import React, {
  useEffect,
  useState,
  useRef,
  useLayoutEffect,
  useCallback,
  useMemo,
} from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import regiterTruk from "../assets/images/png/registar-truk.png";
import Logo from "../assets/images/webp/logo.webp";
import { AppleIcon, FacebookIcon, GoogleIcon } from "../components/common/Icon";
import { Link } from "react-router-dom";
import NewCustomerForm from "../components/ForTruckDrivers/login/NewCustomerForm";
import { connect } from "react-redux";
import Paragraph from "antd/es/typography/Paragraph";
import { NotificationManager } from "react-notifications";
// import Swal from "sweetalert2";
// import withReactContent from "sweetalert2-react-content";
// const MySwal = withReactContent(Swal);
import AppConfig from "../constants/AppConfig.js";
import {
  Button,
  Select,
  Row,
  Col,
  Card,
  Tooltip,
  Badge,
  Statistic,
  List,
  Carousel,
  Space,
  Avatar,
  Rate,
  Image,
  Drawer,
  Typography,
  Divider,
  Tag,
  Modal,
  DatePicker as ADatePicker,
  Radio,
  Form,
  InputNumber,
  Checkbox,
  Popover,
  Input,
  Table,
  notification,
  Alert,
  Spin,
} from "antd";
const { Title } = Typography;
const { Search } = Input;
const { RangePicker } = ADatePicker;
const dateFormat = "MM/DD/YYYY";

import {
  LikeOutlined,
  MessageOutlined,
  StarOutlined,
  PhoneOutlined,
  MailOutlined,
  GoogleOutlined,
  FacebookOutlined,
  SkypeOutlined,
  VideoCameraOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import { useSetState } from "ahooks";
import { actions as actionActions } from "../reducers/actionReducer.js";
import { types as actionTypes } from "../reducers/actionReducer.js";
import { RiArrowRightSLine, RiArrowLeftSLine } from "react-icons/ri";
import {
  getSiteDataSelector,
  getUserDataSelector,
  getReferenceDataSelector,
  getSessionDataSelector,
  getErrorDataSelector,
  getMobileDeviceSelector,
} from "../reselect/sagaReselect.js";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween.js";
dayjs.extend(isBetween);
import * as uuid from "uuid";
// import AppConfig from "../components/constants/AppConfig.js";
import { PNfetch } from "../api/pnfetch.js";
import { isNil, trim, sortBy, round, cloneDeep } from "lodash";
import { PageHeader } from "@ant-design/pro-layout";
import { formatMoney, unformat } from "accounting-js";
import CameraRTC from "../components/ForTruckDrivers/WebRTC/cameraRtc.js";
import Login from "../components/ForTruckDrivers/login/custlogin.js";
import CloverConnect from "../components/ForTruckDrivers/cloversetup/index.js";
import {
  rangePresets,
  rangeForwardPresets,
  formatPhoneNumber,
  printCustName,
  printSiteName,
} from "../../src/util/common.js";

// import ClipLoader from 'react-spinners/ClipLoader';
// const Spinner = () => {
//   return (
//     <div style={{ width: '100px', margin: 'auto', display: 'block' }}>
//       <ClipLoader color="#52bfd9" size={100}/>
//     </div>
//   );
// };

const roles = [
  {
    id: 4,
    name: "User",
  },
];

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const layoutCol = {
  labelCol: {
    span: 7,
  },
  wrapperCol: {
    span: 17,
  },
};

const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};

const blobToBase64 = (blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const fileToBase64 = (file, cb) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(null, reader.result);
  };
  reader.onerror = function (error) {
    cb(error, null);
  };
};

const IconText = ({ icon, text }) => (
  <Space>
    {React.createElement(icon)}
    {text}
  </Space>
);

const gridStyle = {
  width: "100%",
  textAlign: "center",
  borderRadius: 12,
  padding: "12px 16px 16px",
  marginBottom: 16,
};

const contentStyle = {
  // height: "240px",
  color: "#fff",
  lineHeight: "160px",
  textAlign: "center",
  background: "#364d79",
  maxWidth: "300px",
  maxHeight: "300px",
};

const formatNumber = (val) => {
  return formatMoney(val, {
    symbol: "$",
    precision: 2,
    thousand: ",",
    format: {
      pos: "%s %v",
      neg: "%s (%v)",
      zero: "%s --",
    },
  });
};
const SignUp = (props) => {
  const navigate = useNavigate();
  console.log("SignUp Props", props);
  const formRegisterRef = useRef(null);
  // const [formRegisterRef] = Form.useForm();
  // const [formVehicleUseRef] = Form.useForm();

  const openNotificationWithIcon = (type, title, desc) => {
    notification[type]({
      message: title,
      description: desc,
    });
  };

  const setSaveModalVisible = (visible) => {
    setState({ saveModalVisible: visible });
  };

  const [dateRange, setDateRange] = useState([dayjs(), dayjs()]);

  const [state, setState] = useSetState({
    // phoneVerified: false,

    otpModalVisible: false,
    OTPNumber: undefined,
    saveReserveVersion: 1,
    saveModalVisible: false,
    file: undefined,
    fileType: undefined,
    fileContent: undefined,
    document_name: undefined,
    originalFile: undefined,
    newVehicleModalVisible: false,
    searchText: "",
    animating: false,
    site_id: undefined,
    tax_rate: undefined,
    tax: true,
    site_name: undefined,
    vacancyData: undefined,
    vacancyFilteredData: undefined,
    cameraVisible: false,
    showLogin: false,
    showReserve: false,
    invoice_user_id: undefined,
    cloverPaymentModalVisible: false,
    customer: undefined,
    unit: 1,
    sites: [],
    renderVersion: 1,
    space_id: undefined,
    subscription_start_dt: dayjs(),
    subscription_end_dt: dayjs(), //.add(7, "day"),
    space_type_id: -1,
    siteRec: undefined,
    quantity: 1,
    card_id: undefined,
    user: undefined,
    name: undefined,
    invoiceItemsData: [],
    serviceItemsData: [],
    discountItemsData: [],
    note: "",
    availableSpaces: [],
    vehicle_type_id: 1,

    mapSrc:
      "https://www.google.com/maps/embed/v1/place?key=AIzaSyAkYdljz6UUiE_OTntiGQGnBphLhugqZRM&zoom=17&&maptype=satellite&q=",
  });

  const location = useLocation();
  // const nameValue = Form.useWatch("name", formRef);
  const formRef = React.useRef(null);
  const phoneVerified = React.useRef(false);
  const setSiteVisible = (visible) => {
    setState({ siteModalVisible: visible });
  };
  const setRegisterVisible = (visible) => {
    setState({ registerModalVisible: visible });
  };
  const showPasswordModal = () => {
    setState({ passwordModalVisible: true });
  };

  const checkUserEmailAndPhone = async (parms) => {
    //  debugger;

    let URL = process.env.REACT_APP_API_URL + `/reactloginregistration`;
    // let URL = `http://localhost:8080/reactlogin`;
    // if (process.env.NODE_ENV == "production") {
    //   URL = process.env.REACT_APP_API_URL  + `/reactlogin`;
    // }

    return await fetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        ...parms,
      }),
    })
      .then((response) => response.json())
      .catch((error) => error);
  };

  const saveCustomerInDB = (parms, token) => {
    //  debugger;

    return new Promise((resolve, reject) => {
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          proc: "spi_customers",
          input: parms,
        }),
      })
        .then((response) => resolve(response ? response.json() : null))
        .catch((error) => reject(error));
    });

    // return new Promise((resolve, reject) => {
    //   fetch(`https://dev.parknation.org/api/v1.0/payments`, {
    //     method: "POST",
    //     headers: {
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //       authorization: "Bearer " + localStorage.getItem("accessToken"),
    //     },
    //     body: JSON.stringify({
    //       ...parms,
    //     }),
    //   })
    //     .then((response) => resolve(response ? response.json() : null))
    //     .catch((error) => reject(error));
    // });
  };

  const hasDBErrors = (retObj) => {
    if (!isNil(retObj.errCode) && retObj.errCode != "0") {
      return true;
    } else {
      return false;
    }
  };

  const verifyPhone = async () => {
    //  debugger;
    const record = formRegisterRef.current.getFieldsValue(true);
    const phone_number = trim(
      (record.phone_number || "").replace(/[^\d]/g, "")
    );
    if (!phone_number || phone_number.length != 10) {
      NotificationManager.error(
        "Please Enter Valid Phone Number",
        "Phone Number",
        1100
      );
      return false;
    }

    let URL = process.env.REACT_APP_API_URL + `/twilio/otp`;
    // const optionToken = await AsyncStorage.getItem('accessToken');
    console.log("URL", URL);

    const response = await PNfetch(URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        phone: "+1" + phone_number,
      }),
    });

    const retObj = await response.json();
    console.log("response", retObj);
    // console.log('response', response.json());

    if (retObj.messageType == "error") {
      NotificationManager.error(retObj.message, "Phone Number", 1100);
    } else {
      //Show the textbox to verify
      setState({ otpModalVisible: true, OTPNumber: undefined });
      // setOTPVerified(true);
    }
  };

  // const verifyOTP = async () => {
  //   debugger;
  //   if (state.OTPNumber < 6) {
  //     NotificationManager.error(
  //       "Please enter 6 digit otp number",
  //       "Phone Number",
  //       1100
  //     );
  //     return false;
  //   }
  //   const record = formRegisterRef.current.getFieldsValue(true);
  //   const phone_number = trim(
  //     (record.phone_number || "").replace(/[^\d]/g, "")
  //   );
  //   let URL = process.env.REACT_APP_API_URL + `/twilio/otpVerify`;
  //   // const optionToken = await AsyncStorage.getItem('accessToken');
  //   console.log("URL", URL);
  //   const response = await PNfetch(URL, {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       // authorization: "Bearer " + localStorage.getItem("accessToken"),
  //     },
  //     body: JSON.stringify({
  //       phone: "+1" + phone_number,
  //       otp: state.OTPNumber,
  //     }),
  //   });

  //   const retObj = await response.json();
  //   console.log("response", retObj);
  //   // console.log('response', response.json());

  //   if (retObj.messageType == "error") {
  //     NotificationManager.error(retObj.message, "OTP Verification", 1100);
  //   } else {
  //     //Show the textbox to verify

  //     localStorage.setItem("refreshToken", retObj.refresh_token);
  //     localStorage.setItem("accessToken", retObj.access_token);

  //     if (retObj.verification_check.status == "approved") {
  //       phoneVerified.current = true;
  //       setState(
  //         {
  //           otpModalVisible: false,
  //           // phoneVerified: true,
  //           refreshToken: retObj.refresh_token,
  //           accessToken: retObj.access_token,
  //         },
  //         () => {
  //           NotificationManager.success(
  //             "Phone Number is Verified",
  //             "OTP Verification",
  //             1100
  //           );
  //           //  props.navigation.navigate("RegisterUser", { phone: state.phoneNumber });
  //         }
  //       );
  //     } else {
  //       NotificationManager.error(
  //         "Invalid OTP number, Please try again",
  //         "OTP Verification",
  //         1100
  //       );
  //     }
  //     // setOTPVerified(true);
  //   }
  // };

  const updateOTPNumber = (e) => {
    const otpNum = e.target.value.replace(/[^0-9]/g, "");
    setState({ OTPNumber: otpNum });
  };

  const setOTPVisible = (visibile) => {
    setState({ otpModalVisible: visibile });
  };

  const saveCustomer = async (key) => {
    try {
      debugger;
      let token = "",
        htoken = "";

      const record = formRegisterRef.current.getFieldsValue(true);
      console.log("record", record);
      const row = await formRegisterRef.current.validateFields();
      console.log("row", row);
      debugger;

      // if (!record.phoneVerified) {
      //   openNotificationWithIcon(
      //     "error",
      //     "Message",
      //     "Please verify your phone number"
      //   );
      //   return;
      // }

      const resultObj = JSON.parse(
        await checkUserEmailAndPhone({
          email: record.email,
          phone: record.phone_number.slice(record.country_code.length), //record.phone_number,
          password: trim(record.password),
        })
      );

      if (resultObj.messageType == "error") {
        openNotificationWithIcon("error", "Message", resultObj.message);
        return;
      } else {
        token = resultObj.refresh_token;
        htoken = resultObj.htoken;
        localStorage.setItem("refreshToken", resultObj.refresh_token);
        localStorage.setItem("accessToken", resultObj.accessToken);
      }

      setState({ saveModalVisible: true });

      // MySwal.fire({
      //   title: "Registration",
      //   html: "Registering the User, Please be patient.",
      //   allowEscapeKey: false,
      //   allowOutsideClick: false,
      //   didOpen: () => {
      //     MySwal.showLoading();
      //   },
      //   onClose: () => {},
      // }).then((result) => {
      //   if (result.dismiss === Swal.DismissReason.timer) {
      //     console.log("Closed by Timer");
      //   }
      // });
      const parms = [
        props.userId,
        -1,
        record.email,
        record.phone_number.slice(record.country_code.length),
        // record.phone_number,
        record.first_name,
        record.last_name,
        record.street_address,
        record.apt_suite_number ? record.apt_suite_number : "",
        record.city,
        record.state,
        record.zipcode,
        record.country,
        1,
        record.company_name ? record.company_name : "",
        record.role,
        record.groups,
        props.default_site_id ? props.default_site_id : 5, //Default Site Id
        //record.default_site_id,
        record.notes ? record.notes : "",
        record.mapCenter?.lat,
        record.mapCenter?.lng,
        record.user_name,
        htoken, //record.password,
        record.country_code,
      ];
      //  debugger;
      saveCustomerInDB(parms, token)
        .then(async (resultObjCustomer) => {
          //  debugger;
          setState({ saveModalVisible: false });

          if (!hasDBErrors(resultObjCustomer)) {
            // MySwal.close();
            console.log("resultObjCustomer", resultObjCustomer);
            const data = resultObjCustomer.data;
            //  debugger;
            console.log("User Created", data);
            openNotificationWithIcon(
              "success",
              "Success",
              "User registered Succesfully, Please login with the registered email"
            );
            navigate("/login", { replace: true });
            //Ask the use to Relogin
            //  debugger;
            // const resultObj = awaitsendResetEmailToUser(record.email);
            // if (resultObj.messageType == "success") {
            //   // if (resultObj.errCode == "0") {
            //   // MySwal.close();

            //  openNotificationWithIcon(
            //     "success",
            //     "Success",
            //     resultObj.message
            //   );
            // } else {
            //   // MySwal.close();
            //   //Display Error
            //  openNotificationWithIcon(
            //     "error",
            //     "Error",
            //     resultObj.message
            //       ? resultObj.message
            //       : "An error has occured!"
            //   );
            // }
          } else {
            //Display Error
            openNotificationWithIcon(
              "error",
              "Error",
              resultObj.message ? resultObj.message : "An error has occured!"
            );
          }
          // MySwal.close();
        })
        .catch((error) => {
          // MySwal.close();
          setState({ saveModalVisible: false });
          console.log("Error", error);
        });

      //setSpaceTypeVisible(false);
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  return (
    <>
      <section className="flex h-screen overflow-x-hidden bg-white">
        <div className="flex flex-col md:flex-row items-center w-full justify-center">
          <div className="w-full md:hidden flex justify-center my-6">
            <Link aria-label="logo" to="/">
              <img
                className=" max-w-[65px] m-auto sm:max-w-[75px]"
                src={Logo}
                alt="logo"
              />
            </Link>
          </div>

          <div className="w-[50%] hidden md:block">
            <img
              src={regiterTruk}
              alt="BlueTruck"
              className="object-cover w-full min-h-screen xl:max-h-[768px] object-right"
            />
          </div>

          <div className="sm:w-[50%] w-full max-w-[550px] sm:max-w-[550px] mx-auto px-4 lg:px-0 xl:pt-[50px] 2xl:pt-0 ">
            <h2 className="text-lg md:text-[32px] lg:leading-[55px] lg:text-xl font-bold font-Manrope text-black text-center md:text-start">
              Register yourself
            </h2>
            <NewCustomerForm
              id="dataEntryDiv"
              {...layout}
              name="control-ref"
              ref={formRegisterRef}
              onCancel={(e) => navigate("/", { replace: true })}
              onSave={(e) => saveCustomer()}
              initialValues={{
                email: undefined,
                phone_number: undefined,
                first_name: undefined,
                last_name: undefined,
                street_address: undefined,
                city: undefined,
                state: undefined,
                zipcode: undefined,
                country: "USA",
                company_name: undefined,
                user_id: -1,
                role: 4,
                default_site_id: 5, //undefined, //props.default_site_id, //undefined, //Sicklerville
                groups: "1",
                apt_suite_number: undefined,
                user_name: "",
                password: "",
                phone_number: undefined,
                country_code: "1",
              }}
              phoneVerification={true}
              showDefaultSite={true}
              showStatus={false}
              showGroup={false}
              showMap={false}
              showAddress={true}
              sites={props.sites}
              roles={roles}
              disableEmailandPhone={false} //reserveEmailExists == "Y"
            />
            {/* <form action="" className="mt-9 font-Poppins">
              <label className="text-base font-Poppins font-normal w-full text-black block mb-[2px] ">
                Full name
              </label>
              <input
                className="border-[1px] font-Poppins  h-[40px]   border-[#0505053D] bg-white rounded-[6px]  py-[11px] ps-2 w-full focus:outline-primary"
                type="text"
                required
                placeholder="Jhon deo"
              />
              <label className="text-base font-Poppins w-full font-normal text-black block mb-[2px] mt-4">
                Email
              </label>
              <input
                className="border-[1px] font-Poppins  h-[40px] focus:outline-primary border-[#0505053D] bg-white rounded-[6px]  py-[11px] ps-2 w-full"
                type="email"
                required
                placeholder="example@mail.com"
              />
              <label className="text-base font-Poppins w-full font-normal text-black block mb-[2px] mt-4">
                Create password
              </label>
              <input
                className="border-[1px] font-Poppins  h-[40px] focus:outline-primary border-[#0505053D] bg-white rounded-[6px]  py-[11px] ps-2 w-full"
                type="password"
                required
                placeholder="*************"
              />
              <label className="text-base font-Poppins w-full font-normal text-black block mb-[2px] mt-4">
                Confirm password
              </label>
              <input
                className="border-[1px] font-Poppins h-[40px] focus:outline-primary border-[#0505053D] bg-white rounded-[6px]  py-[11px] ps-2 w-full"
                type="password"
                required
                placeholder=" *************"
              />
              <Link to="/" className="text-primary font-semibold">
                <button className="first_btn h-[49px]  w-full py-[12px] text-white text-base font-semibold  mt-[33px] font-Manrope">
                  Register now
                </button>
              </Link>
            </form>
            <div className="flex my-[30px] justify-between items-center">
              <span className="h-[1px]  bg-black w-[139px]"></span>
              <span className="inline-block mx-[27px] font-Poppins text-black text-md opacity-50">
                or
              </span>
              <span className="h-[1px] bg-black w-[139px]"></span>
            </div>
            <div className="flex items-center justify-center gap-4 ">
              <a
                className="hover:-translate-y-1 duration-300 ease-in-out  "
                href="https://www.google.com"
              >
                <GoogleIcon />
              </a>
              <a
                className="hover:-translate-y-1 duration-300 ease-in-out  "
                href="https://www.apple.com"
              >
                <AppleIcon />
              </a>
              <a
                className="hover:-translate-y-1 duration-300 ease-in-out  "
                href="https://www.facebook.com"
              >
                <FacebookIcon />
              </a>
            </div>
            <p className="text-center mt-[33px] font-Poppins text-black text-base">
              Already have an account ?{" "}
              <Link
                to="/login"
                className="text-primary font-semibold hover:opacity-60"
              >
                Login
              </Link>
            </p> */}
          </div>
        </div>
        <Modal
          // title="Saving Reservation"
          centered
          open={state.saveModalVisible}
          onOk={() => setSaveModalVisible(false)}
          onCancel={() => setSaveModalVisible(false)}
          width={1100}
          footer={null}
          closable={false}
          maskClosable={false}
          className="childModal"
        >
          <Space direction="vertical" style={{ width: "80%", padding: "24px" }}>
            <Spin Size="Large">
              <Alert
                message="Login"
                description="Saving Registration, Please be Patient"
                type="info"
              />
            </Spin>
          </Space>
        </Modal>
        {/* <Modal
          title="Enter OTP"
          centered
          open={state.otpModalVisible}
          onOk={() => setOTPVisible(false)}
          onCancel={() => setOTPVisible(false)}
          width={400}
          height={400}
          footer={null}
          closable={false}
          maskClosable={false}
          style={{ zIndex: 1100, borderBottom: "1px solid #ccc" }}
          destroyOnClose={true}
          className="parentModal"
        >
          <div style={{ minHeight: "200px" }}>
            <Row style={{ margin: "25px 0px" }}>
              <Col span="6">
                <span>OTP</span>
              </Col>
              <Col span="18">
                <Input
                  style={{ width: 160 }}
                  value={state.OTPNumber}
                  maxLength={6}
                  onChange={(e) => updateOTPNumber(e)}
                ></Input>
              </Col>
            </Row>
            <Row style={{ marginTop: "16px" }}>
              <Col span="6"></Col>
              <Col span="18" className="text-right">
                <span>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "8px" }}
                    onClick={(e) => verifyOTP()}
                  >
                    Verify
                  </Button>
                  <Button
                    htmlType="button"
                    style={{ marginRight: "8px" }}
                    onClick={(e) => setOTPVisible(false)}
                  >
                    Cancel
                  </Button>
                </span>
              </Col>
            </Row>
          </div>
        </Modal> */}
      </section>
    </>
  );
};

const mapStateToProps = (state /*, ownProps*/) => {
  console.log("State", state);
  const siteData = getSiteDataSelector(state);
  const referenceData = getReferenceDataSelector(state);
  const sessionData = getSessionDataSelector(state);
  const isMobile = getMobileDeviceSelector(state);
  // const vehiclesData = getVehicleDataSelector(state);
  // const user = getUserDataSelector(state);
  // const refData = getReferenceDataSelector(state);
  // const usersData = getUsersDataSelector(state);
  return {
    siteData,
    referenceData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSitesData: (payload) => {
      debugger;
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch getSitesData");
      console.log("dispatch getSitesData payload", payload);
      dispatch(actionActions.getSitesData(payload));
    },
    refreshReferenceData: (payload) => {
      debugger;
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch refreshReferenceData");
      console.log("dispatch refreshReferenceData payload", payload);
      dispatch(actionActions.refreshReferenceData(payload));
    },
    insertUpdateVehicle: (payload) => {
      debugger;
      //   dispatch(InvoiceA(userId, token));
      console.log("dispatch insertUpdateVehicle");
      console.log("dispatch insertUpdateVehicle payload", payload);
      dispatch(actionActions.insertUpdateVehicle(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
