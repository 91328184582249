import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { MaskedInput } from "antd-mask-input";
// import { xml2json } from "xml-js";
const convert = require("xml-js");
import AppConfig from "../../../constants/AppConfig.js";
// import xml2js from "xml2js";
// const parser = new xml2js.Parser();

const ZipCodeInput = ({ validate, onValidate, onChange, ...props }) => {
  const validateZip = useCallback(async (e) => {
    const zipCode = e.target.value;
    if (onChange) {
      onChange(zipCode);
    }

    if (validate === false) {
      return false;
    }
    if (zipCode && zipCode.length == 5 && zipCode.indexOf("_") == -1) {
      let URL = `/zipApi?API=CityStateLookup&XML=<CityStateLookupRequest USERID='601PARKN2685'><ZipCode ID='0'><Zip5>${zipCode}</Zip5></ZipCode></CityStateLookupRequest>`;
      if (process.env.NODE_ENV == "production") {
        URL = `${AppConfig.USPS_URL}${URL}`;
      }
      try {
        const request = await fetch(URL);
        const content = await request.text();
        const retXML = await convert.xml2json(content, {
          compact: true,
          spaces: 4,
        }); //xml2json(content);
        // const retXML = await parser.parseStringPromise(content);
        console.log("retXML", retXML);
        // debugger;
        const response = JSON.parse(retXML).CityStateLookupResponse.ZipCode;
        console.log("response", response);
        if (response && response.Error) {
          if (onValidate) {
            onValidate(false, response.Error.Description._text);
          }
          return;
        }
        const output = {
          city: response?.City._text,
          state: response?.State._text,
          zipCode: zipCode,
        };

        if (onValidate) {
          onValidate(true, output);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, []);

  return <MaskedInput mask={"00000"} onChange={validateZip} {...props} />;
};

ZipCodeInput.propTypes = {
  validate: PropTypes.bool,
  onValidate: PropTypes.func,
  onChange: PropTypes.func,
};

export default ZipCodeInput;
