/* eslint-disable no-unused-vars */
import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import { delay, buffers, eventChannel, END } from "redux-saga";
import * as _ from "lodash";
import { types as customerTypes } from "./../reducers/customerReducer";
import { types as dashBoardTypes } from "./../reducers/dashboardReducer";
import { types as initLoadypes } from "./../reducers/referenceDataReducer";
import { types as subscriptionsTypes } from "./../reducers/subscriptionsReducer";
import { types as subscriptionTypes } from "./../reducers/subscriptionReducer";
import { types as invoicesTypes } from "./../reducers/invoicesReducer";
import { types as paymentTypes } from "./../reducers/paymentsreducer";
import { types as actionTypes } from "./../reducers/actionReducer";
import { types as siteTypes } from "./../reducers/sitereducer";
import { types as spaceTypeTypes } from "./../reducers/spacetypesreducer";
import { types as statementTypes } from "./../reducers/statementreducer";
import { types as sessionTypes } from "./../reducers/sessionreducer";
import { types as spaceTypeRateTypes } from "./../reducers/spacetyperatesreducer";
import { types as ccTypes } from "./../reducers/ccreducer";
import { types as aiTypes } from "./../reducers/aginginvoicereducer";
import { types as vehicleTypes } from "./../reducers/vehiclereducer";
import { types as affiliateTypes } from "./../reducers/affiliatereducer";
import { types as requestsTypes } from "./../reducers/requestsreducer";
import { types as metricsTypes } from "./../reducers/metricsreducer";
import { types as entitlementTypes } from "./../reducers/entitlementsreducer";
import { types as lprTypes } from "./../reducers/lprreducer";
import { types as vacancyTypes } from "./../reducers/vacancyDataReducer";
import { types as spaceAvailableTypes } from "./../reducers/spaceavailabledatareducer";
import { types as reservationTypes } from "./../reducers/reservationsreducer";
import { types as expenseTypes } from "./../reducers/expensesReducer";
import { types as reportsTypes } from "./../reducers/reportsReducer";
import { types as reportTypes } from "./../reducers/reportReducer";
import { types as custStatementTypes } from "./../reducers/custstatementreducer";
import { types as documentTypes } from "./../reducers/documentreducer";
import { types as siteDocumentTypes } from "./../reducers/sitedocumentreducer";

// import { types as timeoutTypes } from "./../reducers/timeoutreducer";
import * as dashboardSagas from "./dashboardSaga";
import * as customerSagas from "./customerSaga";
import * as initLoadSagas from "./initLoadSaga";
import * as subscriptionsSagas from "./subscriptionsSaga";
import * as subscriptionSagas from "./subscriptionSaga";
import * as invoicesSagas from "./invoicesSaga";
import * as paymentsSagas from "./paymentsSaga";
import * as actionSagas from "./actionSaga";
import * as siteSagas from "./sitesSaga";
import * as spaceTypesSaga from "./spaceTypesSaga";
import * as statementSaga from "./statementsaga";
import * as sessionSagas from "./sessionsaga";
import * as spaceRateTypesSaga from "./spacetyperatessaga";
import * as ccSaga from "./ccsaga";
import * as affiliateSaga from "./affiliatesaga";
import * as vehicleSaga from "./vehiclesaga";
import * as requestsSaga from "./requestssaga";
import * as metricsSaga from "./metricssaga";
import * as entitlementsSaga from "./entitlementssaga";
import * as lprSaga from "./lprsaga";
import * as aiSaga from "./aisaga";
import * as vacancySaga from "./vacancysaga";
import * as spaceAvailableSaga from "./spaceavailablesaga";
import * as reservationSaga from "./reservationssaga";
import * as expenseSaga from "./expensesSaga";
import * as reportsSaga from "./reportsSaga";
import * as reportSaga from "./reportSaga";
import * as custStatementSaga from "./custstatementsaga";
import * as documentSaga from "./documentsaga";
import * as siteDocumentSaga from "./sitedocumentsaga";
export default function* rootSaga() {
  try {
    // yield all([fork(watchSocketChannel)]);
    yield all([
      takeEvery(
        [
          siteTypes.FETCH_DATA_REQUEST,
          siteTypes.INSERT_UPDATE_DATA_REQUEST,
          siteTypes.DELETE_REQUEST,
          siteTypes.INSERT_UPDATE_SITEPIC_REQUEST,
          siteTypes.DELETE_SITEPIC_REQUEST,
        ],
        siteSagas.handleRequest
      ),
      takeEvery(
        [
          spaceTypeTypes.FETCH_DATA_REQUEST,
          spaceTypeTypes.INSERT_UPDATE_DATA_REQUEST,
          spaceTypeTypes.DELETE_REQUEST,
        ],
        spaceTypesSaga.handleRequest
      ),
      takeEvery(
        [
          ccTypes.FETCH_DATA_REQUEST,
          ccTypes.INSERT_UPDATE_DATA_REQUEST,
          ccTypes.DELETE_REQUEST,
        ],
        ccSaga.handleRequest
      ),
      takeEvery(
        [
          aiTypes.FETCH_DATA_REQUEST,
          aiTypes.INSERT_UPDATE_DATA_REQUEST,
          aiTypes.DELETE_REQUEST,
        ],
        aiSaga.handleRequest
      ),
      takeEvery(
        [
          affiliateTypes.FETCH_DATA_REQUEST,
          affiliateTypes.INSERT_UPDATE_DATA_REQUEST,
          affiliateTypes.DELETE_REQUEST,
        ],
        affiliateSaga.handleRequest
      ),
      takeEvery(
        [
          requestsTypes.FETCH_DATA_REQUEST,
          requestsTypes.INSERT_UPDATE_DATA_REQUEST,
          requestsTypes.DELETE_REQUEST,
        ],
        requestsSaga.handleRequest
      ),

      takeEvery(
        [
          reservationTypes.FETCH_DATA_REQUEST,
          reservationTypes.INSERT_UPDATE_DATA_REQUEST,
          reservationTypes.DELETE_REQUEST,
        ],
        reservationSaga.handleRequest
      ),
      takeEvery(
        [
          metricsTypes.FETCH_DATA_REQUEST,
          metricsTypes.INSERT_UPDATE_DATA_REQUEST,
          metricsTypes.DELETE_REQUEST,
        ],
        metricsSaga.handleRequest
      ),

      takeEvery(
        [
          entitlementTypes.FETCH_DATA_REQUEST,
          entitlementTypes.INSERT_UPDATE_DATA_REQUEST,
          entitlementTypes.DELETE_REQUEST,
        ],
        entitlementsSaga.handleRequest
      ),

      takeEvery(
        [
          vehicleTypes.FETCH_DATA_REQUEST,
          vehicleTypes.INSERT_UPDATE_DATA_REQUEST,
          vehicleTypes.DELETE_REQUEST,
        ],
        vehicleSaga.handleRequest
      ),
      takeEvery(
        [
          vacancyTypes.FETCH_DATA_REQUEST,
          vacancyTypes.INSERT_UPDATE_DATA_REQUEST,
          vacancyTypes.DELETE_REQUEST,
        ],
        vacancySaga.handleRequest
      ),
      takeEvery(
        [
          expenseTypes.FETCH_DATA_REQUEST,
          expenseTypes.INSERT_UPDATE_DATA_REQUEST,
          expenseTypes.DELETE_REQUEST,
        ],
        expenseSaga.handleRequest
      ),
      takeEvery(
        [
          reportsTypes.FETCH_DATA_REQUEST,
          reportsTypes.INSERT_UPDATE_DATA_REQUEST,
          reportsTypes.DELETE_REQUEST,
        ],
        reportsSaga.handleRequest
      ),
      takeEvery(
        [reportTypes.FETCH_DATA_REQUEST, reportTypes.SET_ACTIVEREPORT_REQUEST],
        reportSaga.handleRequest
      ),
      takeEvery(
        [
          spaceAvailableTypes.FETCH_DATA_REQUEST,
          spaceAvailableTypes.INSERT_UPDATE_DATA_REQUEST,
          spaceAvailableTypes.DELETE_REQUEST,
        ],
        spaceAvailableSaga.handleRequest
      ),
      takeEvery(
        [
          lprTypes.FETCH_DATA_REQUEST,
          lprTypes.INSERT_UPDATE_DATA_REQUEST,
          lprTypes.DELETE_REQUEST,
        ],
        lprSaga.handleRequest
      ),
      takeEvery(
        [
          documentTypes.FETCH_DATA_REQUEST,
          documentTypes.INSERT_UPDATE_DATA_REQUEST,
          documentTypes.DELETE_REQUEST,
        ],
        documentSaga.handleRequest
      ),
      takeEvery(
        [
          siteDocumentTypes.FETCH_DATA_REQUEST,
          siteDocumentTypes.INSERT_UPDATE_DATA_REQUEST,
          siteDocumentTypes.DELETE_REQUEST,
        ],
        siteDocumentSaga.handleRequest
      ),
      takeEvery(
        [
          spaceTypeRateTypes.FETCH_DATA_REQUEST,
          spaceTypeRateTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        spaceRateTypesSaga.handleRequest
      ),
      takeEvery(
        [
          dashBoardTypes.FETCH_DATA_REQUEST,
          dashBoardTypes.INSERT_UPDATE_DATA_REQUEST,
          dashBoardTypes.DELETE_REQUEST,
        ],
        dashboardSagas.handleRequest
      ),
      takeEvery(
        [
          customerTypes.FETCH_DATA_REQUEST,
          customerTypes.FETCH_CUSTOMER_ONLY_DATA_REQUEST,
          customerTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        customerSagas.handleRequest
      ),
      takeEvery(
        [
          subscriptionsTypes.FETCH_DATA_REQUEST,
          subscriptionsTypes.FETCH_RPTDATA_REQUEST,
          subscriptionsTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        subscriptionsSagas.handleRequest
      ),
      takeEvery(
        [
          subscriptionTypes.FETCH_DATA_REQUEST,
          subscriptionTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        subscriptionSagas.handleRequest
      ),
      takeEvery([initLoadypes.FETCH_DATA_REQUEST], initLoadSagas.handleRequest),
      takeEvery(
        [
          sessionTypes.SET_SESSION_DATA_REQUEST,
          sessionTypes.SET_LOGIN_SESSION_DATA_REQUEST,
          sessionTypes.RESET_LOGIN_SESSION_DATA_REQUEST,
        ],
        sessionSagas.handleRequest
      ),
      takeEvery(
        [
          actionTypes.INSERT_UPDATE_SPACETYPE_REQUEST,
          actionTypes.INSERT_UPDATE_SITETYPE_REQUEST,
          actionTypes.INSERT_UPDATE_VEHICLE_REQUEST,
          actionTypes.INSERT_UPDATE_AFFLIATE_REQUEST,
          actionTypes.INSERT_UPDATE_DOCUMENT_REQUEST,
          actionTypes.INSERT_UPDATE_CARD_REQUEST,
          actionTypes.INSERT_UPDATE_CUSTOMER_REQUEST,
          actionTypes.INSERT_UPDATE_SUBSCRIPTION_REQUEST,
          actionTypes.DELETE_SUBSCRIPTION_REQUEST,
          actionTypes.INSERT_UPDATE_INVOICE_REQUEST,
          actionTypes.DELETE_INVOICE_REQUEST,
          actionTypes.DELETE_EXPENSE_REQUEST,
          actionTypes.DELETE_REPORT_REQUEST,
          actionTypes.DELETE_VEHICLE_REQUEST,
          actionTypes.DELETE_AFFILIATE_REQUEST,
          actionTypes.DELETE_CARD_REQUEST,
          actionTypes.DELETE_USER_REQUEST,
          actionTypes.CLEAR_ERROR_REQUEST,
          actionTypes.DELETE_DOCUMENT_REQUEST,
          actionTypes.REFRESH_REFDATA_REQUEST,
          actionTypes.REFRESH_REFDATA_SPECIFIC_REQUEST,
          actionTypes.INSERT_UPDATE_ADMINSITE_REQUEST,
          actionTypes.DELETE_ADMINSITE_REQUEST,
          actionTypes.INSERT_UPDATE_SPACETYPERATE_REQUEST,
          actionTypes.INSERT_UPDATE_LAYOUT_REQUEST,
          actionTypes.INSERT_UPDATE_PROFILE_REQUEST,
          actionTypes.FETCH_INVBALDATA_REQUEST,
          actionTypes.FETCH_NOTIFICATIONDATA_REQUEST,
          actionTypes.UPDATE_NOTIFICATIONS_REQUEST,
          actionTypes.CLEAR_INSERTEDDATA_REQUEST,
          actionTypes.FETCH_SITEDATA_REQUEST,
          actionTypes.FETCH_SITEVACANCYDATA_REQUEST,
        ],
        actionSagas.handleRequest
      ),
      takeEvery(
        [
          invoicesTypes.FETCH_DATA_REQUEST,
          invoicesTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        invoicesSagas.handleRequest
      ),
      takeEvery(
        [
          paymentTypes.FETCH_DATA_REQUEST,
          paymentTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        paymentsSagas.handleRequest
      ),
      takeEvery(
        [
          statementTypes.FETCH_DATA_REQUEST,
          statementTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        statementSaga.handleRequest
      ),
      takeEvery(
        [
          custStatementTypes.FETCH_DATA_REQUEST,
          custStatementTypes.INSERT_UPDATE_DATA_REQUEST,
        ],
        custStatementSaga.handleRequest
      ),
    ]);
  } catch (e) {
    console.log(e);
  }
}
