import React from "react";
import RealTime from "../../../assets/images/webp/real-time.webp";
import { Link } from "react-router-dom";
const RealTimeManagement = () => {
  return (
    <>
      <section className="py-12 md:py-20 lg:py-28 xl:py-[100px] bg-[rgba(168,168,168,0.08)] overflow-x-hidden">
        <div className="container">
          <div className="flex flex-col lg:flex-row gap-12 lg:gap-[35px] items-center">
            <div
              className="order-2 lg:order-none"
              data-aos="fade-right"
              data-aos-duration="1500">
              <div className="max-w-[558px]">
                <h2 className="text-lg md:text-[32px] lg:text-xl font-bold font-Manrope text-black mb-4">
                  Real-Time Management
                </h2>
                <p className="text-base font-Poppins font-normal text-[#000] leading-[160%] opacity-70 mb-12">
                  The ParkNation platform operates in real-time, because that's
                  the speed of business in the 21st century digital environment.
                  From reservations and security visibility, to tracking and
                  reporting: you can see it as it happens and respond with
                  better decisions. Gain actionable data at your fingertips
                  quickly, so you can run your business ultra-efficiently.
                </p>
                <Link
                  to="/account-sign-up"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                  className="p-[8px_16px] inline-block sm:p-[14px_26px] first_btn font-semibold whitespace-nowrap font-Manrope text-base">
                  Sign Up Now
                </Link>
              </div>
            </div>
            <div data-aos="fade-left" data-aos-duration="1500">
              <div className="max-w-[558px]">
                <img className="w-full" src={RealTime} alt="RealTime" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RealTimeManagement;
