/* eslint-disable no-unused-vars */
import merge from "lodash/merge";
import AppConfig from "../constants/AppConfig";
import { GlobalStoreContainer } from "./../../src/store";
import {
  Button,
  Card as ACard,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Dropdown,
  Empty,
  FloatButton,
  Form,
  Input,
  InputNumber,
  Layout,
  Menu,
  message,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Statistic,
  Switch,
  Table,
  Tabs,
  Tag,
  Typography,
} from "antd";

import { isNil } from "lodash";
let baseToken = "2412412214122444";

// fetchJSON is bundled wrapper around fetch which simplifies working
// with JSON API:
//   * Automatically adds Content-Type: application/json to request headers
//   * Parses response as JSON when Content-Type: application/json header is
//     present in response headers
//   * Converts non-ok responses to errors

// Provide your favorite token saving -- to cookies, local storage, ...
const retrieveToken = async () => {
  return await localStorage.getItem("refreshToken"); //accessToken
};

const saveToken = (token) => {
  localStorage.setItem("refreshToken", token.refreshToken);
  localStorage.setItem("accessToken", token.accessToken);
};

const clearToken = () => {
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("accessToken");
  localStorage.removeItem("session_id");
};

// Add token to the request headers
const getRefreshToken = async (optionsWithToken) => {
  const optionsWithTokenEnhanced = {
    ...optionsWithToken,
    body: JSON.stringify({}),
  };
  return await fetch(AppConfig.TOKEN_URL, optionsWithTokenEnhanced)
    .then((response) => response.json())
    .catch((error) => error);
};

const openNotificationWithIcon = (type, title, desc) => {
  notification[type]({
    message: title,
    description: desc,
  });
};

export const PNfetch = async (url, options = {}) => {
  debugger;
  console.log("options", options);
  let token = await retrieveToken();
  // const token = localStorage.getItem("accessToken");
  if (token == null) {
    console.log("*********localStorage**********", localStorage);
    options = merge({}, options, {
      headers: {
        authorization: `Bearer ${baseToken}`,
      },
    });
    token = baseToken;
    // options.headers.authorization = "Bearer " + baseToken;
  } else {
    baseToken = token;
    options = merge({}, options, {
      headers: {
        authorization: `Bearer ${baseToken}`,
      },
    });
  }

  console.log("*********token**********", token);
  console.log("options new", options);
  let optionsWithToken = {};
  if (!isNil(token)) {
    optionsWithToken = merge(
      {},
      {
        method: "POST",
        // mode: "cors",
        // credentials: "include",
        headers: {
          authorization: `Bearer ${token}`, //`Bearer ${retrieveToken()}`,
        },
      }
    );
  }

  console.log("options.headers.authorization", options.headers.authorization);
  const response = await fetch(url, options); //options
  console.log("response", response);
  if (response.status == 403) {
    // debugger;
    console.log("AppConfig.TOKEN_URL", AppConfig.TOKEN_URL);
    const tokenWait = await getRefreshToken(optionsWithToken);
    console.log("tokenWait", tokenWait);
    if (tokenWait.messageType == "success") {
      saveToken(tokenWait);
      return new Promise((resolve, reject) => {
        // const NT = resolve(newToken);
        // console.log("newToken", NT);
        console.log("Calling with new Token");
        optionsWithToken = merge({}, options, {
          headers: {
            authorization: `Bearer ${tokenWait.accessToken}`,
          },
        });

        return fetch(url, optionsWithToken)
          .then((response) => {
            // debugger;
            return resolve(response);
          })
          .catch((error) => {
            // debugger;
            reject(error);
          });
      });
    } else {
      // openNotificationWithIcon(
      //   "error",
      //   "Error",
      //   "Token Expired, Please Re-login"
      // );

      console.log(
        "****accessToken in getDataForUser ****",
        localStorage.getItem("accessToken")
      );
      console.log(
        "****refreshToken in getDataForUser ****",
        localStorage.getItem("refreshToken")
      );

      //Log off user in DB
      if (localStorage.getItem("session_id") != "") {
        debugger;
        const retObj = await fetch(AppConfig.LOGOUT_URL, {
          method: "POST",
          // mode: "cors",
          // credentials: "include",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // authorization: "Bearer " + localStorage.getItem("accessToken"),
          },
          body: JSON.stringify({
            session_id: localStorage.getItem("session_id"),
            timed_out: "Y",
            logged_out: "Y",
          }),
        });
      }

      clearToken();

      let store = GlobalStoreContainer.store;
      // store.dispatch({ type: "CLEAR_ALL_REDUCERS_DATA" });
      store.dispatch({ type: "LOGOUT_USER" });
      window.location.href = "/";
      //Take to Login Screen
      //       return new Promise((resolve, reject) => {
      //         reject(tokenWait);
      //       });
    }
  } else {
    if (response.status == 400) {
      debugger;
      clearToken();
      let store = GlobalStoreContainer.store;
      // store.dispatch({ type: "CLEAR_ALL_REDUCERS_DATA" });
      store.dispatch({ type: "LOGOUT_USER" });
      window.location.href = "/";
    } else {
      return new Promise((resolve, reject) => {
        return resolve(response);
      });
    }
  }
};

export const placeLookup = async (placeId) => {
  let URL = process.env.REACT_APP_API_URL + `/PlaceLookup`;
  try {
    const response = await PNfetch(URL, {
      method: "POST",
      // mode: "cors",
      // credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        placeId: placeId,
      }),
    });
    return await response.json();
  } catch (error) {
    console.log("Address lookup failed", error.message);
  }
};
