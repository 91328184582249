// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "CUSTSTATEMENTS/FETCH_REQUEST",
  CUSTSTATEMENTDATA: "CUSTSTATEMENTS/CUSTSTATEMENTDATA",
  DELETE_REQUEST: "CUSTSTATEMENTS/DELETE_REQUEST",
  INSERT_REQUEST: "CUSTSTATEMENTS/INSERT_REQUEST",
  UPDATE_REQUEST: "CUSTSTATEMENTS/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "CUSTSTATEMENTS/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "CUSTSTATEMENTS/MESSAGE",
  TOKEN: "CUSTSTATEMENTS/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.CUSTSTATEMENTDATA:
      return { ...state, ...action.custStatementData };

    default:
      return state;
  }
};

export const actions = {
  getCustStatementsData: (payload) => payload,
  insertUpdateCustStatement: (payload) => payload,
  deleteCustStatement: (payload) => payload,
};
