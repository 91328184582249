// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "SPACETYPES/FETCH_REQUEST",
  SPACETYPESDATA: "SPACETYPES/SPACETYPESDATA",
  DELETE_REQUEST: "SPACETYPES/DELETE_REQUEST",
  INSERT_REQUEST: "SPACETYPES/INSERT_REQUEST",
  UPDATE_REQUEST: "SPACETYPES/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "SPACETYPES/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "SPACETYPES/MESSAGE",
  TOKEN: "SPACETYPES/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.SPACETYPESDATA:
      return { ...state, ...action.spaceTypesData };

    default:
      return state;
  }
};

export const actions = {
  getSpaceTypesData: (payload) => payload,
  insertUpdateSpaceType: (payload) => payload,
  deleteSpaceType: (payload) => payload,
};
