//isample comment by karthik//

import { capitalize, map, isNil, trim } from "lodash";
import dayjs from "dayjs";
import AppConfig from "../constants/AppConfig";
import { PNfetch } from "../api/pnfetch";
import weekday from "dayjs/plugin/weekday.js";
dayjs.extend(weekday);
export const titleCase = (str) =>
  map((str || "").split(" "), capitalize).join(" ");

export const getState = (zipcode) => {
  // Returns false on invalid zip-- else returns {code:"XX" long:"XXXXXXXXX"}

  // Ensure param is a string to prevent unpredictable parsing results
  if (typeof zipcode !== "string") {
    console.log(
      "Must pass the zipcode as a string. -- Otherwise leading zeros could cause your zip code to be parsed outside base 10."
    );
    return;
  }

  // Ensure you don't parse codes that start with 0 as octal values
  zipcode = parseInt(zipcode, 10);

  // Code blocks alphabetized by state
  var states = [
    { min: 35000, max: 36999, code: "AL", long: "Alabama" },
    { min: 99500, max: 99999, code: "AK", long: "Alaska" },
    { min: 85000, max: 86999, code: "AZ", long: "Arizona" },
    { min: 71600, max: 72999, code: "AR", long: "Arkansas" },
    { min: 90000, max: 96699, code: "CA", long: "California" },
    { min: 80000, max: 81999, code: "CO", long: "Colorado" },
    { min: 6000, max: 6999, code: "CT", long: "Connecticut" },
    { min: 19700, max: 19999, code: "DE", long: "Deleware" },
    { min: 32000, max: 34999, code: "FL", long: "Florida" },
    { min: 30000, max: 31999, code: "GA", long: "Georgia" },
    { min: 96700, max: 96999, code: "HI", long: "Hawaii" },
    { min: 83200, max: 83999, code: "ID", long: "Idaho" },
    { min: 60000, max: 62999, code: "IL", long: "Illinois" },
    { min: 46000, max: 47999, code: "IN", long: "Indiana" },
    { min: 50000, max: 52999, code: "IA", long: "Iowa" },
    { min: 66000, max: 67999, code: "KS", long: "Kansas" },
    { min: 40000, max: 42999, code: "KY", long: "Kentucky" },
    { min: 70000, max: 71599, code: "LA", long: "Louisiana" },
    { min: 3900, max: 4999, code: "ME", long: "Maine" },
    { min: 20600, max: 21999, code: "MD", long: "Maryland" },
    { min: 1000, max: 2799, code: "MA", long: "Massachusetts" },
    { min: 48000, max: 49999, code: "MI", long: "Michigan" },
    { min: 55000, max: 56999, code: "MN", long: "Minnesota" },
    { min: 38600, max: 39999, code: "MS", long: "Mississippi" },
    { min: 63000, max: 65999, code: "MO", long: "Missouri" },
    { min: 59000, max: 59999, code: "MT", long: "Montana" },
    { min: 27000, max: 28999, code: "NC", long: "North Carolina" },
    { min: 58000, max: 58999, code: "ND", long: "North Dakota" },
    { min: 68000, max: 69999, code: "NE", long: "Nebraska" },
    { min: 88900, max: 89999, code: "NV", long: "Nevada" },
    { min: 3000, max: 3899, code: "NH", long: "New Hampshire" },
    { min: 7000, max: 8999, code: "NJ", long: "New Jersey" },
    { min: 87000, max: 88499, code: "NM", long: "New Mexico" },
    { min: 10000, max: 14999, code: "NY", long: "New York" },
    { min: 43000, max: 45999, code: "OH", long: "Ohio" },
    { min: 73000, max: 74999, code: "OK", long: "Oklahoma" },
    { min: 97000, max: 97999, code: "OR", long: "Oregon" },
    { min: 15000, max: 19699, code: "PA", long: "Pennsylvania" },
    { min: 300, max: 999, code: "PR", long: "Puerto Rico" },
    { min: 2800, max: 2999, code: "RI", long: "Rhode Island" },
    { min: 29000, max: 29999, code: "SC", long: "South Carolina" },
    { min: 57000, max: 57999, code: "SD", long: "South Dakota" },
    { min: 37000, max: 38599, code: "TN", long: "Tennessee" },
    { min: 75000, max: 79999, code: "TX", long: "Texas" },
    { min: 88500, max: 88599, code: "TX", long: "Texas" },
    { min: 84000, max: 84999, code: "UT", long: "Utah" },
    { min: 5000, max: 5999, code: "VT", long: "Vermont" },
    { min: 22000, max: 24699, code: "VA", long: "Virgina" },
    { min: 20000, max: 20599, code: "DC", long: "Washington DC" },
    { min: 98000, max: 99499, code: "WA", long: "Washington" },
    { min: 24700, max: 26999, code: "WV", long: "West Virginia" },
    { min: 53000, max: 54999, code: "WI", long: "Wisconsin" },
    { min: 82000, max: 83199, code: "WY", long: "Wyoming" },
  ];

  var state = states.filter(function (s) {
    return s.min <= zipcode && s.max >= zipcode;
  });

  if (state.length == 0) {
    return false;
  } else if (state.length > 1) {
    console.error("Whoops found two states");
  }
  return { code: state[0].code, long: state[0].long };
};

// https://secure.shippingapis.com/ShippingAPI.dll?API=CityStateLookup&XML=
// <CityStateLookupRequest  USERID="601PARKN2685">
// <ZipCode ID="0"><Zip5>10016</Zip5></ZipCode>
// </CityStateLookupRequest>

// API=CityStateLookup&XML=%3CCityStateLookupRequest%20USERID=%27601PARKN2685%27%3E%3CZipCode%20ID=%270%27%3E%3CZip5%3E08536%3C/Zip5%3E%3C/ZipCode%3E%3C/CityStateLookupRequest%3E

export const countryList = [
  { code: "US", code3: "USA", name: "United States", number: "840" },
];
export const stateList = [
  { value: "AK", text: "Alaska" },
  { value: "AL", text: "Alabama" },
  { value: "AR", text: "Arkansas" },
  { value: "AS", text: "American Samoa" },
  { value: "AZ", text: "Arizona" },
  { value: "CA", text: "California" },
  { value: "CO", text: "Colorado" },
  { value: "CT", text: "Connecticut" },
  { value: "DC", text: "District of Columbia" },
  { value: "DE", text: "Delaware" },
  { value: "FL", text: "Florida" },
  { value: "GA", text: "Georgia" },
  { value: "GU", text: "Guam" },
  { value: "HI", text: "Hawaii" },
  { value: "IA", text: "Iowa" },
  { value: "ID", text: "Idaho" },
  { value: "IL", text: "Illinois" },
  { value: "IN", text: "Indiana" },
  { value: "KS", text: "Kansas" },
  { value: "KY", text: "Kentucky" },
  { value: "LA", text: "Louisiana" },
  { value: "MA", text: "Massachusetts" },
  { value: "MD", text: "Maryland" },
  { value: "ME", text: "Maine" },
  { value: "MI", text: "Michigan" },
  { value: "MN", text: "Minnesota" },
  { value: "MO", text: "Missouri" },
  { value: "MS", text: "Mississippi" },
  { value: "MT", text: "Montana" },
  { value: "NC", text: "North Carolina" },
  { value: "ND", text: "North Dakota" },
  { value: "NE", text: "Nebraska" },
  { value: "NH", text: "New Hampshire" },
  { value: "NJ", text: "New Jersey" },
  { value: "NM", text: "New Mexico" },
  { value: "NV", text: "Nevada" },
  { value: "NY", text: "New York" },
  { value: "OH", text: "Ohio" },
  { value: "OK", text: "Oklahoma" },
  { value: "OR", text: "Oregon" },
  { value: "PA", text: "Pennsylvania" },
  { value: "PR", text: "Puerto Rico" },
  { value: "RI", text: "Rhode Island" },
  { value: "SC", text: "South Carolina" },
  { value: "SD", text: "South Dakota" },
  { value: "TN", text: "Tennessee" },
  { value: "TX", text: "Texas" },
  { value: "UT", text: "Utah" },
  { value: "VA", text: "Virginia" },
  { value: "VI", text: "Virgin Islands" },
  { value: "VT", text: "Vermont" },
  { value: "WA", text: "Washington" },
  { value: "WI", text: "Wisconsin" },
  { value: "WV", text: "West Virginia" },
  { value: "WY", text: "Wyoming" },
];

export const filterTypes = [
  { value: "=", description: "is equal to" },
  { value: "<", description: "is less than" },
  { value: "<=", description: "is less than or equal to" },
  { value: ">", description: "is greater than" },
  { value: ">=", description: "is greater than or equal to" },
  { value: "range", description: "is between" },
];

export const dateFilterTypes = [
  { value: "=", description: "is equal to" },
  { value: "<", description: "is before" },
  { value: "<=", description: "is before or on" },
  { value: ">", description: "is after" },
  { value: ">=", description: "is after or on" },
  { value: "range", description: "is between" },
];

export const forwardRangePresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Next 7 Days",
    value: [dayjs(), dayjs().add(7, "d")],
  },
  {
    label: "Next 4 weeks",
    value: [dayjs(), dayjs().add(28, "d")],
  },
  {
    label: "Next 3 months",
    value: [dayjs(), dayjs().add(3, "months")],
  },
  {
    label: "Next 12 months",
    value: [dayjs(), dayjs().add(12, "months")],
  },
  {
    label: "Month to date",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Quarter to date",
    value: [dayjs().startOf("quarter"), dayjs()],
  },
  {
    label: "Year to date",
    value: [dayjs().startOf("year"), dayjs()],
  },
];

export const rangeForwardPresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
  },
  {
    label: "This Weekend",
    value: [dayjs().weekday(5), dayjs().weekday(5).add(3, "d")],
  },

  {
    label: "1 Week",
    value: [dayjs(), dayjs().add(7, "d")],
  },
  // {
  //   label: "Next 2 Weeks",
  //   value: [dayjs().weekday(5), dayjs().weekday(5).add(14, "d")],
  // },
  {
    label: "1 Month",
    value: [dayjs(), dayjs().add(1, "months")],
  },
  {
    label: "3 months",
    value: [dayjs(), dayjs().add(3, "months")],
  },
];

export const rangePresets = [
  {
    label: "Today",
    value: [dayjs(), dayjs()],
  },
  {
    label: "Last 7 Days",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Last 4 weeks",
    value: [dayjs().add(-28, "d"), dayjs()],
  },
  {
    label: "Last 3 months",
    value: [dayjs().add(-3, "months"), dayjs()],
  },
  {
    label: "Last 12 months",
    value: [dayjs().subtract(12, "months"), dayjs()],
  },
  {
    label: "Last 24 months",
    value: [dayjs().subtract(24, "months"), dayjs()],
  },
  {
    label: "Month to date",
    value: [dayjs().startOf("month"), dayjs()],
  },
  {
    label: "Quarter to date",
    value: [dayjs().startOf("quarter"), dayjs()],
  },

  {
    label: "Year to date",
    value: [dayjs().startOf("year"), dayjs()],
  },
];

const hasDBErrors = (retObj) => {
  if (!isNil(retObj.errCode) && retObj.errCode != "0") {
    return true;
  } else {
    return false;
  }
};

const saveLayout = (parms) => {
  // debugger;

  return new Promise((resolve, reject) => {
    PNfetch(AppConfig.SQL_URL, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        authorization: "Bearer " + localStorage.getItem("accessToken"),
      },
      body: JSON.stringify({
        proc: "spi_user_layout_preferences",
        input: parms,
      }),
    })
      .then((response) => resolve(response ? response.json() : null))
      .catch((error) => reject(error));
  });
};

export const saveTableColLayout = (parms) => {
  saveLayout(parms)
    .then((resultObj) => {
      //  // debugger;
      if (!hasDBErrors(resultObj)) {
        // const data = resultObj.data;
      }
    })
    .catch((error) => {
      // MySwal.close();
      console.log("Error", error);
    });
};

export const formatNumber = (val) => {
  return formatMoney(val, {
    symbol: "$",
    precision: 2,
    thousand: ",",
    format: {
      pos: "%s %v",
      neg: "%s (%v)",
      zero: "%s --",
    },
  });
};

export const formatPhoneNumber = (str) => {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");

  //Check if the input is of correct length
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const mapRole = (role) => {
  if (role == 1) {
    return "Super Admin";
  } else if (role == 2) {
    return "Admin";
  } else if (role == 3) {
    return "Site Admin";
  } else if (role == 4) {
    return "User";
  } else {
    return "Unknown";
  }
};

export const printCustName = (item) => {
  //
  let custStr = "";
  if (item.customer_name == "ALL") {
    custStr =
      (item.customer_name ? item.customer_name : " ") + " | " + "All Users ";
  } else {
    custStr =
      (item.last_name ? item.last_name : " ") +
      ", " +
      (item.first_name ? item.first_name : " ") +
      " | " +
      (item.role ? mapRole(item.role) : "No Role") +
      " | " +
      (item.email ? item.email : "No Email") +
      " | " +
      (item.phone_number
        ? formatPhoneNumber(item.phone_number)
        : "No Phone #") +
      " | " +
      (item.company_name ? item.company_name : "No Company Name");
  }

  return custStr;
};

export const printSiteName = (item) => {
  //
  let custStr = "";
  if (item.name == "ALL") {
    custStr = (item.name ? item.name : " ") + " | " + "All Sites ";
  } else {
    custStr =
      (item.name ? item.name : " ") +
      "| " +
      (item.description ? item.description : " ") +
      " | " +
      (item.city ? item.city : "No City") +
      " | " +
      (item.state ? item.state : "No State") +
      " | " +
      (item.zipcode ? item.zipcode : "No Zip");
  }
  return custStr;
};
