import React from "react";
import { ExploreMoreBenefitsData } from "../../common/Helper";
import Level from "../../common/Level";

const ExploreMoreBenefits = () => {
  return (
    <>
      <section className="mb-12 md:mb-20 lg:mb-28 xl:mb-[150px]">
        <div className="container">
          <h2 className="font-Manrope font-bold text-black text-lg md:text-[32px] lg:text-xl text-center mb-4">
            Explore more Benefits{" "}
          </h2>
          <p className="font-Poppins font-normal opacity-70 text-base text-center mx-auto lg:max-w-[735px] mb-12 md:mb-16 lg:mb-20 xl:mb-28 ">
            Tellus a id viverra eu scelerisque felis porttitor vitae. Aliquam
            tortor neque porttitor amet platea dictum. Sit dolor rhoncus sed
            fringilla lorem.
          </p>

          <div
            className="flex flex-col sm:flex-row sm:grid grid-cols-2 justify-between items-center  
          lg:max-w-[1040px]  gap-x-[156px] gap-y-6 md:gap-y-10 lg:gap-y-20 ms-5 sm:mx-11" 
          >
            {/* ==== ExploreMoreBenefitsData START ==== */}
            {ExploreMoreBenefitsData &&
              ExploreMoreBenefitsData.map((obj, index) => (
                <Level obj={obj} key={index} />
              ))}
            {/* ==== ExploreMoreBenefitsData END ==== */}
          </div>
        </div>
      </section>
    </>
  );
};

export default ExploreMoreBenefits;
