import React from "react";
import { blogpagecards } from "../../common/Helper";
import BlogCommonCard from "../../common/BlogCommonCard";

const BlogCards = () => {
  return (
    <>
      <section>
        <div className="container">
          <div className="flex justify-center flex-wrap sm:grid sm:grid-cols-2 gap-6 lg:gap-y-20 lg:grid-cols-3 mt-12 md:mt-16 xl:mt-20">
            {blogpagecards &&
              blogpagecards.map((obj, index) => (
                <div className=" w-full">
                  <BlogCommonCard obj={obj} key={index} />
                </div>
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogCards;
