// import defaultData from "./commonreducer";

export const types = {
  FETCH_DATA_REQUEST: "DASHBOARD/FETCH_REQUEST",
  DASHBOARDDATA: "DASHBOARD/DASHBOARDDATA",
  DELETE_REQUEST: "DASHBOARD/DELETE_REQUEST",
  INSERT_REQUEST: "DASHBOARD/INSERT_REQUEST",
  UPDATE_REQUEST: "DASHBOARD/UPDATE_REQUEST",
  INSERT_UPDATE_DATA_REQUEST: "DASHBOARD/INSERT_UPDATE_DATA_REQUEST",
  MESSAGE: "DASHBOARD/MESSAGE",
  TOKEN: "DASHBOARD/TOKEN",
};

// export const defaultData = {
//   data: [],
//   lastFetchSuccess: Date.now(),
//   loading: true,
//   hasLoaded: false,
//   hasError: false,
// };
export const initialState = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

//export function authState (state = initialState, action) {
export default (state = initialState, action) => {
  //
  switch (action.type) {
    case types.DASHBOARDDATA:
      return { ...state, ...action.dashBoardData };

    default:
      return state;
  }
};

export const actions = {
  getSpaceData: (payload) => payload,
  getDashBoardData: (payload) => payload,
  insertUpdateSpace: (payload) => payload,
  deleteSpace: (payload) => payload,
};
