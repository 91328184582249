import React from "react";
import AuthenticarTruck from "../assets/images/webp/authenticator-truck.webp";
import QRCode from "react-qr-code";
import Logo from "../assets/images/webp/logo.webp";
import { Link } from "react-router-dom";
import OTPInput from "otp-input-react";
import { useState } from "react";

const Authenticator = () => {
  const [OTP, setOTP] = useState("");
  return (
    <>
      <section className="flex items-center  h-screen overflow-x-hidden bg-white">
        <div className="flex flex-col md:flex-row items-center w-full gap-4">
          <div className="w-full md:hidden flex justify-center my-6">
            <Link aria-label="logo" to="/">
              <img
                className=" max-w-[65px] m-auto sm:max-w-[75px]"
                src={Logo}
                alt="logo"
              />
            </Link>
          </div>
          <div className="w-[55%] hidden md:block ">
            <img
              src={AuthenticarTruck}
              alt="AuthenticarTruck"
              className="object-cover w-full h-screen object-right"
            />
          </div>
          <div className="sm:w-[62%] md:w-[51%] w-full sm:max-w-[400px] lg:max-w-[365px] mx-auto px-4 lg:px-0 ">
            <div className="bg-transparent max-w-[150px] xl:max-w-[221px] text-center ml-auto mr-auto md:ml-0 ">
              <QRCode className=" h-[150px] xl:h-[223px] w-full" value="hey" />
            </div>
            <h2 className="text-lg md:text-[32px] pt-[16px] font-bold font-Manrope text-black text-center md:text-start">
              Setup Authenticator
            </h2>

            <p className="font-Poppins font-normal text-[#020202] opacity-70 text-center pt-[16px] md:text-start">
              Use your phone to scan the following QR code with Google
              Authenticator or other Authenticator apps.
            </p>
            <p className="font-Poppins font-normal text-[#020202] opacity-70 mt-4 text-center md:text-start">
              Please enter the six-digit code from your Authenticator app
            </p>
            <form autocomplete="off" action="" className="mt-5 font-Poppins">
              <div className="otp-inputs">
                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  secure
                  required
                  className="flex gap-2 xs:gap-3  justify-center sm:justify-start"
                />
              </div>
              <div className="flex  w-full mt-4 justify-center sm:justify-start items-start">
                <input
                  className="cursor-pointer mt-[6px] "
                  id="donot-show"
                  type="checkbox"
                />
                <label
                  htmlFor="donot-show"
                  className="inline-block text-black opacity-70 ms-[5px] leading-[150%]"
                >
                  Don’t show again on this device for 14 days
                </label>
              </div>
              <button
                onClick={(e) => e.preventDefault()}
                className="first_btn sm:px-[156px] font-Manrope w-full py-[14px]  text-base font-semibold  mt-[33px]"
              >
                Done
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Authenticator;
