/* eslint-disable no-unused-vars */

import {
  all,
  actionChannel,
  call,
  put,
  take,
  takeEvery,
  takeLatest,
  select,
  cancel,
  cancelled,
  fork,
  race,
  apply,
} from "redux-saga/effects";
import prettyNum, { PRECISION_SETTING } from "pretty-num";
import * as uuid from "uuid";
import { deviceDetect } from "react-device-detect";

import { delay, buffers, eventChannel, END } from "redux-saga";
import * as _ from "lodash";
import dayjs from "dayjs";

import * as moment from "moment";
import momentTZ from "moment-timezone";

import { types as sessionTypes } from "../reducers/sessionreducer";
import { isNil, trim, cloneDeep } from "lodash";

import { types as insertedDataTypes } from "../reducers/insertedDataReducer";
import { types as referenceDataTypes } from "../reducers/referenceDataReducer";
import { types as actionDataTypes } from "../reducers/actionReducer";
import { types as subscriptionsTypes } from "../reducers/subscriptionsReducer";
import { types as customerTypes } from "../reducers/customerReducer";
import AppConfig from "../constants/AppConfig";
import { hasErrors } from "./commonSagas";
import { PNfetch } from "./../api/pnfetch";
//import { push } from 'react-router-redux';
//let socketRef;

const defaultData = {
  data: [],
  lastFetchSuccess: Date.now(),
  loading: true,
  hasLoaded: false,
  hasError: false,
};

const defaultSagaData = {
  data: [],
  message: "",
  loading: false,
  hasLoaded: true,
  hasError: false,
  lastFetchSuccess: Date.now(),
};

const headersObj = {
  Accept: "application/json",
  "Content-Type": "application/json",
  authorization: "Bearer " + localStorage.getItem("accessToken"),
};
const restApi = {
  insertSessionData(payload) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + payload.accessToken,
          // authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "spi_session_log",
          input: payload.parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  getData(payload) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + payload.accessToken,
          // authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_Reference_Data",
          input: payload.parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
  getCustomerData(parms) {
    // console.log(userData.user);
    // console.log(userData.password);

    //new Promise((resolve, reject) => {
    //return fetch("http://localhost:3003/loginsvc/", {
    // return fetch("http://hvs.selfip.net:3003/loginsvc/", {
    return (
      PNfetch(AppConfig.SQL_URL, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          authorization: "Bearer " + localStorage.getItem("accessToken"),
        },
        body: JSON.stringify({
          proc: "sps_get_CustomerData",
          input: parms,
        }),
      })
        //.then(statusHelper)
        .then((response) => response.json())
        .catch((error) => error)
    );
  },
};

function statusHelper(response) {
  if (!response.ok) {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
    //throw Error(response);
  }
  return response;
}

function* getData(userData) {
  try {
    //yield call(delay, 5000)
    // debugger;
    console.log(userData);

    //ReferenceData users are being set in the Customers Saga
    //Reset the data as we are only loading all the customer details here, with out balances
    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultData,
        message: "",
        lastFetchSuccess: Date.now(),
      },
    });

    // console.log(userData.payload.password);
    //yield put({ type: dashboardTypes.LOGIN_REQUEST, isLoading: false })

    let resultObj = yield call(restApi.getData, userData);
    if (!isNil(resultObj) && !hasErrors(resultObj)) {
      // resultObj = JSON.parse(resultObj);

      console.log(resultObj.data);

      let refData = {};
      refData.sites = resultObj.data[0].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.siteSpaces = resultObj.data[1].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.space_name +
          "~" +
          rec.space_type_name +
          "~" +
          rec.monthly_rate +
          "~" +
          rec.daily_rate +
          "~" +
          rec.weekly_rate +
          "~" +
          rec.weekend_rate +
          "~",

        ...rec,
      }));
      refData.spaceTypes = resultObj.data[2].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,

        ...rec,
      }));
      refData.roles = resultObj.data[3].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.abilities = resultObj.data[4].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionTypes = resultObj.data[5].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.name + "~" + rec.description,
        ...rec,
      }));
      refData.subscriptionStatus = resultObj.data[6].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.subscriptionRequestStatus = resultObj.data[7].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.refundStatus = resultObj.data[8].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.paymentStatus = resultObj.data[9].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      refData.invoiceStatus = resultObj.data[10].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.documentTypes = resultObj.data[11].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));
      refData.userTypes = resultObj.data[12].map((rec) => ({
        key: uuid.v4(),
        ...rec,
      }));

      //This is loaded from customer Saga

      refData.users = resultObj.data[13].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_id +
          "~" +
          rec.email +
          "~" +
          rec.phone_number +
          "~" +
          rec.first_name +
          "~" +
          rec.last_name +
          "~" +
          rec.street_address +
          "~" +
          rec.city +
          "~" +
          rec.state +
          "~" +
          rec.company_name +
          "~" +
          rec.vehicleSearch +
          "~" +
          rec.spaceNameSearch,
        ...rec,
      }));

      refData.vehicles = resultObj.data[14].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.vehicle_id +
          "~" +
          rec.user_id_fk +
          "~" +
          rec.license_num +
          "~" +
          rec.truck_num +
          "~" +
          rec.trailer_num +
          "~" +
          rec.model +
          "~" +
          rec.make +
          "~" +
          rec.color +
          "~" +
          rec.user_name +
          "~" +
          rec.mc,
        ...rec,
      }));

      refData.unitTypes = resultObj.data[15].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.cards = resultObj.data[16].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.card_brand +
          "~" +
          rec.last_4 +
          "~" +
          rec.exp_month +
          "~" +
          rec.exp_year +
          "~" +
          rec.cardholder_name,

        ...rec,
      }));

      refData.documents = resultObj.data[17].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.user_name +
          "~" +
          rec.file_type +
          "~" +
          rec.file_name +
          "~" +
          rec.document_type +
          "~" +
          rec.file_title,

        ...rec,
      }));

      refData.paymentMethods = resultObj.data[18].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.id + "~" + rec.name,
        ...rec,
      }));

      refData.spaceUsuageDetails = resultObj.data[19].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.title + "~" + rec.space_name + "~" + rec.site_name,
        ...rec,
      }));

      refData.adminSites = resultObj.data[20].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.site_name +
          "~" +
          rec.name +
          "~" +
          rec.user_role +
          "~" +
          rec.user_site_role,

        ...rec,
      }));

      refData.layouts = resultObj.data[21].map((rec) => ({
        key: uuid.v4(),
        searchText: rec.preference_key,
        ...rec,
      }));

      refData.userSites = resultObj.data[25].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      refData.PNSites = resultObj.data[26].map((rec) => ({
        key: uuid.v4(),
        searchText:
          rec.name +
          "~" +
          rec.description +
          "~" +
          rec.phone_number +
          "~" +
          rec.address_line_1 +
          "~" +
          rec.address_line_2
            ? rec.address_line_2
            : " " + "~" + rec.city + "~" + rec.state + "~",

        ...rec,
      }));

      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          data: refData,
          lastFetchSuccess: Date.now(),
        },
      });

      //Don't do this as We are loading this while loading Customers
      //Very IMP to override the customer Data
      // yield put({
      //   type: customerTypes.CUSTOMERDATA,
      //   customerData: {
      //     ...defaultSagaData,
      //     data: cloneDeep(refData.users),
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
    } else {
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "",
          lastFetchSuccess: Date.now(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultSagaData,
        message: e,
        lastFetchSuccess: Date.now(),
      },
    });
  } finally {
    if (yield cancelled())
      yield put({
        type: referenceDataTypes.REFERENCEDATA,
        referenceData: {
          ...defaultSagaData,
          message: "Task Cancelled",
          lastFetchSuccess: Date.now(),
        },
      });
  }
}

function* resetLoginData(userData) {
  try {
    //yield call(delay, 5000)
    yield put({
      type: customerTypes.CUSTOMERDATA,
      customerData: {
        ...defaultSagaData,
        data: cloneDeep([]),
        lastFetchSuccess: Date.now(),
      },
    });
    const getReferenceData = (state) => state.referenceData.data;
    const referenceDataCurrent = yield select(getReferenceData);
    referenceDataCurrent.users = cloneDeep([]);

    yield put({
      type: referenceDataTypes.REFERENCEDATA,
      referenceData: {
        ...defaultSagaData,
        data: cloneDeep(referenceDataCurrent),
        lastFetchSuccess: Date.now(),
      },
    });
  } catch (e) {
    yield put({ type: sessionTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: sessionTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* setLoginData(userData) {
  try {
    //yield call(delay, 5000)

    console.log("Data Sent Up:", userData.parms);
    // debugger;
    const getNetworkData = (state) => state.networkData.data;
    const networkData = yield select(getNetworkData);
    console.log("networkData", networkData);

    const device = deviceDetect();
    const currentTime = new Date();
    console.log("device", device);
    console.log(
      "TZ",
      !isNil(Intl.DateTimeFormat().resolvedOptions().timeZone)
        ? Intl.DateTimeFormat().resolvedOptions().timeZone
        : momentTZ.tz.guess(true)
    );
    console.log("currentUserLocalUTCTime", currentTime);
    console.log(
      "currentUserLocalTIme",
      moment.utc(currentTime).local().format("YYYY-MM-DD HH:mm:ss")
    );

    // const role = userData.parms.user.role.id;
    let site_id = -1,
      user_id = -1,
      session_user_id = -1,
      role = 4;

    session_user_id = userData.parms.user.user_id;
    role = userData.parms.user.role;

    const payload = {
      // parms: [session_user_id, "Y"],
      parms: [session_user_id],
      accessToken: userData.parms.access_token,
    };

    //  need to do this for Regular Login
    // initial loading of the store -- InitloadSagas is taking care of it (This is when you do Hard refresh)
    //***IMP IMP */
    const fetchTask = yield fork(getData, payload);
    console.log("*****role********", role);

    const sessionPayload = {
      parms: [
        session_user_id,
        "-1",
        JSON.stringify(networkData),
        JSON.stringify(device),
        currentTime,
        moment.utc(currentTime).local().format("YYYY-MM-DD HH:mm:ss"),
        !isNil(Intl.DateTimeFormat().resolvedOptions().timeZone)
          ? Intl.DateTimeFormat().resolvedOptions().timeZone
          : momentTZ.tz.guess(true),
        "N",
        "N",
        "N", //Mobile
        "",
      ],
      accessToken: userData.parms.access_token,
    };

    // debugger;
    const sessionId = yield call(setLoginSessionData, sessionPayload);
    console.log("*****sessionId********", sessionId);
    localStorage.setItem("session_id", sessionId);
    // if (role == 4) {
    //   user_id = userData.parms.user_id;
    // }

    // debugger;
    yield put({
      type: sessionTypes.SESSIONDATA,
      sessionData: {
        ...defaultSagaData,
        data: {
          site_id,
          user_id: userData.parms.user.role == "4" ? session_user_id : user_id,
          role,
          session_user_id: session_user_id,
          default_site_id: userData.parms.user.default_site_id,
          user: userData.parms.user,
          userEntitlements: userData.parms.userEntitlements,
          sessionId,
        },
        lastFetchSuccess: Date.now(),
      },
    });
  } catch (e) {
    yield put({ type: sessionTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: sessionTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}
function* setLoginSessionData(userData) {
  try {
    //yield call(delay, 5000)

    console.log("Data Sent Up:", userData.parms);
    let resultObj = yield call(restApi.insertSessionData, userData);
    console.log("resultObj", resultObj);
    //// debugger;
    if (resultObj) {
      // resultObj = JSON.parse(resultObj);
      // const data =
      //   resultObj.data.length > 0 ? resultObj.data[0] : resultObj.data;
      //// debugger;
      console.log("data", resultObj.data);
      console.log("sessionObj", resultObj.data[0][0]);
      // const dataEnriched = data.map((rec) => ({
      //   key: uuid.v4(),
      //   ...rec,
      // }));

      return resultObj.data[0][0]?.session_id;

      // yield put({
      //   type: expensesTypes.EXPENSESDATA,
      //   expensesData: {
      //     ...defaultSagaData,
      //     data: dataEnriched,
      //     lastFetchSuccess: Date.now(),
      //   },
      // });
    } else {
      console.log("Blank****");
      // yield put({
      //   type: expensesTypes.MESSAGE,
      //   message: "",
      // });
    }
  } catch (e) {
    yield put({ type: sessionTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: sessionTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

function* setData(userData) {
  try {
    //yield call(delay, 5000)

    console.log("Data Sent Up:", userData.parms);

    const getSessionData = (state) => state.sessionData.data;
    const sessionData = yield select(getSessionData);

    //// debugger;
    yield put({
      type: sessionTypes.SESSIONDATA,
      sessionData: {
        ...defaultSagaData,
        data: {
          site_id: userData.parms.site_id,
          user_id: userData.parms.user_id,
          session_user_id: sessionData.session_user_id,
          role: sessionData.role, //role belongs to the the user who logged in, not the user selected from the session dropdown
          default_site_id: sessionData.default_site_id,
          user: sessionData.user,
          userEntitlements: sessionData.userEntitlements,
          sessionId: sessionData.sessionId,
          // role:
          //   !isNil(userData.parms.user) && !isNil(userData.parms.user.role)
          //     ? userData.parms.user.role.id
          //     : 4,
        },
        lastFetchSuccess: Date.now(),
      },
    });
  } catch (e) {
    yield put({ type: sessionTypes.MESSAGE, message: e });
  } finally {
    if (yield cancelled())
      yield put({
        type: sessionTypes.MESSAGE,
        message: "Task Cancelled",
      });
  }
}

export function* handleRequest(action) {
  console.log("authSaga request", action);
  console.log(action.payload);
  //yield put({ type: "ITEMS_IS_LOADING", isLoading: true });
  //yield call(updateStatus);
  try {
    switch (action.type) {
      case sessionTypes.SET_SESSION_DATA_REQUEST: {
        const fetchTask = yield fork(setData, action.payload);
        break;
      }
      case sessionTypes.SET_LOGIN_SESSION_DATA_REQUEST: {
        const fetchTask = yield fork(setLoginData, action.payload);
        break;
      }
      case sessionTypes.RESET_LOGIN_SESSION_DATA_REQUEST: {
        const fetchTask = yield fork(resetLoginData, action.payload);
        break;
      }
      default: {
        return null;
        break;
      }
    }
  } catch (e) {
    yield put({ type: sessionTypes.LOGIN_FAILURE, error: e });
  }
}
